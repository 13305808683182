import React, { useState, useEffect } from "react";
import { ReactComponent as FilterIcon } from "../../../../../../assets/images/filter.svg";
import { ReactComponent as Close } from "../../../../../../assets/images/close.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { setService } from "../../../../../../services";
import {
	bookingService,
	kitService,
	surgeonService,
	operationService,
} from "../../../../../../services";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

const Filter = ({
	handleFilterToggle,
	filterModal,
	filterCreateDate,
	filterPoChase,
	handleFilterRefreesh,
}) => {
	const [filterToggle, setFilterToggle] = useState([
		{ label: "All", value: "all" },
	]);
	const [options, setOptions] = useState([
		{ label: "All", value: "all" },
		{ label: "Checked", value: "checked" },
		{ label: "Unchecked", value: "unchecked" },
	]);
	const [createDate, setCreateDate] = useState("");
	const [selectedHospital, setSelectedHospital] = useState([
		{ label: "All Hospital", value: "" },
	]);
	const [hospitalId, setHospitalId] = useState("");
	const [selectedKit, setSelectedKit] = useState([]);
	const [selectedSurgeon, setSelectedSurgeon] = useState([
		{ label: "All Surgeon", value: "" },
	]);
	const [surgeonId, setSurgeonId] = useState("");
	const [selectedOperation, setSelectedOperation] = useState([
		{ label: "All Operation", value: "" },
	]);
	const [operationId, setOperationId] = useState("");
	const [selectedKitForSets, setSelectedKitForSets] = useState("");
	const [optionStatus, setOptionStatus] = useState([
		{ label: "All Status", value: "" },
		{ label: "Booked", value: "booked" },
		{ label: "Dispatched", value: "dispatch" },
	]);
	const [selectedStatus, setSelectedStatus] = useState({
		label: "All Status",
		value: "",
	});
	const [sets, setSetsList] = useState([]);
	const [selectedSet, setSelectedSet] = useState([]);
	const [hospitals, setHospitalList] = useState([]);
	const [operationsList, setOperationsList] = useState([]);
	const [kits, setKits] = useState([]);
	const [surgeons, setSurgeons] = useState([]);

	const getSurgeonListDropdown = async () => {
		try {
			const res = await surgeonService.getSurgeonsList({ search: "" });

			let surgeonList = res?.data ? res?.data : [];
			let tempSurgeonList = [{ label: "All Surgeon", value: "" }];
			surgeonList &&
				surgeonList.map((item) => {
					let option = {
						value: item._id,
						label: item.first_name + " " + item.last_name,
						notes: item?.notes ? item?.notes : "",
					};
					tempSurgeonList.push(option);
				});
			setSurgeons(tempSurgeonList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const showHospitalsList = (hospitalsList) => {
		let tempHospitals = [{ label: "All Hospital", value: "" }];
		hospitalsList.map((item) => {
			let option = { value: item._id, label: item.name };
			tempHospitals.push(option);
		});
		setHospitalList(tempHospitals);
	};

	const getKitListDropdown = async () => {
		try {
			const res = await kitService.kitListDropdown({ search: "" });

			let kitList = res?.data ? res?.data : [];
			let tempKitList = [];

			kitList?.length &&
				kitList.map((item) => {
					var option = { value: item._id, label: item.name };
					tempKitList.push(option);
				});

			setKits(tempKitList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getOperationListDropdown = async () => {
		try {
			const res = await operationService.getOperationsList({
				search: "",
			});

			let operationList = res?.data ? res?.data : [];
			let tempOperationList = [{ label: "All Operation", value: "" }];
			operationList &&
				operationList.map((item) => {
					let option = {
						value: item.name,
						label: item.name,
						id: item._id,
					};
					tempOperationList.push(option);
				});
			setOperationsList(tempOperationList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getHospitalList = async () => {
		try {
			const res = await bookingService.getHospitalsList({ search: "" });
			if (res.status === 200) {
				showHospitalsList(res?.data);
			}
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	useEffect(() => {
		getHospitalList();
		getKitListDropdown();
		getSurgeonListDropdown();
		getOperationListDropdown();
	}, []);

	const handleFilter = () => {
		let selectdKitValue = "";
		let selectdSetValue = "";

		selectedKit?.length &&
			selectedKit.map((task) => {
				if (selectdKitValue != "") {
					selectdKitValue += ",";
				}
				selectdKitValue += task.value;
			});

		selectedSet?.length &&
			selectedSet.map((set) => {
				if (selectdSetValue != "") {
					selectdSetValue += ",";
				}
				selectdSetValue += set.value;
			});

		console.log("new---00111", selectedStatus.value);

		handleFilterRefreesh({
			filterPoChase: filterToggle[0].value,
			filterCreateDate:
				createDate != "" ? moment(createDate).format("YYYY-MM-DD") : "",
			hospitalId: hospitalId,
			selectedHospitalProp: selectedHospital,
			selectdKitValue: selectdKitValue,
			selectedKitProp: selectedKit,
			surgeonId: surgeonId,
			selectedSurgeonProp: selectedSurgeon,
			operationId: operationId,
			selectedOperationProp: selectedOperation,
			selectdSetValue: selectdSetValue,
			selectedStatus: selectedStatus.value,
		});
	};

	const handlePO = (e) => {
		setFilterToggle([e]);
	};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const handleCreateDate = (val) => {
		setCreateDate(val != null ? new Date(val) : "");
	};

	const handleFilterClear = () => {
		setCreateDate("");
		setFilterToggle([{ label: "All", value: "all" }]);
		setSelectedHospital([{ label: "All Hospital", value: "" }]);
		setSelectedSurgeon([{ label: "All Surgeon", value: "" }]);
		setSelectedOperation([{ label: "All Operation", value: "" }]);
		setSelectedKit([]);
		setHospitalId("");
		setSurgeonId("");
		setOperationId("");
		setSelectedSet([]);
		setSetsList([]);
		setSelectedKitForSets("");
		setSelectedStatus({ label: "All Status", value: "" });
		handleFilterRefreesh({
			filterPoChase: "all",
			filterCreateDate: "",
			hospitalId: "",
			selectedHospitalProp: [{ label: "All Hospital", value: "" }],
			selectdKitValue: "",
			selectedKitProp: [],
			surgeonId: "",
			selectedSurgeonProp: [{ label: "All Surgeon", value: "" }],
			operationId: "",
			selectedOperationProp: [
				{ label: "All Operation", value: "", selectdSetValue: "" },
			],
			selectdSetValue: "",
			selectedStatus: "",
		});
	};

	useEffect(() => {
		console.log(setOptions);
		console.log(setOptionStatus);
		setFilterToggle([
			{
				label: capitalizeFirstLetter(filterPoChase),
				value: filterPoChase,
			},
		]);
		setCreateDate(filterCreateDate != "" ? new Date(filterCreateDate) : "");
		setSelectedHospital(selectedHospital);
		setSelectedKit(selectedKit);
		setSelectedSurgeon(selectedSurgeon);
		setSelectedOperation(selectedOperation);
	}, [filterModal]);

	const setSelectedOptionHospital = (e) => {
		let selectedHospitalValue = [{ value: e.value, label: e.label }];
		setHospitalId(e.value);
		setSelectedHospital(selectedHospitalValue);
	};

	const setSelectedOptionKit = (selectedOptionKit) => {
		let selectdKitValue = "";

		selectedOptionKit?.length &&
			selectedOptionKit.map((task) => {
				if (selectdKitValue != "") {
					selectdKitValue += ",";
				}
				selectdKitValue += task.value;
			});

		setSetsList([]);
		setSelectedSet([]);

		setSelectedKitForSets(selectdKitValue);

		setSelectedKit(selectedOptionKit);
	};

	const setSelectedOptionSurgoen = (selectedOptionSurgeon) => {
		setSurgeonId(selectedOptionSurgeon.value);
		setSelectedSurgeon(selectedOptionSurgeon);
	};

	const setSelectedOptionOperation = (selectedOptionOperation) => {
		setOperationId(selectedOptionOperation.value);
		setSelectedOperation(selectedOptionOperation);
	};

	useEffect(() => {
		if (selectedKitForSets !== "" && selectedKit?.length) {
			getSetsUsingKitIdForBookingFilter();
		}
	}, [selectedKitForSets]);

	const setSelectedOptionSet = (selectedOptionSet) => {
		setSelectedSet(selectedOptionSet);
	};

	const handleStatus = (e) => {
		setSelectedStatus(e);
	};

	const getSetsUsingKitIdForBookingFilter = async () => {
		try {
			const res = await setService.getSetsUsingKitIdForBookingFilter({
				kitId: selectedKitForSets,
			});

			let setsList = res?.data ? res?.data : [];
			let tempSetsList = [];
			setsList &&
				setsList.map((item) => {
					let option = {
						value: item._id,
						label: item.set_name,
						id: item._id,
						kitID: item.kitID,
					};
					tempSetsList.push(option);
				});
			setSetsList(tempSetsList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	return (
		<>
			<div
				className={
					"filter_section" + (filterModal == false ? " hide " : "")
				}
			>
				<div className="card">
					<div className="card-header">
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h5 className="mb-0">
									<FilterIcon className="img-svg"></FilterIcon>
									<span>Filters</span>
								</h5>
							</div>

							<div className="d-flex align-items-center">
								<a
									className="mr-3"
									onClick={() => handleFilterClear()}
									href="javascript:;"
								>
									<span>Clear Filters</span>
								</a>
								<Close
									onClick={() => handleFilterToggle()}
									className="img-svg"
								></Close>
							</div>
						</div>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-6">
								<div
									className="mb-3 text-left position_up"
									style={{ zIndex: 10 }}
								>
									<label className="form-label">
										Chase PO Number
									</label>
									<Select
										value={filterToggle}
										onChange={(e) => handlePO(e)}
										options={options}
									/>
								</div>
							</div>
							<div className="col-6">
								<div
									className="mb-3 text-left position_up"
									style={{ zIndex: 10 }}
								>
									<label className="form-label">
										Create Date
									</label>
									<DatePicker
										dateFormat="dd/MM/yyyy"
										showClearButton={false}
										placeholderText="Select Date"
										selected={createDate}
										onChange={(e) => handleCreateDate(e)}
										className="form-control"
										customInput={
											<MaskedInput
												pipe={autoCorrectedDatePipe}
												mask={[
													/\d/,
													/\d/,
													"/",
													/\d/,
													/\d/,
													"/",
													/\d/,
													/\d/,
													/\d/,
													/\d/,
												]}
												keepCharPositions={true}
												guide={true}
												showMask={false}
											/>
										}
									/>
								</div>
							</div>

							<div className="col-lg-6  col-md-6 col-12">
								<div
									className="mb-3 text-left position_up"
									style={{ zIndex: 9 }}
								>
									<label className="form-label">
										All Hospital
									</label>
									<Select
										value={selectedHospital}
										onChange={(e) =>
											setSelectedOptionHospital(e)
										}
										options={hospitals}
									/>
								</div>
							</div>

							<div className="col-lg-6  col-md-6 col-12">
								<div
									className="mb-3 text-left position_up"
									style={{ zIndex: 8 }}
								>
									<label>Operation Name</label>
									<Select
										value={selectedOperation}
										onChange={(e) =>
											setSelectedOptionOperation(e)
										}
										options={operationsList}
									/>
								</div>
							</div>

							<div className="col-lg-6  col-md-6 col-12">
								<div
									className="mb-3 text-left react-multiselect-checkbox"
									style={{ zIndex: 7 }}
								>
									<label className="form-label">
										All Kits
									</label>
									<ReactMultiSelectCheckboxes
										placeholderButtonLabel="All Kits"
										value={selectedKit}
										onChange={setSelectedOptionKit}
										className={""}
										options={kits}
										placeholder="All Kits"
									/>
								</div>
							</div>

							<div className="col-lg-6 col-md-6 col-12">
								<div
									className="mb-3 text-left react-multiselect-checkbox"
									style={{ zIndex: 6 }}
								>
									<label className="form-label">
										Sets List
									</label>
									<ReactMultiSelectCheckboxes
										placeholderButtonLabel="Sets List"
										value={selectedSet}
										onChange={setSelectedOptionSet}
										className={""}
										options={sets}
										placeholder="Sets List"
									/>
								</div>
							</div>

							<div className="col-lg-6 col-md-6 col-12">
								<div
									className="mb-3 text-left position_up"
									style={{ zIndex: 1 }}
								>
									<label className="form-label">
										All Surgoen
									</label>
									<Select
										value={selectedSurgeon}
										onChange={(e) =>
											setSelectedOptionSurgoen(e)
										}
										options={surgeons}
										placeholder="All Surgoen"
									/>
								</div>
							</div>

							<div className="col-lg-6 col-md-6 col-12">
								<div
									className="mb-3 text-left position_up"
									style={{ zIndex: 1 }}
								>
									<label className="form-label">
										All Status
									</label>
									<Select
										value={selectedStatus}
										onChange={(e) => handleStatus(e)}
										options={optionStatus}
										placeholder="All Status"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer text-right">
						<a
							onClick={() => handleFilter()}
							className="btn btn-sm btn-primary"
						>
							Apply
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Filter;
