import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { setService } from "../../../../services";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

const ConsignmentSidebar = ({ filterCreateDate,
    filterPoChase,
    kits, selectedKitProp,
    surgeons, selectedSurgeonProp,
    operationsList, selectedOperationProp,handleFilterRefreesh,
    selectedFilterOperationToDate,selectedFilterOperationFromDate,
    hospitals,selectedHospitalProp }) => {

    const [filterToggle, setFilterToggle] = useState([{ label: "All", value: "all" }]);
    const [options, setOptions] = useState([{ label: "All", value: "all" }, { label: "Checked", value: "checked" }, { label: "Unchecked", value: "unchecked" }]);
    const [createDate, setCreateDate] = useState("");
    const [selectedKit, setSelectedKit] = useState([]);
    const [selectedSurgeon, setSelectedSurgeon] = useState([{ label: "All Surgeon", value: "" }]);
    const [surgeonId, setSurgeonId] = useState("");
    const [selectedOperation, setSelectedOperation] = useState([{ label: "All Operation", value: "" }]);
    const [operationId, setOperationId] = useState("");
    const [selectedKitForSets, setSelectedKitForSets] = useState("");

    const [sets, setSetsList] = useState([]);
    const [selectedSet, setSelectedSet] = useState([]);

    const [filterOperationToDate, setfilterOperationToDate] = useState("");
    const [filterOperationFromDate, setfilterOperationFromDate] = useState("");
    const [searchOperationToDate, setSearchOperationToDate] = useState("");
    const [searchOperationFromDate, setSearchOperationFromDate] = useState("");

    const [selectedHospital,setSelectedHospital] = useState([{label:"All Hospital",value:""}]);
    const [hospitalId,setHospitalId] = useState("");

    const [createDateZIndex, setCreateDateZIndex] = useState(4);
    const [operationZIndex, setOperationZIndex] = useState(6);

    const handleFilter = () => {
        let selectdKitValue = "";
        let selectdSetValue = "";

        selectedKit?.length && selectedKit.map((task) => {
            if (selectdKitValue != "") {
                selectdKitValue += ",";
            }
            selectdKitValue += task.value;
        });

        selectedSet?.length && selectedSet.map((set) => {
            if (selectdSetValue != "") {
                selectdSetValue += ",";
            }
            selectdSetValue += set.value;
        });

        handleFilterRefreesh({
            filterPoChase: filterToggle[0].value,
            filterCreateDate: createDate != "" ? moment(createDate).format("YYYY-MM-DD") : "",
            selectdKitValue: selectdKitValue,
            selectedKitProp: selectedKit,
            surgeonId: surgeonId,
            selectedSurgeonProp: selectedSurgeon,
            operationId: operationId,
            selectedOperationProp: selectedOperation,
            selectdSetValue: selectdSetValue,
            sideBarFilterOperationToDate : filterOperationToDate,
            sideBarFilterOperationFromDate : filterOperationFromDate,
            selectedHospitalProp:selectedHospital,
            hospitalId : hospitalId,
        });
    };

    const handlePO = (e) => {

        setFilterToggle([e]);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    const handleCreateDate = (val) => {
        setCreateDate(val != null ? new Date(val) : "");
    };

    const handleFilterClear = () => {
        setCreateDate("");
        setFilterToggle([{ label: "All", value: "all" }]);
        setSelectedSurgeon([{ label: "All Surgeon", value: "" }]);
        setSelectedOperation([{ label: "All Operation", value: "" }]);
        setSelectedHospital([{label:"All Hospital",value:""}]);
        setSelectedKit([]);
        setSurgeonId("");
        setOperationId("");
        setSelectedSet([]);
        setSetsList([]);
        setHospitalId("");
        setfilterOperationToDate("");
        setfilterOperationFromDate("");
        setSearchOperationToDate("");
        setSearchOperationFromDate("");
        handleFilterRefreesh({
            filterPoChase: "all", filterCreateDate: "",
            selectdKitValue: "", selectedKitProp: [],
            surgeonId: "", selectedSurgeonProp: [{ label: "All Surgeon", value: "" }],
            operationId: "", selectedOperationProp: [{label: "All Operation", value: "",selectdSetValue: ""}],
            selectdSetValue: "",
            sideBarFilterOperationToDate:"",sideBarFilterOperationFromDate:"",
            hospitalId : "",selectedHospitalProp:[{label:"All Hospital",value:""}],
        });
    };

    useEffect(() => {
        console.log(setOptions,surgeonId,operationId);
        setFilterToggle([{ label: capitalizeFirstLetter(filterPoChase), value: filterPoChase }]);
        setCreateDate(filterCreateDate != "" ? new Date(filterCreateDate) : "");
        setSelectedKit(selectedKitProp);
        setSelectedSurgeon(selectedSurgeonProp);
        setSelectedOperation(selectedOperationProp);
        setSearchOperationToDate(selectedFilterOperationToDate);
        setSearchOperationFromDate(selectedFilterOperationFromDate);
        setSelectedHospital(selectedHospitalProp);
    }, []);

    const setSelectedOptionKit = (selectedOptionKit) => {

        let selectdKitValue = "";

        selectedOptionKit?.length && selectedOptionKit.map((task) => {
            if (selectdKitValue != "") {
                selectdKitValue += ",";
            }
            selectdKitValue += task.value;
        });
        console.log(kits,sets);
        setSelectedKit(selectedOptionKit);
        setSetsList([]);
        setSelectedSet([]);

        setSelectedKitForSets(selectdKitValue);


    };

    const setSelectedOptionSurgoen = (selectedOptionSurgeon) => {
        setSurgeonId(selectedOptionSurgeon.value);
        setSelectedSurgeon(selectedOptionSurgeon);
    };

    const setSelectedOptionOperation = (selectedOptionOperation) => {
        setOperationId(selectedOptionOperation.value);
        setSelectedOperation(selectedOptionOperation);
    };

    useEffect(() => {
        if (selectedKitForSets !== "" && selectedKit?.length) {
            getSetsUsingKitIdForBookingFilter();
        }
    }, [selectedKitForSets]);

    const setSelectedOptionSet = (selectedOptionSet) => {
        setSelectedSet(selectedOptionSet);
    };

    const getSetsUsingKitIdForBookingFilter = async () => {
        try {

            const res = await setService.getSetsUsingKitIdForBookingFilter({ kitId: selectedKitForSets });

            let setsList = res?.data ? res?.data : [];
            let tempSetsList = [];
            setsList && setsList.map(item => {
                let option = { value: item._id, label: item.set_name, id: item._id, kitID: item.kitID };
                tempSetsList.push(option);
            });
            setSetsList(tempSetsList);

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleSearchOperationFromDate = (val) => {
        setSearchOperationFromDate(val != null ? new Date(val) : "");
        setfilterOperationFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
    };

    const handleSearchOperationToDate = (val) => {
        setSearchOperationToDate(val != null ? new Date(val) : "");
        setfilterOperationToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
    };

    const setSelectedOptionHospital = (e) => {
        let selectedHospitalValue=[{value:e.value, label:e.label}];
        setHospitalId(e.value);
        setSelectedHospital(selectedHospitalValue);
    };


    return (
        <div className="" >

            <div className="card-body">
                <div className="row no-gutters">

                <div className="col-12">
                    <div className="mb-3 text-left position_up" style={{ zIndex: 12 }}>
                        <label className="form-label">Hospital Name</label>
                        <Select
                            value={selectedHospital}
                            onChange={(e) => setSelectedOptionHospital(e)}
                            options={hospitals}

                        />
                    </div>
                </div>

                <div className="col-12" style={{ zIndex: 11 }}>
                        <div className="mb-3 text-left react-multiselect-checkbox" >
                            <label className="form-label">Kits List</label>
                            <ReactMultiSelectCheckboxes
                                placeholderButtonLabel="All Kits"
                                value={selectedKit}
                                onChange={setSelectedOptionKit}
                                className={""}
                                options={kits}
                                placeholder="All Kits"
                            />
                        </div>
                    </div>

                    <div className="col-12" style={{ zIndex: 10 }}>
                        <div className="mb-3 text-left react-multiselect-checkbox" >
                            <label className="form-label">Sets List</label>
                            <ReactMultiSelectCheckboxes
                                placeholderButtonLabel="Sets List"
                                value={selectedSet}
                                onChange={setSelectedOptionSet}
                                className={""}
                                options={sets}
                                placeholder="Sets List"
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="mb-3 text-left position_up" style={{ zIndex: 8 }}>
                            <label>Operation Name</label>
                            <Select
                                value={selectedOperation}
                                onChange={(e) => setSelectedOptionOperation(e)}
                                options={operationsList}
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="mb-3 text-left position_up" style={{ zIndex: 7 }}>
                            <label className="form-label">Surgeon Name</label>
                            <Select
                                value={selectedSurgeon}
                                onChange={(e) => setSelectedOptionSurgoen(e)}
                                options={surgeons}
                                placeholder="All Surgeon"
                            />
                        </div>
                    </div>

                    <label className="form-label">Collection Date</label>
                    <div className="row position_up" style={{ zIndex: operationZIndex }}>
                        <div className="col-12 mb-2">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                showClearButton={false}
                                placeholderText="From"
                                selected={searchOperationFromDate}
                                onChange={(e) => handleSearchOperationFromDate(e)}
                                onFocus={()=> setOperationZIndex(19)}
                                onCalendarClose={()=> setOperationZIndex(6)}
                                className="form-control"
                                customInput={
                                    <MaskedInput
                                        pipe={autoCorrectedDatePipe}
                                        mask={[
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        keepCharPositions={true}
                                        guide={true}
                                        showMask={false}
                                    />
                                }
                            />
                        </div>

                        <div className="col-12 mb-2">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                showClearButton={false}
                                placeholderText="To"
                                selected={searchOperationToDate}
                                onChange={(e) => handleSearchOperationToDate(e)}
                                onFocus={()=> setOperationZIndex(19)}
                                onCalendarClose={()=> setOperationZIndex(6)}
                                className="form-control"
                                customInput={
                                    <MaskedInput
                                        pipe={autoCorrectedDatePipe}
                                        mask={[
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        keepCharPositions={true}
                                        guide={true}
                                        showMask={false}
                                    />
                                }
                            />
                        </div>
                    </div>


                    <div className="col-12">
                        <div className="mb-3 text-left position_up" style={{ zIndex: 5 }}>
                            <label className="form-label ">Chase PO Number</label>
                            <Select
                                value={filterToggle}
                                onChange={(e) => handlePO(e)}
                                options={options}

                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="mb-3 text-left position_up" style={{ zIndex: createDateZIndex }}>
                            <label className="form-label">Create Date</label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                showClearButton={false}
                                placeholderText="Select Date"
                                selected={createDate}
                                onChange={e =>
                                    handleCreateDate(
                                        e,
                                    )
                                }
                                onFocus={()=> setCreateDateZIndex(20)}
                                onCalendarClose={()=> setCreateDateZIndex(4)}
                                className="form-control"
                                customInput={
                                    <MaskedInput
                                        pipe={autoCorrectedDatePipe}
                                        mask={[
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        keepCharPositions={true}
                                        guide={true}
                                        showMask={false}
                                    />
                                }
                            />
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="card-footer text-right">
                <a className="mr-3" onClick={()=>handleFilterClear()}   href="javascript:;"> 
                    <span>Clear Filters</span> 
                </a>
                <a onClick={()=>handleFilter()} className="btn btn-sm btn-primary">Apply</a>
            </div>

        </div>
    );
};
export default ConsignmentSidebar;

