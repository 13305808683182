import { authHeader,check419 } from "../helpers";
export const returnBookingService = {
    getList,
    countByDate,
    uCountByDate,
    getUnprocessedList,
    getUnprocessedExport,
    getBookingReturnListExport
};

function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingReturnList", requestOptions).then(handleResponse)
        .then(returnList => {
            return returnList;
        });
}

function getUnprocessedList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getPastBookingReturnList", requestOptions).then(handleResponse)
        .then(returnList => {
            return returnList;
        });
}

function getUnprocessedExport(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getPastBookingReturnListExport", requestOptions).then(handleResponse)
        .then(returnList => {
            return returnList;
        });
}


function getBookingReturnListExport(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingReturnListExport", requestOptions).then(handleResponse)
        .then(returnList => {
            return returnList;
        });
}

function countByDate(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/countBookingReturn", requestOptions).then(handleResponse)
        .then(count => {
            return count;
        });
}

function uCountByDate(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/countPastBookingReturn", requestOptions).then(handleResponse)
        .then(count => {
            return count;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            } else if(response.status === 419){
                check419();
            }
        }

        return data;
    });
}