import React from "react";
import moment from "moment";
import dateFormat from "dateformat";

export default function BookedByDetails({ bookingInfo }) {
  console.log("ayushi", bookingInfo);
  return (
    <div className="d-card">
      <h4>Booked By</h4>
      <div>
        <span>
          Booking Date:{" "}
          <strong>{moment(bookingInfo.createdAt).format("DD MMM YYYY")}</strong>
        </span>
      </div>
      <div>
        <span>
          Status:{" "}
          <strong>
            {bookingInfo.status == "dispatch"
              ? "Dispatched"
              : bookingInfo.status == "partial-dispatch"
              ? "Partially Dispatched"
              : bookingInfo.status == "reject"
              ? "Rejected"
              : bookingInfo.status == "close"
              ? "Closed"
              : bookingInfo.status == "booked"
              ? "Booked"
              : bookingInfo.status == "pending"
              ? "Pending"
              : bookingInfo.status == "cancel"
              ? "Cancelled"
              : bookingInfo.status}
          </strong>
        </span>
      </div>
      <div>
        <span>
          Created By:{" "}
          <strong>
            {bookingInfo.createdData && bookingInfo.createdData[0]
              ? `${bookingInfo.createdData[0].first_name} ${bookingInfo.createdData[0].last_name}`
              : ""}
          </strong>
        </span>
      </div>
      <div>
        <span>
          Delivery Date:{" "}
          <strong>
            {bookingInfo.delivery_date
              ? dateFormat(bookingInfo.delivery_date, "ddd, mmm dS")
              : ""}
          </strong>
        </span>
      </div>

      <div className="form-group">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <span style={{ whiteSpace: "nowrap" }}>Note: </span>
          <span
            style={{
              marginLeft: "0.5rem",
              textIndent: "0",
              whiteSpace: "normal",
              display: "inline-block",
            }}
          >
            <strong>{bookingInfo.note ? bookingInfo.note : ""}</strong>
          </span>
        </div>
      </div>
    </div>
  );
}
