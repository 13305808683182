import { bookingRequestConstants } from "../constants";

const initialState =  { loading: null, lists: null, } || {};

export function bookingRequests(state = initialState, action) {
    switch (action.type) {
    case bookingRequestConstants.GETALLBOOKINGSDISPATCH_REQUEST:
        return {
            loading: true
        };
    case bookingRequestConstants.GETALLBOOKINGSDISPATCH_SUCCESS:
        return {
            loading: false,
            bookingListReq: action.bookingList.data,
            activePage: action.bookingList.page,
            totalItemsCount: action.bookingList.total_count,
            limit: action.bookingList.page_count
        };
    case bookingRequestConstants.GETALLBOOKINGSDISPATCH_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case bookingRequestConstants.BOOKINGACCEPTSTATUSUPDATE_REQUEST:
        return {
            loading: true,
        };
    case bookingRequestConstants.BOOKINGACCEPTSTATUSUPDATE_SUCCESS:
        return {
            loading: false,
            redirect:true
        };
    case bookingRequestConstants.BOOKINGACCEPTSTATUSUPDATE_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case bookingRequestConstants.BOOKINGREJECTSTATUSUPDATE_REQUEST:
        return {
            loading: true,
        };
    case bookingRequestConstants.BOOKINGREJECTSTATUSUPDATE_SUCCESS:
        return {
            loading: false,
            redirect:true
        };
    case bookingRequestConstants.BOOKINGREJECTSTATUSUPDATE_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    default:
        return state;
    }
}