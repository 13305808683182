export const kitConstants = {
    KITADD_REQUEST: "KIT_ADD_REQUEST",
    KITADD_SUCCESS: "KIT_ADD_SUCCESS",
    KITADD_FAILURE: "KIT_ADD_FAILURE",

    KITEDIT_REQUEST: "KIT_EDIT_REQUEST",
    KITEDIT_SUCCESS: "KIT_EDIT_SUCCESS",
    KITEDIT_FAILURE: "KIT_EDIT_FAILURE",

    KITUPDATE_REQUEST: "KIT_UPDATE_REQUEST",
    KITUPDATE_SUCCESS: "KIT_UPDATE_SUCCESS",
    KITUPDATE_FAILURE: "KIT_UPDATE_FAILURE",

    GETALLKITS_REQUEST: "KITS_GETALL_REQUEST",
    GETALLKITS_SUCCESS: "KITS_GETALL_SUCCESS",
    GETALLKITS_FAILURE: "KITS_GETALL_FAILURE",


    GETDROPDOWNKITSLIST_REQUEST: "KITS_GETDROPDOWN_REQUEST",
    GETDROPDOWNKITSLIST_SUCCESS: "KITS_GETDROPDOWN_SUCCESS",
    GETDROPDOWNKITSLIST_FAILURE: "KITS_GETDROPDOWN_FAILURE",

    DELETE_REQUEST: "KITS_DELETE_REQUEST",
    DELETE_SUCCESS: "KITS_DELETE_SUCCESS",
    DELETE_FAILURE: "KITS_DELETE_FAILURE"    
};
