import "jspdf-autotable";
import * as excelJs from "exceljs";
export const exportXls = {
    handleExportClick
};

async function handleExportClick(data,headingContent){
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Return Worksheet");
    // Add a header row with a merged cell for the heading
    const headingRow = ws.addRow([headingContent]);
    headingRow.getCell(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
    };

    headingRow.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 13,
        };
    });

    ws.mergeCells("A1:D1"); // Merge cells for the heading
    
    const header1 = [
        "HOSPITAL NAME",
        "BOOKING ID",
        "BOXES",
        "SET",
        "RECEIVED (Y/N)",
        "SEND BOX (Y/N)",
        "COMMENT",
        "CHECK SET"
    ];
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
        };
        cell.border = {
            top: {style:"thin"},
            left: {style:"thin"},
            bottom: {style:"thin"},
            right: {style:"thin"}
        };
    });

    if (data?.length) {
        var kitset = [];
        var boxesSet = "";
        let rowIndex = 2; // Start from row index 2 (after the header row)
        data && data.map(item => {
            kitset = [];
            boxesSet = "";
            item.bookingKitSetId &&
                item.bookingKitSetId.map(kit => {
                    if (kitset.length) {
                        //kitset += "\n" + kit.setId[0].set_name;
                        kitset.push(kit.setId[0].set_name);
                    } else {
                        //kitset += kit.setId[0].set_name;
                        kitset.push(kit.setId[0].set_name);
                    }
                });

            item.bookingdispatches &&
                item?.bookingdispatches[0]?.bookingdispatchboxes.map(box => {
                    if (boxesSet != "") {
                        boxesSet +=
                            "\n" +
                            box.box_type +
                            " - " +
                            box.number_of_box +
                            (box.number_of_box > 1 ? "Boxes" : "Box");
                    } else {
                        boxesSet +=
                            box.box_type +
                            " - " +
                            box.number_of_box +
                            (box.number_of_box > 1 ? "Boxes" : "Box");
                    }
                });

            const rowData = [
                item.hospitalId.name,
                item?.booking_id,
                boxesSet,
                kitset,
                "",
                "",
                "",
                "" // "CHECK SET"
            ];

            const setsArray = kitset;

            if(setsArray?.length){
                for (let i = 0; i < setsArray.length; i++) {
                    if (i == 0) {
                      const mainDataRow = [...rowData.slice(0, 3), setsArray[i],"","",""];
                      ws.addRow(mainDataRow);
                      rowIndex += 1;
                    } else {
                      const setRow = ["", "", "", setsArray[i],"","","",""]; // Empty values for name, address, and age, set value for sets column on subsequent rows of sets
                      ws.addRow(setRow);

                      if(setsArray.length -1 === i){
                        // Merge cells in the name, address, and age columns for the first row of each group of sets
                        if (setsArray.length > 1) {
                            ws.mergeCells(rowIndex, 1, rowIndex + setsArray.length - 1, 1); // Merge cells in the name column
                            ws.mergeCells(rowIndex, 2, rowIndex + setsArray.length - 1, 2); // Merge cells in the address column
                            ws.mergeCells(rowIndex, 3, rowIndex + setsArray.length - 1, 3); // Merge cells in the age column
                        }
                        rowIndex += setsArray.length ? setsArray.length - 1 : 1; // Increment rowIndex for the next group of sets
                      }
                    }
                }
            }else{
                const mainDataRow = [...rowData.slice(0, 3),"","",""];
                ws.addRow(mainDataRow);
                rowIndex += 1;
            }
        });
    }
    // ws.columns.forEach((col) => (col.width = 18));

    // Iterate through each column to calculate and set width
    ws.columns.forEach((column, columnIndex) => {
        let maxLength = 0;
        // Start from the third row (index 2) to skip the header row
        for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
            const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
            if(columnIndex+1 === 3){
                const array = cellValue.split("\n");
                if(array?.length){
                    array.forEach(str => {
                        if (str.length > maxLength) {
                            maxLength = str.length;
                        }
                    });
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }else{
                if (cellValue && cellValue.toString().length > maxLength) {
                    maxLength = cellValue.toString().length;
                }
            }
        }
        // Set the width of the column based on the maximum length of its content
        column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
    });

    ws.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "left",
                };
            });
        }
    });

    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "booking-return";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);  
}

