import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { bookingService, surgeonService } from "../../../../../services";
import dateFormat from "dateformat";

const NavbarComponent = ({ setSearchParams }) => {
  // State for selected hospital and surgeon
  const [selectedSurgeon, setSelectedSurgeon] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [searchOperationFromDate, setSearchOperationFromDate] = useState("");
  const [searchOperationToDate, setSearchOperationToDate] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const [surgeons, setSurgeons] = useState([]);
  const [searchKit, setSearchKit] = useState("");
  const [searchBookingId, setSearchBookingId] = useState("");

  useEffect(() => {
    setSearchParams({
      surgeon: selectedSurgeon?.value || "",
      hospital: selectedHospital?.value || "",
      kit: searchKit || "",
      search: searchBookingId || "",
      delivery_from_date: searchOperationFromDate
        ? dateFormat(searchOperationFromDate, "yyyy-mm-dd")
        : "",
      delivery_to_date: searchOperationToDate
        ? dateFormat(searchOperationToDate, "yyyy-mm-dd")
        : "",
    });
  }, [
    selectedSurgeon,
    selectedHospital,
    searchKit,
    searchBookingId,
    searchOperationFromDate,
    searchOperationToDate,
  ]);

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await bookingService.getHospitalsList({ search: "" });
        const hospitalOptions = response.data.map((hospital) => ({
          value: hospital._id,
          label: hospital.name,
        }));
        setHospitals(hospitalOptions);
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };

    const fetchSurgeons = async () => {
      try {
        const response = await surgeonService.getSurgeonsList({ search: "" });
        const surgeonOptions = response.data.map((surgeon) => ({
          value: surgeon._id,
          label: surgeon.first_name + " " + surgeon.last_name,
        }));
        setSurgeons(surgeonOptions);
      } catch (error) {
        console.error("Error fetching surgeons:", error);
      }
    };

    fetchHospitals();
    fetchSurgeons();
  }, []);

  // Search functions
  const debouncedKitSearch = (e) => {
    console.log("Searching Kits: ", e.target.value);
  };

  const debouncedHospitalSearch = (selectedOption) => {
    setSelectedHospital(selectedOption);
    console.log("Selected Hospital: ", selectedOption);
  };

  const handleSearchDeliveryDate = () => {
    setSelectedSurgeon("");
    setSelectedHospital("");
    setSearchOperationFromDate("");
    setSearchOperationToDate("");
    setSearchKit("");
    setSearchBookingId("");
  };

  const debouncedSurgeonSearch = (selectedOption) => {
    setSelectedSurgeon(selectedOption);
    console.log("Selected Surgeon: ", selectedOption);
  };

  const debouncedSearch = (e) => {
    console.log("Searching Booking IDs: ", e.target.value);
  };

  const handleSearchOperationFromDate = (date) => {
    setSearchOperationFromDate(date);
  };

  const handleSearchOperationToDate = (date) => {
    setSearchOperationToDate(date);
  };

  return (
    <div>
      <div className="row pt-2 pr-2 px-2 mb-0">
        <div className="col-md-6 col-lg-4">
          <ul className="sub-menu">
            <li>
              <Link to="/reps/consignment-request" className="active">
                Open
              </Link>
            </li>
            <li>
              <Link to="/reps/rejected-consignment-request">Rejected</Link>
            </li>
            <li>
              <Link to="/reps/cancelled-consignment-request">Cancelled</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="row pt-1 align-items-center px-2">
        {/* Search Kits */}
        <div className="col-md-3 col-lg-2">
          <div className="form-search">
            <Input
              type="text"
              placeholder="Search Kits..."
              onKeyUp={debouncedKitSearch}
              value={searchKit}
              onChange={(e) => setSearchKit(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        {/* Hospital Selector */}
        <div className="col-md-3 col-lg-2">
          <Select
            value={selectedHospital}
            onChange={debouncedHospitalSearch}
            options={hospitals}
            className="small"
            placeholder="Select Hospital"
          />
        </div>

        {/* Surgeon Selector */}
        <div className="col-md-3 col-lg-2">
          <Select
            value={selectedSurgeon}
            onChange={debouncedSurgeonSearch}
            options={surgeons}
            className="small"
            placeholder="Select Surgeon"
          />
        </div>

        {/* Search Booking IDs */}
        <div className="col-md-2 col-lg-2">
          <div className="form-search">
            <Input
              type="text"
              placeholder="Search Booking IDs..."
              value={searchBookingId}
              onChange={(e) => setSearchBookingId(e.target.value)}
              onKeyUp={debouncedSearch}
              className="form-control"
            />
          </div>
        </div>

        {/* Operation Date */}
        <div className="col-md-3 col-lg-3 d-flex mb-2">
          <div className="w-100">
            <label className="small">Delivery Date</label>
            <div className="row no-gutters">
              <div className="col">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={searchOperationFromDate}
                  onChange={handleSearchOperationFromDate}
                  className="form-control"
                  placeholderText="From"
                />
              </div>
              <div className="col ml-3">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={searchOperationToDate}
                  onChange={handleSearchOperationToDate}
                  className="form-control"
                  placeholderText="To"
                />
              </div>
            </div>
            <div className="">
              <span className="small btn-clear">
                <a
                  style={{
                    position: "relative",
                    top: "5px",
                  }}
                  href="javascript:;"
                  onClick={() => handleSearchDeliveryDate()}
                >
                  Clear
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="col-sm-1 text-right">
          <a
            href="consignment-request/add"
            className="btn btn-primary add_option"
          >
            <i className="fa fa-plus"></i> <span>Add New</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
