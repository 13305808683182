import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from "reactstrap";
import { repsBookingService } from "../../../../../services";

const CancelModal = (props) => {
  const [loading, setLoading] = useState(false);

  const modalClose = (refresh) => {
    props.cancelModalClose(refresh);
  };

  const handleCancel = async () => {
    setLoading(true);
    const params = {
      id: props.cancelId
    };
    try {
      const response = await repsBookingService.cancelConsignmentRequest(params);
      console.log("API response:", response);
      modalClose(true); // Pass true to indicate that the page should be refreshed
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      backdrop="static"
      centered
      isOpen={props.cancelModal}
      toggle={() => modalClose(false)}
    >
      <ModalHeader className="" toggle={() => modalClose(false)}>
        Cancel
      </ModalHeader>
      <ModalBody>Are you sure you want to Cancel?</ModalBody>
      <ModalFooter>
        <div className="">
          <button
            onClick={() => modalClose(false)}
            className="btn btn-secondary mr-2"
            data-dismiss="modal"
          >
            Cancel
          </button>
          {!loading && (
            <button
              className="btn btn-primary"
              onClick={handleCancel}
            >
              Ok
            </button>
          )}
          {loading && (
            <button
              className="btn btn-primary loader-icon"
              disabled
            >
              <Spinner className="img-svg"></Spinner>
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CancelModal;
