import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";
import { bookingService } from "../../../../../services";

const Cancle = (props) => {
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);


    const modalClose = (flag) =>{
        setLoading(false);
        setError({});
        props.bunchForCloseModalClose(flag);
    };

    const handleOk = async () => {
        setLoading(true);
        try {
            console.log(props.bunchForCloseId);
            let bunchForCloseId=props.bunchForCloseId;
            await bookingService.bookingsForceClosed({
                selectedBookings: bunchForCloseId.length?bunchForCloseId.join(","):""
            });
            setError({});
            setLoading(false);
            props.bunchForCloseModalClose(true);

        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
            setError(error);
        }
    };

    return (
        <Modal
            size="md"
            backdrop="static"
            isOpen={props.bunchForCloseModal}
            toggle={() => modalClose(false)}>
            <ModalHeader
                className=""
                toggle={() => modalClose(false)}>
                Force Close Booking
            </ModalHeader>
            <ModalBody>
            <div className="my-2">Please confirm if you want to close this bookings?</div>
                       
                        {error && error ? (
                            <span className="text-danger">
                                {error.common ? error.common : ""}
                            </span>
                        ) : (
                            ""
                        )}
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        onClick={() => modalClose(false)}
                        className="btn btn-secondary mr-2"
                        data-dismiss="modal">
                        Cancel
                    </button>
                    {loading == false && (
                        <button
                            className="btn btn-primary"
                            onClick={() => handleOk()}>
                            Ok
                        </button>
                    )}
                    {loading == true && (
                        <button
                            className="btn btn-primary loader-icon"
                            disabled>
                            <Spinner className="img-svg"></Spinner>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default Cancle;