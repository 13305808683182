import React, { useEffect, useState,useMemo } from "react";
import { Link } from "react-router-dom";
import { Input, Table, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import dateFormat from "dateformat";
// import * as XLSX from "xlsx";
// import { ExportSheet } from "react-xlsx-sheet";
import { bookingRequestService } from "../../../../services/bookingRequest.services";
import { bookingService } from "../../../../services/booking.services";
import Select from "react-select";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { debounce } from "lodash";
import * as excelJs from "exceljs";

// const head = [
//     { title: "HOSPITAL NAME",dataIndex: "hospital_name"},
//     { title: "KITS REQUIRED",dataIndex: "kits"},
//     { title: "IMPORTANT DATES",dataIndex: "import_dates"},
//     { title: "REP",dataIndex: "rep_name" },
// ];

const NewAdminBookingRequest = () => {
    // const [dataXlsx, setDataXlsx] = useState([]);
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const [bookingList, setBookingList] = useState([]);
    const [hospitalsList, setHospitalList] = useState([]);
    const [repsList, setRepsList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([{label:"Booked By",value:""}]);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedHospital, setSelectedHospital] = useState([{label:"All Hospital",value:""}]);
    const [selectedHospitalId, setSelectedHospitalId] = useState("");
    const [rejectComment,setRejectComment] = useState("");
    const [cancelModal, setCancelModal] = useState(false);
    const [acceptModal, setAcceptModal] = useState(false);
    const [bookingId,setBookingId] = useState(0);
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [rejectStatus,setRejectStatus] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = (val) => {
        setSearch(val.target.value);
        setActivePage(1);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        // getList();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const showXlsData = async (data) => {
        // setDataXlsx(newDataXlsx);
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet("Request Booking Worksheet");
        const headers = [
            "Hospital Name",
            "Kits Required",
            "Important Dates",
            "Rep"
        ];

        const headerRow = ws.addRow(headers);

        headerRow.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 11,
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" } // Background color (e.g., yellow)
            };
            cell.border = {
                top: {style:"thin"},
                left: {style:"thin"},
                bottom: {style:"thin"},
                right: {style:"thin"}
            };
        });

        data.forEach((item) => {
            
            const kitset = item.bookingkits.map(kit => kit.kits[0].name).join("\n");
            let imDate="";

            imDate="Delivery Date: "+dateFormat(item.delivery_date, "ddd,mmm dS")+" "+item.delivery_date_slot;
            imDate+="\nOperation Date: "+dateFormat(item.operation_date, "ddd,mmm dS")+" "+item.operation_date_slot;
            imDate+="\nCollection Date: "+dateFormat(item.collection_date, "ddd,mmm dS");

            const rowData = [
                item.hospitalId.name+"\n Requested on "+dateFormat(item.createdAt, "ddd,mmm dS"),
                kitset,
                imDate,
                item.assigned_rep.first_name+" "+item.assigned_rep.last_name
            ];

            let setLength = item?.bookingkits?.length ? item.bookingkits.length : 1;

            let row = ws.addRow(rowData);

            row.height = setLength > 3 ? setLength*14 : 50;
            ws.getCell("A"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            ws.getCell("B"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            ws.getCell("C"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            // ws.properties.outlineLevelRow = 1;
        });

        // Iterate through each column to calculate and set width
        ws.columns.forEach((column, columnIndex) => {
            let maxLength = 0;
            // Start from the third row (index 2) to skip the header row
            for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
                const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
                if(columnIndex+1 === 1 || columnIndex+1 === 2 || columnIndex+1 === 3){
                    const array = cellValue.split("\n");
                    if(array?.length){
                        array.forEach(str => {
                            if (str.length > maxLength) {
                                maxLength = str.length;
                            }
                        });
                    }else{
                        if (cellValue && cellValue.toString().length > maxLength) {
                            maxLength = cellValue.toString().length;
                        }
                    }
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }
            // Set the width of the column based on the maximum length of its content
            column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
        });

        ws.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin", color: { argb: "000000" } },
                        left: { style: "thin", color: { argb: "000000" } },
                        bottom: { style: "thin", color: { argb: "000000" } },
                        right: { style: "thin", color: { argb: "000000" } },
                    };
                });
            }
            if (rowNumber === 1) {
                row.eachCell((cell) => {
                    cell.font = {
                        bold: true,
                        size: 13,
                    };
                });
            }
        });

        setIsLoading(false);
        const excelBlob = await workbook.xlsx.writeBuffer();    
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );

        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "booking-request";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);

        };

    const getList = async () => {
        setBookingList([]);
        setLoading(true);
        try {
            const res = await bookingRequestService.getList({
                bookedByFilter: selectedUserId,
                hospitalFilter: selectedHospitalId,
                type: ["loan"],
                status: ["pending"],
                deliveryDateFiter: "",
                toDeliveryDateFiter: "",
                search: search,
                page: activePage,
                limit: limit,
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const getHospitalList = async () => {
        setHospitalList([]);
        try {
            const res = await bookingService.getHospitalsList({
                search: ""
            });
            showHospitalsList(res.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const getRepsList = async () => {
        setRepsList([]);
        try {
            const res = await bookingService.getRepsList({
                search: ""
            });
            showRepsList(res.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    useEffect(() => {
        getRepsList();
        getHospitalList();
        setUser(JSON.parse(localStorage.getItem("user")));
    }, []);


    // useEffect(() => {
    //     showXlsData();
    //     setUser(JSON.parse(localStorage.getItem("user")));
    // }, [bookingList]);

    useEffect(() => {
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getList();
    }, [search, activePage,selectedHospitalId, selectedUserId]);

    // useEffect(() => {
    //     getList();
    // }, [selectedHospitalId, selectedUserId]);

    const showHospitalsList = (hospitalsList) => {
        var tempHospitals = [{label:"All Hospital",value:""}];
        hospitalsList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempHospitals.push(option);
        });
        setHospitalList(tempHospitals);
    };

    const showRepsList = (repsList) => {
        var tempRepsList = [{label:"Booked By",value:""}];
        repsList.map((item)=> {
            var option = {value: item._id, label: item.first_name+" "+item.last_name};
            tempRepsList.push(option);
        });
        setRepsList(tempRepsList);
    };

    const setSelectedOptionUser = (e) => {
        setActivePage(1);
        var selectedUser=[{value:e.value, label:e.label}];
        setSelectedUser(selectedUser);
        setSelectedUserId(e.value);
    }; 

    const setSelectedOptionHospital = (e) => {
        setActivePage(1);
        var selectedHospital=[{value:e.value, label:e.label}];
        setSelectedHospital(selectedHospital);
        setSelectedHospitalId(e.value);
    };


    const cancelModalOpen = (id) => {
        setRejectComment("");
        setCancelModal(true);
        setRejectStatus(false);
        setBookingId(id);
    };

    const cancelModalClose = () => {
        setRejectComment("");
        setCancelModal(false);
        setRejectStatus(false);
    };

    const acceptModalClose = () => {
        setAcceptModal(false);
    };

    const handleRejectComment = (comment) => {
        setRejectComment(comment);
    };

    const handleCancle = async () => {
        try {
            const res = await bookingRequestService.updateRejectStatus({
                id: bookingId,
                log_by_id:user.data._id,
                log_status:"rejected", 
                comment: rejectComment, 
                status:"reject"
            });
            
            if(res.status === 200){
                setCancelModal(false);
                setRejectStatus(true);
                getList();
            }

        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        }
    };

    const handleAccept = async () => {
        try {
            const res = await bookingRequestService.updateAcceptStatus({
                id: bookingId,
                log_by_id:user.data._id,
                status:"booking"
            });
            
            if(res.status === 200){
                setCancelModal(false);
                getList();
            }

        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        }

    };

    const debouncedSearch = useMemo(() => {
        return debounce(handleSearch, 1500);
    }, [search]);

    const getXlsxData = async () => {
        setIsLoading(true);
        try {
            const res = await bookingRequestService.getBookingRequestExport({
                bookedByFilter: selectedUserId,
                hospitalFilter: selectedHospitalId,
                type: ["loan"],
                status: ["pending"],
                deliveryDateFiter: "",
                toDeliveryDateFiter: "",
                search: search,
                page: activePage,
                limit: limit,
            });
            if(res.status === 200){
                showXlsData(res?.data);
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    if (!user || user.data.user_role !== "admin") {
        return <Redirect to="/" />;
    }

    return (
        <div>
        <Navbar activePage="dashboard"/>
        <main className="offset">
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-lg-2 side-filters">     
                        <span className="side-heading">Dashboard</span>
                        <DashboardSidebar activePage="request" requestCount={totalItemsCount} rejectStatus={rejectStatus}/>
                    </div>
                   
                    <div className="col-lg-10">
                        <div className="ground">
                            <div className="row">
                                <div className="col-md-4 col-sm-8"> 
                                    <div className="sub-menu">
                                        <div className="d-flex">
                                            <div className="col p-0">
                                                <div className="mr-2">
                                                    <Select 
                                                        value={selectedUser}
                                                        onChange={setSelectedOptionUser}
                                                        options={repsList}
                                                        className="small text-left"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col p-0">
                                                <div className="ml-2">
                                                    <Select 
                                                        value={selectedHospital}
                                                        onChange={setSelectedOptionHospital}
                                                        options={hospitalsList}
                                                        className="small text-left"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <div className="form-search">
                                        <Input 
                                            className="form-control mr-sm-2" 
                                            type="text"
                                            placeholder="Search.."
                                            // value={search}
                                            // onChange={(e) => handleSearch(e.target.value)}
                                            onChange={debouncedSearch}
                                        />    
                                    </div>
                                </div>
                                <div className="col-md-4 text-right">
                                   
                                    <button className={!loading && !isLoading ? "btn btn-default add_option" : "btn btn-default add_option dropdown-disabled"} onClick={() => !loading && !isLoading && bookingList?.length ? getXlsxData() : ""} >Print</button>
                                  
                                </div>
                            </div>
                            <div className="table-scroll mt-4">
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{minWidth:"200px"}}>Hospital Name </th>
                                            <th scope="col" >Kits Required</th>
                                            <th scope="col">Important Dates </th>
                                            <th scope="col">Rep </th>
                                            <th scope="col" className="text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       
                                        { bookingList && bookingList.map((item) => (
                                            <tr key={item._id}>
                                                <td width="200" data-th="Hospital Name">
                                                    <div className="">                   
                                                        <Link to={"/admin/booking/details/"+item?._id}>
                                                            <span><strong><span style={{"display": "block"}} className="theme-text">{item?.booking_id}</span>
                                                                {item?.hospitalId?.name}</strong></span><br />
                                                            <small>Requested on {dateFormat(item?.createdAt, "ddd,mmm dS")}</small>                          
                                                        </Link>
                                                    </div>
                                                </td>                                                        
                                                <td data-th="Kits Required">
                                                    <small>
                                                        { item.bookingkits && item.bookingkits.map((kit,ind)=>(
                                                            <React.Fragment key={ind}>
                                                                <div><span>{kit.kits[0].name} </span></div>
                                                            </React.Fragment>
                                                        ))}
                                                    </small>                        
                                                </td>
                                                <td data-th="Important Dates">
                                                    <small>
                                                    Delivery Date: <strong>{dateFormat(item.delivery_date, "ddd,mmm dS")} {item.delivery_date_slot}</strong><br />
                                                    Operation Date: <strong>{dateFormat(item.operation_date, "ddd,mmm dS")} {item.operation_date_slot}</strong><br />
                                                    Collection Date: <strong>{dateFormat(item.collection_date, "ddd,mmm dS")}</strong>
                                                    </small>
                                                </td> 
                                                <td data-th="Rep">{item.assigned_rep.first_name} {item.assigned_rep.last_name}</td>
                                                
                                                <td className="text-right">                                 
                                                    <a href="javascript:;" onClick={()=>cancelModalOpen(item._id)} className="btn btn-secondary my-1">Reject</a>
                                                    <Link className="btn btn-primary ml-2 my-1" to={"/admin/booking/edit/"+item._id}>Accept</Link>
                                                </td> 
                                            </tr>
                                        ))}

                                        {bookingList && bookingList.length == 0 && loading == true &&                                                    
                                            <div className="loading-state">
                                                <div className="loading"></div>
                                            </div>                                               
                                        }

                                        { bookingList && bookingList.length==0 && loading==false && 
                                            <tr className="text-center">
                                                <td colSpan="5">No Record Found</td>
                                            </tr>
                                        } 
                                    </tbody>
                                </Table>
                            </div>
                        </div>


                        {bookingList && bookingList.length >0 &&
                        <div>
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={limit}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center mb-5"
                                activeLinkClass="active"
                                nextPageText="Next"
                                prevPageText="Prev"
                            />
                            <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                        </div>
                        }

                     

                        <Modal size="md" backdrop="static" isOpen={cancelModal} toggle={() => cancelModalClose()}>
                            <ModalHeader className="" toggle={() => cancelModalClose()}>
                                Reject Booking
                            </ModalHeader>
                            <ModalBody>
                                {/* <div className="my-2">Please confirm if you want to reject this booking?</div> */}
                                <textarea 
                                    className="form-control my-2" 
                                    placeholder="Comment"
                                    value={rejectComment}
                                    onChange={(e) => handleRejectComment(e.target.value)}
                                ></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <div className="">
                                    <button onClick={() => cancelModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                    { loading==false && 
                                        <button className="btn btn-primary" onClick={() =>handleCancle()}>Ok</button>
                                    }
                                    {loading==true && 
                                        <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                    }
                                </div>
                            </ModalFooter>
                        </Modal>

                        {/* Reject Modal */}

                        <Modal size="ml" backdrop="static" isOpen={acceptModal} toggle={() => acceptModalClose()}>
                            <ModalHeader className="" toggle={() => acceptModalClose()}>
                                Accept Booking
                            </ModalHeader>
                            <ModalBody>
                                <div className="my-2">Please confirm if you want to accept this booking?</div>
                            </ModalBody>
                            <ModalFooter>
                                <div className="">
                                    <button onClick={() => acceptModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                    <button className="btn btn-primary" onClick={() =>handleAccept()}>Ok</button>
                                </div>
                            </ModalFooter>
                        </Modal>
                        {/* Accept Modal*/}

                        {/* Set Details Modal */}
                    </div>
                </div>{/*<!--/row-->*/}
            </div>
        </main>

  
        <Footer />
    </div>
    );
};
export default NewAdminBookingRequest;