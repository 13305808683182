import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import {
  bookingService,
  surgeonService,
  repsBookingService,
} from "../../../../../../services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";

const BasicDetails = ({ onSave, id, validationErrors }) => {
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedHospitalData, setSelectedHospitalData] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [hospitalAddress, setHospitalAddress] = useState([]);
  const [hospitalDepartment, setHospitalDepartment] = useState([]);
  const [selectedSurgeon, setSelectedSurgeon] = useState([]);
  const [surgeons, setSurgeons] = useState([]);
  const [editData, setEditData] = useState([]);
  const [bookingInfo, setBookingInfo] = useState({
    pickup_location_checkbox: false,
    pickup_location_text: "",
    note: "",
    delivery_date: null,
  });

  useEffect(() => {
    handleSave();
  }, [
    selectedHospital,
    hospitalAddress,
    hospitalDepartment,
    selectedSurgeon,
    bookingInfo,
  ]);

  const handleSave = () => {
    const data = {
      hospitalId: selectedHospital?.value,
      hospital_address:
        hospitalAddress.find((addr) => addr.checked)?.name || "",
      hospital_department:
        hospitalDepartment.find((dept) => dept.checked)?.name || "",
      surgeonData: selectedSurgeon.map((surgeon) => surgeon.value) || [],
      pickup_location_checkbox: bookingInfo.pickup_location_checkbox || false,
      pickup_location_text: bookingInfo.pickup_location_text || "",
      note: bookingInfo.note || "",
      delivery_date: bookingInfo.delivery_date
        ? dateFormat(bookingInfo.delivery_date, "yyyy-mm-dd")
        : "",
    };
    onSave(data);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await repsBookingService.editConsignmentRequest({
          id,
        });
        const data = response.data;
        setEditData(data[0]);

        const initialDeliveryDate = data[0].delivery_date
          ? new Date(data[0].delivery_date)
          : null;

        setBookingInfo({
          pickup_location_checkbox: data[0].pickup_location_checkbox || false,
          pickup_location_text: data[0].pickup_location_text || "",
          note: data[0].note || "",
          delivery_date: initialDeliveryDate,
        });
        const hospitalOptions = data[0].hospitalData.map((item) => ({
          value: item._id,
          label: item.name,
        }));
        setHospitals(hospitalOptions);

        const initialHospital = hospitalOptions.find(
          (hospital) => hospital.value === data[0].hospitalData[0]._id
        );
        setSelectedHospital(initialHospital);

        const surgeonOptions = data[0]?.surgeonData?.map((surgeon) => ({
          value: surgeon._id,
          label: `${surgeon.first_name} ${surgeon.last_name}`,
        }));
        setSurgeons(surgeonOptions);
        setSelectedSurgeon(surgeonOptions);
      } catch (err) {
        console.error("Error fetching consignment details:", err);
      }
    };

    fetchInitialData();
  }, [id]);

  useEffect(() => {
    if (selectedHospital?.value && editData) {
      const fetchHospitalDetails = async () => {
        try {
          const response = await bookingService.getHospitalDetailsById(
            selectedHospital.value
          );
          const { addresses, departments } = response.data;
          setSelectedHospitalData(response.data);

          setHospitalAddress(
            addresses.map((addr) => ({
              ...addr,
              checked: addr.name === editData.hospital_address,
            }))
          );

          setHospitalDepartment(
            departments.map((dept) => ({
              ...dept,
              checked: dept.name === editData.hospital_department,
            }))
          );
        } catch (err) {
          console.error("Error fetching hospital details:", err);
        }
      };

      fetchHospitalDetails();
    }
  }, [selectedHospital, editData]);

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await bookingService.getHospitalsList({ search: "" });
        const hospitalOptions = response.data.map((hospital) => ({
          value: hospital._id,
          label: hospital.name,
        }));
        setHospitals(hospitalOptions);
      } catch (err) {
        console.error("Error fetching hospitals:", err);
      }
    };

    fetchHospitals();
  }, []);

  useEffect(() => {
    const fetchSurgeons = async () => {
      try {
        const response = await surgeonService.getSurgeonsList({ search: "" });
        const surgeonOptions = response.data.map((surgeon) => ({
          value: surgeon._id,
          label: `${surgeon.first_name} ${surgeon.last_name}`,
        }));
        setSurgeons(surgeonOptions);
      } catch (err) {
        console.error("Error fetching surgeons:", err);
      }
    };

    fetchSurgeons();
  }, []);

  const handleBookingAddress = (checked, index) => {
    setHospitalAddress((prevAddresses) =>
      prevAddresses.map((addr, i) => ({
        ...addr,
        checked: i === index ? checked : false,
      }))
    );
  };

  const handleBookingDepartment = (checked, index) => {
    setHospitalDepartment((prevDepartments) =>
      prevDepartments.map((dept, i) => ({
        ...dept,
        checked: i === index ? checked : false,
      }))
    );
  };

  const handleBookingInfo = (field, value) => {
    setBookingInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleDeliveryDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setBookingInfo((prevState) => ({
        ...prevState,
        delivery_date: date,
      }));
    } else {
      console.error("Invalid date:", date);
    }
  };

  return (
    <div className="d-card">
      <h4>Basic Details</h4>
      <div className="form-group">
        <label>
          Hospital Name <span className="text-danger">*</span>
        </label>
        <div className="info position_up" style={{ zIndex: 8 }}>
          <Select
            value={selectedHospital}
            onChange={setSelectedHospital}
            options={hospitals}
            maxMenuHeight={190}
          />
          {validationErrors.hospitalId && (
            <span className="text-danger">Please select hospital name</span>
          )}
        </div>
      </div>

      {selectedHospital && (
        <div className="row position-relative">
          <div className="info col-md-6">
            <h6>Address</h6>
            <hr />
            {hospitalAddress.map((item, ind) => (
              <div
                className="info-left"
                style={{ marginLeft: "1.25rem" }}
                key={item._id}
              >
                <Input
                  type="radio"
                  id={item._id}
                  name="hospitalAddress"
                  value={item.name}
                  checked={item.checked}
                  onChange={(e) => handleBookingAddress(e.target.checked, ind)}
                />
                <strong> {selectedHospitalData?.contact_person_name}</strong>
                <div>
                  <small>Location: {item.name}</small>
                </div>
                <div>
                  <small>Email: {selectedHospitalData?.email}</small>
                </div>
                <div>
                  <small>
                    Phone: {selectedHospitalData?.contact_person_phone_number}
                  </small>
                </div>
              </div>
            ))}
          </div>
          <div className="info col-md-6">
            <h6>Department</h6>
            <hr />
            {hospitalDepartment.map((item, ind) => (
              <div
                className="info-left"
                style={{ marginLeft: "1.25rem" }}
                key={item._id}
              >
                <Input
                  type="radio"
                  id={item._id}
                  name="hospitalDepartment"
                  value={item.name}
                  checked={item.checked}
                  onChange={(e) =>
                    handleBookingDepartment(e.target.checked, ind)
                  }
                />
                <div>
                  <small>{item.name}</small>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-12">
            <div className="form-group mt-3">
              <span>
                <Input
                  type="checkbox"
                  className="ml-0 form-check-input"
                  checked={bookingInfo.pickup_location_checkbox}
                  onChange={(e) =>
                    handleBookingInfo(
                      "pickup_location_checkbox",
                      e.target.checked
                    )
                  }
                />
              </span>
              <label className="ml-4">
                Is Pickup Location And Contact Details Different
              </label>
              {bookingInfo.pickup_location_checkbox && (
                <Input
                  type="text"
                  className="form-control"
                  value={bookingInfo.pickup_location_text}
                  onChange={(e) =>
                    handleBookingInfo("pickup_location_text", e.target.value)
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="form-group mt-2">
        <label>
          Surgeon Name <span className="text-danger">*</span>
        </label>
        <div className="info position_up" style={{ zIndex: 7 }}>
          <Select
            value={selectedSurgeon}
            onChange={setSelectedSurgeon}
            options={surgeons}
            maxMenuHeight={190}
            isMulti={true}
          />
          {validationErrors.surgeonData && (
            <span className="text-danger">Please select surgeon name</span>
          )}
        </div>
      </div>
      <div className="form-group mt-2">
        <label>
          Delivery Date (minimum 6 weeks) - has to be confirmed by office{" "}
          <span className="text-danger">*</span>
        </label>
        <DatePicker
          selected={
            bookingInfo.delivery_date instanceof Date &&
            !isNaN(bookingInfo.delivery_date)
              ? bookingInfo.delivery_date
              : null
          }
          onChange={handleDeliveryDateChange}
          className="form-control"
          minDate={new Date(Date.now() + 6 * 7 * 24 * 60 * 60 * 1000)}
          dateFormat="yyyy/MM/dd"
        />
        {validationErrors.delivery_date && (
          <span className="text-danger">Please select a delivery date</span>
        )}
      </div>
      <div className="form-group mt-2">
        <label>Note</label>
        <Input
          type="textarea"
          value={bookingInfo.note}
          onChange={(e) => handleBookingInfo("note", e.target.value)}
          className="form-control"
        />
        {validationErrors.note && (
          <span className="text-danger">Please add a note</span>
        )}
      </div>
    </div>
  );
};

export default BasicDetails;
