import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { repsBookingService } from "../../../../../services";
import Navbar from "../../Navbar";
import { BasicDetails } from "./BasicDetails";
import { KitDetails } from "./KitDetailsTable";
import BookingTimeline from "./BookingTimeline";
import BookedByDetails from "./BookedByDetails";

const BookingReview = () => {
  const [bookingInfo, setBookingInfo] = useState({});
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await repsBookingService.editConsignmentRequest({
          id,
        });
        console.log("object", response.data[0]);
        setBookingInfo(response.data[0]);
      } catch (err) {
        console.error("Error fetching consignment details:", err);
      }
    };
    fetchInitialData();
  }, [id]);

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container">
        <h1 className="date-scroll pt-4">
          <a onClick={() => history.goBack()}>
            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
            <strong>Review Consignment Request</strong>
          </a>
        </h1>
        <div className="row pt-4 mobile_nocard">
          <div className="col-md-8">
            <BasicDetails bookingInfo={bookingInfo} />
            <KitDetails consignmentKits={bookingInfo.consignmentKits} />
          </div>
          <div className="col-md-4">
            <BookedByDetails bookingInfo={bookingInfo} />
            <BookingTimeline logData={bookingInfo.logData}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingReview;
