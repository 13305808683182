import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar";
import { Redirect, Link } from "react-router-dom";
import { repsBookingService } from "../../../../../services";
import dateFormat from "dateformat";
import NavbarComponent from "./navbar";

const RejectedBooking = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalItemsCount, setTotalItemsCount] = useState(0); 
  const [searchParams, setSearchParams] = useState({});
  
 const getList = async (searchParams, pageNumber = 1) => {
    try {
      setIsLoading(true);
      const res = await repsBookingService.getRejectedConsignmentRequest({
        ...searchParams,
        page: pageNumber,
      });
      const newBookings = res.data;

      setBookings((prevBookings) => {
        return page === 1 ? newBookings : [...prevBookings, ...newBookings];
      });
      setHasMore(newBookings.length > 0);
      setIsLoading(false);
      if (res.status) {
        setTotalItemsCount(res?.total_count);
      }
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      getList(searchParams, page);
    }
  }, [searchParams,page]);

  useEffect(() => {
    console.log("Search Params updated: ", searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (page > 1) {
      getList(searchParams, page);
    }
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading ||
      !hasMore
    )
      return;

    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll,hasMore]);

  if (!user) {
    return <Redirect to="/" />;
  }
  if (user.data.user_role !== "reps") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid">
      <NavbarComponent setSearchParams={setSearchParams} />
        {/* <div className="row pt-4 align-items-center">
          <div className="col-lg-5 col-md-5">
            <ul className="sub-menu">
              <li>
                <Link to="/reps/consignment-request">Open</Link>
              </li>
              <li>
                <Link
                  to="/reps/rejected-consignment-request"
                  className="active"
                >
                  Rejected
                </Link>
              </li>
              <li>
                <Link to="/reps/cancelled-consignment-request">Cancelled</Link>
              </li>
            </ul>
          </div>

          <div className="col-md-5 col-sm-12">
            <div className="form-search">
              <Input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="col-sm-2 text-right">
            <a
              href="consignment-request/add"
              className="btn btn-primary add_option"
            >
              <i className="fa fa-plus"></i> <span>Add New</span>
            </a>
          </div>
        </div> */}
        <div
						className="small mr-3 badge badge-info"
						style={{
							fontWeight: 500,
							background: "#5d75e9",
							marginBottom: 0,
						}}
					>
						{totalItemsCount} Records found
					</div>
        <div id="table-scroll" className="table-scroll mt-2">
          <table id="main-table" className="main-table">
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Hospital Name
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Contact Person
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Surgeon Name
                </th>
                <th scope="col">Kits</th>
                <th scope="col">Delivery Date</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking) => (
                  <tr key={booking._id}>
                    <td data-th="Hospital Name">
                      <div>
                      <Link
														to={
															"/reps/consignment-request/details/" +
															booking.consignments._id
														}
													>  
                        <span>
                          <strong>
                            <span
                              style={{ display: "block" }}
                              className="theme-text"
                            >
                              {booking.consignments.consignment_id}
                            </span>
                            {booking.hospitalData[0]?.name}
                          </strong>
                        </span>
                        <br />
                        <small>
                          Booked on{" "}
                          {dateFormat(booking.consignments.createdAt, "ddd,mmm dS")}
                        </small>
                        </Link>
                      </div>
                    </td>
                    <td data-th="Contact Person">
                      <div className="info-left">
                        <strong>
                          {booking.hospitalData[0]?.contact_person_name}{" "}
                        </strong>
                        <br />
                        <small>
                          Location: {booking.consignments.hospital_address}{" "}
                        </small>
                        <br />
                        <small>Email: {booking.hospitalData[0]?.email}</small>
                        <br />
                        <small>
                          Phone:{" "}
                          {booking.hospitalData[0]?.contact_person_phone_number}
                        </small>
                        <br />
                        <small>
                          Department:{booking.consignments.hospital_department}
                        </small>
                      </div>
                    </td>
                    <td data-th="Surgeon Name">
                      <div className="info-left">
                        {booking.surgeonData.length > 0
                          ? booking.surgeonData.map((surgeon) => (
                              <div key={surgeon._id}>
                                <strong>
                                  {surgeon.title} {surgeon.first_name}{" "}
                                  {surgeon.last_name}
                                </strong>
                                <br />
                              </div>
                            ))
                          : "Not Assigned"}
                      </div>
                    </td>
                    <td data-th="Kits">
                      <small>
                        {booking.consignmentKits.length > 0
                          ? booking.consignmentKits.map((kit) => (
                              <a
                                key={kit._id}
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#kitDetails"
                              >
                                {kit.kits[0]?.name} <br />
                              </a>
                            ))
                          : "None"}
                      </small>
                    </td>
                    <td data-th="Delivery Date">
                      {booking?.consignments?.delivery_date? dateFormat(booking?.consignments?.delivery_date,"ddd, mmm dS"):""}
                    </td>
                    <td>
                      <div className="mb-2">
                        <span className={"capsule " + "red-dim " + " mb-1"}>
                          Reviewed and Rejected
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No bookings available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isLoading && (
            <div className="text-center">
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RejectedBooking;
