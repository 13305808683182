import "jspdf-autotable";
import * as excelJs from "exceljs";
import moment from "moment";

export const exportXls = {
    handleExportClick
};

async function handleExportClick(data,headingContent){
    const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet("Test Worksheet");
        const options = ["Normal", "High"];
        const headers = [
            "Collection Number",
            "Hospital Name",
            "Address",
            "Contact Name",
            "Contact Number",
            "Department",
            "PO Number",
            "Operation Date",
            "Surgeon Name",
            "No. of Boxes",
            "Sets Name & No.",
            "Priority Level",
            "Check Set"
            // "Comments",
        ];

        // Add a header row with a merged cell for the heading
        const headingRow = ws.addRow([headingContent]);
        headingRow.getCell(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
        };
    
        headingRow.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 13,
            };
        });
    
        ws.mergeCells("A1:B1"); // Merge cells for the heading
    
        const header1Row = ws.addRow(headers);

        header1Row.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 11,
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
            };
            cell.border = {
                top: {style:"thin"},
                left: {style:"thin"},
                bottom: {style:"thin"},
                right: {style:"thin"}
            };
        });
    
        data?.length && data.forEach((item, index) => {
            const kitset = item.bookingKitSetId.map(kit => kit?.setId[0]?.set_name).join("\n");
            const boxesSet = item.bookingdispatches[0].bookingdispatchboxes.map(box => {
                return `${box.box_type} - ${box.number_of_box} ${box.number_of_box > 1 ? "Boxes" : "Box"}`;
            }).join("\n");
            
            const rowData = [
                index + 1,
                item.hospitalId.name,
                item.hospital_address,
                item.hospitalId.contact_person_name,
                item.hospitalId.contact_person_phone_number,
                item.hospital_department,
                item.po_number,
                moment(item.operation_date).format("ddd, MMMM Do, YYYY"),
                //item.bookings.hospital_department,
                item?.bookingSurgeonData?.SurgeonDetail[0]?.first_name +" " +item?.bookingSurgeonData?.SurgeonDetail[0]?.last_name,
                boxesSet,
                kitset,
                item.bookingcollections[0] ? item.bookingcollections[0].priority_level : "",
                item?.check_set ? item?.check_set :""
                 // item.bookingcollections[0] ? item.bookingcollections[0].comment : "",
            ];

            let setLength = item?.bookingKitSetId?.length ? item.bookingKitSetId.length : 1;
            let boxLength = item?.bookingdispatches && item?.bookingdispatches[0]?.bookingdispatchboxes.length ? item?.bookingdispatches[0]?.bookingdispatchboxes.length : 1;
    
            let row = ws.addRow(rowData);

            row.height = setLength >= boxLength ? setLength*12 : boxLength*12;
            ws.getCell("J"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            ws.getCell("K"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            // ws.properties.outlineLevelRow = 1;
        });
    
        // ws.columns.forEach((col) => (col.width = 18));

        // Iterate through each column to calculate and set width
        ws.columns.forEach((column, columnIndex) => {
            let maxLength = 0;
            // Start from the third row (index 2) to skip the header row
            for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
                const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
                if(columnIndex+1 === 10 || columnIndex+1 === 11){
                    const array = cellValue.split("\n");
                    if(array?.length){
                        array.forEach(str => {
                            if (str.length > maxLength) {
                                maxLength = str.length;
                            }
                        });
                    }else{
                        if (cellValue && cellValue.toString().length > maxLength) {
                            maxLength = cellValue.toString().length;
                        }
                    }
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }
            // Set the width of the column based on the maximum length of its content
            column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
        });
    
        ws.dataValidations.add("L3:L99999", {
            type: "list",
            allowBlank: false,
            formulae: [`"${options.join(",")}"`],
            showDropDown: false,
            showDropDownInCell: true,
        });
    
        ws.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin", color: { argb: "000000" } },
                        left: { style: "thin", color: { argb: "000000" } },
                        bottom: { style: "thin", color: { argb: "000000" } },
                        right: { style: "thin", color: { argb: "000000" } },
                    };
                });
            }
            if (rowNumber === 1) {
                row.eachCell((cell) => {
                    cell.font = {
                        bold: true,
                        size: 13,
                    };
                });
            }
        });
    
        ws.getColumn("L").eachCell({ includeEmpty: true }, function(cell) {
            if (cell.value === "High") {
                cell.font = {
                    color: { argb: "FFDC143C"  } 
                };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFC0CB" }, 
                };
            } else if (cell.value === "Normal") {
                cell.font = {
                    color: { argb: "000000" }, 
                };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFFFFF" }, 
                };
            }
        });

        for (let rowNumber = 2; rowNumber <= ws.rowCount; rowNumber++) {
            const priorityCell = ws.getCell(`I${rowNumber}`);
            if (priorityCell.value === "High") {
                priorityCell.font = {
                    color: { argb: "FFDC143C" },
                };
                priorityCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFC0CB" },
                };
            } else if (priorityCell.value === "Normal") {
                priorityCell.font = {
                    color: { argb: "000000" },
                };
                priorityCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFFFFF" },
                };
            }
        }
    
    
        const excelBlob = await workbook.xlsx.writeBuffer();    
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );
    
        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "booking-collection";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
}

