import React, { useEffect, useState } from "react";
import { Input,Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Select from "react-select";
import { ReactComponent as Spinner } from "../../../../../../../assets/images/Spinner-1s-200px.svg";
import { bookingService } from "../../../../../../../services/booking.services";

const options = [
    { label: "All Aces", value: "All Aces" },
    { label: "APC", value: "APC" },
    { label: "Sameday (HBC)", value: "Sameday (HBC)" },
    { label: "Other", value: "Other" },
];

const boxOptions = [
    { label: "Black Box", value: "Black Box" },
    { label: "Blue Box", value: "Blue Box" },
    { label: "Grey case", value: "Grey case" },
    { label: "Cardboard Box", value: "Cardboard Box" }
];

const dispatchedByOptions = [
    { label: "Lily Rose Winters", value: "Lily Rose Winters" },
    { label: "Ekaterina Karateodorova", value: "Ekaterina Karateodorova" },
    { label: "George Viziteu", value: "George Viziteu" },
    { label: "Ana Marie Sendrea", value: "Ana Marie Sendrea" },
    { label: "Jeff Allen", value: "Jeff Allen" },
    { label: "Others", value: "Other" }
];

const AddDispatch = (props) => {

    const [boxes,setBoxes] = useState([{box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false}]);
    const [selectedOption,setSelectedOption] = useState([{ label: "All Aces", value: "All Aces" }]);
    const [selectedDispatchedByOptions,setSelectedDispatchedByOptions] = useState([{ label: "Lily Rose Winters", value: "Lily Rose Winters" }]);
    const [selectedDispatchedByValue,setSelectedDispatchedByValue] = useState("");
    const [dispatch,setDispatch] = useState({
        courier: "All Aces",
        dispatchBoxesData: [{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }],
        tracker: "",
        bookingId: "",
        log_status: "dispatched",
        log_by_id: "",
        notes: "",
        dispatched_by:"Lily Rose Winters",
        other_dispatched_by:""
        
    });
    const [otherDispatchedFlag,setOtherDispatchedFlag] = useState("");
    const [selectedNumOfBox,setSelectedNumOfBox] = useState([{ label: "Black Box", value: "Black Box" }]);
    const [error,setError] = useState({});
    const [submitFlag,setSubmitFlag] = useState(0);
    const [loading, setLoading] = useState(false);
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));

    useEffect(()=>{
        setUser(JSON.parse(localStorage.getItem("user")));
        setDispatch({
            courier: "All Aces",
            dispatchBoxesData: [{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }],
            tracker: "",
            bookingId: props.bookingId,
            log_status: "dispatched",
            log_by_id: user.data._id,
            notes: "",
            dispatched_by:"Lily Rose Winters",
            other_dispatched_by:""
        });
        setBoxes([{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }]);
        setSubmitFlag(0);
        setSelectedDispatchedByValue("");
        setOtherDispatchedFlag(false);
        setSelectedDispatchedByOptions([{label: "Lily Rose Winters", value: "Lily Rose Winters" }]);
        setSelectedOption([{ label: "All Aces", value: "All Aces" }]);
    },[props.bookingId]);

    const handleCourier = (val) => {
        let value = val.value;
        dispatch.courier = value;
        setDispatch(dispatch);
        setSelectedOption(val);
    };

    const handleDispatchedBy = (val) => {
        let value = val.value;
        dispatch.dispatched_by = value;
        dispatch.other_dispatched_by = "";
        setDispatch(dispatch);
        setOtherDispatchedFlag(false);

        setSelectedDispatchedByValue(value);
        setSelectedDispatchedByOptions(val);
    };

    const handleOtherDispatchedName = (val) => {
        const dispatchData = {...dispatch};
        dispatchData.other_dispatched_by = val;
        setDispatch(dispatchData);
        setOtherDispatchedFlag(false);
    };

    const handleBoxType = (val, index) => {
        var  boxeses  = [...boxes];
        boxeses[index].box_type = val.value;
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
        setSelectedNumOfBox(val);
    };

    const handleBoxNumber = (val, index) => {
        var  boxeses  = [...boxes];
        
        if (val != "" && val < 1) {
            val = "";
        }

        boxeses[index].number_of_box = val;
        boxeses[index].numberOfBoxFlag = false;
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
    };

    const addMoreBox = () => {
        var  boxeses  = [...boxes];
        boxeses.push({ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false });
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
    };

    const handleBoxRemove = (ind) => {
        var  boxeses  = [...boxes];
        boxeses.splice(ind, 1);
        dispatch.dispatchBoxesData = boxeses;
        setBoxes(boxeses);
        setDispatch(dispatch);
    };

    const handleTrackerChange = (e) => {
        const { value } = e.target; 
        const dispatchData = {...dispatch};
        dispatchData.tracker = value;
        setDispatch(dispatchData);
    };

    const handleNotesChange = (e) => {      
        const { value } = e.target;
        const dispatchData = {...dispatch};
        dispatchData.notes = value;
        setDispatch(dispatchData);
    };

    const handleDispatch = async () => {
        setSubmitFlag(1);
        setOtherDispatchedFlag(true);

        var flag = 0;

        boxes.map(item => {
            if (item.box_type == "" || item.number_of_box == "") {
                flag = 1;
            }
            if (item.number_of_box == "") {
                item.numberOfBoxFlag = true;
            }
        });

        if (
            dispatch.courier == "" ||
            dispatch.tracker == ""
        ) {
            setLoading(true);
            try {
                const res = await bookingService.saveDispatchs(dispatch);
                if(res?.status === 200){
                    modalClose(true);
                }
                console.log(props.bookingId);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }

        } else if (flag == 0) {
            setLoading(true);
            try {
                const res = await bookingService.saveDispatchs(dispatch);
                console.log("res",res);
                if(res?.status === 200){
                    modalClose(true);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error: ", error);
                setError(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const modalClose = (flag) =>{
        setSubmitFlag(0);
        setSelectedDispatchedByValue("");
        setOtherDispatchedFlag(false);
        setError({});
        props.dispatchModalClose(flag);
    };


    return (
        <Modal
            size="md"
            backdrop="static"
            isOpen={props.dispatchModal}
            toggle={() => modalClose(false)}>
            <ModalHeader
                className=""
                toggle={() =>
                    modalClose(false)
                }>
                Add Dispatch
            </ModalHeader>
            <ModalBody>
                <div className="form-group position_up"
                    style={{ zIndex: boxes.length + 2 }}
                >
                    <label>Courier</label>
                    <Select
                        defaultValue={selectedOption}
                        onChange={(e) => handleCourier(e)}
                        options={options}
                    />
                    {error &&
                        submitFlag == 1 ? (
                        <span className="text-danger">
                            {error.courier
                                ? error.courier
                                : ""}
                        </span>
                    ) : (
                        ""
                    )}
                </div>

                <div className="form-group position_up"
                    style={{ zIndex: boxes.length + 1 }}
                >
                    <label>Dispatched By</label>
                    <Select
                        defaultValue={selectedDispatchedByOptions}
                        onChange={(e) => handleDispatchedBy(e)}
                        options={dispatchedByOptions}
                    />
                    {error &&
                        submitFlag == 1 ? (
                        <span className="text-danger">
                            {error.dispatched_by
                                ? error.dispatched_by
                                : ""}
                        </span>
                    ) : (
                        ""
                    )}
                </div>



                {selectedDispatchedByValue === "Other" ?
                    <div className="row">
                        <div className="col-sm-12 mb-3">
                            <Input
                                type="text"
                                value={
                                    dispatch?.other_dispatched_by
                                }
                                onChange={e =>
                                        handleOtherDispatchedName(
                                        e.target.value
                                    )
                                }
                                className="form-control"
                                name=""
                                placeholder="Other Dispatched Name"
                            />
                            {(dispatch?.other_dispatched_by)?.trim() ==
                                "" &&
                                otherDispatchedFlag ==
                                true ? (
                                <span className="text-danger">
                                    Please Enter Other Dispatched Name
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div> : ""}


                <div className="form-group">
                    <label>Number of Boxes</label>
                    {boxes &&
                        boxes.map(
                            (item, index) => (
                                <div
                                    className="form-group row position_up"
                                    style={{ zIndex: boxes.length - index }}
                                    key={index}>
                                    <div className="col-5 mb-3">
                                        <Select
                                            defaultValue={selectedNumOfBox}
                                            onChange={(e) => handleBoxType(e, index)}
                                            options={boxOptions}
                                            placeholder="Box Type"
                                        />
                                        {item.box_type ==
                                            "" &&
                                            submitFlag ==
                                            1 ? (
                                            <span className="text-danger">
                                                Please
                                                enter
                                                box type
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-5 col-sm-6 mb-3">
                                        <Input
                                            type="number"
                                            value={
                                                item.number_of_box
                                            }
                                            onChange={(e)=> handleBoxNumber(e.target.value,index)
                                            }
                                            className="form-control"
                                            name=""
                                            placeholder="Number of Boxes"
                                        />
                                        {item.number_of_box ==
                                            "" &&
                                            submitFlag ==
                                            1
                                            && item?.numberOfBoxFlag ? (
                                            <span className="text-danger">
                                                Please
                                                enter
                                                number
                                                of boxes
                                            </span>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                    <div className="col-1">
                                        {index != 0 && (
                                            <i
                                                className="fa fa-trash"
                                                onClick={() =>
                                                    handleBoxRemove(
                                                        index,
                                                    )
                                                }></i>
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                    <a
                        href="javascript:;"
                        className="sml-btn"
                        onClick={() =>
                            addMoreBox()
                        }>
                        <i className="fa fa-plus"></i>{" "}
                        Add More
                    </a>
                </div>
                <div className="form-group">
                    <label>GPS Tracker</label>
                    <Input
                        type="text"
                        className="form-control"
                        name=""
                        onChange={(e)=>handleTrackerChange(e)}
                    />
                    {error &&
                        submitFlag == 1 ? (
                        <span className="text-danger">
                            {error.tracker
                                ? error.tracker
                                : ""}
                        </span>
                    ) : (
                        ""
                    )}

                </div>
                <div className="form-group">
                    <label>Notes</label>
                    <Input
                        type="textarea"
                        className="form-control"
                        name=""
                        onChange={(e)=>handleNotesChange(e)}

                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        onClick={() =>
                            modalClose(false)
                        }
                        className="btn btn-secondary mr-2"
                        data-dismiss="modal">
                        Cancel
                    </button>
                    {loading == false && (
                        <button
                            className="btn btn-primary"
                            onClick={() =>
                                handleDispatch()
                            }>
                            Ok
                        </button>
                    )}
                    {loading == true && (
                        <button
                            className="btn btn-primary loader-icon"
                            disabled>
                            <Spinner className="img-svg"></Spinner>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default AddDispatch;



