import { setConstants } from "../constants";
import { setService } from "../services";

export const setActions = {
    getSetsList,
    getKitsList,
    getProductsList,
    addSet,
    getById,
    updateSet,
    delete: _delete
};

function getSetsList(search) {
    return dispatch => {
        dispatch(request());

        setService.getSetsList(search)
            .then(
                setsList => dispatch(success(setsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: setConstants.GETSETLIST_REQUEST }; }
    function success(setsList) { return { type: setConstants.GETSETLIST_SUCCESS, setsList }; }
    function failure(error) { return { type: setConstants.GETSETLIST_FAILURE, error }; }
}

function getKitsList(searchString) {
    return dispatch => {
        dispatch(request());

        setService.getKitsList(searchString)
            .then(
                kitsList => dispatch(success(kitsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: setConstants.GETKITSLIST_REQUEST }; }
    function success(kitsList) { return { type: setConstants.GETKITSLIST_SUCCESS, kitsList }; }
    function failure(error) { return { type: setConstants.GETKITSLIST_FAILURE, error }; }
}

function getProductsList(searchString) {
    return dispatch => {
        dispatch(request());

        setService.getProductsList(searchString)
            .then(
                productsList => dispatch(success(productsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: setConstants.GETPRODUCTSLIST_REQUEST }; }
    function success(productsList) { return { type: setConstants.GETPRODUCTSLIST_SUCCESS, productsList }; }
    function failure(error) { return { type: setConstants.GETPRODUCTSLIST_FAILURE, error }; }
}

function addSet(setInfo) {
    return dispatch => {
        dispatch(request());

        setService.addSet(setInfo)
            .then(
                setInfo => dispatch(success(setInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: setConstants.SETADD_REQUEST }; }
    function success(setInfo) { return { type: setConstants.SETADD_SUCCESS, setInfo }; }
    function failure(error) { return { type: setConstants.SETADD_FAILURE, error }; }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        setService.getById(id)
            .then(
                set => dispatch(success(set)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: setConstants.SETEDIT_REQUEST }; }
    function success(set) { return { type: setConstants.SETEDIT_SUCCESS, set }; }
    function failure(error) { return { type: setConstants.SETEDIT_FAILURE, error }; }
}

function updateSet(setInfo) {
    return dispatch => {
        dispatch(request());

        setService.updateSet(setInfo)
            .then(
                setInfo => dispatch(success(setInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: setConstants.SETUPDATE_REQUEST }; }
    function success(setInfo) { return { type: setConstants.SETUPDATE_SUCCESS, setInfo }; }
    function failure(error) { return { type: setConstants.SETUPDATE_FAILURE, error }; }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        setService.delete(id)
            .then(
                set => { 
                    console.log(set);
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: setConstants.DELETE_REQUEST, id }; }
    function success(id) { return { type: setConstants.DELETE_SUCCESS, id }; }
    function failure(id, error) { return { type: setConstants.DELETE_FAILURE, id, error }; }
}