import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { bookingService } from "../../../../../services";
import Select from "react-select";

const RepsUpdateModal = (props) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState({});
    const [errorMsg, setErrorMsg] = useState({ assigned_rep: "" });
    const [selectedReps, setSelectedReps] = useState([]);
    const [selectedAdditionalRep, setSelectedAdditionalRep] = useState([]);
    const [updateBookingInfo, setUpdateBookingInfo] = useState({
        id: "",
        assigned_rep: "",
        additional_reps: []
    });

    const setBookingData = (data) => {
        var defaultReps = props.reps.find(
            val => val.value === data.assigned_rep,
        );

        var defaultAdditionalReps = [];
        data.additional_reps.map(repValue => {
            defaultAdditionalReps.push(
                props.additionalReps.find(val => val.value === repValue),
            );
        });

        const updateBookingInfoResult = updateBookingInfo;
        updateBookingInfoResult["id"] = data["_id"];
        updateBookingInfoResult["assigned_rep"] = data["assigned_rep"];
        var tempAdditionalReps = [];
        data.additional_reps.map(item => {
            tempAdditionalReps.push(item);
        });
        updateBookingInfoResult["additional_reps"] = tempAdditionalReps;

        setUpdateBookingInfo(updateBookingInfoResult);
        setSelectedReps(defaultReps);
        setSelectedAdditionalRep(defaultAdditionalReps);
    };

    const setSelectedOptionAdditionalRep = selectedAdditionalRep => {
        var tempAdditionalReps = [];
        selectedAdditionalRep.map(item => {
            tempAdditionalReps.push(item.value);
        });

        setSelectedAdditionalRep(selectedAdditionalRep);
        setUpdateBookingInfo({...updateBookingInfo,additional_reps:tempAdditionalReps});
    };

    const setSelectedOptionRep = (e) => {
        var selectedRep = [{ value: e.value, label: e.label }];
        setSelectedReps(selectedRep);
        setUpdateBookingInfo({...updateBookingInfo,assigned_rep:e.value});
    };

     const handleSubmit = async() => {
        if (updateBookingInfo["assigned_rep"] == "") {
            let errorMsg = {
                assigned_rep: updateBookingInfo["assigned_rep"] == "" ? "Assigned reps is required." : "",
            };
            setErrorMsg(errorMsg);

        } else {
            updateBookingInfo["mail_sent"] = "false";
            updateBookingInfo["status"] = "repsUpdated";
            updateBookingInfo["log_by_id"] = user.data._id;
            var payload = {
                additional_reps: updateBookingInfo["additional_reps"],
                assigned_rep: updateBookingInfo["assigned_rep"],
                id: updateBookingInfo["id"],
                log_by_id: user.data._id,
                //status: "repsUpdated"
            };
            try{
                await bookingService.repsUpdate(payload);
                setErrorMsg({ assigned_rep: "", additional_reps: "" });
                props.getList(1,props.search);
                props.repsUpdateModalClose(true);
            }catch(error){
                setError(error);
            }
        }

    };

    useEffect(() => {
        if(props.bookingId !== 0 && props.isRepsUpdateModal){
            setUser(JSON.parse(localStorage.getItem("user")));
        }
    }, [props.bookingId,props.isRepsUpdateModal]);

    useEffect(() => {
        if(props.bookingInfoById?.assigned_rep !=undefined){
            setBookingData(props.bookingInfoById);
        }
    }, [props.bookingInfoById]);
    console.log("props11", props);
    return (
        <div>
            <Modal
                size="ml"
                backdrop="static"
                isOpen={props.isRepsUpdateModal}
                toggle={() => {props.repsUpdateModalClose(false); setErrorMsg({ assigned_rep: "", additional_reps: "" }); setSelectedReps([]); setSelectedAdditionalRep([]);
                }}>
                <ModalHeader
                    className=""
                    toggle={() => {props.repsUpdateModalClose(false); setErrorMsg({ assigned_rep: "", additional_reps: "" }); setSelectedReps([]); setSelectedAdditionalRep([]);
                    }}>
                    Reps Update
                </ModalHeader>
                <ModalBody>
                    <div>

                        <div className="d-card mt-4">
                            <div className="form-group">
                                <label>
                                    Assigned Rep{" "}
                                    <span className="text-danger">*</span>
                                </label>
                                <div className="position_up">
                                    <Select
                                        value={selectedReps}
                                        onChange={setSelectedOptionRep}
                                        options={props.reps}
                                    />
                                    {errorMsg ? (
                                        <span className="text-danger">
                                            {error?.assigned_rep
                                                ? error?.assigned_rep
                                                : errorMsg?.assigned_rep ? errorMsg?.assigned_rep : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Additional Reps{" "}
                                    {/* <span className="text-danger">*</span> */}
                                </label>
                                <Select
                                    value={selectedAdditionalRep}
                                    onChange={
                                        setSelectedOptionAdditionalRep
                                    }
                                    options={props.additionalReps}
                                    isMulti={true}
                                />
                            </div>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button
                            onClick={() => {props.repsUpdateModalClose(false); setErrorMsg({ assigned_rep: "", additional_reps: "" }); setSelectedReps([]); setSelectedAdditionalRep([]); }}
                            className="btn btn-secondary mr-2"
                            data-dismiss="modal">
                            Cancel
                        </button>

                        <button
                            className="btn btn-primary"
                            onClick={() => handleSubmit()}
                        >
                            Update
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

        </div>
    );
};

export default RepsUpdateModal;

