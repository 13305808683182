import {collectionBookingConstants } from "../constants";

const initialState =  { loading: null, lists: null, } || {};

export function collectionBookings(state = initialState, action) {
    switch (action.type) {
    case collectionBookingConstants.GETALLCOLLECTIONBOOKINGS_REQUEST:
        return {
            loading: true
        };
    case collectionBookingConstants.GETALLCOLLECTIONBOOKINGS_SUCCESS:
        return {
            loading: false,
            collectionList: action.collectionList.data,
            activePage: action.collectionList.page,
            totalItemsCount: action.collectionList.total_count,
            limit: action.collectionList.page_count
        };
    case collectionBookingConstants.GETALLCOLLECTIONBOOKINGS_FAILURE:
        return { 
            error: action.error
        };
    case collectionBookingConstants.COUNTCOLECTIONNBOOKINGS_REQUEST:
        return {
            loading: true,
        };
    case collectionBookingConstants.COUNTCOLECTIONBOOKINGS_SUCCESS:
        return {
            loading: false,
            collectionCount:action.count.data,
        };
    case collectionBookingConstants.COUNTCOLECTIONBOOKINGS_FAILURE:
        return { 
            error: action.error,
        };
  
    default:
        return state;
    }
}