import { productConstants } from "../constants";
import { productService } from "../services";

export const productActions = {
    getProductsList,
    addProduct,
    getById,
    updateProduct,
    delete: _delete
};

function getProductsList(search) {
    return dispatch => {
        dispatch(request());

        productService.getProductsList(search)
            .then(
                productsList => dispatch(success(productsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: productConstants.GETALLPRODUCTS_REQUEST }; }
    function success(productsList) { return { type: productConstants.GETALLPRODUCTS_SUCCESS, productsList }; }
    function failure(error) { return { type: productConstants.GETALLPRODUCTS_FAILURE, error }; }
}

function addProduct(productInfo) {
    return dispatch => {
        dispatch(request());

        productService.addProduct(productInfo)
            .then(
                productInfo => dispatch(success(productInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: productConstants.PRODUCTADD_REQUEST }; }
    function success(productInfo) { return { type: productConstants.PRODUCTADD_SUCCESS, productInfo }; }
    function failure(error) { return { type: productConstants.PRODUCTADD_FAILURE, error }; }
}

function updateProduct(productInfo) {
    return dispatch => {
        dispatch(request());

        productService.update(productInfo)
            .then(
                productInfo => dispatch(success(productInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: productConstants.PRODUCTUPDATE_REQUEST }; }
    function success(productInfo) { return { type: productConstants.PRODUCTUPDATE_SUCCESS, productInfo }; }
    function failure(error) { return { type: productConstants.PRODUCTUPDATE_FAILURE, error }; }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        productService.getById(id)
            .then(
                product => dispatch(success(product)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: productConstants.PRODUCTEDIT_REQUEST }; }
    function success(product) { return { type: productConstants.PRODUCTEDIT_SUCCESS, product }; }
    function failure(error) { return { type: productConstants.PRODUCTEDIT_FAILURE, error }; }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        productService.delete(id)
            .then(
                product => { 
                    console.log(product);
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: productConstants.DELETE_REQUEST, id }; }
    function success(id) { return { type: productConstants.DELETE_SUCCESS, id }; }
    function failure(id, error) { return { type: productConstants.DELETE_FAILURE, id, error }; }
}