import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import { Input, UncontrolledTooltip } from "reactstrap";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import FilterTooltip from "./Modal/Filter";
import {
	bookingService,
	kitService,
	surgeonService,
	operationService,
} from "../../../../services";
import dateFormat from "dateformat";
import moment from "moment";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { ReactComponent as FilterIcon } from "../../../../assets/images/filter.svg";
import RepsUpdateModal from "./Modal/RepsUpdateModal";
import InfinitScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import ChangeDate from "./Modal/ChangeDate";
import Cancel from "./Modal/Cancel";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ForClose from "./Modal/ForClose";
import BunchForClose from "./Modal/BunchForClose";
import Norecord from "./Norecord";
import Select from "react-select";
const head = [
	{ title: "Id", dataIndex: "Id" },
	{ title: "Hospital Name", dataIndex: "Hospital Name" },
	{ title: "Dispatch Date", dataIndex: "Dispatch Date" },
	{ title: "Delivery Time", dataIndex: "Delivery Time" },
	{ title: "Operation Date", dataIndex: "Operation Date" },
	{ title: "Slot", dataIndex: "Slot" },
	{ title: "Notes", dataIndex: "Notes" },
	{ title: "Kit", dataIndex: "Kit" },
];

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

const Index = () => {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
	const [newBookingList, setNewBookingList] = useState([]);
	const [oldBookingList, setOldBookingList] = useState([]);
	const [search, setSearch] = useState("");
	const [searchKit, setSearchKit] = useState("");
	const [searchHospital, setSearchHospital] = useState("");
	const [todayDate, setTodayDate] = useState("");
	const [activePage, setActivePage] = useState(1);
	const [limit, setLimit] = useState(30);
	const [loading, setLoading] = useState(false);
	const [cancelModal, setCancelModal] = useState(false);
	const [cancelId, setCancelId] = useState(0);
	const [bookingId, setBookingId] = useState(0);
	const [dateModal, setDateModal] = useState(false);
	const [bokkingDate, setBokkingDate] = useState({
		date_delivery_date: "",
		date_collection_date: "",
		date_operation_date: "",
	});
	const [deliveryDateIsDisabled, setDeliveryDateIsDisabled] = useState(true);
	const [dataXlsx, setDataXlsx] = useState([]);
	const [filterOperationToDate, setfilterOperationToDate] = useState("");
	const [
		filterOperationFromDate,
		setfilterOperationFromDate,
		filterOperationFromDateRef,
	] = useState(moment().format("YYYY-MM-DD"));
	const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState("");
	const [searchDeliveryFromDate, setsearchDeliveryFromDate] = useState("");
	const [searchOperationFromDate, setsearchOperationFromDate] = useState(
		new Date()
	);
	const [searchOperationToDate, setsearchOperationToDate] = useState("");
	const [filterModal, setFilterModal] = useState(false);
	const [filterPoChase, setFilterPoChase, filterPoChaseRef] = useState("all");
	const [checkSetOnReturn, setCheckSetOnReturn] = useState(false);
	const [filterCreateDate, setFilterCreateDate, filterCreateDateRef] =
		useState("");
	const [repsUpdateModal, setRepsUpdateModal] = useState(false);
	const [bookingRepsUpdateId, setBookingRepsUpdateId] = useState(0);
	const [listFlag, setListFlag] = useState(0);
	const [reps, setReps] = useState([]);
	const [additionalReps, setAdditionalReps] = useState([]);
	const [bookingInfoById, setBookingInfoById] = useState({});
	const [hoverBookingId, setHoverBookingId] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [totalItemsCount, setTotalItemsCount, totalItemsCountRef] =
		useState(0);
	const [hospitals, setHospitalList] = useState([]);
	// const [hospitalId, setHospitalId, hospitalIdRef] = useState("");
	const [kits, setKits] = useState([]);
	const [selectedKit, setSelectedKit] = useState([]);
	const [selectdKitValue, setSelectdKitValue, selectdKitValueRef] =
		useState("");
	const [surgeons, setSurgeons] = useState([]);
	const [selectedSurgeon, setSelectedSurgeon] = useState([
		{ label: "All Surgeon", value: "" },
	]);
	const [surgeonId, setSurgeonId, surgeonIdRef] = useState("");
	const [operationsList, setOperationsList] = useState([]);
	const [selectedOperation, setSelectedOperation] = useState([
		{ label: "All Operation", value: "" },
	]);
	const [operationId, setOperationId, operationIdRef] = useState("");
	const [selectdSetValue, setSelectdSetValue, selectdSetValueRef] =
		useState("");
	const [selectedStatus, setSelectedStatus, selectedStatusRef] = useState("");
	const [selectedAllCheckBox, setSelectedAllCheckBox] = useState(false);
	const [forCloseModal, setForCloseModal] = useState(false);
	const [forCloseId, setForCloseId] = useState(0);
	const [bunchForCloseModal, setBunchForCloseModal] = useState(false);
	const [bunchForCloseId, setBunchForCloseId] = useState([]);
	const [selectedHospital, setSelectedHospital] = useState([
		{ label: "All Hospital", value: "" },
	]);

	const handleFilterToggle = () => {
		setFilterModal(!filterModal);
	};

	const handleFilterRefreesh = (data) => {
		if (data !== undefined) {
			setDataXlsx([]);
			setNewBookingList([]);
			setOldBookingList([]);

			if (
				filterPoChaseRef.current === data?.filterPoChase &&
				filterCreateDateRef.current === data?.filterCreateDate &&
				// hospitalIdRef.current === data?.hospitalId &&
				selectdKitValueRef.current === data?.selectdKitValue &&
				surgeonIdRef.current === data?.surgeonId &&
				operationIdRef.current === data?.operationId &&
				selectdSetValueRef.current === data?.selectdSetValue &&
				selectedStatusRef.current === data?.selectedStatus
			) {
				getList(1);
			}

			setActivePage(1);
			setFilterModal(!filterModal);
			setFilterPoChase(data?.filterPoChase);
			setCheckSetOnReturn(data?.checkSetOnReturn);
			setFilterCreateDate(data?.filterCreateDate);
			// setHospitalId(data?.hospitalId);
			// setSelectedHospital(data?.selectedHospitalProp);
			setSelectdKitValue(data?.selectdKitValue);
			setSelectedKit(data?.selectedKitProp);
			setSurgeonId(data?.surgeonId);
			setSelectedSurgeon(data?.selectedSurgeonProp);
			setOperationId(data?.operationId);
			setSelectedOperation(data?.selectedOperationProp);
			setSelectdSetValue(data?.selectdSetValue);
			setSelectedStatus(data?.selectedStatus);
		}
	};

	const getList = async (flag, searchTerm, currentPage) => {
		if (currentPage === true) {
			setActivePage(activePage);
		} else {
			setActivePage(flag ? 1 : activePage + 1);
		}
		setLoading(true);

		try {
			const res = await bookingService.getList({
				operation_from_date: filterOperationFromDateRef.current,
				operation_to_date: filterOperationToDate,
				type: ["loan"],
				status:
					selectedStatusRef.current == ""
						? ["booked", "dispatch", "return"]
						: [selectedStatus],
				working_delivery_from_date: filterDeliveryFromDate,
				search: searchTerm != undefined ? searchTerm : search,
				searchKit: searchKit,
				searchHospital: "",
				page: flag ? 1 : activePage + 1,
				limit: limit,
				check_set: checkSetOnReturn,
				po_check: filterPoChaseRef.current,
				created_date: filterCreateDateRef.current,
				hospitalFilter: searchHospital,
				kitFilter: selectdKitValueRef.current,
				surgeonFilter: surgeonIdRef.current,
				operationFilter: operationIdRef.current,
				setFilter: selectdSetValueRef.current,
			});
			if (res.status) {
				arrangeBookingList(res.data, flag);
				setTotalItemsCount(res?.total_count);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error: ", error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const handleSearch = (val) => {
		console.log("handleSearch", val);
		setDataXlsx([]);
		setNewBookingList([]);
		setOldBookingList([]);
		setSearch(val.target.value);
		setActivePage(1);
	};
	

	const updatePONumber = (ev, item, mainInd, index) => {
		let result = newBookingList;
		result[mainInd][index].po_number = ev.target.value;
		result[mainInd][index].po_check = ev.target.value == "" ? true : false;
		setNewBookingList(result);
		bookingService
			.dashboardPOChecked({
				id: item?._id,
				po_check: ev.target.value == "" ? true : false,
				po_number: ev.target.value,
			})
			.then((res) => {
				console.log("res", res);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const debouncedSearch = useMemo(() => {
		getList(1);
		return debounce(handleSearch, 1500);
	}, [search]);

	const handleKItSearch = (val) => {
		console.log("handleKItSearch", val);
		setDataXlsx([]);
		setNewBookingList([]);
		setOldBookingList([]);
		setSearchKit(val.target.value);
		setActivePage(1);
	};

	const debouncedKitSearch = useMemo(() => {
		getList(1);
		return debounce(handleKItSearch, 1500);
	}, [searchKit]);

	const handleHospitalSearch = (val) => {
		setSelectedHospital(val);
		console.log("handleHospitalSearch", val);
		setDataXlsx([]);
		setNewBookingList([]);
		setOldBookingList([]);
		setSearchHospital(val.value);
		setActivePage(1);
	};

	const debouncedHospitalSearch = useMemo(() => {
		getList(1);
		return debounce(handleHospitalSearch, 1500);
	}, [searchHospital]);

	const debouncedPONumber = useMemo(() => {
		return debounce(updatePONumber, 500);
	}, [newBookingList]);

	const handleSearchDeliveryFromDate = (val) => {
		setDataXlsx([]);
		setNewBookingList([]);
		setOldBookingList([]);
		setActivePage(0);
		setsearchDeliveryFromDate(val != null ? new Date(val) : "");
		setfilterDeliveryFromDate(
			val != null ? moment(val).format("YYYY-MM-DD") : ""
		);
	};

	const handleSearchOperationFromDate = (val) => {
		setDataXlsx([]);
		let currentDeliveryFromDate =
			val != null ? moment(val).format("YYYY-MM-DD") : "";
		if (currentDeliveryFromDate != filterOperationFromDateRef.current) {
			setNewBookingList([]);
			setOldBookingList([]);
			setActivePage(0);
		}

		setsearchOperationFromDate(val != null ? new Date(val) : "");
		setfilterOperationFromDate(
			val != null ? moment(val).format("YYYY-MM-DD") : ""
		);
	};

	const handleSearchOperationToDate = (val) => {
		setDataXlsx([]);
		setNewBookingList([]);
		setOldBookingList([]);
		setActivePage(0);
		setsearchOperationToDate(val != null ? new Date(val) : "");
		setfilterOperationToDate(
			val != null ? moment(val).format("YYYY-MM-DD") : ""
		);
	};

	const handleSearchDeliveryDate = () => {
		setDataXlsx([]);
		if (
			filterOperationFromDate === "" &&
			filterOperationToDate === "" &&
			filterDeliveryFromDate === ""
		) {
			getList(1, "");
		}

		document.getElementById("searchInput").value = "";
		document.getElementById("searchKitInput").value = "";
		document.getElementById("searchHospitalInput").value = "";

		setNewBookingList([]);
		setOldBookingList([]);
		setActivePage(0);
		setSearch("");
		setSelectedHospital([{ label: "All Hospital", value: "" }]);
		setSearchKit("");
		setSearchHospital("");
		setsearchOperationFromDate("");
		setsearchOperationToDate("");
		setsearchDeliveryFromDate("");
		setfilterOperationFromDate("");
		setfilterOperationToDate("");
		setfilterDeliveryFromDate("");
	};

	const cancelModalOpen = (id) => {
		setCancelModal(true);
		setCancelId(id);
	};

	const cancelModalClose = (flag) => {
		if (flag) {
			setNewBookingList([]);
			setOldBookingList([]);
		}
		setCancelModal(false);
		setCancelId("");
	};

	const dateModalOpen = (item) => {
		setDateModal(true);
		setBookingId(item._id);
		setBokkingDate({
			id: item._id,
			log_status: "updated",
			log_by_id: user.data._id,
			delivery_date: dateFormat(item.delivery_date, "yyyy-mm-dd"),
			collection_date: dateFormat(item.collection_date, "yyyy-mm-dd"),
			operation_date: dateFormat(item.operation_date, "yyyy-mm-dd"),
		});
		setDeliveryDateIsDisabled(
			item?.bookingdispatches?.length > 0 &&
				item.bookingcollections.length === 0
				? false
				: true
		);
	};

	const dateModalClose = (flag) => {
		if (flag) {
			setNewBookingList([]);
			setOldBookingList([]);
		}
		setBokkingDate({
			date_delivery_date: "",
			date_collection_date: "",
			date_operation_date: "",
		});
		setDateModal(false);
		setBookingId(0);
		setDeliveryDateIsDisabled(true);
	};

	const arrangeBookingList = (bookingList, flag) => {
		let allBookingList = [];
		var newBookingListArray = [];
		var prevDate = "";
		var prevIndex = 0;
		var todayDate = "";
		var dateArray = [];
		allBookingList = flag
			? bookingList
			: oldBookingList.concat(bookingList);

		console.log("arrangeBookingList", allBookingList);
		setOldBookingList(allBookingList);

		allBookingList.map((bookingItem, index) => {
			var currDate = bookingItem.operation_date;

			if (hoverBookingId !== bookingItem._id) {
				bookingItem.po_hover = undefined;
			}

			if (currDate !== prevDate) {
				if (index != 0) {
					prevIndex = prevIndex + 1;
				}
				prevDate = bookingItem.operation_date;
				newBookingListArray[prevIndex] = [];
			}

			todayDate = moment().format("YYYY-MM-DD");
			var dateToCheck = moment(currDate).format("YYYY-MM-DD");

			if (moment(dateToCheck).isSameOrAfter(todayDate)) {
				if (!dateArray.includes(prevIndex)) {
					dateArray.push(prevIndex);
				}
			}
			newBookingListArray[prevIndex].push(bookingItem);
		});
		console.log("newBookingListArray", newBookingListArray);
		setNewBookingList(newBookingListArray);
		setTodayDate(todayDate);

		if (selectedAllCheckBox == true) {
			setSelectedAllCheckBox(true);
			var bokIds = allBookingList.map((option) => option._id);
			setBunchForCloseId(bokIds);
		}
	};

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const showXlsData = (data) => {
		var dataXlsx = [];
		var todayIndex = [];

		data.map((item, index) => {
			var kitDataArray = [];

			item.bookingKitSetId.map((kitdata) => {
				if (!kitDataArray.includes(kitdata?.kitData[0]?.name)) {
					kitDataArray.push(kitdata?.kitData[0]?.name);
				}
			});

			dataXlsx.push({
				Id: item.booking_id,
				"Hospital Name": item.hospitalData[0].name,
				"Dispatch Date": moment(item.delivery_date).format(
					"ddd, MMMM Do, YYYY"
				),
				"Operation Date": moment(item.operation_date).format(
					"ddd, MMMM Do, YYYY"
				),
				Slot: item.operation_date_slot,
				"Delivery Time": item.delivery_date_slot,
				Notes: item.additional_notes,
				Kit: kitDataArray.join(),
			});

			var todayDate = moment().format("DD/MM/YYYY");

			if (moment(item.operation_date).format("DD/MM/YYYY") == todayDate) {
				todayIndex.push(index);
			}
		});

		if (todayIndex.length !== 0) {
			for (var i = 0; i < todayIndex.length; i++) {
				var todayBooking = dataXlsx[todayIndex[i]];
				dataXlsx.splice(todayIndex[i], 1);
				dataXlsx.unshift(todayBooking);
			}
		}

		setDataXlsx(dataXlsx);
		exportToExcel(dataXlsx);
		setIsLoading(false);
	};

	const exportToExcel = (data) => {
		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});
		const blob = new Blob([excelBuffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "bookings.xlsx";
		a.click();
		URL.revokeObjectURL(url);
	};

	const handlePOCheck = (ev, item, mainInd, index) => {
		let result = newBookingList;
		result[mainInd][index].po_number =
			!item?.po_check == true ? "" : item.po_number;
		result[mainInd][index].po_check = ev.target.checked;
		setListFlag(1);
		setNewBookingList(result);
		bookingService
			.dashboardPOChecked({
				id: item?._id,
				po_check: ev.target.checked,
				po_number: ev.target.checked == true ? "" : item.po_number,
			})
			.then((res) => {
				console.log("res", res);
				setListFlag(0);

			})
			.catch((error) => {
				setListFlag(0);
				console.error("Error:", error);
			});
	};

	const handleCheckSet = (ev, item, mainInd, index) => {
		let result = newBookingList;
		result[mainInd][index].check_set = ev.target.checked;
		setListFlag(1);
		setNewBookingList(result);
		bookingService
			.dashboardCheckSet({
				id: item?._id,
				check_set: ev.target.checked,
			})
			.then((res) => {
				console.log("res", res);
				setListFlag(0);

			})
			.catch((error) => {
				setListFlag(0);
				console.error("Error:", error);
			});
	};

	const updateMouseLeave = (item, mainInd, index) => {
		setHoverBookingId("");
		let result = [...newBookingList];
		result[mainInd][index].po_hover = undefined;
		setNewBookingList(result);
	};

	const updateMouseEnter = (item, mainInd, index) => {
		setHoverBookingId(item?._id);
		let result = [...newBookingList];
		result[mainInd][index].po_hover = 1;
		setNewBookingList(result);
	};

	const repsUpdateModalOpen = (item) => {
		if (item?._id) {
			getByIdInfo(item?._id);
			setBookingRepsUpdateId(item._id);
			setRepsUpdateModal(true);
		}
	};

	const repsUpdateModalClose = (flag) => {
		if (flag) {
			setNewBookingList([]);
			setOldBookingList([]);
		}
		setBookingInfoById({});
		setRepsUpdateModal(false);
	};

	const showRepsList = (repsList) => {
		var tempRepsList = [];
		repsList.map((item) => {
			var option = {
				value: item._id,
				label: item.first_name + " " + item.last_name,
			};
			tempRepsList.push(option);
		});

		setReps(tempRepsList);
		setAdditionalReps(tempRepsList);
	};

	const getAllRepsList = async () => {
		try {
			const res = await bookingService.getRepsList({ search: "" });
			showRepsList(res.data);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getByIdInfo = async (id) => {
		try {
			const res = await bookingService.getById(id);
			setBookingInfoById(res.data);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const searchKeyUp = (e) => {
		let keyCodeArray = [
			8, 13, 16, 17, 18, 20, 27, 37, 39, 45, 46, 113, 114, 115, 117, 118,
			119, 120, 121, 122, 123, 132,
		];
		let flag = keyCodeArray.includes(e.keyCode);
		console.log(e.keyCode);
		if (e.altKey || e.ctrlKey) {
			return true;
		} else if (!flag) {
			setLoading(true);
			setNewBookingList([]);
			setOldBookingList([]);
		}
	};

	useEffect(() => {
		getAllRepsList();
		getHospitalList();
		getKitListDropdown();
		getSurgeonListDropdown();
		getOperationListDropdown();
		setUser(JSON.parse(localStorage.getItem("user")));
		//setLimit(50);
		console.log(
			bookingId,
			setLimit,
			filterOperationFromDate,
			// hospitalId,
			selectdKitValue,
			surgeonId,
			operationId,
			selectdSetValue
		);
		handleSearchOperationFromDate(moment().toISOString());
	}, []);

	//activePage
	useEffect(() => {
		getList(1);
	}, [
		filterOperationFromDateRef.current,
		filterCreateDateRef.current,
		filterPoChaseRef.current,
		checkSetOnReturn,
		filterOperationToDate,
		filterDeliveryFromDate,
		selectdKitValueRef.current,
		surgeonIdRef.current,
		operationIdRef.current,
		selectdSetValueRef.current,
		selectedStatusRef.current,
	]);

	useEffect(() => {
		console.log(listFlag, checkSetOnReturn, filterPoChase, filterCreateDate, totalItemsCount);
	}, []);

	const getXlsxData = async () => {
		setIsLoading(true);
		try {
			const res = await bookingService.getBookingsExport({
				operation_from_date: filterOperationFromDateRef.current,
				operation_to_date: filterOperationToDate,
				type: ["loan"],
				status: ["booked", "dispatch", "return"],
				working_delivery_from_date: filterDeliveryFromDate,
				search: "",
				page: 1,
				limit: totalItemsCountRef.current,
				po_check: filterPoChaseRef.current,
				check_set: checkSetOnReturn,
				created_date: filterCreateDateRef.current,
				hospitalFilter: search,
				kitFilter: selectdKitValueRef.current,
				surgeonFilter: surgeonIdRef.current,
				operationFilter: operationIdRef.current,
				setFilter: selectdSetValueRef.current,
			});
			if (res.status) {
				showXlsData(res?.data);
			}
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getHospitalList = async () => {
		try {
			const res = await bookingService.getHospitalsList({ search: "" });
			if (res.status === 200) {
				showHospitalsList(res?.data);
			}
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const showHospitalsList = (hospitalsList) => {
		let tempHospitals = [{ label: "All Hospital", value: "" }];
		hospitalsList.map((item) => {
			let option = { value: item._id, label: item.name };
			tempHospitals.push(option);
		});
		setHospitalList(tempHospitals);
	};

	const getKitListDropdown = async () => {
		try {
			const res = await kitService.kitListDropdown({ search: "" });

			let kitList = res?.data ? res?.data : [];
			let tempKitList = [];

			kitList?.length &&
				kitList.map((item) => {
					var option = { value: item._id, label: item.name };
					tempKitList.push(option);
				});

			setKits(tempKitList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getSurgeonListDropdown = async () => {
		try {
			const res = await surgeonService.getSurgeonsList({ search: "" });

			let surgeonList = res?.data ? res?.data : [];
			let tempSurgeonList = [{ label: "All Surgeon", value: "" }];
			surgeonList &&
				surgeonList.map((item) => {
					let option = {
						value: item._id,
						label: item.first_name + " " + item.last_name,
						notes: item?.notes ? item?.notes : "",
					};
					tempSurgeonList.push(option);
				});
			console.log("surgeonList", tempSurgeonList);
			setSurgeons(tempSurgeonList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getOperationListDropdown = async () => {
		try {
			const res = await operationService.getOperationsList({
				search: "",
			});

			let operationList = res?.data ? res?.data : [];
			let tempOperationList = [{ label: "All Operation", value: "" }];
			operationList &&
				operationList.map((item) => {
					let option = {
						value: item.name,
						label: item.name,
						id: item._id,
					};
					tempOperationList.push(option);
				});
			console.log("operationList", tempOperationList);
			setOperationsList(tempOperationList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const forCloseModalOpen = (id) => {
		setForCloseModal(true);
		setForCloseId(id);
		setBookingId(id._id);
	};

	const forCloseModalClose = (flag) => {
		if (flag) {
			setNewBookingList([]);
			setOldBookingList([]);
		}
		setForCloseModal(false);
		setForCloseId("");
		setBookingId(0);
	};

	const bunchForCloseModalOpen = () => {
		setBunchForCloseModal(true);
	};

	const bunchForCloseModalClose = (flag) => {
		if (flag) {
			setNewBookingList([]);
			setOldBookingList([]);
			setBunchForCloseId([]);
			getList(1);
		}
		setBunchForCloseModal(false);
	};

	const handleCheckBunchForCloseId = (e, item) => {
		if (e.target.checked == true) {
			setBunchForCloseId([...bunchForCloseId, item._id]);
		} else {
			setSelectedAllCheckBox(false);
			let ids = bunchForCloseId;
			const newIds = ids.filter((x) => x !== item._id);
			setBunchForCloseId(newIds);
		}
	};

	const handleAllCheckBox = (e) => {
		if (e.target.checked == true) {
			setSelectedAllCheckBox(true);
			var bokIds = oldBookingList.map((option) => option._id);
			setBunchForCloseId(bokIds);
		} else {
			setSelectedAllCheckBox(false);
			setBunchForCloseId([]);
		}
	};


	if (!user || user.data.user_role !== "admin") {
		return <Redirect to="/" />;
	}

	return (
		<div>
			<Navbar activePage="bookings" />
			<div className="container-fluid">
				<div className="row pt-2">
					<div className="col-md-6 col-lg-4">
						<ul className="sub-menu">
							<li>
								<Link to="/admin/bookings" className="active">
									Open
								</Link>
							</li>
							<li>
								<Link to="/admin/bookings/close">Closed</Link>
							</li>
							<li>
								<Link to="/admin/bookings/cancel">
									Cancelled
								</Link>
							</li>
							<li>
								<Link to="/admin/bookings/booking">Simple</Link>
							</li>
						</ul>
					</div>
				</div>

				<div className="row pt-2 mb-2">
					<div className="col-md-6 col-lg-2 mb-5 mb-md-4 mb-lg-0">
						<div className="form-search">
							<Input
								className="form-control mr-sm-2"
								type="text"
								placeholder="Search Kits..."
								id="searchKitInput"
								onKeyUp={(e) => {
									searchKeyUp(e);
								}}
								onChange={debouncedKitSearch}
							/>
						</div>
					</div>
					<div className="col-md-6 col-lg-2 mb-5 mb-md-4 mb-lg-0">
						<div className="form-search">
							<Select
								value={selectedHospital}
								onChange={debouncedHospitalSearch}
								options={hospitals}
								className="small text-left"
								id="searchHospitalInput"
							/>
						</div>
					</div>
					<div className="col-md-6 col-lg-3 mb-5 mb-md-4 mb-lg-0">
						<div className="form-search">
							<Input
								className="form-control mr-sm-2"
								type="text"
								placeholder="Search Booking IDs..."
								id="searchInput"
								//value={search}
								onKeyUp={(e) => {
									searchKeyUp(e);
								}}
								onChange={debouncedSearch}
							/>
						</div>
					</div>
					<div className="col-10 col-md-6 col-lg-3">
						<div className="label_adjust mx-lg-auto">
							<label className="small mb-0">
								Operation Date{" "}
							</label>
							<div className="row no-gutters">
								<div className="col">
									<DatePicker
										dateFormat="dd/MM/yyyy"
										showClearButton={false}
										placeholderText="From"
										selected={searchOperationFromDate}
										onChange={(e) =>
											handleSearchOperationFromDate(e)
										}
										className="form-control"
										disabled={!loading ? false : true}
										customInput={
											<MaskedInput
												pipe={autoCorrectedDatePipe}
												mask={[
													/\d/,
													/\d/,
													"/",
													/\d/,
													/\d/,
													"/",
													/\d/,
													/\d/,
													/\d/,
													/\d/,
												]}
												keepCharPositions={true}
												guide={true}
												showMask={false}
											/>
										}
									/>
								</div>
								<div className="col">
									<div className="mx-1">
										<DatePicker
											dateFormat="dd/MM/yyyy"
											showClearButton={false}
											placeholderText="To"
											selected={searchOperationToDate}
											onChange={(e) =>
												handleSearchOperationToDate(e)
											}
											className="form-control"
											disabled={!loading ? false : true}
											customInput={
												<MaskedInput
													//onMouseLeave={(e)=>handleClearToDate(e)}
													pipe={autoCorrectedDatePipe}
													mask={[
														/\d/,
														/\d/,
														"/",
														/\d/,
														/\d/,
														"/",
														/\d/,
														/\d/,
														/\d/,
														/\d/,
													]}
													keepCharPositions={true}
													guide={true}
													showMask={false}
												/>
											}
										/>
									</div>
								</div>
								<div className="col">
									<div className="label_adjust">
										<label className="small mb-0">
											Delivery Date{" "}
										</label>
										<span
											className={
												loading
													? "clear-disabled small ml-2 btn-clear"
													: "small ml-2 btn-clear"
											}
										>
											<a
												style={{
													position: "relative",
													top: "5px",
												}}
												href="javascript:;"
												onClick={() =>
													loading
														? ""
														: handleSearchDeliveryDate()
												}
											>
												Clear
											</a>
										</span>
										<div className="no-gutters">
											<DatePicker
												dateFormat="dd/MM/yyyy"
												showClearButton={false}
												selected={
													searchDeliveryFromDate
												}
												onChange={(e) =>
													handleSearchDeliveryFromDate(
														e
													)
												}
												className="form-control"
												disabled={
													!loading ? false : true
												}
												customInput={
													<MaskedInput
														//onMouseLeave={(e)=>handleClearOperationDate(e)}
														pipe={
															autoCorrectedDatePipe
														}
														mask={[
															/\d/,
															/\d/,
															"/",
															/\d/,
															/\d/,
															"/",
															/\d/,
															/\d/,
															/\d/,
															/\d/,
														]}
														keepCharPositions={true}
														guide={true}
														showMask={false}
													/>
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="text-right col-2 col-md-6 col-lg-2">
						<div className="custom_fltr">
							<a onClick={() => handleFilterToggle()}>
								<FilterIcon className="img-svg"></FilterIcon>
							</a>
							<FilterTooltip
								handleFilterToggle={() => handleFilterToggle()}
								filterModal={filterModal}
								filterPoChase={filterPoChaseRef.current}
								filterCreateDate={filterCreateDateRef.current}
								handleFilterRefreesh={(data) =>
									handleFilterRefreesh(data)
								}
								hospitals={hospitals}
								setSelectedHospital={setSelectedHospital}
								selectedHospitalProp={selectedHospital}
								kits={kits}
								selectedKitProp={selectedKit}
								surgeons={surgeons}
								selectedSurgeonProp={selectedSurgeon}
								operationsList={operationsList}
								selectedOperationProp={selectedOperation}
							/>
						</div>

						<Link
							className="btn btn-primary add_option"
							to="/admin/booking/add"
						>
							<i className="fa fa-plus"></i> <span>Add</span>
						</Link>

						<button
							className={
								!loading && !isLoading
									? "btn btn-default add_print ml-3"
									: "btn btn-default add_print ml-3 dropdown-disabled"
							}
							onClick={() =>
								!loading && !isLoading && oldBookingList?.length
									? getXlsxData()
									: ""
							}
							id="ReportDownload"
						>
							<i className="fa fa-print"></i>
						</button>

						{!loading && !isLoading ? (
							""
						) : (
							<UncontrolledTooltip target={"ReportDownload"}>
								{loading
									? "Please wait"
									: isLoading
									? "Please wait"
									: "No Record Found"}
							</UncontrolledTooltip>
						)}

						{dataXlsx && (
							<ExportSheet
								header={head}
								fileName={"bookings"}
								dataSource={dataXlsx}
								xlsx={XLSX}
							>
								<span></span>
							</ExportSheet>
						)}
					</div>
				</div>
				<InfinitScroll
					dataLength={oldBookingList.length}
					next={() => {
						oldBookingList.length > 5 ? getList(0) : () => {};
					}}
					scrollThreshold={"100%"}
					hasMore={true}
				>
					<div className="d-flex">
						<div
							className="small mr-3 badge badge-info"
							style={{ fontWeight: 500, background: "#5d75e9" }}
						>
							{totalItemsCount} Records found
						</div>
						{bunchForCloseId.length > 0 && (
							<div
								className="small badge1 badge-info1"
								style={{ display: "inline-block" }}
							>
								{bunchForCloseId.length} of {totalItemsCount}{" "}
								row(s) selected
							</div>
						)}
					</div>

					<div id="" className="table-scroll mt-2">
						<table id="" className="main-table">
							<thead>
								<tr>
									<th scope="col" width="20">
										<input
											type="checkbox"
											checked={selectedAllCheckBox}
											onChange={(e) =>
												handleAllCheckBox(e)
											}
										/>
									</th>
									<th scope="col" width="200">
										Hospital Name
									</th>
									<th scope="col" className="mobilehide">
										Contact Person
									</th>
									<th scope="col" className="mobilehide">
										Sets
									</th>
									<th scope="col">Operation Name</th>
									<th scope="col">Important Dates</th>
									<th scope="col">Booked By</th>
									<th
										scope="col"
										className="text-right"
										width="185"
									>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{newBookingList &&
									newBookingList.map((item, mainInd) =>
										item.map((innerItem, index) => {
											return(
											<React.Fragment
												key={
													innerItem._id +
													Math.random()
												}
											>
												{index == 0 && (
													<tr
														className="data_date"
														key={
															innerItem._id +
															Math.random()
														}
													>
														<th colSpan="4">
															{mainInd == 0 &&
																bunchForCloseId.length >
																	0 && (
																	<div>
																		<button
																			className="btn btn-default add_print ml-3 btn-disabled"
																			onClick={() =>
																				bunchForCloseModalOpen()
																			}
																		>
																			Force
																			Close
																		</button>
																	</div>
																)}
														</th>
														<th
															colSpan="4"
															className="text-left"
														>
															{moment(
																moment(
																	innerItem.operation_date
																).format(
																	"YYYY-MM-DD"
																)
															).isSame(todayDate)
																? "Today"
																: moment(
																		innerItem.operation_date
																  ).format(
																		"ddd, MMMM Do"
																  )}
														</th>
													</tr>
												)}
												<tr>
													<td width="20">
														<input
															type="checkbox"
															checked={
																bunchForCloseId.filter(
																	(x) =>
																		x ==
																		innerItem._id
																).length > 0
																	? true
																	: false
															}
															onChange={(e) =>
																handleCheckBunchForCloseId(
																	e,
																	innerItem
																)
															}
														/>
													</td>
													<td
														width="200"
														data-th="Hospital Name"
														className="mobilehide_heading"
													>
														<div className="">
															<Link
																to={
																	"/admin/booking/details/" +
																	innerItem._id
																}
															>
																<span>
																	<strong>
																		<span
																			style={{
																				display:
																					"block",
																			}}
																			className="theme-text"
																		>
																			{
																				innerItem.booking_id
																			}
																		</span>
																		{
																			innerItem
																				.hospitalData[0]
																				.name
																		}
																	</strong>
																</span>
																<br />
																{innerItem
																	?.bookingSurgeonData
																	?.SurgeonDetail
																	?.length >
																	0 && (
																	<React.Fragment>
																		<span>
																			<small>
																				Surgeon:
																			</small>{" "}
																			<strong>
																				{innerItem
																					.bookingSurgeonData
																					.SurgeonDetail[0]
																					.first_name +
																					" " +
																					innerItem
																						.bookingSurgeonData
																						.SurgeonDetail[0]
																						.last_name}
																			</strong>
																		</span>
																		<br />
																	</React.Fragment>
																)}
																<small className="mobilehide">
																	Booked on{" "}
																	{dateFormat(
																		innerItem.createdAt,
																		"ddd,mmm dS"
																	)}
																</small>
															</Link>
														</div>
													</td>
													<td
														data-th="Contact Person"
														className="mobilehide"
													>
														<div className="info-left">
															<strong>
																{
																	innerItem
																		.hospitalData[0]
																		.contact_person_name
																}{" "}
															</strong>
															<br />
															<small>
																Location:{" "}
																{
																	innerItem.hospital_address
																}{" "}
															</small>
															<br />
															<small>
																Email:{" "}
																{
																	innerItem
																		.hospitalData[0]
																		.email
																}
															</small>
															<br />
															<small>
																Phone:{" "}
																{
																	innerItem
																		.hospitalData[0]
																		.contact_person_phone_number
																}
															</small>
															<br />
															<small>
																Department:{" "}
																{
																	innerItem.hospital_department
																}{" "}
															</small>
														</div>
													</td>
													<td
														data-th="Sets"
														className="mobilehide"
													>
														<small>
															{innerItem.bookingKitSetId &&
																innerItem.bookingKitSetId.map(
																	(
																		kset,
																		ind
																	) => (
																		<React.Fragment
																			key={
																				ind
																			}
																		>
																			{/* <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
																			<div>
																				<span>
																					{
																						kset
																							.setId[0]
																							?.set_name
																					}{" "}
																				</span>
																			</div>
																		</React.Fragment>
																	)
																)}
														</small>
													</td>
													<td data-th="Operation Name">
														{innerItem.operation_name ===
														"Other" ? (
															<span>
																{" "}
																{
																	innerItem.other_opration
																}{" "}
															</span>
														) : (
															<span>
																{" "}
																{
																	innerItem.operation_name
																}{" "}
															</span>
														)}
													</td>
													<td
														data-th="Important Dates"
														className="mobilehide_heading"
													>
														<small>
															{innerItem.booking_type ==
																"loan" && (
																<div>
																	<span className="mobilehide">
																		Delivery
																		Date:{" "}
																		<strong>
																			{dateFormat(
																				innerItem.delivery_date,
																				"ddd,mmm dS"
																			)}{" "}
																			{
																				innerItem.delivery_date_slot
																			}
																		</strong>
																		<br />
																	</span>
																	Operation
																	Date:{" "}
																	<strong>
																		{dateFormat(
																			innerItem.operation_date,
																			"ddd, mmm dS"
																		)}{" "}
																		{
																			innerItem.operation_date_slot
																		}
																	</strong>
																	<br />
																</div>
															)}
															{innerItem.booking_type !=
																"loan" && (
																<div>
																	<span className="mobilehide">
																		Delivery
																		Date:{" "}
																		<strong>
																			{dateFormat(
																				innerItem.delivery_date,
																				"ddd,mmm dS"
																			)}
																		</strong>
																	</span>
																	<br />
																</div>
															)}
															<span className="mobilehide">
																Collection Date:{" "}
																<strong>
																	{dateFormat(
																		innerItem.collection_date,
																		"ddd,mmm dS"
																	)}
																</strong>
															</span>
															<br />
															<span className="mobilehide">
																Create Date:{" "}
																<strong>
																	{dateFormat(
																		innerItem.confirm_booking_date,
																		"ddd,mmm dS"
																	)}
																</strong>
															</span>
														</small>
													</td>
													<td
														data-th="Booked By"
														className="mobilehide_heading"
													>
														{innerItem
															?.booked_by_user
															?.length > 0
															? innerItem
																	.booked_by_user[0]
																	.first_name +
															  " " +
															  innerItem
																	.booked_by_user[0]
																	.last_name
															: "-"}
													</td>
													<td className="text-right">
														<div className="d-flex justify-content-between">
															<div className="text-center">
																<div>
																	<span
																		className={
																			"mr-2 capsule " +
																			(innerItem.status ==
																			"booked"
																				? "green-dim "
																				: "red-dim ")
																		}
																	>
																		{innerItem.status ==
																			"dispatch" && (
																			<div>
																				Dispatched
																			</div>
																		)}
																		{innerItem.status !=
																			"dispatch" &&
																			capitalize(
																				innerItem.status
																			)}
																	</span>
																	<span>
																		<Link
																			className="btn-link mr-2 desktophide"
																			to={
																				"/admin/booking/details/" +
																				innerItem._id
																			}
																		>
																			More
																			Details
																		</Link>
																	</span>
																</div>
																<div className="mt-2 small">
																	{innerItem.po_hover !=
																	undefined ? (
																		<small className="mobilehide">
																			PO
																			Number:{" "}
																			<input
																				type="text"
																				className="text-center"
																				onMouseLeave={() =>
																					updateMouseLeave(
																						innerItem,
																						mainInd,
																						index
																					)
																				}
																				onChange={(
																					e
																				) =>
																					debouncedPONumber(
																						e,
																						innerItem,
																						mainInd,
																						index
																					)
																				}
																				defaultValue={
																					innerItem.po_number
																				}
																				//autoFocus={innerItem.bookings.po_hover && hoverBookingId === innerItem.bookings._id ? true : false}
																			/>
																		</small>
																	) : (
																		<small className="mobilehide">
																			PO
																			Number:{" "}
																			<input
																				onMouseEnter={() =>
																					updateMouseEnter(
																						innerItem,
																						mainInd,
																						index
																					)
																				}
																				type="text"
																				className="bg-hide text-center"
																				onChange={(
																					e
																				) =>
																					debouncedPONumber(
																						e,
																						innerItem,
																						mainInd,
																						index
																					)
																				}
																				defaultValue={
																					innerItem.po_number ==
																					""
																						? "TBC"
																						: innerItem.po_number
																				}
																			/>
																		</small>
																	)}
																</div>
																<div className="mt-2 small">
																	<label className=" small align-middle">
																		Chase PO
																		Number
																		&nbsp;
																	</label>
																	<input
																		type="checkbox"
																		checked={
																			innerItem.po_check
																				? true
																				: false
																		}
																		onChange={(
																			e
																		) =>
																			handlePOCheck(
																				e,
																				innerItem,
																				mainInd,
																				index
																			)
																		}
																	/>
																</div>
																<div className="mt-2 small">
																	<label className=" small align-middle">
																		Check Set
																		on Return
																		&nbsp;
																	</label>
													
																	<input
																		type="checkbox"
																		checked={
																			innerItem.check_set
																				? true
																				: false
																		}
																		onChange={(
																			e
																		) =>
																		handleCheckSet(
																				e,
																				innerItem,
																				mainInd,
																				index
																			)
																		}
																	/>
																</div>
															</div>
															<div className="action-area dropdown">
																<a
																	className="dropdown-toggle"
																	href="#"
																	id="navbarDropdown"
																	role="button"
																	data-bs-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																>
																	<span className="dots">
																		...
																	</span>
																</a>
																<div
																	className="dropdown-menu navbarDropdownSize"
																	aria-labelledby="navbarDropdown"
																>
																	<Link
																		className="dropdown-item mobilehide"
																		to={
																			"/admin/booking/details/" +
																			innerItem._id
																		}
																	>
																		More
																		Details
																	</Link>

																	{innerItem
																		?.bookingcollections
																		?.length ===
																		0 &&
																		innerItem
																			?.bookingdispatches
																			?.length >
																			0 && (
																			<Link
																				className="dropdown-item"
																				to={
																					"/admin/booking/edit/" +
																					innerItem._id
																				}
																			>
																				Edit
																			</Link>
																		)}

																	{innerItem.status ==
																		"booked" &&
																		dateFormat(
																			innerItem.delivery_date,
																			"yyyy-mm-dd"
																		) ==
																			todayDate && (
																			<Link
																				className="dropdown-item"
																				to={
																					"/admin/booking/details/" +
																					innerItem._id
																				}
																			>
																				Dispatch
																			</Link>
																		)}

																	{innerItem.status ==
																		"dispatch" && (
																		<Link
																			className="dropdown-item"
																			to={
																				"/admin/booking/item-used/o/" +
																				innerItem._id
																			}
																		>
																			Item
																			Used
																		</Link>
																	)}
																	{innerItem.status ==
																		"dispatch" && (
																		<a
																			href="javascript:;"
																			onClick={() =>
																				dateModalOpen(
																					innerItem
																				)
																			}
																			className="dropdown-item"
																		>
																			Change
																			Dates
																		</a>
																	)}

																	{innerItem.status ===
																		"booked" && (
																		<Link
																			className="dropdown-item"
																			to={
																				"/admin/booking/edit/" +
																				innerItem._id
																			}
																		>
																			Edit
																		</Link>
																	)}

																	{innerItem.status ==
																		"dispatch" && (
																		<a
																			href="javascript:;"
																			onClick={() =>
																				forCloseModalOpen(
																					innerItem
																				)
																			}
																			className="dropdown-item"
																		>
																			Force
																			Close
																		</a>
																	)}

																	{innerItem.status ==
																		"booked" && (
																		<a
																			href="javascript:;"
																			onClick={() =>
																				cancelModalOpen(
																					innerItem._id
																				)
																			}
																			className="dropdown-item"
																		>
																			Cancel
																		</a>
																	)}

																	{innerItem.status ==
																		"dispatch" && (
																		<a
																			href="javascript:;"
																			onClick={() =>
																				repsUpdateModalOpen(
																					innerItem
																				)
																			}
																			className="dropdown-item"
																		>
																			Assign
																			Reps
																		</a>
																	)}
																</div>
															</div>
														</div>
													</td>
												</tr>
											</React.Fragment>
										);
																		})
									)}
								{newBookingList &&
									Object.keys(newBookingList).length == 0 &&
									loading == false && (
										<tr className="text-center">
											<td colSpan="8">No Record Found</td>
										</tr>
									)}

								{loading == true ||
								(loading == true &&
									newBookingList &&
									newBookingList.length == 0) ? (
									<Norecord />
								) : (
									""
								)}
							</tbody>
						</table>
					</div>
				</InfinitScroll>
			</div>

			<RepsUpdateModal
				bookingId={bookingRepsUpdateId}
				isRepsUpdateModal={repsUpdateModal}
				repsUpdateModalClose={(flag) => repsUpdateModalClose(flag)}
				reps={reps}
				additionalReps={additionalReps}
				bookingInfoById={bookingInfoById}
				getList={(flag, search) => getList(flag, search)}
				search={search}
			/>

			<ChangeDate
				dateModal={dateModal}
				dateModalClose={(flag) => dateModalClose(flag)}
				bokkingDate={bokkingDate}
				setBokkingDate={setBokkingDate}
				deliveryDateIsDisabled={deliveryDateIsDisabled}
				getList={(flag, search) => getList(flag, search)}
				search={search}
			/>

			<Cancel
				cancelModal={cancelModal}
				cancelModalClose={(flag) => cancelModalClose(flag)}
				cancelId={cancelId}
				getList={(flag, search) => getList(flag, search)}
				search={search}
			/>

			<ForClose
				forCloseModal={forCloseModal}
				forCloseModalClose={(flag) => forCloseModalClose(flag)}
				forCloseId={forCloseId}
				getList={(flag, search) => getList(flag, search)}
				search={search}
				userId={user?.data?._id}
				bookingId={bookingId}
			/>

			<BunchForClose
				bunchForCloseId={bunchForCloseId}
				bunchForCloseModalClose={(flag) =>
					bunchForCloseModalClose(flag)
				}
				bunchForCloseModal={bunchForCloseModal}
			/>

			{/* Delete Modal */}
			<Footer />
		</div>
	);
};

export default Index;
