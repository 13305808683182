import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Select from "react-select";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import KitNavbar from "./KitNavbar";
import { kitActions } from "../../../../actions";
import { kitService } from "../../../../services";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import KitModal from "./loanCharge";

import CategoryAdd from "./Category/Add";
import CategoryEdit from "./Category/Edit";
import CategoryReadMore from "./Category/ReadMore";

const statusOptions = [
    { value: "", label: "Status" },
    { value: "Active", label: "Show" },
    { value: "Hide", label: "Hide" },
];

class Index extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            addNewModal: false,
            editModal: false,
            deleteModal: false,
            id: "",
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            error: false,
            status: "",
            readMoreContent: [],
            readMoreModel: false,
            selectedKit: null,
        };

        this.getKitsList = this.getKitsList.bind(this);
        this.addNewModalOpen = this.addNewModalOpen.bind(this);
        this.addNewModalClose = this.addNewModalClose.bind(this);
        this.editModalOpen = this.editModalOpen.bind(this);
        this.editModalClose = this.editModalClose.bind(this);
        this.deleteModalOpen = this.deleteModalOpen.bind(this);
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.handleReadMore = this.handleReadMore.bind(this);
        this.handleReadMoreClose = this.handleReadMoreClose.bind(this);
        this.refreshMainList = this.refreshMainList.bind(this);
    }

    openKitModal = (item) => {
        this.setState({ selectedKit: item });
    };

    closeKitModal = () => {
        this.setState({ selectedKit: null });
    };

    handleReadMore(val) {
        this.setState(
            {
                readMoreContent: val,
                readMoreModel: true
            });
    }

    handleReadMoreClose() {
        this.setState(
            {
                readMoreContent: [],
                readMoreModel: false
            });
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        if(pageNumber !== this.state.activePage){
            this.setState(
                { 
                    activePage: pageNumber 
                }, () => {
                    this.getKitsList();
                }
            );
            window.scrollTo({top: 0});
        }
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState({
            activePage: activePage,
            totalItemsCount: totalItemsCount,
            limit: limit,
        });
    }

    handleStatus(e) {
        this.setState(
            {
                status: e.value,
            },
            () => {
                this.getKitsList();
            }
        );
    }

    updateStatus(id, status) {
        console.log("ssssss", id, status);
        kitService
            .updateKitStatus({ status: status, id: id })
            .then(() => this.getKitsList());
    }

    getKitsList() {
        this.props.dispatch(
            kitActions.getKitsList({
                search: this.state.search,
                page: this.state.activePage,
                limit: this.state.limit,
                status: this.state.status,
            })
        );
    }

    handleSearch(val) {
        this.setState(
            {
                search: val,
                activePage: 1,
            },
            () => {
                this.getKitsList();
            }
        );
    }

    addNewModalOpen() {
        this.setState({ addNewModal: true });
    }

    addNewModalClose() {
        this.setState({ addNewModal: false });
    }

    editModalOpen(id) {
        this.setState({ editModal: true, id: id });
    }

    editModalClose() {
        this.setState({ editModal: false, id: 0 });
    }

    refreshMainList(flag) {
        const {activePage} = this.props;
        this.setState(
            {
                addNewModal: false,
                editModal: false,
                id: 0,
                activePage: flag ? 1 : activePage != "" ? activePage : 1,
            },
            () => {
                this.getKitsList();
            }
        );
    }

    deleteModalOpen() {
        this.setState({ deleteModal: true });
    }

    deleteModalClose() {
        this.setState({
            deleteModal: false,
            name: "",
            error: false,
        });
    }

    handleDelete(id) {
        this.setState({
            id: id,
        });
        this.deleteModalOpen();
    }

    doDelete() {
        this.props.dispatch(kitActions.delete({ id: this.state.id }));
        window.scrollTo({top: 0});
    }

    componentDidMount() {
        this.getKitsList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.refreshList == true) {
            this.setState(
                {
                    activePage: 1,
                },
                () => {
                    this.getKitsList();
                }
            );
        }

        if (
            nextProps.activePage != "" &&
            nextProps.totalItemsCount != "" &&
            nextProps.limit != ""
        ) {
            this.handlePageStates(
                nextProps.activePage,
                nextProps.totalItemsCount,
                this.state.limit
            );
        }

        if (nextProps.deleteModal == false) {
            this.deleteModalClose();
        }

        if (nextProps.error !== "") {
            this.setState({
                error: true,
            });
        }
    }

    render() {
       
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        const { kitsList, error, loading } = this.props;
        return (
            <div>
                <Navbar activePage="kits" />
                <div className="container-fluid">
                    <div className="pt-4 row align-items-center">
                        <div className="col-md-3">
                            <div className="sub-menu">
                                <KitNavbar activePage="categories" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-search my-1">
                                <Input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={(e) =>
                                        this.handleSearch(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <form className="form-search my-1">
                                <Select
                                    defaultValue={this.state.status}
                                    onChange={this.handleStatus}
                                    options={statusOptions}
                                />
                            </form>
                        </div>
                        <div className="col-md-2 text-right">
                            <button
                                className="btn btn-primary add_option"
                                onClick={this.addNewModalOpen}
                            >
                                <i className="fa fa-plus"></i>{" "}
                                <span>Add New</span>
                            </button>
                        </div>
                    </div>
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ minWidth: "50%" }}>
                                        Kit Name
                                    </th>
                                    <th scope="col">File</th>
                                    <th>Suggested kits</th>

                                    <th scope="col">Status</th>
                                    <th
                                        scope="col"
                                        className="text-right"
                                        width="100"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {kitsList &&
                                    kitsList.map((item) => (
                                        <tr key={item._id}>
                                            <td>
                                                <div className="">
                                                    <strong
                                                        onClick={() => this.openKitModal(item)}
                                                        style={{ cursor: "pointer" }}
                                                    >  {item.name}
                                                    </strong>
                                                </div>
                                            </td>
                                            <td>
                                                {item.kitDocument.length >
                                                    0 && (
                                                        <a
                                                            href={
                                                                process.env
                                                                    .REACT_APP_APIURL +
                                                                "assets/uploads/kitDocuments/" +
                                                                item.kitDocument[0]
                                                                    .name
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {
                                                                item.kitDocument[0]
                                                                    .original_name
                                                            }
                                                        </a>
                                                    )}
                                            </td>
                                            <td>
                                                {item.suggestedKits.length > 0 && item.suggestedKits.slice(0, 10).map((sugKit, sugInd) =>
                                                    <React.Fragment key={sugInd}>
                                                        {sugInd > 0 ? ", " : ""}{sugKit.suggestedKitDetail[0]?.name}
                                                    </React.Fragment>
                                                )}
                                                {item.suggestedKits.length > 10 &&
                                                    <div>.. <a href="javascript:;" onClick={() => this.handleReadMore(item.suggestedKits)} >Read More</a></div>

                                                }
                                            </td>
                                            <td>
                                           
                                                {item.status == "Active"
                                                    ? "Show"
                                                    : "Hide"}
                                            </td>

                                            <td className="text-right">
                                                <div className="action-area dropdown">
                                                    <a
                                                        className="dropdown-toggle"
                                                        href="#"
                                                        id="navbarDropdown"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span className="dots">
                                                            ...
                                                        </span>
                                                    </a>
                                                    <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="navbarDropdown"
                                                    >
                                                        <a
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                this.editModalOpen(
                                                                    item._id
                                                                )
                                                            }
                                                            href="javascript:;"
                                                        >
                                                            Edit
                                                        </a>
                                                        {item.status ==
                                                            "Active" ? (
                                                            <a
                                                                className="dropdown-item"
                                                                onClick={() =>
                                                                    this.updateStatus(
                                                                        item._id,
                                                                        "Hide"
                                                                    )
                                                                }
                                                                href="javascript:;"
                                                            >
                                                                Hide
                                                            </a>
                                                        ) : (
                                                            <a
                                                                className="dropdown-item"
                                                                onClick={() =>
                                                                    this.updateStatus(
                                                                        item._id,
                                                                        "Active"
                                                                    )
                                                                }
                                                                href="javascript:;"
                                                            >
                                                                Show
                                                            </a>
                                                        )}
                                                        <a
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                this.handleDelete(
                                                                    item._id
                                                                )
                                                            }
                                                            href="javascript:;"
                                                        >
                                                            Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                {kitsList &&
                                    kitsList.length == 0 &&
                                    loading == false && (
                                        <tr className="text-center">
                                            <td colSpan="4">No Record Found</td>
                                        </tr>
                                    )}

                                { kitsList && kitsList.length == 0 && loading == true &&  
                                    <tr className="text-center">
                                        <td colSpan="5">
                                            <div className="loading-state-new">
                                                <div className="loading"></div>
                                            </div>                                               
                                        </td>
                                    </tr>                                                                
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {kitsList && kitsList.length > 0 && (
                    <div>
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center mb-5"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Prev"
                    />
                    <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                    </div>
                )}

                <Modal
                    size="md"
                    backdrop="static"
                    isOpen={this.state.deleteModal}
                    toggle={() => this.deleteModalClose()}
                >
                    <ModalHeader
                        className=""
                        toggle={() => this.deleteModalClose()}
                    >
                        Delete Kit
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">
                            Please confirm if you want to delete this kit?
                        </div>
                        {this.state.error && error ? (
                            <span className="text-danger">
                                {error.common ? error.common : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.deleteModalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            {loading == false && (
                                <button
                                    className="btn btn-primary"
                                    onClick={this.doDelete}
                                >
                                    Ok
                                </button>
                            )}
                            {loading == true && (
                                <button
                                    className="btn btn-primary loader-icon"
                                    disabled
                                >
                                    <Spinner className="img-svg"></Spinner>
                                </button>
                            )}
                        </div>
                    </ModalFooter>
                </Modal>

                <CategoryAdd
                    refreshMainList={(flag)=>this.refreshMainList(flag)}
                    addNewModal={this.state.addNewModal}
                    addNewModalClose={this.addNewModalClose.bind(this)}
                />
                <CategoryEdit
                    id={this.state.id}
                    refreshMainList={(flag)=>this.refreshMainList(flag)}
                    editModal={this.state.editModal}
                    editModalClose={this.editModalClose.bind(this)}
                />
                <CategoryReadMore
                    readMoreContent={this.state.readMoreContent}
                    handleReadMoreClose={this.handleReadMoreClose.bind(this)}
                    readMoreModel={this.state.readMoreModel}
                />
                <KitModal
                    isOpen={!!this.state.selectedKit}
                    toggle={this.closeKitModal}
                    item={this.state.selectedKit}
                />

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { kitsList, activePage, totalItemsCount, limit } =
        state.rootReducer.kits;
    const { error, modal, editModal, kit, refreshList, loading, deleteModal } =
        state.rootReducer.kits;

    // console.log(state.rootReducer.kits);
    return {
        kitsList,
        error,
        modal,
        editModal,
        refreshList,
        kit,
        loading,
        deleteModal,
        activePage,
        totalItemsCount,
        limit,
    };
}

export default connect(mapStateToProps)(Index);
