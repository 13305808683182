import { kitConstants } from "../constants";

const initialState =  { loading: null, usersList: null, } || {};

export function kits(state = initialState, action) {
    switch (action.type) {
    case kitConstants.GETALLKITS_REQUEST:
        return {
            loading: true,
            kitsList: [],
        };
    case kitConstants.GETALLKITS_SUCCESS:
        return {
            loading: false,
            kitsList: action.kitsList.data,
            activePage: action.kitsList.page,
            totalItemsCount: action.kitsList.total_count,
            limit: action.kitsList.page_count
        };
    case kitConstants.GETALLKITS_FAILURE:
        return { 
            error: action.error
        };
    case kitConstants.GETDROPDOWNKITSLIST_REQUEST:
        return {
            loading: true
        };
    case kitConstants.GETDROPDOWNKITSLIST_SUCCESS:
        return {
            loading: false,
            kitListDropdown: action.kitListDropdown.data
        };
    case kitConstants.GETDROPDOWNKITSLIST_FAILURE:
        return { 
            loading: false,
            error: action.error
        };
    case kitConstants.KITADD_REQUEST:
        return {
            loading: true,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITADD_SUCCESS:
        return {
            loading: false,
            kitsList: action.data,
            modal:false,
            refreshList:true
        };
    case kitConstants.KITADD_FAILURE:
        return { 
            loading: false,
            error: action.errors,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITEDIT_REQUEST:
        return {
            loading: true,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITEDIT_SUCCESS:
        return {
            loading: false,
            kitsList: state.kitsList, 
            kit:action.kit.data,
            editModal:true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITEDIT_FAILURE:
        return { 
            loading: false,
            error: action.error,
            kitsList: state.kitsList,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITUPDATE_REQUEST:
        return {
            loading: true,
            kitsList: state.kitsList,
            kit: state.kit,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITUPDATE_SUCCESS:
        return {
            loading: false,
            kitsList: state.kitsList, 
            editModal:false,
            refreshList:true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.KITUPDATE_FAILURE:
        return { 
            loading: false,
            error: action.errors,
            kitsList: state.kitsList,
            kit: state.kit,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case kitConstants.DELETE_REQUEST:
        // add 'deleting:true' property to user being deleted
        return {
            ...state,
            loading: true,
            kitsList: state.kitsList.map(kit =>
                kit._id === action.id
                    ? { ...kit, deleting: true }
                    : kit
            ),
            editModal: false
        };
    case kitConstants.DELETE_SUCCESS:
        // remove deleted user from state
        return {
            loading: false,
            deleteModal:false,
            refreshList:true,
            editModal: false
        };
    case kitConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        return {
            ...state,
            loading: false,
            kitsList: state.kitsList.map(kit => {
                if (kit._id === action.id) {
                    // make copy of user without 'deleting:true' property
                    // const { deleting, ...userCopy } = user;
                    const { ...kitCopy } = kit;
                    // return copy of user with 'deleteError:[error]' property
                    return { ...kitCopy, deleteError: action.error };
                }

                return kit;
            }),
            editModal: false,
            error: action.error
        };
    default:
        return state;
    }
}