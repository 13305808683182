import { hospitalConstants } from "../constants";
import { hospitalService } from "../services";

export const hospitalActions = {
    getList,
    add,
    getById,
    update,
    delete: _delete
};

function getList(search) {
    return dispatch => {
        dispatch(request());

        hospitalService.getList(search)
            .then(
                list => dispatch(success(list)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: hospitalConstants.GETALLHOSPITALS_REQUEST }; }
    function success(lists) { return { type: hospitalConstants.GETALLHOSPITALS_SUCCESS, lists }; }
    function failure(error) { return { type: hospitalConstants.GETALLHOSPITALS_FAILURE, error }; }
}

function add(info) {
    return dispatch => {
        dispatch(request());

        hospitalService.add(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: hospitalConstants.HOSPITALADD_REQUEST }; }
    function success(info) { return { type: hospitalConstants.HOSPITALADD_SUCCESS, info }; }
    function failure(error) { return { type: hospitalConstants.HOSPITALADD_FAILURE, error }; }
}

function update(info) {
    return dispatch => {
        dispatch(request());

        hospitalService.update(info)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: hospitalConstants.HOSPITALUPDATE_REQUEST }; }
    function success(info) { return { type: hospitalConstants.HOSPITALUPDATE_SUCCESS, info }; }
    function failure(error) { return { type: hospitalConstants.HOSPITALUPDATE_FAILURE, error }; }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        hospitalService.getById(id)
            .then(
                list => dispatch(success(list)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: hospitalConstants.HOSPITALEDIT_REQUEST }; }
    function success(list) { return { type: hospitalConstants.HOSPITALEDIT_SUCCESS, list }; }
    function failure(error) { return { type: hospitalConstants.HOSPITALEDIT_FAILURE, error }; }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        hospitalService.delete(id)
            .then(
                kit => { 
                    console.log(kit);
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: hospitalConstants.DELETE_REQUEST, id }; }
    function success(id) { return { type: hospitalConstants.DELETE_SUCCESS, id }; }
    function failure(id, error) { return { type: hospitalConstants.DELETE_FAILURE, id, error }; }
}