import React from "react";

const Norecord =()=>{

    return (
        [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <tr key={"sk" + item + Math.random()}>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
          <td>
            <p className="placeholder-glow">
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
              <div className="skeleton skeleton-text"></div>
            </p>
          </td>
        </tr>
          ))
    );
};

export default Norecord;

