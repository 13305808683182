export const returnBookingConstants = {
   
    GETALLRETURNBOOKINGS_REQUEST: "RETURNBOOKINGS_GETALL_REQUEST",
    GETALLRETURNBOOKINGS_SUCCESS: "RETURNBOOKINGS_GETALL_SUCCESS",
    GETALLRETURNBOOKINGS_FAILURE: "RETURNBOOKINGS_GETALL_FAILURE",

    COUNTRETURNBOOKINGS_REQUEST: "RETURNBOOKINGS_COUNT_REQUEST",
    COUNTRETURNBOOKINGS_SUCCESS: "RETURNBOOKINGS_COUNTL_SUCCESS",
    COUNTRETURNBOOKINGS_FAILURE: "RETURNBOOKINGS_COUNT_FAILURE"


};