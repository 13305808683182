import React from "react";
import moment from "moment";

const BookingTimeline = ({ logData }) => {
  console.log("logData", logData);

  return (
    <div>
      {logData && logData.length > 0 && (
        <>
          <h4 className="mt-5 ml-3" style={{ fontSize: "16px" }}>
            Booking Timeline
          </h4>
          <ul className="timeline mt-4 sml-text">
            {logData.map((item, index) => (
              <li key={index} data-dd={item}>
                <strong>
                  {moment(item.createdAt).format("ddd, Do MMM h:mm:ss")}
                </strong>
                {item.status === "booked" && (
                  <>
                    <p>
                      Booking added by {item.userData[0].first_name}{" "}
                      {item.userData[0].last_name} <br />
                      <strong>Note: </strong>{item.text}
                    </p>
                  </>
                )}
                {item.status === "reject" && (
                  <>
                    <p>
                      Booking rejected by {item.userData[0].first_name}{" "}
                      {item.userData[0].last_name}{" "}
                      <br />
                      <strong>Note: </strong>{item.text}
                    </p>
                  </>
                )}
                {item.status === "dispatch" && (
                  <>
                    <p>
                      Booking dispatched by {item.userData[0].first_name}{" "}
                      {item.userData[0].last_name}{" "}
                      <br />
                      <strong>Note: </strong>{item.text}
                    </p>
                  </>
                )}
                {item.status === "partial-dispatch" && (
                  <>
                    <p>
                      Booking dispatched by {item.userData[0].first_name}{" "}
                      {item.userData[0].last_name}{" "}
                      <br />
                      <strong>Note: </strong>{item.text}
                    </p>
                  </>
                )}
                {item.status === "close" && (
                  <>
                    <p>
                      Booking closed by {item.userData[0].first_name}{" "}
                      {item.userData[0].last_name}{" "}
                      <br />
                      <strong>Note: </strong>{item.text}
                    </p>
                  </>
                )}
                 {item.status == "prepare" && (
                        <p>
                          Booking prepared by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}
                {item.status === "pending" && (
                  <p>
                    {item.userData[0].first_name} {item.userData[0].last_name}{" "}
                    requested for Booking
                  </p>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default BookingTimeline;
