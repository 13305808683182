import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  Input
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { consignmentService } from "../../../../../services/consignment.services";
import dateFormat from "dateformat";

const AcceptModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(""); 
  const [selectedDate, setSelectedDate] = useState(null); 
  const [error, setError]=  useState([]);

  useEffect(() => {
    if (props.isOpen) {
      setSelectedDate(props.deliveryDate ? new Date(props.deliveryDate) : null);
    }
  }, [props.isOpen, props.deliveryDate]);

  const modalClose = (flag) => {
    props.toggle(flag);
    setError([]);
    setComment("");
    setSelectedDate(null);
  };

  const handleAccept = async () => {
    setLoading(true);
    const params = {
      id: props.bookingId,
      comment, 
      delivery_date: dateFormat(selectedDate, "yyyy-mm-dd"), 
    };
    try {
      const response = await consignmentService.acceptConsingnment(params);
      console.log("API response:", response);
      modalClose(true);
      props.onReload();
    } catch (error) {
      console.error("API call failed:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      backdrop="static"
      centered
      isOpen={props.isOpen}
      toggle={() => modalClose(false)}
    >
      <ModalHeader toggle={() => modalClose(false)}>
        Accept Booking
      </ModalHeader>
      <ModalBody>
        <div>Are you sure you want to Accept Booking?</div>
        <div className="mt-3">
          <label htmlFor="datePicker">Delivery Date <span className="text-danger">*</span></label>
          <DatePicker
            id="datePicker"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            className="form-control"
            dateFormat="yyyy/MM/dd"
            placeholderText="Select date"
          />
           {error.delivery_date && (
            <span className="text-danger">{error.delivery_date}</span>
          )}
        </div>
        <div className="mt-3">
          <label htmlFor="comment">Comment <span className="text-danger">*</span></label>
          <Input
            id="comment"
            type="textarea"
            placeholder="Enter comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)} 
          />
           {error.comment && (
            <span className="text-danger">{error.comment}</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div>
          <button
            onClick={() => modalClose(false)}
            className="btn btn-secondary mr-2"
            data-dismiss="modal"
          >
            Cancel
          </button>
          {!loading && (
            <button
              className="btn btn-primary"
              onClick={handleAccept}
            >
              Ok
            </button>
          )}
          {loading && (
            <button
              className="btn btn-primary loader-icon"
              disabled
            >
              <Spinner className="img-svg"></Spinner>
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AcceptModal;
