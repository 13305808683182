import React, { useState, useEffect } from "react";
import { ReactComponent as FilterIcon } from "../../../../../assets/images/filter.svg";
import { ReactComponent as Close } from "../../../../../assets/images/close.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { setService } from "../../../../../services";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

const Filter = ({handleFilterToggle,filterModal,filterCreateDate,
    filterPoChase,handleFilterRefreesh,
    kits,selectedKitProp,
    surgeons,selectedSurgeonProp,
    operationsList,selectedOperationProp}) => {
    
    const [filterToggle, setFilterToggle] = useState([{label:"All",value:"all"}]);
	const [options, setOptions] = useState([{label:"All",value:"all"},{label:"Checked",value:"checked"},{label:"Unchecked",value:"unchecked"}]);
    const [createDate, setCreateDate] = useState("");
    const [selectedKit,setSelectedKit] = useState([]);
    const [selectedSurgeon,setSelectedSurgeon] = useState([{label:"All Surgeon",value:""}]);
    const [surgeonId,setSurgeonId] = useState("");
    const [selectedOperation,setSelectedOperation] = useState([{label:"All Operation",value:""}]);
    const [operationId,setOperationId] = useState("");
    const [selectedKitForSets,setSelectedKitForSets] = useState("");

    const [sets,setSetsList] = useState([]);
    const [selectedSet,setSelectedSet] = useState([]);

    const handleFilter = () => {
        let selectdKitValue="";
        let selectdSetValue="";

        selectedKit?.length && selectedKit.map((task)=>{
            if(selectdKitValue!="")
            {
                selectdKitValue+=",";
            }
            selectdKitValue+=task.value;
        });

        selectedSet?.length && selectedSet.map((set)=>{
            if(selectdSetValue!="")
            {
                selectdSetValue+=",";
            }
            selectdSetValue+=set.value;
        });

    	handleFilterRefreesh({
            filterPoChase:filterToggle[0].value,
            filterCreateDate: createDate != "" ? moment(createDate).format("YYYY-MM-DD"): "",
            selectdKitValue:selectdKitValue,
            selectedKitProp:selectedKit,
            surgeonId:surgeonId,
            selectedSurgeonProp:selectedSurgeon,
            operationId:operationId,
            selectedOperationProp:selectedOperation,
            selectdSetValue:selectdSetValue,
        });
    };

    const handlePO = (e) => {

    	setFilterToggle([e]);
    };

    const capitalizeFirstLetter = (string) => { 
	    return string.charAt(0).toUpperCase() + string.slice(1);
	};


    const handleCreateDate = (val) => {
        setCreateDate( val != null ? new Date(val) : "");
    };

    const handleFilterClear = () => {
        setCreateDate("");
        setFilterToggle([{label:"All",value:"all"}]);
        setSelectedSurgeon([{label:"All Surgeon",value:""}]);
        setSelectedOperation([{label:"All Operation",value:""}]);
        setSelectedKit([]);
        setSurgeonId("");
        setOperationId("");
        setSelectedSet([]);
        setSetsList([]);
        handleFilterRefreesh({filterPoChase:"all",filterCreateDate:"",
        selectdKitValue:"",selectedKitProp:[],
        surgeonId:"",selectedSurgeonProp:[{label:"All Surgeon",value:""}],
        operationId:"",selectedOperationProp:[{label:"All Operation",value:"",
        selectdSetValue:""}],selectdSetValue:""
       });
    };

    useEffect(() => {
    	console.log(setOptions);
    	setFilterToggle([{label:capitalizeFirstLetter(filterPoChase),value:filterPoChase}]);
        setCreateDate( filterCreateDate != "" ? new Date(filterCreateDate) : "");
        setSelectedKit(selectedKitProp);
        setSelectedSurgeon(selectedSurgeonProp);
        setSelectedOperation(selectedOperationProp);
    }, [filterModal]);

    const setSelectedOptionKit = (selectedOptionKit) => {
        
        let selectdKitValue="";

        selectedOptionKit?.length && selectedOptionKit.map((task)=>{
            if(selectdKitValue!="")
            {
                selectdKitValue+=",";
            }
            selectdKitValue+=task.value;
        });
        
        setSelectedKit(selectedOptionKit);
        setSetsList([]);
        setSelectedSet([]);

        setSelectedKitForSets(selectdKitValue);

        
    }; 

    const setSelectedOptionSurgoen = (selectedOptionSurgeon) => {
        setSurgeonId(selectedOptionSurgeon.value);
        setSelectedSurgeon(selectedOptionSurgeon);
    };
    
    const setSelectedOptionOperation = (selectedOptionOperation) => {
        setOperationId(selectedOptionOperation.value);
        setSelectedOperation(selectedOptionOperation);
    };

    useEffect(() => {
        if(selectedKitForSets !=="" && selectedKit?.length){
            getSetsUsingKitIdForBookingFilter();
        }
    }, [selectedKitForSets]);

    const setSelectedOptionSet = (selectedOptionSet) => {
        setSelectedSet(selectedOptionSet);
    };

    const getSetsUsingKitIdForBookingFilter = async () => {
        try {

            const res = await setService.getSetsUsingKitIdForBookingFilter({kitId:selectedKitForSets});
            
            let setsList = res?.data ? res?.data : [];
            let tempSetsList = [];
            setsList && setsList.map(item => {
                let option = { value: item._id, label: item.set_name,id:item._id,kitID:item.kitID };
                tempSetsList.push(option);
            });
            setSetsList(tempSetsList); 

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    return (
        <>
            <div className={"filter_section" + (filterModal==false?" hide ":"")} >
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div >
                                <h5 className="mb-0">
                                    <FilterIcon className="img-svg"></FilterIcon> 
                                    <span>Filters</span>
                                </h5>
                               
                            </div>
                            
                            <div className="d-flex align-items-center">
                                <a className="mr-3" onClick={()=>handleFilterClear()}  href="javascript:;"> 
                                    <span>Clear Filters</span> 
                                </a>
                                <Close onClick={()=>handleFilterToggle()} className="img-svg"></Close>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                    	<div className="row">
                    		<div className="col-6">
                    			<div className="mb-3 text-left position_up" style={{ zIndex: 10 }}>
                    				<label className="form-label">Chase PO Number</label>
                    				<Select
                                        value={filterToggle}
                                        onChange={(e)=>handlePO(e)} 
                                        options={options}
                                       
                                    />
                                    
                    				
                    			</div>
                    		</div>
                            <div className="col-6">
                                <div className="mb-3 text-left position_up" style={{ zIndex: 10 }}>
                                    <label className="form-label">Create Date</label>
                                     <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            showClearButton={false}
                                            placeholderText="Select Date"
                                            selected={createDate}
                                            onChange={e =>
                                                handleCreateDate(
                                                    e,
                                                )
                                            }
                                            className="form-control"
                                            customInput={
                                                <MaskedInput
                                                    pipe={autoCorrectedDatePipe}
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    keepCharPositions={true}
                                                    guide={true}
                                                    showMask={false}
                                                />
                                            }
                                        />
                                    
                                    
                                </div>
                            </div>
                            
                            
                            <div className="col-lg-6  col-md-6 col-12">
                                <div className="mb-3 text-left position_up" style={{ zIndex: 9 }}>
                                    <label className="form-label">All Surgoen</label>
                                    <Select
                                        value={selectedSurgeon}
                                        onChange={(e)=>setSelectedOptionSurgoen(e)} 
                                        options={surgeons}
                                        placeholder="All Surgoen"
                                    />
                                </div>
                            </div>
                            
                            <div className="col-lg-6  col-md-6 col-12">
                                <div className="mb-3 text-left position_up" style={{ zIndex: 8 }}>
                                    <label>Operation Name</label>
                                        <Select
                                            value={selectedOperation}
                                            onChange={(e) => setSelectedOptionOperation(e)}
                                            options={operationsList}
                                        />
                                </div>
                            </div> 

                            <div className="col-lg-6  col-md-6 col-12">
                                <div className="mb-3 text-left react-multiselect-checkbox" style={{zIndex:7}}>
                                <label className="form-label">All Kits</label>
                                    <ReactMultiSelectCheckboxes  
                                        placeholderButtonLabel="All Kits"
                                        value={selectedKit}
                                        onChange={setSelectedOptionKit} 
                                        className={""}
                                        options={kits} 
                                        placeholder="All Kits"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="mb-3 text-left react-multiselect-checkbox" style={{zIndex:6}}>
                                <label className="form-label">Sets List</label>
                                    <ReactMultiSelectCheckboxes  
                                        placeholderButtonLabel="Sets List"
                                        value={selectedSet}
                                        onChange={setSelectedOptionSet} 
                                        className={""}
                                        options={sets} 
                                        placeholder="Sets List"
                                    />
                                </div>
                            </div>

                    	</div>
                    </div>
                    <div className="card-footer text-right">
                        <a onClick={()=>handleFilter()} className="btn btn-sm btn-primary">Apply</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;
