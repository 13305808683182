import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Navbar from "../../Navbar";
import { Button, Spinner } from "reactstrap";
import { repsBookingService } from "../../../../../services";
import BasicDetails from "./BasicDetails/Index";
import MyKitPage from "./KitSection/Index";  

const CombinedFormEdit = () => {
  const [basicDetailsData, setBasicDetailsData] = useState({});
  const [kitData, setKitData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams(); 
  const [validationErrors, setValidationErrors] = useState({});

  const handleBasicDetailsSave = (data) => {
    setBasicDetailsData(data);
  };

  const handleKitDataSave = (data) => {
    setKitData(data);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setValidationErrors({});
    const combinedData = {
      ...basicDetailsData,
      id: id,
      kitData: kitData,
    };

    console.log("Payload to be sent:", combinedData);

    try {
      await repsBookingService.updateConsignmentRequest(combinedData);
      history.push("/reps/consignment-request");
    } catch (err) {
      console.error("Failed to update consignment request:", err);
      setValidationErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid px-5">
        <h1 className="date-scroll pt-4">
          <Link to="/reps/consignment-request">
            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
            <strong>Back</strong>
          </Link>
        </h1>
        <div className="row pt-4 mobile_nocard">
          <div className="col-md-6">
            <BasicDetails id={id} onSave={handleBasicDetailsSave}  validationErrors={validationErrors} />
          </div>
          <div className="col-md-6">
            <MyKitPage id={id} onSave={handleKitDataSave}  validationErrors={validationErrors} setValidationErrors={(err)=>setValidationErrors(err)} />
            <div className="col-sm-12 text-right mt-4">
              <Link to="/reps/consignment-request" className="btn btn-secondary">
                Cancel
              </Link>
              <Button color="primary ml-2" onClick={handleSave} disabled={isLoading}>
                {isLoading ? <Spinner size="sm" /> : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedFormEdit;
