import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../../GlobalComponents/Footer";
import Navbar from "../../Navbar";
import { repsBookingService } from "../../../../../services/repsBooking.services";
import Select from "react-select";
import useDebounce from "../../../../../helpers/UseDebounce";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Norecord from "./Norecord";
import { bookingService } from "../../../../../services";
const statusOptions = [
  { value: "", label: "All" },
  { value: "yes", label: "Filled" },
  { value: "no", label: "Empty" },
];

const RepsItemUsedList = () => {
  // const [dataXlsx, setDataXlsx] = useState([]);
  const [hospitals, setHospitals] = useState([
    { label: "All Hospital", value: "" },
  ]);
  const [hospitalsList, setHospitalsList] = useState([]);
  const [search, setSearch] = useState("");
  const searchRef = useRef(null);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([
    { value: "", label: "All" },
  ]);
  const [firstLoad, setFirstLoad] = useState(0);
  const [selectedHospital, setSelectedHospital] = useState([
    { label: "Search by Hospital", value: "" },
  ]);

  const getHospitals = async () => {
    const result = await bookingService.getHospitalsList({ search: search });
    console.log("hospital", result);
    setHospitalsList(result.data);
  };

  const setSelectedOptionHospital = (e) => {
    var selectedHospital = [{ value: e.value, label: e.label }];
    setSelectedHospital(selectedHospital);
  };

  const showHospitalsList = (hospitalsList) => {
    var tempHospitals = [{ label: "Search by Hospital", value: "" }];
    hospitalsList.map((item) => {
      var option = { value: item._id, label: item.name };
      tempHospitals.push(option);
    });
    setHospitals(tempHospitals);
  };

  useEffect(() => {
    if (hospitalsList !== undefined) {
      showHospitalsList(hospitalsList);
    }
  }, [hospitalsList]);

  const handleSearch = (val) => {
    setSearch(val);
    setActivePage(1);
    //debouncedSearch(val);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (pageNumber !== activePage) {
      setLoading(true);
      window.scrollTo({ top: 0 });
    }
    // getList();
  };

  const getList = async () => {
    setBookingList([]);
    setLoading(true);
    setFirstLoad(1);
    try {
      const res = await repsBookingService.getAllBookingForItemUsed({
        type: ["loan"],
        status: ["dispatch", "booked"],
        search: search,
        page: activePage,
        limit: 50,
        reps_id: user?.data?._id,
        hospitalId: selectedHospital[0]?.value,
        itemUsedProduct_filter: status,
      });

      setBookingList(res.data);
      setTotalItemsCount(res.total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleStatus = (e) => {
    setActivePage(1);
    setStatus(e.value);
    setSelectedStatus(e);
  };

  useDebounce(
    () => {
      if (firstLoad != 0) getList();
    },
    [search],
    800
  );

  useEffect(() => {
    getList();
  }, [activePage, status, selectedHospital]);

  useEffect(() => {
    searchRef.current.focus();
    getHospitals();
  }, []);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  if (!user || user.data.user_role !== "reps") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="item-used" />
      <main className="offset">
        <div className="container-fluid">
          <div className="row pt-4">
            <div className="col-lg-12">
              <div className="ground">
                <div className="row">
                  <div className="col-lg-4 col-md-12 mb-2">
                    <div className="sub-menu mb-0 mb-sm-3">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <Link
                          type="button"
                          className="btn btn-secondary"
                          to="/reps/item-used"
                        >
                          Today
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary mx-1"
                          to="/reps/item-used/tomorrow"
                        >
                          Tomorrow
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary"
                          to="/reps/item-used/past"
                        >
                          Past Bookings
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary btn-disabled active mx-1"
                          to="/reps/item-used/all"
                        >
                          All Bookings
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 mb-2">
                    <div className="form-search">
                      <input
                        className="form-control mr-sm-2"
                        type="text"
                        placeholder="Search by Booking ID"
                        value={search}
                        ref={searchRef}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-6 mb-2">
                    <div className="row no-gutters">
                      <div className="col-lg-5 col-12 mr-5 mb-2">
                        <div className="label_adjust">
                          <label className="small mb-0"></label>
                          <Select
                            value={selectedHospital}
                            onChange={setSelectedOptionHospital}
                            options={hospitals}
                            className="small text-left"
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-12">
                        <div className="label_adjust">
                          <label className="small mb-0">Item Used</label>
                          <Select
                            placeholder="Item Used"
                            value={selectedStatus}
                            onChange={handleStatus}
                            options={statusOptions}
                            className="text-left"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-scroll mt-4">
                  <Table className="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            minWidth: "200px",
                          }}
                        >
                          Booking #
                        </th>
                        <th scope="col">Hospital Name </th>
                        <th scope="col">Operation Name </th>

                        <th scope="col">Item Used </th>
                        <th scope="col" className="text-manage">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingList &&
                        bookingList.map((item, index) => (
                          <tr key={item._id + "-" + index}>
                            <td
                              width="200"
                              data-th="Booking #"
                            >
                              <Link
                                to={
                                  "/reps/booking/details/" + item.bookings._id
                                }
                              >
                                <span>
                                  <strong> {item.bookings.booking_id} </strong>
                                </span>
                              </Link>
                            </td>
                            <td   
                              data-th="Hospital Name"
                            >
                              {item.hospitalId.name}
                            </td>
                            <td
                              data-th="Operation Name"
                              className=""
                            >
                              <div>{item.bookings.operation_name}</div>
                            </td>

                            <td
                              data-th="Filled Status"
                            >
                              <span
                                className={
                                  "capsule " +
                                  (item.itemUsedProducts.length > 0
                                    ? "green-dim "
                                    : "red-dim ") +
                                  " mr-2 mb-1"
                                }
                              >
                                {item.itemUsedProducts.length > 0
                                  ? "Filled"
                                  : "Empty"}
                              </span>
                            </td>

                            <td
                              className="text-manage"
                            >
                              <div className="action-area dropdown">
                                {item.bookings.status == "dispatch" && (
                                  <div className="mb-2">
                                    <span>
                                      <Link
                                        className="btn btn-outline-primary btn-sm1"
                                        to={
                                          "/reps/booking/item-used/o/" +
                                          item.bookings._id
                                        }
                                      >
                                        Item Used
                                      </Link>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      {loading == true && <Norecord />}

                      {bookingList &&
                        bookingList.length == 0 &&
                        firstLoad != 0 &&
                        loading == false && (
                          <tr className="text-center">
                            <td colSpan="7">No Record Found</td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>

              {bookingList && bookingList.length > 0 && (
                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center mb-5"
                    activeLinkClass="active"
                    nextPageText="Next"
                    prevPageText="Prev"
                  />
                  <br className="d-block d-sm-none" />
                  <br className="d-block d-sm-none" />
                </div>
              )}

              {/* Dispatch Details Modal */}
            </div>
          </div>
          {/*<!--/row-->*/}
        </div>
      </main>

      <Footer />
    </div>
  );
};
export default RepsItemUsedList;
