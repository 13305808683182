import React, { useEffect, useState,useMemo } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import dateFormat from "dateformat";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { EquipmentUsedReportService } from "../../../../services/equipmentUsedReport.services";
import moment from "moment";

import * as excelJs from "exceljs";
import { debounce } from "lodash";

const EquipmentUsedReport = () => {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [searchFromDate, setSearchFromDate] = useState("");
    const [searchToDate, setSearchToDate] = useState("");
    const [filterDeliveryToDate, setfilterDeliveryToDate] = useState("");
    const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 

    const handleSearch = (val) => {
        setActivePage(1);
        setSearch(val.target.value);
    };

    const handleSearchFromDate = (val) => {
        setSearchFromDate(val != null ? val : "");
        setfilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setActivePage(1);
    };

    const handleclearToDate = event => {
        if (event.key === "Backspace") {
            setSearchToDate("");
            setfilterDeliveryToDate("");
        }
    };

    const handleclearFromDate = event => {
        if (event.key === "Backspace") {
            setSearchFromDate("");
            setfilterDeliveryFromDate("");
        }
    };

    const handleSearchToDate = (val) => {
        setSearchToDate(val != null ? val : "");
        setfilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setActivePage(1);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        getData();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const showXlsData = async (data) => {
    // setDataXlsx(newDataXlsx);
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Simple Worksheet");
    const headers = [
        "Booking ID",
        "Hospital Name",
        "Surgeon Name",
        "Operation Name",
        "Sets",
        "Operation Date",
        "Outcome Of The Case",
        "Notes"
    ];

    const headerRow = ws.addRow(headers);

    headerRow.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF" } // Background color (e.g., yellow)
        };
        cell.border = {
            top: {style:"thin"},
            left: {style:"thin"},
            bottom: {style:"thin"},
            right: {style:"thin"}
        };
    });

    data.forEach((item) => {
        
        const kitset = item.bookingKitSetData.map(set => set.setId[0].set_name).join("\n");
        let notesLines = item?.bookingproductreviewsData[0]?.description?.length > 40 ? addNewlines(item?.bookingproductreviewsData[0]?.description, 40) : item?.bookingproductreviewsData[0]?.description;
        const rowData = [
            item?.booking_id,
            item?.hospitalData[0]?.name,
            item?.bookingSurgeonData?.surgeonData?.first_name + " " + item?.bookingSurgeonData?.surgeonData?.last_name,
            item?.operation_name === "Other" ? item?.other_opration : item?.operation_name,
            kitset,
            dateFormat(item.operation_date, "ddd,mmm dS"),
            //moment(item.bookings.operation_date).format("ddd, MMMM Do, YYYY"),
            item.bookingproductreviewsData[0] ? item.bookingproductreviewsData[0].review : "",
            notesLines?.length ? notesLines : "",
        ];

        let setLength = item?.bookingKitSetData?.length ? item.bookingKitSetData.length : 1;

        let row = ws.addRow(rowData);

        row.height = setLength > 0 ? setLength*12 : 16;
        ws.getCell("E"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
        ws.getCell("H"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
        // ws.properties.outlineLevelRow = 1;
    });

    // Iterate through each column to calculate and set width
    ws.columns.forEach((column, columnIndex) => {
        let maxLength = 0;
        // Start from the third row (index 2) to skip the header row
        for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
            const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
            if(columnIndex+1 === 5 || columnIndex+1 === 8){
                const array = cellValue.split("\n");
                if(array?.length){
                    array.forEach(str => {
                        if (str.length > maxLength) {
                            maxLength = str.length;
                        }
                    });
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }else{
                if (cellValue && cellValue.toString().length > maxLength) {
                    maxLength = cellValue.toString().length;
                }
            }
        }
        // Set the width of the column based on the maximum length of its content
        column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
    });

    ws.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                };
            });
        }
        if (rowNumber === 1) {
            row.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    size: 13,
                };
            });
        }
    });

    setIsLoading(false);
    const excelBlob = await workbook.xlsx.writeBuffer();    
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "equipment-used";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);

    };

    const addNewlines = (string, interval) => {
        let result = "";
        for (let i = 0; i < string.length; i++) {
          if (i > 0 && i % interval === 0) {
            result += "\n";
          }
          result += string[i];
        }
        return result;
    };

    const getData = async () => {
        setData([]);
        setLoading(true);
        try {
            const res = await EquipmentUsedReportService.equipmentUsedReportApi({
                "search": search,
                "from_date_filter": filterDeliveryFromDate,
                "to_date_filter": filterDeliveryToDate,
                "page": activePage,
                "limit": limit,
                "status": ["booked", "dispatch", "pending", "return", "close"],
            });
            setData(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getData();
    }, [search, searchFromDate, searchToDate, activePage, limit]);
    
    const handleToggleText = (index, fullText) => {
        setData((data) => {
            const newData = [...data];
            newData[index].bookingproductreviewsData[0].showMore = !newData[index].bookingproductreviewsData[0].showMore;
            const element = document.getElementById(`notes-${index}`);
            element.textContent = newData[index].bookingproductreviewsData[0].showMore ? fullText : truncateText(fullText, 25);
            return newData;
        });
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const debouncedSearch = useMemo(() => {
        return debounce(handleSearch, 1500);
    }, [search]);

    const getXlsxData = async () => {
        setIsLoading(true);
        try {
            const res = await EquipmentUsedReportService.equipmentUsedReportDownloadApi({
                search: search,
                from_date_filter: filterDeliveryFromDate,
                to_date_filter: filterDeliveryToDate,
                page: activePage,
                limit: limit,
                status: ["booked", "dispatch", "pending", "return", "close"],
            });
            if(res.status === 200){
                showXlsData(res?.data);
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    return (
        <div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar activePage="equipment" />
                        </div>

                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-search">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                // value={search}
                                                // onChange={(e) => handleSearch(e.target.value)}
                                                onChange={debouncedSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-sm-6 d-flex justify-content-between align-items-center">
                                        <div className="label_adjust">
                                            <label className="small mb-0">Date </label>
                                            <div className="row no-gutters">
                                                <div className="col">

                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="From"
                                                        selected={searchFromDate}
                                                        onChange={(date) => handleSearchFromDate(date)}
                                                        onKeyDown={handleclearFromDate}
                                                        className="form-control"
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="mx-1">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            showClearButton={false}
                                                            placeholderText="To"
                                                            selected={searchToDate}
                                                            onChange={e => handleSearchToDate(e,)}
                                                            onKeyDown={handleclearToDate}
                                                            className="form-control"
                                                            customInput={
                                                                <MaskedInput
                                                                    pipe={
                                                                        autoCorrectedDatePipe
                                                                    }
                                                                    mask={[
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    keepCharPositions={true}
                                                                    guide={true}
                                                                    showMask={false}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div>

                                            <button  className={!loading && !isLoading ? "btn btn-default add_option" : "btn btn-default add_option dropdown-disabled"}
                                                onClick={() => !loading && !isLoading && data?.length ? getXlsxData() : ""}>Print
                                            </button>
                                            
                                        </div>

                                    </div>
                                </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" >Booking #</th>
                                                <th scope="col" style={{ minWidth: "200px" }}>Hospital Name</th>
                                                <th scope="col" style={{ minWidth: "200px" }} >Surgeon Name</th>
                                                <th scope="col" >Operation Name</th>
                                                <th scope="col" style={{ minWidth: "200px" }}>Sets</th>
                                                <th scope="col">Operation Date</th>
                                                <th scope="col">Outcome Of The Case</th>
                                                <th scope="col">Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading && data && data.map((item, inx) => (
                                                <tr key={inx}>
                                                    <td width="200" data-th="Booking #" className="align-top">
                                                        <div className="">
                                                            <Link to={"/admin/booking/details/" + item._id}>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item?.booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td data-th="Hospital Name" className="align-top">
                                                        <div className="">
                                                            {item.hospitalData && item.hospitalData.map((hospital, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{hospital.name} </span></div>
                                                                </React.Fragment>
                                                            ))}

                                                        </div>
                                                    </td>
                                                    <td data-th="Surgeon Name" className="align-top">
                                                        <div>
                                                        <div><span>{item?.bookingSurgeonData?.surgeonData?.first_name + " " + item?.bookingSurgeonData?.surgeonData?.last_name} </span></div>
                                                            {/* {item.bookingSurgeonData && item.bookingSurgeonData.map((surgeon, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{surgeon.surgeonData[0].first_name + " " + surgeon.surgeonData[0].last_name} </span></div>
                                                                </React.Fragment>
                                                            ))} */}

                                                        </div>
                                                    </td>
                                                    <td data-th="Operation Name" className="align-top">
                                                        <div>
                                                            {item?.operation_name === "Other" ? item?.other_opration : item?.operation_name}
                                                        </div>
                                                    </td>
                                                    <td data-th="Sets" className="align-top">
                                                        <div>
                                                            {item.bookingKitSetData && item.bookingKitSetData.map((kit, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{kit?.setId[0]?.set_name} </span></div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </td>
                                                    <td data-th="Operation Date" className="align-top">
                                                        <div>
                                                            {dateFormat(item.operation_date, "ddd,mmm dS")}
                                                        </div>
                                                    </td>
                                                    <td data-th="Outcome Of The Case" className="align-top">
                                                        <div>
                                                            {item?.bookingproductreviewsData[0] ? item?.bookingproductreviewsData[0]?.review : ""}
                                                        </div>
                                                    </td>
                                                    <td data-th="Notes" className="align-top">
                                                        <div id={`notes-${inx}`} className={item?.bookingproductreviewsData[0]?.showMore ? "" : "truncate"}>
                                                            {item?.bookingproductreviewsData[0] ? truncateText(item?.bookingproductreviewsData[0]?.description, 25) : ""}
                                                        </div>
                                                        {item?.bookingproductreviewsData[0] && item?.bookingproductreviewsData[0]?.description && item?.bookingproductreviewsData[0]?.description.length > 25 && (
                                                            <Link onClick={() => handleToggleText(inx, item.bookingproductreviewsData[0]?.description)}>
                                                                {item?.bookingproductreviewsData[0]?.showMore ? "Show Less" : "Read More"}
                                                            </Link>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}

                                            {data && data.length == 0 && loading == true &&                                                    
                                                <div className="loading-state">
                                                    <div className="loading"></div>
                                                </div>                                               
                                            }

                                            {data && data.length == 0 && loading == false &&
                                                <tr className="text-center">
                                                    <td colSpan="8">No Record Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                    {data && data.length > 0 && loading == false && (
                                        <div>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={limit}
                                            totalItemsCount={totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={(pageNumber) => handlePageChange(pageNumber)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center mb-5"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Prev"
                                        />
                                        <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};
export default EquipmentUsedReport;

