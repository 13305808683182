export const productConstants = {
    PRODUCTADD_REQUEST: "PRODUCT_ADD_REQUEST",
    PRODUCTADD_SUCCESS: "PRODUCT_ADD_SUCCESS",
    PRODUCTADD_FAILURE: "PRODUCT_ADD_FAILURE",

    PRODUCTEDIT_REQUEST: "PRODUCT_EDIT_REQUEST",
    PRODUCTEDIT_SUCCESS: "PRODUCT_EDIT_SUCCESS",
    PRODUCTEDIT_FAILURE: "PRODUCT_EDIT_FAILURE",

    PRODUCTUPDATE_REQUEST: "PRODUCT_UPDATE_REQUEST",
    PRODUCTUPDATE_SUCCESS: "PRODUCT_UPDATE_SUCCESS",
    PRODUCTUPDATE_FAILURE: "PRODUCT_UPDATE_FAILURE",

    GETALLPRODUCTS_REQUEST: "PRODUCTS_GETALL_REQUEST",
    GETALLPRODUCTS_SUCCESS: "PRODUCTS_GETALL_SUCCESS",
    GETALLPRODUCTS_FAILURE: "PRODUCTS_GETALL_FAILURE",

    DELETE_REQUEST: "PRODUCTS_DELETE_REQUEST",
    DELETE_SUCCESS: "PRODUCTS_DELETE_SUCCESS",
    DELETE_FAILURE: "PRODUCTS_DELETE_FAILURE"    
};
