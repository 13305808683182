import { authHeader,check419 } from "../helpers";
export const collectionBookingService = {
    getList,
    countByDate,
    uCountByDate,
    getUnprocessedList,
    getUnprocessedExport,
    getBookingCollectionExport
};

function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingCollectionList", requestOptions).then(handleResponse)
        .then(collectionList => {
            return collectionList;
        });
}

function getUnprocessedList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getPastBookingCollectionList", requestOptions).then(handleResponse)
        .then(collectionList => {
            return collectionList;
        });
}

function getUnprocessedExport(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getPastBookingCollectionExport", requestOptions).then(handleResponse)
        .then(collectionList => {
            return collectionList;
        });
}


function getBookingCollectionExport(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingCollectionExport", requestOptions).then(handleResponse)
        .then(collectionList => {
            return collectionList;
        });
}

function countByDate(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/countBookingCollection", requestOptions).then(handleResponse)
        .then(count => {
            return count;
        });
}


function uCountByDate(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/countPastBookingCollection", requestOptions).then(handleResponse)
        .then(count => {
            return count;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            } else if(response.status === 419){
                check419();
            }
        }

        return data;
    });
}