import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { repsBookingService } from "../../../../../services";
import Select from "react-select";

const RepsUpdateModal = (props) => {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
	const [selectedAdditionalRep, setSelectedAdditionalRep] = useState([]);
	const [updateBookingInfo, setUpdateBookingInfo] = useState({
		id: "",
		assigned_rep: user.data._id,
		additional_reps: [],
	});

	const setBookingData = (data) => {
		console.log("object", data);
		var defaultAdditionalReps = [];
		data.additional_reps.map((repValue) => {
			defaultAdditionalReps.push(
				props.additionalReps.find((val) => val.value === repValue)
			);
		});
		const updateBookingInfoResult = updateBookingInfo;
		updateBookingInfoResult["id"] = data["_id"];
		updateBookingInfoResult["assigned_rep"] = user.data._id;
		var tempAdditionalReps = [];
		data.additional_reps.map((item) => {
			tempAdditionalReps.push(item);
		});
		updateBookingInfoResult["additional_reps"] = tempAdditionalReps;

		setUpdateBookingInfo(updateBookingInfoResult);
		setSelectedAdditionalRep(defaultAdditionalReps);
	};

	const setSelectedOptionAdditionalRep = (selectedAdditionalRep) => {
		var tempAdditionalReps = [];
		selectedAdditionalRep.map((item) => {
			tempAdditionalReps.push(item.value);
		});

		setSelectedAdditionalRep(selectedAdditionalRep);
		setUpdateBookingInfo({
			...updateBookingInfo,
			additional_reps: tempAdditionalReps,
		});
	};

	const handleSubmit = async () => {
		console.log("ll", updateBookingInfo);
		updateBookingInfo["mail_sent"] = "false";
		updateBookingInfo["status"] = "repsUpdated";
		updateBookingInfo["log_by_id"] = user.data._id;
		var payload = {
			additional_reps: updateBookingInfo["additional_reps"],
			assigned_rep: user.data._id,
			id: updateBookingInfo["id"],
			log_by_id: user.data._id,
		};
		console.log("payload", payload);
		try {
			await repsBookingService.repsUpdate(payload);
			props.getList(1, props.search);
			props.repsUpdateModalClose(true);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (props.bookingId !== 0 && props.isRepsUpdateModal) {
			setUser(JSON.parse(localStorage.getItem("user")));
		}
	}, [props.bookingId, props.isRepsUpdateModal]);

	useEffect(() => {
		if (props.bookingInfoById?.assigned_rep != undefined) {
			setBookingData(props.bookingInfoById);
		}
	}, [props.bookingInfoById]);
	console.log("atata", user.data);
	console.log("props", props);
	return (
		<div>
			<Modal
				size="ml"
				backdrop="static"
				isOpen={props.isRepsUpdateModal}
				toggle={() => {
					props.repsUpdateModalClose(false);
					setSelectedAdditionalRep([]);
				}}
			>
				<ModalHeader
					className=""
					toggle={() => {
						props.repsUpdateModalClose(false);
						setSelectedAdditionalRep([]);
					}}
				>
					Reps Update
				</ModalHeader>
				<ModalBody>
					<div>
						<div className="d-card mt-4">
							<div className="form-group">
								<label>Additional Reps </label>
								<Select
									value={selectedAdditionalRep}
									onChange={setSelectedOptionAdditionalRep}
									options={props.additionalReps}
									isMulti={true}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="">
						<button
							onClick={() => {
								props.repsUpdateModalClose(false);
								setSelectedAdditionalRep([]);
							}}
							className="btn btn-secondary mr-2"
							data-dismiss="modal"
						>
							Cancel
						</button>

						<button
							className="btn btn-primary"
							onClick={() => handleSubmit()}
						>
							Update
						</button>
					</div>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default RepsUpdateModal;
