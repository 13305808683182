export const setConstants = {
    GETSETLIST_REQUEST: "SET_GET_REQUEST",
    GETSETLIST_SUCCESS: "SET_GET_SUCCESS",
    GETSETLIST_FAILURE: "SET_GET_FAILURE",

    SETADD_REQUEST: "SET_ADD_REQUEST",
    SETADD_SUCCESS: "SET_ADD_SUCCESS",
    SETADD_FAILURE: "SET_ADD_FAILURE",

    SETEDIT_REQUEST: "SET_EDIT_REQUEST",
    SETEDIT_SUCCESS: "SET_EDIT_SUCCESS",
    SETEDIT_FAILURE: "SET_EDIT_FAILURE",

    SETUPDATE_REQUEST: "SET_UPDATE_REQUEST",
    SETUPDATE_SUCCESS: "SET_UPDATE_SUCCESS",
    SETUPDATE_FAILURE: "SET_UPDATE_FAILURE",

    GETKITSLIST_REQUEST: "KITS_GET_REQUEST",
    GETKITSLIST_SUCCESS: "KITS_GET_SUCCESS",
    GETKITSLIST_FAILURE: "KITS_GET_FAILURE",

    GETPRODUCTSLIST_REQUEST: "PRODUCTS_GET_REQUEST",
    GETPRODUCTSLIST_SUCCESS: "PRODUCTS_GET_SUCCESS",
    GETPRODUCTSLIST_FAILURE: "PRODUCTS_GET_FAILURE",

    DELETE_REQUEST: "SETS_DELETE_REQUEST",
    DELETE_SUCCESS: "SETS_DELETE_SUCCESS",
    DELETE_FAILURE: "SETS_DELETE_FAILURE"    
};
