import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Spinner,
} from "reactstrap";
import {
	bookingService,
} from "../../../../../../services";


const PrepareModal = (props) => {
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

	const modalClose = (flag) => {
		props.prepareModalClose(flag);
	};

	const handleDoPrepare = async () => {
		setLoading(true);
		const params = {
			bookingId: props.bookingId,
			log_by_id: user.data._id,
		};
		try {
			const response = await bookingService.doPrepare(params);
			console.log("API response:", response);
			modalClose(true);
		} catch (error) {
			console.error("API call failed:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem("user")));
	}, []);

	return (
		<Modal
			size="md"
			backdrop="static"
			centered 
			isOpen={props.prepareModal}
			toggle={() => modalClose(false)}
		>
			<ModalHeader className="" toggle={() => modalClose(false)}>
			 Prepare
			</ModalHeader>
			<ModalBody>Are you sure you want to start preparing?</ModalBody>
			<ModalFooter>
				<div className="">
					<button
						onClick={() => modalClose(false)}
						className="btn btn-secondary mr-2"
						data-dismiss="modal"
					>
						Cancel
					</button>
					{!loading && (
						<button
							className="btn btn-primary"
							onClick={handleDoPrepare}
						>
							Ok
						</button>
					)}
					{loading && (
						<button
							className="btn btn-primary loader-icon"
							disabled
						>
							<Spinner className="img-svg"></Spinner>
						</button>
					)}
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default PrepareModal;
