import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import dateFormat from "dateformat";
import { bookingService, kitService, surgeonService, operationService } from "../../../../services";
import InfinitScroll from "react-infinite-scroll-component";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import * as excelJs from "exceljs";
import FilterTooltip from "./Modal/ConsignmentFilter";

import ConsignmentSidebar from "./ConsignmentSidebar";

const Consignment = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [activePage, setActivePage] = useState(1);
    const [limit, setLimit] = useState(6);
    const [bookingList, setBokingList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalItemsCount, setTotalItemsCount, totalItemsCountRef] = useState(0);

    const [filterModal, setFilterModal] = useState(false);
    const [filterPoChase, setFilterPoChase, filterPoChaseRef] = useState("all");
    const [filterCreateDate, setFilterCreateDate, filterCreateDateRef] = useState("");
    const [surgeons, setSurgeons] = useState([]);
    const [selectedSurgeon, setSelectedSurgeon] = useState([{ label: "All Surgeon", value: "" }]);
    const [surgeonId, setSurgeonId, surgeonIdRef] = useState("");
    const [operationsList, setOperationsList] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState([{ label: "All Operation", value: "" }]);
    const [hospitals, setHospitalList] = useState([]);
    const [hospitalId, setHospitalId, hospitalIdRef] = useState("");
    const [selectedHospital, setSelectedHospital] = useState([{ label: "All Hospital", value: "" }]);
    const [operationId, setOperationId, operationIdRef] = useState("");

    const [sideBarFilterOperationToDate,setSideBarFilterOperationToDate,sideBarFilterOperationToDateRef]=useState("");
    const [sideBarFilterOperationFromDate,setSideBarFilterOperationFromDate,sideBarFilterOperationFromDateRef]=useState("");

    const [kits,setKits] = useState([]);
    const [selectedKit,setSelectedKit] = useState([]);
    const [selectdKitValue,setSelectdKitValue,selectdKitValueRef]=useState("");
    const [selectdSetValue,setSelectdSetValue,selectdSetValueRef]=useState("");

    const getList = async (flag, searchTerm) => {
        setLoading(true);
        setActivePage(flag ? 1 : activePage + 1);

        try {
            const res = await bookingService.getConsignmentReport({
                working_delivery_from_date: "",
                type: ["consignment"],
                status: ["booked", "dispatch", "return", "close", "cancle"],
                collection_from_date: sideBarFilterOperationFromDateRef.current,
                collection_to_date: sideBarFilterOperationToDateRef.current,
                search: searchTerm != undefined ? searchTerm : "",
                page: flag ? 1 : activePage + 1,
                limit: limit,
                kitFilter:selectdKitValueRef.current,
                setFilter:selectdSetValueRef.current,
                po_check: filterPoChaseRef.current,
                created_date: filterCreateDateRef.current,
                hospitalFilter: hospitalIdRef.current,
                surgeonFilter: surgeonIdRef.current,
                operationFilter: operationIdRef.current
            });
            let bookingRes = flag ? res.data : bookingList.concat(res.data);
            setBokingList(bookingRes);
            setTotalItemsCount(res?.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setBokingList([]);
        setUser(JSON.parse(localStorage.getItem("user")));
        getList(1);

    }, [filterCreateDateRef.current, filterPoChaseRef.current,
        surgeonIdRef.current, operationIdRef.current, hospitalIdRef.current,sideBarFilterOperationToDateRef.current,sideBarFilterOperationFromDateRef.current,selectdKitValueRef.current,selectdSetValueRef.current]);

    useEffect(() => {
        console.log(setLimit, totalItemsCount, hospitalId,selectdKitValue,selectdSetValue,
            surgeonId, operationId, filterPoChase, filterCreateDate,sideBarFilterOperationToDate,sideBarFilterOperationFromDate);
        getKitListDropdown();
        getHospitalList();
        getSurgeonListDropdown();
        getOperationListDropdown();
    }, []);

    const getXlsxData = async () => {

        try {
            const res = await bookingService.getConsignmentReportExport({
                working_delivery_from_date: "",
                type: ["consignment"],
                status: ["booked", "dispatch", "return", "close", "cancle"],
                collection_from_date: sideBarFilterOperationFromDateRef.current,
                collection_to_date: sideBarFilterOperationToDateRef.current,
                search: "",
                page: 1,
                limit: totalItemsCountRef.current,
                kitFilter:selectdKitValueRef.current,
                setFilter:selectdSetValueRef.current,
                po_check: filterPoChaseRef.current,
                created_date: filterCreateDateRef.current,
                hospitalFilter: hospitalIdRef.current,
                surgeonFilter: surgeonIdRef.current,
                operationFilter: operationIdRef.current
            });
            if (res.status) {
                handleExportClick(res?.data);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error: ", error);
        }
    };

    const addNewlines = (string, interval) => {
        let result = "";
        for (let i = 0; i < string.length; i++) {
            if (i > 0 && i % interval === 0) {
                result += "\n";
            }
            result += string[i];
        }
        return result;
    };

    const handleExportClick = async (data) => {
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet("Consigment Worksheet");

        const header1 = [
            "Booking Id",
            "Hospital Name",
            "Kit",
            "Sets",
            "Return Date",
            "Dispatch Date",
            "Delivery Time",
            "Collection Date",
            "Slot",
            "Notes",
        ];

        const header1Row = ws.addRow(header1);
        header1Row.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 11,
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "CCC0DA" }
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
            };
        });

        if (data?.length) {
            let kitset = "";
            let kitName = "";
            let returnDate = "";
            data && data.map(item => {
                kitset = "";
                kitName = "";
                returnDate = "";
                let kitDataArray = [];
                let notesLines = item?.additional_notes?.length > 40 ? addNewlines(item?.additional_notes, 40) : item?.additional_notes;

                item.bookingKitSetId &&
                    item.bookingKitSetId.map(kset => {
                        if (kitset != "") {
                            kitset += "\n" + kset.setId[0].set_name;
                            returnDate += "\n" + moment(kset?.set_return_date).format("ddd, MMMM Do, YYYY");
                        } else {
                            kitset += kset.setId[0].set_name;
                            returnDate += moment(kset?.set_return_date).format("ddd, MMMM Do, YYYY");
                        }
                        if (!kitDataArray.includes(kset.kitData[0].name)) {
                            kitDataArray.push(kset.kitData[0].name);
                        }
                    });

                item?.bookingKitSetId?.length === 0 && item?.bookingkits &&
                    item?.bookingkits.map(kit => {
                        if (!kitDataArray.includes(kit?.kits[0]?.name)) {
                            kitDataArray.push(kit?.kits[0]?.name);
                        }
                    });

                kitDataArray && kitDataArray.map((kitValue) => {
                    if (kitName != "") {
                        kitName += "\n" + kitValue;
                    } else {
                        kitName += kitValue;
                    }
                });

                const rowData = [
                    item?.booking_id,
                    item.hospitalData[0].name,
                    kitset,
                    kitName,
                    returnDate,
                    moment(item.delivery_date).format("ddd, MMMM Do, YYYY"),
                    item.delivery_date_slot,
                    moment(item.collection_date
                        ).format("ddd, MMMM Do, YYYY"),
                    item.operation_date_slot,
                    notesLines
                ];
                let row = ws.addRow(rowData);
                row.height = item?.bookingKitSetId?.length ? item.bookingKitSetId.length * 12 : 15;
                ws.getCell("C" + row?._number).alignment = { vertical: "bottom", horizontal: "left" };
                ws.getCell("D" + row?._number).alignment = { vertical: "bottom", horizontal: "left" };
                ws.getCell("E" + row?._number).alignment = { vertical: "bottom", horizontal: "left" };
                ws.getCell("I" + row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            });
        }

        // Iterate through each column to calculate and set width
        ws.columns.forEach((column, columnIndex) => {
            let maxLength = 0;
            // Start from the third row (index 2) to skip the header row
            for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
                const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
                if (columnIndex + 1 === 3 || columnIndex + 1 === 5 || columnIndex + 1 === 10 || columnIndex + 1 === 4) {
                    const array = cellValue.split("\n");
                    if (array?.length) {
                        array.forEach(str => {
                            if (str.length > maxLength) {
                                maxLength = str.length;
                            }
                        });
                    } else {
                        if (cellValue && cellValue.toString().length > maxLength) {
                            maxLength = cellValue.toString().length;
                        }
                    }
                } else {
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }
            // Set the width of the column based on the maximum length of its content
            column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
        });

        ws.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin", color: { argb: "000000" } },
                        left: { style: "thin", color: { argb: "000000" } },
                        bottom: { style: "thin", color: { argb: "000000" } },
                        right: { style: "thin", color: { argb: "000000" } },
                    };
                });
            }
        });

        const excelBlob = await workbook.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );

        setIsLoading(false);

        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "consignment-reports";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    };

    if (!user || user.data.user_role !== "admin") {
        return <Redirect to="/" />;
    }

    const getKitListDropdown = async () => {
        try {

            const res = await kitService.kitListDropdown({ search: "" });

            let kitList = res?.data ? res?.data : [];
            let tempKitList = [];

            kitList?.length && kitList.map((item) => {
                var option = { value: item._id, label: item.name };
                tempKitList.push(option);
            });

            setKits(tempKitList);

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleFilterToggle = () => {
        setFilterModal(!filterModal);
    };

    const handleFilterRefreesh = (data) => {
        if (data !== undefined) {
            setBokingList([]);

            if (filterPoChaseRef.current === data?.filterPoChase &&
                filterCreateDateRef.current === data?.filterCreateDate &&
                hospitalIdRef.current === data?.hospitalId &&
                surgeonIdRef.current === data?.surgeonId &&
                operationIdRef.current === data?.operationId &&
                sideBarFilterOperationToDateRef.current === data?.sideBarFilterOperationToDate &&
                sideBarFilterOperationFromDateRef.current === data?.sideBarFilterOperationFromDate &&
                selectdKitValueRef.current === data?.selectdKitValue &&
                selectdSetValueRef.current === data?.selectdSetValue) {
                getList(1);
            }

            setActivePage(1);
            //setFilterModal(!filterModal);
            setFilterPoChase(data?.filterPoChase);
            setFilterCreateDate(data?.filterCreateDate);
            setHospitalId(data?.hospitalId);
            setSelectedHospital(data?.selectedHospitalProp);
            setSurgeonId(data?.surgeonId);
            setSelectedSurgeon(data?.selectedSurgeonProp);
            setOperationId(data?.operationId);
            setSelectedOperation(data?.selectedOperationProp);
            setSideBarFilterOperationToDate(data?.sideBarFilterOperationToDate);
            setSideBarFilterOperationFromDate(data?.sideBarFilterOperationFromDate);

            setSelectedKit(data?.selectedKitProp);
            setSelectdKitValue(data?.selectdKitValue);
            setSelectdSetValue(data?.selectdSetValue);
        }
    };

    const getSurgeonListDropdown = async () => {
        try {

            const res = await surgeonService.getSurgeonsList({ search: "" });

            let surgeonList = res?.data ? res?.data : [];
            let tempSurgeonList = [{ label: "All Surgeon", value: "" }];
            surgeonList && surgeonList.map(item => {
                let option = {
                    value: item._id,
                    label: item.first_name + " " + item.last_name,
                    notes: item?.notes ? item?.notes : ""
                };
                tempSurgeonList.push(option);
            });
            setSurgeons(tempSurgeonList);

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const getOperationListDropdown = async () => {
        try {

            const res = await operationService.getOperationsList({ search: "" });

            let operationList = res?.data ? res?.data : [];
            let tempOperationList = [{ label: "All Operation", value: "" }];
            operationList && operationList.map(item => {
                let option = { value: item.name, label: item.name, id: item._id };
                tempOperationList.push(option);
            });
            setOperationsList(tempOperationList);

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const getHospitalList = async () => {
        try {
            const res = await bookingService.getHospitalsList({
                search: ""
            });

            if (res.status === 200) {
                showHospitalsList(res?.data);
            }

        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const showHospitalsList = (hospitalsList) => {
        let tempHospitals = [{ label: "All Hospital", value: "" }];
        hospitalsList.map((item) => {
            let option = { value: item._id, label: item.name };
            tempHospitals.push(option);
        });
        setHospitalList(tempHospitals);
    };

    return (
        <div>
            <Navbar activePage="reports" />
            <div className="container-fluid">

                <div className="row pt-4">
                    <div className="col-lg-2 side-filters side-reports">
                        <div className="card side-report">
                            <ConsignmentSidebar
                                activePage="dispatch"
                                dispatchCount={totalItemsCount}
                                filterPoChase={filterPoChaseRef.current}
                                filterCreateDate={filterCreateDateRef.current}
                                handleFilterRefreesh={(data) => handleFilterRefreesh(data)}
                                kits={kits}
                                selectedKitProp={selectedKit}
                                surgeons={surgeons}
                                selectedSurgeonProp={selectedSurgeon}
                                operationsList={operationsList}
                                selectedOperationProp={selectedOperation}
                                selectedFilterOperationToDate={sideBarFilterOperationToDateRef.current}
                                selectedFilterOperationFromDate={sideBarFilterOperationFromDateRef.current}
                                hospitals={hospitals}
                                selectedHospitalProp={selectedHospital}
                            />
                        </div>


                    </div>

                    <div className="col-lg-10 align-items-center">
                        <div className="ground">
                            <div className="row">
                                <div className="col-lg-5 col-md-5">
                                    <ul className="sub-menu">
                                        <li><Link to="/admin/reports">Historical</Link></li>
                                        <li><Link to="/admin/reports/consignment" className="active">Consignment</Link></li>
                                    </ul>
                                </div>

                                <div className="col-md-7 kit-filter text-right">

                                    <div className="mb-2 custom_fltr">
                                        {/* <a onClick={() => handleFilterToggle()}><FilterIcon className="img-svg"></FilterIcon></a> */}
                                        <FilterTooltip
                                            handleFilterToggle={() => handleFilterToggle()}
                                            filterModal={filterModal}
                                            filterPoChase={filterPoChaseRef.current}
                                            filterCreateDate={filterCreateDateRef.current}
                                            handleFilterRefreesh={(data) => handleFilterRefreesh(data)}
                                            surgeons={surgeons}
                                            selectedSurgeonProp={selectedSurgeon}
                                            operationsList={operationsList}
                                            selectedOperationProp={selectedOperation}
                                            hospitals={hospitals}
                                            selectedHospitalProp={selectedHospital}
                                        />
                                    </div>

                                    <button className={!loading && !isLoading ? "btn btn-default add_print ml-1 mb-2" : "btn btn-default add_print ml-1 mb-2 dropdown-disabled"}
                                        onClick={() => !loading && !isLoading && bookingList?.length ? getXlsxData() : ""}
                                        id="ReportDownload"
                                    >
                                        <i className="fa fa-print" ></i>
                                    </button>

                                    {!loading && !isLoading ? "" : <UncontrolledTooltip
                                        target={"ReportDownload"}
                                    >
                                        {loading ? "Please wait" : isLoading ? "Please wait" : "No Record Found"}
                                    </UncontrolledTooltip>}
                                </div>
                            </div>


                            <InfinitScroll
                                dataLength={bookingList.length}
                                next={() => { bookingList.length > 5 ? getList(0) : () => { }; }}
                                hasMore={true}
                                scrollThreshold={"100%"}
                            >
                                <div id="table-scroll" className="table-scroll mt-4">
                                    <table id="main-table" className="main-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="200">Hospital Name</th>
                                                <th scope="col">Contact Person</th>
                                                <th scope="col">Sets</th>
                                                {/* <th scope="col">Return Date</th> */}
                                                <th scope="col">Operation Name</th>
                                                <th scope="col">Important Dates</th>
                                                {/* <th scope="col">Booked By</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList && bookingList.map((item) => (
                                                <tr key={item._id}>
                                                    <td width="200" data-th="Hospital Name">
                                                        <div className="">
                                                            <Link to={"/admin/booking/details/" + item._id}>
                                                                <span><strong><span style={{ "display": "block" }} className="theme-text">{item.booking_id}</span>
                                                                    {item?.hospitalData[0]?.name}</strong></span><br />
                                                                <small>Booked on {dateFormat(item.createdAt, "ddd,mmm dS")}</small><br />
                                                                {item?.bookingSurgeonData
                                                                    ?.SurgeonDetail?.length  >
                                                                    0 && (
                                                                        <React.Fragment>
                                                                            <span>
                                                                                <small>
                                                                                    Surgeon:
                                                                                </small>{" "}
                                                                                <strong>
                                                                                {item
                                                                                    .bookingSurgeonData
                                                                                    .SurgeonDetail[0]
                                                                                    .first_name +
                                                                                    " " +
                                                                                    item
                                                                                        .bookingSurgeonData
                                                                                        .SurgeonDetail[0]
                                                                                        .last_name}
                                                                                </strong>
                                                                            </span><br />
                                                                        </React.Fragment>
                                                                    )}
                                                                {
                                                                    item.po_number !== "" &&
                                                                    <React.Fragment>
                                                                        <small className="mobilehide">PO Number: {item.po_number}</small>
                                                                    </React.Fragment>
                                                                }
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td data-th="Contact Person">
                                                        <div className="info-left">
                                                            <strong>{item?.hospitalData[0]?.contact_person_name} </strong><br />
                                                            <small>Location: {item.hospital_address} </small><br />
                                                            <small>Email: {item?.hospitalData[0]?.email}</small><br />
                                                            <small>Phone: {item?.hospitalData[0]?.contact_person_phone_number}</small><br />
                                                            <small>Department:{item.hospital_department}</small>
                                                        </div>
                                                    </td>
                                                    <td data-th="Sets" className="align-top">
                                                        <small>
                                                            <ul className="list">
                                                                {item.bookingKitSetId && item.bookingKitSetId.map((kset, ind) => (
                                                                    <React.Fragment key={ind}>
                                                                        <li>
                                                                            <span
                                                                                className={
                                                                                    "status circle mr-1 " +
                                                                                    (kset.status ==
                                                                                        "close"
                                                                                        ? "green "
                                                                                        : "red ")
                                                                                }
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title=""
                                                                                data-original-title="Retuned">
                                                                            </span>
                                                                            <span>{kset.setId[0].set_name} {" "}</span>
                                                                        </li>
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </small>
                                                    </td>

                                                    <td data-th="Operation Name">
                                                        {item
                                                            .operation_name ===
                                                            "Other" ? (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    item
                                                                        .other_opration
                                                                }{" "}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {" "}
                                                                {
                                                                    item
                                                                        .operation_name
                                                                }{" "}
                                                            </span>
                                                        )}
                                                    </td>

                                                    <td
                                                        data-th="Important Dates"
                                                        className="">
                                                        <small>
                                                            {item
                                                                .booking_type ==
                                                                "loan" && (
                                                                    <div>
                                                                        <span className="">
                                                                            Delivery
                                                                            Date:{" "}
                                                                            <strong>
                                                                                {dateFormat(
                                                                                    item
                                                                                        .delivery_date,
                                                                                    "ddd,mmm dS",
                                                                                )}{" "}
                                                                                {
                                                                                    item
                                                                                        .delivery_date_slot
                                                                                }
                                                                            </strong>
                                                                            <br />
                                                                        </span>
                                                                        Operation
                                                                        Date:{" "}
                                                                        <strong>
                                                                            {dateFormat(
                                                                                item
                                                                                    .operation_date,
                                                                                "ddd, mmm dS",
                                                                            )}{" "}
                                                                            {
                                                                                item
                                                                                    .operation_date_slot
                                                                            }
                                                                        </strong>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            {item
                                                                .booking_type !=
                                                                "loan" && (
                                                                    <div>
                                                                        <span className="">
                                                                            Delivery
                                                                            Date:{" "}
                                                                            <strong>
                                                                                {dateFormat(
                                                                                    item.delivery_date,
                                                                                    "ddd,mmm dS",
                                                                                )}
                                                                            </strong>
                                                                        </span>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            <span className="">
                                                                Collection Date:{" "}
                                                                <strong>
                                                                    {dateFormat(
                                                                        item.collection_date,
                                                                        "ddd,mmm dS",
                                                                    )}
                                                                </strong>
                                                            </span><br />
                                                            <span className="">

                                                                Create Date:{" "}
                                                                <strong>
                                                                    {dateFormat(
                                                                        item.confirm_booking_date,
                                                                        "ddd,mmm dS",
                                                                    )}
                                                                </strong>
                                                            </span>
                                                        </small>
                                                    </td>
                                                </tr>
                                            ))}
                                            {bookingList && bookingList.length == 0 && loading == false &&
                                                <tr className="text-center">
                                                    <td colSpan="5">No Record Found</td>
                                                </tr>
                                            }

                                            {loading == true || bookingList && bookingList.length == 0 && loading == true ?
                                                ([1, 2, 3, 4, 5].map((item) => (
                                                    <tr key={item}>
                                                        <td >
                                                            <p className="placeholder-glow">
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                            </p>
                                                        </td>
                                                        <td >
                                                            <p className="placeholder-glow">
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                            </p>
                                                        </td>
                                                        <td >
                                                            <p className="placeholder-glow">
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>

                                                            </p>
                                                        </td>
                                                        <td >
                                                            <p className="placeholder-glow">
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>

                                                            </p>
                                                        </td>
                                                        <td >
                                                            <p className="placeholder-glow">
                                                                <div className="skeleton skeleton-text"></div>
                                                                <div className="skeleton skeleton-text"></div>

                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))) : ""
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </InfinitScroll>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Consignment;