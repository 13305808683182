import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { bookingActions } from "../../../../../../actions";
import { bookingService } from "../../../../../../services";
import { ReactComponent as Spinner } from "../../../../../../assets/images/Spinner-1s-200px.svg";
import moment from "moment";

import Select from "react-select";

class Return extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            updateBookingInfo: {
                id: "",
                hospitalId: "",
                hospital_address: "",
                po_number: "",
                operation_name: "",
                shipping_charge: "",
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date: "",
                delivery_date_slot: "",
                operation_date: "",
                operation_date_slot: "",
                collection_date: "",
                booking_type: "",
                kitData: [],
                kitSetData: [],
                return_date: ""
            },
            returnData: {
                courier: "a",
                return_date: "",
                next_return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status: "returned",
                log_by_id: ""
            },
            showDateInput: true,
            boxArray: [{ box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false }],
            returnKit: [],
            returnModal: this.props.returnModal,
            returnSubmitFlag: 0,
            returnSetDataLength: 0,
            kitWithKitSet: [],
            editData: [],
            boxOptions: [
                { label: "Black Box", value: "Black Box" },
                { label: "Blue Box", value: "Blue Box" },
                { label: "Grey case", value: "Grey case" },
                { label: "Cardboard Box", value: "Cardboard Box" }
            ],
            selectedNumOfBox: [{ label: "Black Box", value: "Black Box" }]

        };

        this.getSingleBooking = this.getSingleBooking.bind(this);
        this.getEditData = this.getEditData.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
        this.returnModalClose = this.returnModalClose.bind(this);
        this.handleCourier = this.handleCourier.bind(this);
        this.handleReturnDate = this.handleReturnDate.bind(this);
        //this.handleNote = this.handleNote.bind(this);
        this.submitReturnData = this.submitReturnData.bind(this);
    }

    handleMinMax() {
        const { kitWithKitSet } = this.state;
        const dateArray = [];
        kitWithKitSet &&
            kitWithKitSet.map((kit) =>
                kit.sets
                    .filter((setd) => setd.added === 1 && setd.status !== "close" && setd.set_return_date !== undefined && setd.isreturn == false)
                    .map((setd) => {
                        dateArray.push(setd.set_return_date);
                    })
            );

        if (dateArray.length > 0 && dateArray !== undefined) {
            const maxDate = new Date(Math.max(...dateArray.map(date => {
                return new Date(date);
            })));
            const minDate = new Date(Math.min(...dateArray.map(date => {
                return new Date(date);
            })));

            const formattedminDate = moment(minDate).format("YYYY-MM-DD");
            const formattedmaxDate = moment(maxDate).format("YYYY-MM-DD");
            this.setState({
                returnData: {
                    ...this.state.returnData,
                    next_return_date: formattedminDate,
                    return_date: formattedmaxDate
                }
            });
        }
    }

    handleSetReturnDate(e, outerIndex, innerIndex) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet[outerIndex].sets[innerIndex].set_return_date = e.target.value;
        this.setState({
            kitWithKitSet
        }, () => {
            this.checkDateInput();
            this.handleMinMax();
        });
    }


    handleSetReturnComment(e, outerIndex, innerIndex) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet[outerIndex].sets[innerIndex].comment = e.target.value;
        this.setState({
            kitWithKitSet
        });
    }


    getSingleBooking(id) {
        this.props.dispatch(bookingActions.getById(id));
    }

    getEditData(id) {
        bookingService.getById(id).then((Response) => {
            this.setState({
                editData: Response.data
            });
        });
    }

    handleReturn() {
        this.setState({
            returnModal: true,
            returnSubmitFlag: 0,
            returnSetDataLength: 0
        });
    }

    returnModalOpen(bookingId) {
        this.setState({
            returnModal: true,
            bookingId: bookingId,
            returnSetDataLength: 0,
            returnSubmitFlag: 0,
        });
        this.getSingleBooking(bookingId);
        this.getEditData(bookingId);

    }

    returnModalClose() {
        const { kitWithKitSet } = this.state;
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                set.isreturn = false;
            });
        });

        this.setState({
            returnModal: false,
            returnSubmitFlag: 0,
            returnData: {
                courier: "a",
                return_date: "",
                next_return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status: "returned",
                log_by_id: ""
            },
            boxArray: [{ box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false }],
            kitWithKitSet: kitWithKitSet
        });

        this.props.returnModalClose();
    }

    returnSuccess() {
        const { kitWithKitSet } = this.state;

        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                set.isreturn = false;
            });
        });

        this.setState({
            returnModal: false,
            returnData: {
                courier: "a",
                return_date: "",
                next_return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status: "returned",
                log_by_id: ""
            },
            boxArray: [{ box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false }],
            kitWithKitSet: kitWithKitSet
        });

        this.props.returnSuccess();
    }

    handleCourier(val) {
        this.setState({
            returnData: {
                ...this.state.returnData,
                courier: val
            },
        });
    }

    handleReturnSet(val, outerIndex, innerIndex) {
        const { kitWithKitSet } = this.state;
        
        var currentDate = new Date();
        currentDate = moment(currentDate).format("YYYY-MM-DD");
        kitWithKitSet[outerIndex].sets[innerIndex].set_return_date = currentDate;

        kitWithKitSet[outerIndex].sets[innerIndex].isreturn = val;

        this.setState({
            kitWithKitSet
        }, () => {
            this.checkDateInput();
            this.handleMinMax();
        });
    }

    checkDateInput() {
        const { kitWithKitSet } = this.state;
        var trueCount = 0;
        var setCount = 0;
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                if (set.added == 1 && set.status != "close") {
                    setCount++;
                }
                if (set.isreturn === true && set.status != "close" && set.added == 1) {
                    trueCount++;
                }
            });
        });

        if (trueCount !== setCount) {
            this.setState({
                showDateInput: true
            });
        } else {
            this.setState({
                showDateInput: false
            });
        }
        this.setState({
            kitWithKitSet
        }, () => {
            this.handleMinMax();
        });
    }

    handleReturnDate(val) {
        this.setState({
            returnData: {
                ...this.state.returnData,
                return_date: val
            },
        });
    }

    handlenextReturnDate(val) {
        this.setState({
            returnData: {
                ...this.state.returnData,
                next_return_date: val
            },
        });
    }

    handleReturnBoxType(index, val) {
        const { boxArray } = this.state;
        boxArray[index].box_type = val.value;
        this.setState({
            boxArray
        });
    }

    handleReturnBoxNumber(index, val) {
        const { boxArray } = this.state;
        boxArray[index].number_of_box = val;
        boxArray[index].numberOfBoxFlag = false;
        this.setState({
            boxArray
        });
    }

    // handleNote(val) {
    //     this.setState({
    //         returnData: {
    //             ...this.state.returnData,
    //             additional_note: val
    //         },
    //     });
    // }

    handleBoxAdd(e) {
        e.preventDefault();
        const { boxArray } = this.state;
        boxArray.push({ box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false });
        this.setState({
            boxArray
        });
    }

    deleteAddBox(e, index) {
        e.preventDefault();
        const { boxArray } = this.state;
        var deleteBox = boxArray;
        deleteBox.splice(index, 1);
        this.setState({
            boxArray: deleteBox
        });
    }

    submitReturnData() {
        const { kitWithKitSet, returnData } = this.state;
        var falg = 0;
        var kitSetLength = 0;
        var returnSetArray = [];
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                if (set.added == 1 && set.status != "close") {
                    kitSetLength++;
                }
                if (set.added == 1 && set.status != "close") {
                    returnSetArray.push({ "bookingKitSetId": set._id, "set_return_date": set.set_return_date, "setStatusChecked": set.isreturn,"comment" : set.comment });
                }

            });
        });

        returnData.returnBoxesData = this.state.boxArray;
        returnData.returnSetData = returnSetArray;
        returnData.bookingId = this.state.bookingId;
        returnData.log_status = "returned";
        returnData.log_by_id = this.state.user.data._id;

        this.state.boxArray.map((item) => {
            if (item.box_type == "" || item.number_of_box == "") {
                falg = 1;
            }

            if(item.number_of_box=="")
            {
                item.numberOfBoxFlag=true;
            }
        });


        var checkedFlag=returnData.returnSetData.filter((task)=>task.setStatusChecked==true).length;


        if (checkedFlag == 0 ) {
            falg = 1;
        }

        if (checkedFlag > 0 && checkedFlag != kitSetLength && returnData.return_date == "") {
            falg = 1;
        }
        else if (returnData.return_date != "" && checkedFlag > 0 && checkedFlag== kitSetLength) {
            const isChecked = returnData.returnSetData.map((val) => val.setStatusChecked);
            const allTrue = isChecked.every(value => value === true);
            if (allTrue) {
                returnData.return_date = "";
                returnData.next_return_date = "";
            }
        }

        this.setState({
            returnSubmitFlag: 1,
            returnSetDataLength: checkedFlag
        });

        if (falg == 0) {
            this.setState({
                returnData
            }, () => {
                this.props.dispatch(bookingActions.saveReturn(this.state.returnData));
            });
        }
    }

    setBookingData(data) {
        this.setState({
            kitWithKitSet: []
        });
        data.bookingKitId.map((item) => {

            this.props.dispatch(bookingActions.getKitSetByKit({ kitId: item.kitId }));
        });
    }

    showKitsSetList(data) {
        const { kitWithKitSet } = this.state;
        var subKitSet = [];
        var lnt = 0;
        var hire_charge = "";
        var status = "";
        var date = "";
        var comment = "";

        data[0]["kitsets"].map((kset) => {
            hire_charge = "";
            status = "";
            date = "";
            lnt = data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == this.state.bookingId).length;
            if (lnt > 0) {
                data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == this.state.bookingId).map((result) => {
                    hire_charge = result.hire_charge;
                    status = result.status;
                    date=result.set_return_date?result.set_return_date:"";
                    comment = result?.comment;
                });

            }


            subKitSet.push({ set_name: kset.set_name, _id: kset._id, hire_charge: hire_charge, added: lnt, status: status, isreturn: false, set_return_date: moment(date).format("YYYY-MM-DD"),comment: comment});
        });

        kitWithKitSet.push({ name: data[0].name, _id: data[0]._id, sets: subKitSet });

        this.setState({
            kitWithKitSet
        }, () => {
            this.checkDateInput();
            this.handleMinMax();
        });
    }

    redirectPage() {
        this.setState({
            redirect: true
        });

    }

    componentDidMount() {
        //this.getSingleBooking(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        if (nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        if (nextProps.kitSetList !== undefined) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if (nextProps.returnModal !== undefined && nextProps.returnModal == true && this.state.returnModal == false) {
            this.returnModalOpen(nextProps.bookingId);
        }

        if (this.state.returnModal == true && nextProps.returnRefresh == true) {
            this.returnSuccess();
        }
    }

    render() {
        const { error, loading } = this.props;
        const { boxOptions, selectedNumOfBox } = this.state;
        return (
            <div>
                <Modal size="lg" backdrop="static" isOpen={this.state.returnModal} toggle={() => this.returnModalClose()}>
                    <ModalHeader charcode="Y" toggle={() => this.returnModalClose()}>
                        Add Return
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="form-group" style={{ "display": "none" }}>
                                <label>Courier</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={this.state.returnData.courier}
                                    onChange={(e) => this.handleCourier(e.target.value)}
                                />
                                {this.state.returnSubmitFlag == 1 && error ? <span className="text-danger">{error.courier ? error.courier : ""}</span> : ""}

                            </div>
                            <div className="form-group">
                                <label>Select the returned Sets</label>
                                {this.state.kitWithKitSet && this.state.kitWithKitSet.map((kit, index) => (
                                    <React.Fragment key={index}>
                                        {kit.sets.map((setd, ind) => (
                                            <React.Fragment key={ind}>
                                                {setd.added == 1 && setd.status != "close" &&
                                                    <div className="row" key={ind}>
                                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                                            <div className="form-check">
                                                                <Input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={setd.isreturn}
                                                                    onChange={(e) => this.handleReturnSet(e.target.checked, index, ind)}
                                                                />
                                                                <label className="form-check-label" htmlFor="r`eturn_set">{setd.set_name}</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="date"
                                                                    className="small"
                                                                    disabled={setd.isreturn}
                                                                    //defaultValue={moment(setd.set_return_date).format("YYYY-MM-DD")}
                                                                    value={moment(setd.set_return_date).format("YYYY-MM-DD")}
                                                                    onChange={(e) => this.handleSetReturnDate(e, index, ind)}
                                                                />
                                                                {this.state.returnSubmitFlag === 1 && setd.set_return_date === "Invalid date" && (
                                                                    <span className="text-danger">Please enter a date</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                                            <div className="form-group">
                                                                <Input
                                                                    type="text"
                                                                    className="small"
                                                                    placeholder="Comment"
                                                                    //disabled={setd.isreturn}
                                                                    defaultValue={setd.comment}
                                                                    onChange={(e) => this.handleSetReturnComment(e, index, ind)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                                {(this.state.returnSubmitFlag == 1 && this.state.returnSetDataLength == 0) ? <span className="text-danger">Please check at least one item</span> : ""}

                            </div>

                            
                            {this.state.boxArray.map((boxItem, index) => (
                                (index == 0) ?
                                    <div className="row" key={index}>
                                        <div className="col-5 mb-3">
                                        <div className="form-group position_up"
                                            style={{zIndex:this.state.boxArray.length+1}}
                                        >
                                        <label>Box Type</label>
                                            <Select
                                                defaultValue={selectedNumOfBox}
                                                onChange={(e) => this.handleReturnBoxType(index,e)}
                                                options={boxOptions}
                                                placeholder="Box Type"
                                            />
                                            {boxItem.box_type ==
                                                "" &&
                                            this.state
                                                .returnSubmitFlag ==
                                                1 ? (
                                                <span className="text-danger">
                                                    Please
                                                    select
                                                    box type
                                                </span>
                                            ) : (
                                                ""
                                            )}


                                        </div>
                                        </div>

                                        <div className="col-5 col-sm-6 mb-3">
                                            <div className="form-group">
                                                <label>Number of Boxes</label>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Number of Boxes"
                                                    min="0"
                                                    onChange={(e) => this.handleReturnBoxNumber(index, e.target.value)}
                                                    value={boxItem.number_of_box}
                                                />
                                                {boxItem.number_of_box == "" && this.state.returnSubmitFlag == 1 && boxItem?.numberOfBoxFlag ? <span className="text-danger">Please enter number of boxes</span> : ""}
                                            </div>
                                        </div>
                                        {index > 0 &&
                                            <div className="col-1 mb-3 text-center">
                                                <a className="ml-2" onClick={(e) => this.deleteAddBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="form-group row align-items-center position_up" 
                                    style={{zIndex:this.state.boxArray.length-index}}
                                    key={index}>
                                        <div className="col-5 mb-3">

                                        <Select
                                            defaultValue={selectedNumOfBox}
                                            onChange={(e) => this.handleReturnBoxType(index,e)}
                                            options={boxOptions}
                                            placeholder="Box Type"
                                        />
                                        {boxItem.box_type ==
                                            "" &&
                                        this.state
                                            .returnSubmitFlag ==
                                            1 ? (
                                            <span className="text-danger">
                                                Please
                                                select
                                                box type
                                            </span>
                                        ) : (
                                            ""
                                        )}

                                        </div>
                                        <div className="col-5 col-sm-6 mb-3">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                placeholder="Number of Boxes"
                                                min="0"
                                                onChange={(e) => this.handleReturnBoxNumber(index, e.target.value)}
                                                value={boxItem.number_of_box}
                                            />
                                            {boxItem.number_of_box == "" && this.state.returnSubmitFlag == 1 && boxItem?.numberOfBoxFlag ? <span className="text-danger">Please enter number of boxes</span> : ""}
                                        </div>
                                        {index > 0 &&
                                            <div className="col-1 mb-3 text-center">
                                                <a className="ml-2" onClick={(e) => this.deleteAddBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                                            </div>
                                        }
                                    </div>
                            ))}
                            <a href="#!" className="sml-btn" onClick={(e) => this.handleBoxAdd(e)}><i className="fa fa-plus"></i> Add More</a>

                            {this.state.showDateInput && 
                                <div className="form-group mt-4" style={{ "display": "none" }}>
                                    <label>Please specify the new return date in case of no return or partial return</label>
                                    <Input
                                        type="date"
                                        value={
                                            this.state.returnData.return_date
                                        }
                                        onChange={(e) => this.handleReturnDate(e.target.value)}
                                        className="small"
                                        readOnly
                                    />
                                    {(this.state.returnSubmitFlag == 1 && this.state.returnData.return_date == "") ? <span className="text-danger">Please pick date for next return</span> : ""}

                                    {this.state.returnSubmitFlag == 1 && error ? <span className="text-danger">{error.return_date ? error.return_date : ""}</span> : ""}
                                    <label>Next Return Date</label>

                                    <Input
                                        type="date"
                                        value={
                                            this.state.returnData.next_return_date
                                        }
                                        onChange={(e) => this.handlenextReturnDate(e.target.value)}
                                        className="small"
                                        readOnly
                                    />
                                    {this.state.returnSubmitFlag == 1 && error ? <span className="text-danger">{error.next_return_date ? error.next_return_date : ""}</span> : ""}


                                </div>
                            }

                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary mr-2" onClick={this.returnModalClose}>Cancel</button>
                            {this.state.kitWithKitSet && this.state.kitWithKitSet.length > 0 && loading == false &&
                                <button className="btn btn-primary" onClick={this.submitReturnData}>Submit</button>
                            }

                            {this.state.kitWithKitSet && this.state.kitWithKitSet.length > 0 && loading == true &&
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Return Details Modal */}

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingInfo, error, kitSetList, redirect, returnRefresh, loading } = state.rootReducer.bookings;

    return {
        bookingInfo,
        kitSetList,
        error,
        redirect,
        returnRefresh,
        loading

    };
}

export default connect(mapStateToProps)(Return);


