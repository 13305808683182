import { surgeonConstants } from "../constants";

const initialState =  { loading: false, lists: null, } || {};

export function surgeons(state = initialState, action) {
    switch (action.type) {
    case surgeonConstants.GETALLSURGEONS_REQUEST:
        return {
            loading: true
        };
    case surgeonConstants.GETALLSURGEONS_SUCCESS:
        return {
            loading: false,
            lists: action.lists.data,
            activePage: action.lists.page,
            totalItemsCount: action.lists.total_count,
            limit: action.lists.page_count
        };
    case surgeonConstants.GETALLSURGEONS_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case surgeonConstants.SURGEONADD_REQUEST:
        return {
            loading: true,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.SURGEONADD_SUCCESS:
        return {
            loading: false,
            lists: action.data,
            modal:false,
            refreshList:true
        };
    case surgeonConstants.SURGEONADD_FAILURE:
        return { 
            loading: false,
            error: action.error,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.SURGEONEDIT_REQUEST:
        return {
            loading: true,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.SURGEONEDIT_SUCCESS:
        return {
            loading: false,
            lists: state.lists, 
            list:action.list.data,
            editModal:true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.SURGEONEDIT_FAILURE:
        return { 
            loading: false,
            error: action.error,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.SURGEONUPDATE_REQUEST:
        return {
            loading: true,
            lists: state.lists,
            kit: state.kit,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.SURGEONUPDATE_SUCCESS:
        return {
            loading: false,
            lists: state.lists, 
            editModal:false,
            refreshList:true
        };
    case surgeonConstants.SURGEONUPDATE_FAILURE:
        return { 
            loading: false,
            error: action.error,
            lists: state.lists,
            kit: state.kit,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case surgeonConstants.DELETE_REQUEST:
        // add 'deleting:true' property to user being deleted
        return {
            editModal:false,
            modal:false,
            loading: true,
        };
    case surgeonConstants.DELETE_SUCCESS:
        // remove deleted user from state
        return {
            deleteModal:false,
            refreshList:true,
            editModal:false,
            modal:false,
            loading: false,
        };
    case surgeonConstants.GETALLSURGEONSLIST_REQUEST:
        return {
            loading: true
        };
    case surgeonConstants.GETALLSURGEONSLIST_SUCCESS:
        return {
            loading: false,
            allLists: action.lists.data
          
        };
    case surgeonConstants.GETALLSURGEONSLIST_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case surgeonConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        return {
            ...state,
            loading: false,
            kitsList: state.kitsList.map(lst => {
                if (lst._id === action.id) {
                    // make copy of user without 'deleting:true' property
                    // const { deleting, ...userCopy } = user;
                    const { ...lstCopy } = lst;
                    // return copy of user with 'deleteError:[error]' property
                    return { ...lstCopy, deleteError: action.error };
                }

                return lst;
            })
        };
    default:
        return state;
    }
}