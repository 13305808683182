import React, { useState,useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";
import { bookingService } from "../../../../../services";

const Cancle = (props) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorFlag, setErrorFlag] = useState(false);
    const [deleteComment, setDeleteComment] = useState("");

    const deleteCommentHandler = (val) => {
        setDeleteComment(val);
    };

    const modalClose = (flag) =>{
        setDeleteComment("");
        setErrorFlag(false);
        setLoading(false);
        setError({});
        props.cancelModalClose(flag);
    };

    const handleCancle = async () => {
        setErrorFlag(true);
        setLoading(true);
        try {
             await bookingService.updateStatus({
                id: props.cancelId,
                delete_comment: deleteComment,
                status: "cancel",
                log_by_id: user.data._id,
            });
            setErrorFlag(false);
            props.getList(1,props.search,true);
            setLoading(false);
            modalClose(true);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));

    }, []);


    return (
        <Modal
            size="md"
            backdrop="static"
            isOpen={props.cancelModal}
            toggle={() => modalClose(false)}>
            <ModalHeader
                className=""
                toggle={() => modalClose(false)}>
                Cancel Booking
            </ModalHeader>
            <ModalBody>
                <div className="form-group row">
                    <div className="col">
                        <label>
                            Reason Of Cancellation{" "}
                            <strong className="text-danger">*</strong>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={deleteComment}
                            onChange={e =>
                                deleteCommentHandler(
                                    e.target.value,
                                )
                            }
                        />
                        {errorFlag == true && error ? (
                            <span className="text-danger">
                                {error.delete_comment
                                    ? error.delete_comment
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                        {error && error ? (
                            <span className="text-danger">
                                {error.common ? error.common : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        onClick={() => modalClose(false)}
                        className="btn btn-secondary mr-2"
                        data-dismiss="modal">
                        Cancel
                    </button>
                    {loading == false && (
                        <button
                            className="btn btn-primary"
                            onClick={() => handleCancle()}>
                            Ok
                        </button>
                    )}
                    {loading == true && (
                        <button
                            className="btn btn-primary loader-icon"
                            disabled>
                            <Spinner className="img-svg"></Spinner>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default Cancle;