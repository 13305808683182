export const bookingRequestConstants = {
    BOOKINGACCEPTSTATUSUPDATE_REQUEST: "BOOKING_ACCEPT_STATUSUPDATE_REQUEST",
    BOOKINGACCEPTSTATUSUPDATE_SUCCESS: "BOOKING_ACCEPT_STATUSUPDATE_SUCCESS",
    BOOKINGACCEPTSTATUSUPDATE_FAILURE: "BOOKING_ACCEPT_STATUSUPDATE_FAILURE",

    BOOKINGREJECTSTATUSUPDATE_REQUEST: "BOOKING_REJECT_STATUSUPDATE_REQUEST",
    BOOKINGREJECTSTATUSUPDATE_SUCCESS: "BOOKING_REJECT_STATUSUPDATE_SUCCESS",
    BOOKINGREJECTSTATUSUPDATE_FAILURE: "BOOKING_REJECT_STATUSUPDATE_FAILURE",

    GETALLBOOKINGSDISPATCH_REQUEST: "BOOKINGS_GETALLDISPATCH_REQUEST",
    GETALLBOOKINGSDISPATCH_SUCCESS: "BOOKINGS_GETALLDISPATCH_SUCCESS",
    GETALLBOOKINGSDISPATCH_FAILURE: "BOOKINGS_GETALLDISPATCH_FAILURE"
};