import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import dateFormat from "dateformat";

const DoubleBookingDetails = (props) => {
    const [bookingData, setBookingData] = useState([]);
    
    const getBooking = () => {
      if(props.doubleBookingInfo.length){
        setBookingData(props.doubleBookingInfo);
      }
    };

    useEffect(() => {
        if (props.doubleBookingDetailModal == true) {
            getBooking();
        }
    }, [props.doubleBookingDetailModal]);
  
    return (
        <>
            <div>
                <Modal size={"bg"} backdrop="static" isOpen={props.doubleBookingDetailModal}>
                    {bookingData[0]?._id &&
                        <React.Fragment>
                            <div className="row">
                                <div className={"col-md-12"}>
                                    <ModalHeader className="">
                                    {"Might be a double booking with  Booking ID "+ bookingData[0]?.booking_id}
                                    </ModalHeader>
                                    <ModalBody>
                                        <h4 className="lined-h">Hospital Details </h4>
                                        <h5 className="text-danger">{bookingData[0]?.hospitalData?.[0].name} </h5>
                                        <div className="form-group">
                                            <div className="info">
                                                <div className="info-left">
                                                <small>Surgeon:</small>{" "}
                                                 <strong className="text-danger">{bookingData[0]?.bookingSurgeonId[0]?.surgeonId[0].first_name +" " +bookingData[0]?.bookingSurgeonId[0]?.surgeonId[0]?.last_name}</strong>
                                                    <div><small>Location: {bookingData[0]?.hospital_address}</small></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <span>Operation Name: <strong className="text-danger">{bookingData[0]?.operation_name}</strong> </span>
                                        </div>
                                        <div className="form-group">
                                            <span>PO Number: <strong>{bookingData[0]?.po_number}</strong> </span>
                                        </div>

                                        <h4 className="lined-h mt-5">Important Dates</h4>
                                        
                                        {bookingData[0]?.booking_type == "loan" &&
                                            <div className="form-group">
                                                <label>Operation date</label>
                                                <div><strong className="text-danger">{dateFormat(bookingData[0]?.operation_date, "ddd,mmm dS")} {bookingData?.operation_date_slot}</strong></div>
                                            </div>
                                        }

                                        {bookingData[0]?.booking_type != "loan" &&
                                            <div className="form-group">
                                                <label>Delivery date</label>
                                                <div><strong>{dateFormat(bookingData[0]?.delivery_date, "ddd,mmm dS")} </strong></div>
                                            </div>
                                        }
                                        {bookingData[0]?.booking_type == "loan" &&
                                            <div className="form-group">
                                                <label>Delivery date</label>
                                                <div><strong>{dateFormat(bookingData[0]?.delivery_date, "ddd,mmm dS")} {bookingData?.delivery_date_slot}</strong></div>
                                            </div>
                                        }

                                        <div className="form-group">
                                            <label>Collection date</label>
                                            <div><strong>{dateFormat(bookingData[0]?.collection_date, "ddd,mmm dS")} AM</strong></div>
                                        </div>

                                        <h4 className="mt-5 lined-h">Purchase/Hire Details</h4>
                                        <table className="table">
                                            <tbody>
                                                {bookingData[0]?.bookingKitSetId?.length > 0 && bookingData[0].bookingKitSetId.map((bkit, ind) =>
                                                    <React.Fragment key={ind}>
                                                        {ind == 0 &&
                                                            <tr>
                                                                <th colSpan="2">{bkit?.kitId[0]?.name}</th>
                                                            </tr>
                                                        }
                                                        {ind > 0 && bookingData[0]?.bookingKitSetId[ind - 1]?.kitId[0]?._id != bookingData[0]?.bookingKitSetId[ind]?.kitId[0]?._id &&
                                                            <tr>
                                                                <th colSpan="2">{bkit?.kitId[0]?.name}</th>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td>
                                                                <div className="mb-2">
                                                                    <a href="javascript:;" className="text-danger" data-toggle="modal" data-target="#kitDetails">{bkit?.setId[0]?.set_name}</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>{bkit?.hire_charge}</div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                </div>
                            </div>
                            <ModalFooter>
                                <div className="">
                                    <button className="btn btn-primary" onClick={()=>{props.saveChangeHandle(false);}}>Proceed</button>
                                </div>

                                <div className="">
                                    <button className="btn btn-secondary" onClick={props.doubleBookingDetailModalClose}>Close</button>
                                </div>
                            </ModalFooter>
                        </React.Fragment>
                    }
                </Modal>
            </div>
        </>
    );
};

export default DoubleBookingDetails;

