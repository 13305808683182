import { operationConstants } from "../constants";

const initialState =  { loading: null, opLists: null, } || {};

export function operations(state = initialState, action) {
    switch (action.type) {
    case operationConstants.GETALLOPERATIONSLIST_REQUEST:
        return {
            loading: true
        };
    case operationConstants.GETALLOPERATIONSLIST_SUCCESS:
        return {
            loading: false,
            opLists: action.lists.data
        };
    case operationConstants.GETALLOPERATIONSLIST_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    default:
        return state;
    }
}