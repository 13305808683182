import React, { Component } from "react";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { authActions } from "../../../actions";
import HolidayList from "./Booking/holidayList";
import { Button } from "reactstrap";
import { NavDropdown } from "react-bootstrap";
import { Redirect } from "react-router-dom";

class Navbar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuVisible: false,
			holidayListVisible: false,
			redirectHolidayList: false,
		};

		this.handleLogout = this.handleLogout.bind(this);
		this.handleHolidayList = this.handleHolidayList.bind(this);
		this.handleMobileMenu = this.handleMobileMenu.bind(this);
	}

	getUserInitials(Name) {
		if (Name != "") {
			const initials = Name.charAt(0);
			return initials.toUpperCase();
		} else {
			return "";
		}
	}

	handleMobileMenu() {
		this.setState({
			menuVisible: !this.state.menuVisible,
		});
	}

	toggleHolidayList() {
		this.setState((prevState) => ({
			holidayListVisible: !prevState.holidayListVisible,
		}));
	}

	handleLogout(e) {
		e.preventDefault();
		const { dispatch } = this.props;
		dispatch(authActions.logout());
	}

	handleHolidayList() {
		this.setState({
			redirectHolidayList: true,
		});
	}

	render() {
		console.log("this.props.activePage",this.props.activePage);
		if (this.state.redirectHolidayList) {
			return <Redirect to="/admin/holiday-list" />;
		}
		const { user } = this.props;


		return (
			<div>
				<nav className="navbar navbar-expand-lg fixed-top">
					<a className="navbar-brand" href="#">
						<img src={logo} height="50" alt="Logo" />
					</a>
					<button
						className="navbar-toggler"
						onClick={this.handleMobileMenu}
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div
						className={
							"collapse navbar-collapse " +
							(this.state.menuVisible == true ? " show " : "")
						}
						id="navbarSupportedContent"
					>
						<ul className="navbar-nav mr-auto ml-5" id="menu">
							<li
								className={
									this.props.activePage === "dashboard"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link
									className="nav-link"
									to="/admin/dashboard/booking-dispatch"
								>
									Dashboard
								</Link>
							</li>
							<li
								className={
									this.props.activePage === "availability"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link
									className="nav-link"
									to="/admin/availability"
								>
									Availability
								</Link>
							</li>
								<NavDropdown title="Bookings" id="bookingsDropdown">
								<NavDropdown.Item
									as={Link}
									active={
										this.props.activePage === "bookings"
									}
									to="/admin/bookings"
								>
									Loan
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									active={
										this.props.activePage === "consignment"
									}
									to="/admin/bookings/consignment"
								>
									Consignment
								</NavDropdown.Item>
							</NavDropdown>
							<li
								className={
									this.props.activePage === "consignment-request"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link className="nav-link" to="/admin/consignment-request">
								Pending Consignment Request 
								</Link>
							</li>
							<li
								className={
									this.props.activePage === "users"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link className="nav-link" to="/admin/users">
									Users
								</Link>
							</li>
							<li
								className={
									this.props.activePage === "reports"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link className="nav-link" to="/admin/reports">
									Reports
								</Link>
							</li>
							<NavDropdown title="More" id="moreDropdown">
								<NavDropdown.Item
									as={Link}
									active={
										this.props.activePage === "hospitals"
									}
									to="/admin/hospitals"
								>
									Hospitals
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									active={
										this.props.activePage === "surgeons"
									}
									to="/admin/surgeons"
								>
									Surgeons
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									active={this.props.activePage === "kits"}
									to="/admin/categories"
								>
									Kits
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									active={
										this.props.activePage === "operations"
									}
									to="/admin/operations"
								>
									Operations
								</NavDropdown.Item>
							</NavDropdown>
						</ul>
						{/* <button onClick={this.handleLogout}>Logout</button> */}
						<div className="my-2 my-lg-0">
							<div className="ml-auto userinfo">
								<div className="user-area dropdown">
									<a
										className="icon__mg dropdown-toggle"
										href="javascript:;"
										type="button"
										id="navbarDropdown"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<div className="avtar">
											{user &&
												this.getUserInitials(
													user.data.first_name
												) +
													this.getUserInitials(
														user.data.last_name
													)}
										</div>
										<span className="hide-mobile">
											{user &&
												user.data.first_name +
													" " +
													user.data.last_name}
										</span>
									</a>
									<div
										className="dropdown-menu"
										aria-labelledby="navbarDropdown"
									>
										<Button
											className="dropdown-item"
											onClick={this.handleHolidayList}
										>
											{" "}
											Holiday List{" "}
										</Button>
										{this.state.holidayListVisible && (
											<HolidayList />
										)}
										<a
											className="dropdown-item"
											href="javascript:;"
											onClick={this.handleLogout}
										>
											Logout
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
				<div className="offset"></div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggingIn } = state.rootReducer.authentication;
	const { user } = state.rootReducer.authentication;
	return {
		loggingIn,
		user,
	};
}

export default connect(mapStateToProps)(Navbar);
