// import React, { useState } from "react";
// import {
// 	Modal,
// 	ModalBody,
// 	ModalHeader,
// 	ModalFooter,
// 	Spinner,
// } from "reactstrap";
// import { consignmentService } from "../../../../../services/consignment.services";


// const RejectModal = (props) => {
// 	const [loading, setLoading] = useState(false);

// 	const modalClose = (flag) => {
// 		props.toggle(flag);
// 	};

// 	const handleReject = async () => {
// 		setLoading(true);
// 		const params = {
// 			id: props.bookingId,
// 			};
// 		try {
// 			const response = await consignmentService.rejectConsingnment(params);
// 			console.log("API response:", response);
// 			modalClose(true);
// 			props.onReload(); 
// 		} catch (error) {
// 			console.error("API call failed:", error);
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	return (
// 		<Modal
// 			size="md"
// 			backdrop="static"
// 			centered 
// 			isOpen={props.isOpen}
// 			toggle={() => modalClose(false)}
// 		>
// 			<ModalHeader className="" toggle={() => modalClose(false)}>
// 			 Reject Booking
// 			</ModalHeader>
// 			<ModalBody>Are you sure you want to Reject Booking?</ModalBody>
// 			<ModalFooter>
// 				<div className="">
// 					<button
// 						onClick={() => modalClose(false)}
// 						className="btn btn-secondary mr-2"
// 						data-dismiss="modal"
// 					>
// 						Cancel
// 					</button>
// 					{!loading && (
// 						<button
// 							className="btn btn-primary"
// 							onClick={handleReject}
// 						>
// 							Ok
// 						</button>
// 					)}
// 					{loading && (
// 						<button
// 							className="btn btn-primary loader-icon"
// 							disabled
// 						>
// 							<Spinner className="img-svg"></Spinner>
// 						</button>
// 					)}
// 				</div>
// 			</ModalFooter>
// 		</Modal>
// 	);
// };

// export default RejectModal;
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
  Input
} from "reactstrap";
import { consignmentService } from "../../../../../services/consignment.services";

const RejectModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(""); 
  const [error, setError] = useState([]);

  const modalClose = (flag) => {
    props.toggle(flag);
    setComment("");
    setError([]);
  };

  const handleReject = async () => {
    setLoading(true);
    const params = {
      id: props.bookingId,
      comment: comment 
    };
    try {
      const response = await consignmentService.rejectConsingnment(params);
      console.log("API response:", response);
      modalClose(true);
      props.onReload(); 
    } catch (error) {
      console.error("API call failed:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      backdrop="static"
      centered
      isOpen={props.isOpen}
      toggle={() => modalClose(false)}
    >
      <ModalHeader className="" toggle={() => modalClose(false)}>
        Reject Booking
      </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to Reject Booking?</p>
        <label>
          Comment<span className="text-danger">*</span>
        </label>
        <Input
          type="textarea"
          placeholder="Enter reason for rejection"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        {error.comment && (
            <span className="text-danger">{error.comment}</span>
          )}
      </ModalBody>
      <ModalFooter>
        <div className="">
          <button
            onClick={() => modalClose(false)}
            className="btn btn-secondary mr-2"
            data-dismiss="modal"
          >
            Cancel
          </button>
          {!loading && (
            <button
              className="btn btn-primary"
              onClick={handleReject}
            >
              Ok
            </button>
          )}
          {loading && (
            <button
              className="btn btn-primary loader-icon"
              disabled
            >
              <Spinner className="img-svg"></Spinner>
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RejectModal;
