import { combineReducers } from "redux";
import { users } from "./user.reducer";
import { authentication } from "./authentication.reducer";
import { kits } from "./kit.reducer";
import { products } from "./product.reducer";
import { sets } from "./set.reducer";
import { hospitals } from "./hospital.reducer";
import { bookings } from "./booking.reducer";
import { availabilitys } from "./availability.reducer";
import { bookingRequests } from "./bookingRequest.reducer";
import { returnBookings } from "./returnBooking.reducer";
import { collectionBookings } from "./collectionBooking.reducer";
import { repsBookings } from "./repsBooking.reducer";
import { surgeons } from "./surgeon.reducer";
import { operations } from "./operation.reducer";

const rootReducer = combineReducers({
    authentication,
    users,
    kits,
    products,
    sets,
    hospitals,
    bookings,
    availabilitys,
    bookingRequests,
    repsBookings,
    returnBookings,
    collectionBookings,
    surgeons,
    operations
});

export default rootReducer;