import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import FilterTooltip from "./Filter";
import { ReactComponent as FilterIcon } from "../../../../../../assets/images/filter.svg";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import Footer from "../../../../../GlobalComponents/Footer";
import Navbar from "../../../Navbar";
import dateFormat from "dateformat";
import {
	bookingService,
	kitService,
	surgeonService,
	operationService,
} from "../../../../../../services";
import RepsUpdateModal from "../../Modal/RepsUpdateModal";
import { debounce } from "lodash";
import InfinitScroll from "react-infinite-scroll-component";

const Close = () => {
	const [surgeonId, setSurgeonId, surgeonIdRef] = useState("");
	const [filterCreateDate, setFilterCreateDate, filterCreateDateRef] =
		useState("");
		const [totalItemsCount, setTotalItemsCount] = useState(0);
	const [hospitalId, setHospitalId, hospitalIdRef] = useState("");
	const [filterPoChase, setFilterPoChase, filterPoChaseRef] = useState("all");
	const [search, setSearch] = useState("");
	const [activePage, setActivePage] = useState(1);
	const [limit, setLimit] = useState(6);
	const [bookingList, setBokingList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [repsUpdateModal, setRepsUpdateModal] = useState(false);
	const [bookingRepsUpdateId, setBookingRepsUpdateId] = useState(0);
	const [reps, setReps] = useState([]);
	const [additionalReps, setAdditionalReps] = useState([]);
	const [bookingInfoById, setBookingInfoById] = useState({});
	const [filterModal, setFilterModal] = useState(false);
	const [kits, setKits] = useState([]);
	const [operationsList, setOperationsList] = useState([]);
	const [selectdKitValue, setSelectdKitValue, selectdKitValueRef] =
		useState("");
	const [selectedHospital, setSelectedHospital] = useState([
		{ label: "All Hospital", value: "" },
	]);
	const [operationId, setOperationId, operationIdRef] = useState("");
	const [selectdSetValue, setSelectdSetValue, selectdSetValueRef] =
		useState("");
	const [selectedKit, setSelectedKit] = useState([]);
	const [selectedOperation, setSelectedOperation] = useState([
		{ label: "All Operation", value: "" },
	]);
	const [selectedSurgeon, setSelectedSurgeon] = useState([
		{ label: "All Surgeon", value: "" },
	]);
	const [surgeons, setSurgeons] = useState([]);
	const [hospitals, setHospitalList] = useState([]);

	const showHospitalsList = (hospitalsList) => {
		let tempHospitals = [{ label: "All Hospital", value: "" }];
		hospitalsList.map((item) => {
			let option = { value: item._id, label: item.name };
			tempHospitals.push(option);
		});
		setHospitalList(tempHospitals);
	};

	const getHospitalList = async () => {
		try {
			const res = await bookingService.getHospitalsList({ search: "" });
			if (res.status === 200) {
				showHospitalsList(res?.data);
			}
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const handleFilterRefreesh = (data) => {
		if (data !== undefined) {
			if (
				filterPoChaseRef?.current === data?.filterPoChase &&
				filterCreateDateRef?.current === data?.filterCreateDate &&
				hospitalIdRef?.current === data?.hospitalId &&
				selectdKitValueRef?.current === data?.selectdKitValue &&
				surgeonIdRef?.current === data?.surgeonId &&
				operationIdRef?.current === data?.operationId &&
				selectdSetValueRef?.current === data?.selectdSetValue
			) {
				getList(1);
			}

			setActivePage(1);
			setFilterModal(!filterModal);
			setFilterPoChase(data?.filterPoChase);
			setFilterCreateDate(data?.filterCreateDate);
			setHospitalId(data?.hospitalId);
			setSelectedHospital(data?.selectedHospitalProp);
			setSelectdKitValue(data?.selectdKitValue);
			setSelectedKit(data?.selectedKitProp);
			setSurgeonId(data?.surgeonId);
			setSelectedSurgeon(data?.selectedSurgeonProp);
			setOperationId(data?.operationId);
			setSelectedOperation(data?.selectedOperationProp);
			setSelectdSetValue(data?.selectdSetValue);
		}
	};

	const getSurgeonListDropdown = async () => {
		try {
			const res = await surgeonService.getSurgeonsList({ search: "" });

			let surgeonList = res?.data ? res?.data : [];
			let tempSurgeonList = [{ label: "All Surgeon", value: "" }];
			surgeonList &&
				surgeonList.map((item) => {
					let option = {
						value: item._id,
						label: item.first_name + " " + item.last_name,
						notes: item?.notes ? item?.notes : "",
					};
					tempSurgeonList.push(option);
				});
			setSurgeons(tempSurgeonList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getKitListDropdown = async () => {
		try {
			const res = await kitService.kitListDropdown({ search: "" });

			let kitList = res?.data ? res?.data : [];
			let tempKitList = [];

			kitList?.length &&
				kitList.map((item) => {
					var option = { value: item._id, label: item.name };
					tempKitList.push(option);
				});

			setKits(tempKitList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getOperationListDropdown = async () => {
		try {
			const res = await operationService.getOperationsList({
				search: "",
			});

			let operationList = res?.data ? res?.data : [];
			let tempOperationList = [{ label: "All Operation", value: "" }];
			operationList &&
				operationList.map((item) => {
					let option = {
						value: item.name,
						label: item.name,
						id: item._id,
					};
					tempOperationList.push(option);
				});
			setOperationsList(tempOperationList);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const handleFilterToggle = () => {
		setFilterModal(!filterModal);
	};

	const getList = async (flag, searchTerm,currentPage) => {
		if (currentPage === true) {
			setActivePage(activePage);
		} else {
			setActivePage(flag ? 1 : activePage + 1);
		}
		setLoading(true);
		try {
			const res = await bookingService.getList({
				working_delivery_from_date: "",
				type: ["consignment"],
				status: ["close"],
				operation_from_date: "",
				operation_to_date: "",
				operation_date: "",
				searchHospital: "",
				searchKit: "",
				search: searchTerm != undefined ? searchTerm : search,
				page: flag ? 1 : activePage + 1,
				limit: limit,
				po_check: filterPoChaseRef.current,
				created_date: filterCreateDateRef.current,
				hospitalFilter: hospitalIdRef.current,
				kitFilter: selectdKitValueRef.current,
				surgeonFilter: surgeonIdRef.current,
				operationFilter: operationIdRef.current,
				setFilter: selectdSetValueRef.current,
			});
			let bookingRes = flag ? res.data : bookingList.concat(res.data);
			setBokingList(bookingRes);
			setLoading(false);
			if (res.status) {
				setTotalItemsCount(res?.total_count);
			}
		} catch (error) {
			console.error("Error: ", error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const showRepsList = (repsList) => {
		var tempRepsList = [];
		repsList.map((item) => {
			var option = {
				value: item._id,
				label: item.first_name + " " + item.last_name,
			};
			tempRepsList.push(option);
		});

		setReps(tempRepsList);
		setAdditionalReps(tempRepsList);
	};

	const getAllRepsList = async () => {
		try {
			const res = await bookingService.getRepsList({ search: "" });
			showRepsList(res.data);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const getByIdInfo = async (id) => {
		try {
			const res = await bookingService.getById(id);
			setBookingInfoById(res.data);
		} catch (error) {
			console.error("Error: ", error);
		}
	};

	const handleSearch = (val) => {
		setBokingList([]);
		setSearch(val.target.value);
		setActivePage(1);
		getList(1, val.target.value);
	};

	const debouncedSearch = useMemo(() => {
		setBokingList([]);
		return debounce(handleSearch, 1500);
	}, [search]);

	const repsUpdateModalOpen = (item) => {
		if (item?._id) {
			getByIdInfo(item?._id);
			setBookingRepsUpdateId(item._id);
			setRepsUpdateModal(true);
		}
	};

	const repsUpdateModalClose = () => {
		setBookingInfoById({});
		setRepsUpdateModal(false);
	};

	useEffect(() => {
		getHospitalList();
		getKitListDropdown();
		getSurgeonListDropdown();
		getOperationListDropdown();
		getAllRepsList();
	}, []);

	useEffect(() => {
		console.log(
			setLimit,
			filterPoChase, 
			filterCreateDate,  
			hospitalId,
			selectdKitValue,
			surgeonId,
			operationId,
			selectdSetValue
		  );
		getList(1);
	}, [
		filterCreateDateRef?.current,
		filterPoChaseRef?.current,
		hospitalIdRef?.current,
		selectdKitValueRef?.current,
		surgeonIdRef?.current,
		operationIdRef?.current,
		selectdSetValueRef?.current,
	]);

	return (
		<div>
			<Navbar activePage="bookings" />
			<div className="container-fluid">
				<div className="row pt-4 align-items-center">
					<div className="col-md-5">
						<ul className="sub-menu">
							<li>
								<Link to="/admin/bookings/consignment">Open</Link>
							</li>
							<li>
								<Link
									to="/admin/bookings/consignment/close"
									className="active"
								>
									Closed
								</Link>
							</li>
						</ul>
					</div>
					<div className="col-md-3 col-sm-12">
						<div className="form-search">
							<Input
								className="form-control mr-sm-2"
								type="text"
								placeholder="Search.."
								onChange={debouncedSearch}
							/>
						</div>
					</div>
					<div className="col-md-4 text-right">
						<div className="custom_fltr">
							<a onClick={() => handleFilterToggle()}>
								<FilterIcon className="img-svg"></FilterIcon>
							</a>
							<FilterTooltip
								handleFilterToggle={() => handleFilterToggle()}
								filterModal={filterModal}
								filterPoChase={filterPoChaseRef?.current}
								filterCreateDate={filterCreateDateRef?.current}
								handleFilterRefreesh={(data) =>
									handleFilterRefreesh(data)
								}
								hospitals={hospitals}
								selectedHospitalProp={selectedHospital}
								kits={kits}
								selectedKitProp={selectedKit}
								surgeons={surgeons}
								selectedSurgeonProp={selectedSurgeon}
								operationsList={operationsList}
								selectedOperationProp={selectedOperation}
							/>
						</div>
						<Link
							className="btn btn-primary add_option"
							to="/admin/booking/add"
						>
							<i className="fa fa-plus"></i> <span>Add New</span>
						</Link>
					</div>
				</div>

					<InfinitScroll
					dataLength={bookingList.length}
					next={() => {
						getList(0);
					}}
					hasMore={true}
					scrollThreshold={"100%"}
				>
					<div
					className="small mr-3 badge badge-info"
					style={{ fontWeight: 500, background: "#5d75e9",marginBottom:0 }}
				>
					{totalItemsCount} Records found
				</div>
					<div id="table-scroll" className="table-scroll mt-2">
						<table id="main-table" className="main-table">
							<thead>
								<tr>
									<th scope="col" width="200">
										Hospital Name
									</th>
									<th scope="col">Contact Person</th>
									<th
										scope="col"
										style={{ minWidth: "100px" }}
									>
										Sets
									</th>
									<th scope="col" width="200">
										Important Dates
									</th>
									<th
										scope="col"
										className="text-right"
										width="175"
									>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{bookingList &&
									bookingList.map((item) => (
										<tr key={item._id}>
											<td
												width="200"
												data-th="Hospital Name"
											>
												<div className="">
													<Link
														to={
															"/admin/booking/details/" +
															item._id
														}
													>
														<span>
															<strong>
																<span
																	style={{
																		display:
																			"block",
																	}}
																	className="theme-text"
																>
																	{
																		item.booking_id
																	}
																</span>
																{
																	item
																		.hospitalData[0]
																		.name
																}
															</strong>
														</span>
														<br />
														<small>
															Booked on{" "}
															{dateFormat(
																item.createdAt,
																"ddd,mmm dS"
															)}
														</small>
														{item.po_number !==
															"" && (
															<React.Fragment>
																<br />
																<small className="mobilehide">
																	PO Number:{" "}
																	{
																		item.po_number
																	}
																</small>
															</React.Fragment>
														)}
													</Link>
												</div>
											</td>
											<td data-th="Contact Person">
												<div className="info-left">
													<strong>
														{
															item.hospitalData[0]
																.contact_person_name
														}{" "}
													</strong>
													<br />
													<small>
														Location:{" "}
														{item.hospital_address}{" "}
													</small>
													<br />
													<small>
														Email:{" "}
														{
															item.hospitalData[0]
																.email
														}
													</small>
													<br />
													<small>
														Phone:{" "}
														{
															item.hospitalData[0]
																.contact_person_phone_number
														}
													</small>
													<br />
													<small>
														Department:
														{
															item.hospital_department
														}
													</small>
												</div>
											</td>
											<td data-th="Sets">
												<small>
													{item.bookingKitSetId &&
														item.bookingKitSetId.map(
															(kset, ind) => (
																<React.Fragment
																	key={ind}
																>
																	{/* <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
																	<div>
																		<span>
																			{
																				kset
																					.setId[0]
																					.set_name
																			}{" "}
																		</span>
																	</div>
																</React.Fragment>
															)
														)}
												</small>
											</td>
											<td data-th="Important Dates">
												<small>
													Delivery Date:{" "}
													<strong>
														{dateFormat(
															item.delivery_date,
															"ddd,mmm dS"
														)}{" "}
														{
															item.delivery_date_slot
														}
													</strong>
													<br />
													Operation Date:{" "}
													<strong>
														{dateFormat(
															item.operation_date,
															"ddd,mmm dS"
														)}{" "}
														{
															item.operation_date_slot
														}
													</strong>
													<br />
													Collection Date:{" "}
													<strong>
														{dateFormat(
															item.collection_date,
															"ddd,mmm dS"
														)}
													</strong>
												</small>
											</td>
											<td className="text-right">
												<div className="action-area dropdown">
													<span className="capsule green-dim">
														Closed
													</span>
													<a
														className="dropdown-toggle"
														href="#"
														id="navbarDropdown"
														role="button"
														data-bs-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														<span className="dots">
															...
														</span>
													</a>
													<div
														className="dropdown-menu"
														aria-labelledby="navbarDropdown"
													>
														<Link
															className="dropdown-item"
															to={
																"/admin/booking/item-used/c/" +
																item._id
															}
														>
															Item Used
														</Link>

														<Link
															className="dropdown-item"
															to={
																"/admin/booking/details/" +
																item._id
															}
														>
															More Details
														</Link>

														<a
															href="javascript:;"
															onClick={() =>
																repsUpdateModalOpen(
																	item
																)
															}
															className="dropdown-item"
														>
															Assign Reps
														</a>
													</div>
												</div>
											</td>
										</tr>
									))}
								{bookingList &&
									bookingList.length == 0 &&
									loading == false && (
										<tr className="text-center">
											<td colSpan="5">No Record Found</td>
										</tr>
									)}

								{loading == true ||
								(bookingList &&
									bookingList.length == 0 &&
									loading == true)
									? [1, 2, 3, 4, 5].map((item) => (
											<tr key={item}>
												<td>
													<p className="placeholder-glow">
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
													</p>
												</td>
												<td>
													<p className="placeholder-glow">
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
													</p>
												</td>
												<td>
													<p className="placeholder-glow">
														<div className="skeleton skeleton-text"></div>

														<div className="skeleton skeleton-text"></div>
													</p>
												</td>
												<td>
													<p className="placeholder-glow">
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
														<div className="skeleton skeleton-text"></div>
													</p>
												</td>
												<td>
													<p className="placeholder-glow">
														<div className="skeleton skeleton-text"></div>
													</p>
												</td>
											</tr>
									  ))
									: ""}
							</tbody>
						</table>
					</div>
				</InfinitScroll>
			</div>

			<RepsUpdateModal
				bookingId={bookingRepsUpdateId}
				isRepsUpdateModal={repsUpdateModal}
				repsUpdateModalClose={(flag) => repsUpdateModalClose(flag)}
				reps={reps}
				additionalReps={additionalReps}
				bookingInfoById={bookingInfoById}
				getList={getList}
			/>

			<Footer />
		</div>
	);
};

export default Close;
