export const BasicDetails = ({ bookingInfo }) => (
  <div className="d-card">
    <h4 className="mb-3">Hospital Details</h4>
    <h4 className="mb-3">{bookingInfo?.consignment_id}</h4>
    <h5 className="mb-3">{bookingInfo?.hospitalData?.[0]?.name || ""}</h5>
    <div className="form-group">
      <div className="info">
        <div className="info-left">
          {bookingInfo.hospitalData && bookingInfo.hospitalData.length > 0 && (
            <div>
              <strong>{bookingInfo.hospitalData[0].contact_person_name}</strong>
              <div>
                <small>Location: {bookingInfo.hospital_address}</small>
              </div>
              <div>
                <small>Email: {bookingInfo.hospitalData[0].email || ""}</small>
              </div>
              <div>
                <small>
                  Phone:{" "}
                  {bookingInfo.hospitalData[0].contact_person_phone_number}
                </small>
              </div>
              {bookingInfo.pickup_location_text && (
                <div className="mt-3">
                  <strong>Pickup Location:</strong>{" "}
                  {bookingInfo.pickup_location_text}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="form-group">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <span style={{ whiteSpace: "nowrap" }}>Surgeon Name: </span>
        <span
          style={{
            marginLeft: "0.5rem",
            textIndent: "0",
            whiteSpace: "normal",
            display: "inline-block",
          }}
        >
          <strong>
            {bookingInfo.surgeonData &&
              bookingInfo.surgeonData
                .map(
                  (surgeon) =>
                    `${surgeon.title} ${surgeon.first_name} ${surgeon.last_name}`
                )
                .join(", ")}
          </strong>
        </span>
      </div>
    </div>
  </div>
);
