import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { setActions } from "../../../../actions";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitList: [],
            productList: [],
            selectedKit: "",
            selectedProductId: "",
            selectedProductList: [],
            quantity: "",
            error: false,
            //product_error: "",
            kit_error: "",
            searchProducts: "",
            setData: {
                set_name: "",
                description: "",
                kitID: "",
                quarantine_check:false,
                missing_stock_check:false,
                productData: {
                    products: [],
                },
            },
        };

        this.handleOnKitSearch = this.handleOnKitSearch.bind(this);
        this.handleOnKitSelect = this.handleOnKitSelect.bind(this);

        this.handleKitList = this.handleKitList.bind(this);

        this.addSet = this.addSet.bind(this);

    }



    handleSetData = (name, value) => {
        const { setData } = this.state;
        setData[name] = value;
        this.setState({
            setData,
        });
    };

    handleOnKitSearch(searchString, result) {
        console.log(result);
        const { setData } = this.state;
        setData["kitID"] = "";
        setData["selectedKit"] = "";
        this.setState({
            setData,
        });

        this.props.dispatch(setActions.getKitsList({ search: searchString }));
    }

    handleOnKitSelect(item) {
        const { setData } = this.state;
        setData["kitID"] = item._id;
        setData["selectedKit"] = item;
        this.setState({
            setData,
        });
    }

    handleKitList(kitList) {
        this.setState({
            kitList: kitList,
        });
    }

    addSet() {

        // if(this.state.setData.name== "" || this.state.setData.kitID== "" || this.state.setData.description== "") {
        var selectedProductList = this.state.selectedProductList;
        var tempProducts = [];

        selectedProductList.map((product) => {
            var tempObj = {};
            tempObj.id = product._id;
            tempObj.quantity = product.quantity;
            tempProducts.push(tempObj);
        });

        this.setState(
            {
                setData: {
                    ...this.state.setData,
                    productData: {
                        products: tempProducts,
                    },
                },
            },
            () => {
                this.props.dispatch(setActions.addSet(this.state.setData));
            }
        );
        // }
    }

    componentDidMount() {
        this.handleOnKitSearch("", "");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        if (nextProps.kitsList) {
            this.handleKitList(nextProps.kitsList);
        }
        if (nextProps.productsList) {
            this.handleProductList(nextProps.productsList);
        }

        if (nextProps.error !== "") {
            this.setState({
                error: true,
            });
        }
    }

    render() {
        const { redirect, error } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        if (redirect) {
            return <Redirect to="/admin/kits" />;
        }

        return (
            <div>
                <Navbar activePage="kits" />
                <section className="b-burger">
                    <div className="container">
                        <h1 className="date-scroll pt-4">
                            {" "}
                            <Link to="/admin/kits">
                                <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                                <strong>Back</strong>
                            </Link>
                        </h1>

                        <div className="row pt-4">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="d-card">
                                    <h4>Basic Details</h4>
                                    <div className="form-group" style={{zIndex:1,position:"relative"}}>
                                        <label>Select Kit</label>
                                        <ReactSearchAutocomplete
                                            //className="form-control chosen-select"
                                            items={this.state.kitList}
                                            onSearch={this.handleOnKitSearch}
                                            onSelect={this.handleOnKitSelect}
                                            fuseOptions={{ minMatchCharLength: 1 }}
                                            inputDebounce="100"
                                            autoFocus
                                            placeholder="Search..."
                                            // styling={{searchIconMargin:"0 0 0 90%"}}
                                        />
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.kitID ? error.kitID : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Set Name (Set Unique Identifier)</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.setData.set_name}
                                            onChange={(e) =>
                                                this.handleSetData("set_name", e.target.value)
                                            }
                                        />
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.set_name ? error.set_name : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Set Description</label>
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            value={this.state.setData.description}
                                            onChange={(e) =>
                                                this.handleSetData("description", e.target.value)
                                            }
                                        ></textarea>
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.description ? error.description : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="mt-5 text-right">
                                    <Link
                                        to="/admin/kits"
                                        className="btn btn-secondary mb-3 mr-2"
                                    >
                    Cancel
                                    </Link>
                                    <button
                                        className="btn btn-primary mb-3"
                                        onClick={this.addSet}
                                    >
                    Save Changes
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { kitsList, productsList, redirect, error } = state.rootReducer.sets;

    return {
        kitsList,
        productsList,
        redirect,
        error,
    };
}

export default connect(mapStateToProps)(Index);
