import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";
import { bookingService } from "../../../../../services";

const ChangeDate = (props) => {
    
    const [dateSubmit, setDateSubmit] = useState(0);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const modalClose = (flag) =>{
        setDateSubmit(0);
        setError({});
        props.dateModalClose(flag);
    };

    const handleUpdateDate = async () => {
        setDateSubmit(1);
        setLoading(true);
        try {
            await bookingService.updateBookingDates(props.bokkingDate);
            setDateSubmit(0);
            props.getList(1,props.search,true);
            setError({});
            setLoading(false);
            modalClose(true);
        } catch (error) {
            console.error("Error: ", error);
            setError(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleCollectionDate = (val) => {
        const result = { ...props.bokkingDate };
        result.collection_date = val;
        props.setBokkingDate(result);
    };

    const handleOprationDate = (val) => {
        const result = { ...props.bokkingDate };
        result.operation_date = val;
        props.setBokkingDate(result);
    };

    const handleDeliveryDate = (val) => {
        const result = { ...props.bokkingDate };
        result.delivery_date = val;
        props.setBokkingDate(result);
    };

    return (
        <Modal
            size="ml"
            backdrop="static"
            isOpen={props.dateModal}
            toggle={() => modalClose(false)}>
            <ModalHeader
                className=""
                toggle={() => modalClose(false)}>
                Purchase/Hire Details
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="form-group">
                        <label>
                            Delivery Date{" "}
                            <strong className="text-danger">*</strong>
                        </label>
                        <Input
                            type="date"
                            id="datepicker"
                            value={props.bokkingDate.delivery_date}
                            // readonly={deliveryDateIsDisabled}
                            disabled={props.deliveryDateIsDisabled}
                            className="small"
                            onChange={e => handleDeliveryDate(e.target.value)}
                        />
                        {error && dateSubmit == 1 ? (
                            <span className="text-danger">
                                {error.delivery_date
                                    ? error.delivery_date
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="form-group">
                        <label>
                            Operation Date{" "}
                            <strong className="text-danger">*</strong>
                        </label>
                        <Input
                            type="date"
                            id="datepicker"
                            className="small "
                            value={props.bokkingDate.operation_date}
                            onChange={e =>
                                handleOprationDate(e.target.value)
                            }
                        />
                        {error && dateSubmit == 1 ? (
                            <span className="text-danger">
                                {error.operation_date
                                    ? error.operation_date
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="form-group">
                        <label>
                            Collection Date{" "}
                            <strong className="text-danger">*</strong>
                        </label>
                        <Input
                            type="date"
                            id="datepicker"
                            className="small "
                            value={props.bokkingDate.collection_date}
                            onChange={e =>
                                handleCollectionDate(
                                    e.target.value,
                                )
                            }
                        />
                        {error && dateSubmit == 1 ? (
                            <span className="text-danger">
                                {error.collection_date
                                    ? error.collection_date
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        onClick={() => modalClose(false)}
                        className="btn btn-secondary mr-2"
                        data-dismiss="modal">
                        Cancel
                    </button>
                    {loading == false && (
                        <button
                            className="btn btn-primary"
                            onClick={() => handleUpdateDate()}>
                            Update
                        </button>
                    )}
                    {loading == true && (
                        <button
                            className="btn btn-primary loader-icon"
                            disabled>
                            <Spinner className="img-svg"></Spinner>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ChangeDate;