import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { hospitalActions } from "../../../../../actions/hospital.actions";
import { hospitalService } from "../../../../../services/hospital.services";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";

class Add extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      name: "",
      id: "",
      hospitalInfo: {
        name: "",
        switch_board_phone_number: "",
        contact_person_name: "",
        contact_person_phone_number: "",
        sac_code: "",
        email: "",
        addressData: { address: [{ name: "" }] },
        departmentData: { department: [{ name: "" }] },
        notes: "",
      },
      submitModalFalg: 0,
      suggestionHospitalList: [],
      suggestionsActive: false,
    };

    this.addNewModalClose = this.addNewModalClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnHospitalSelect = this.handleOnHospitalSelect.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  handleNotesChange = (e) => {
    console.log("ddd",e.target.value);
    const { value } = e.target.value;
    this.setState((prevState) => ({
        hospitalInfo: {
        ...prevState.hospitalInfo,
        notes: value,
      },
    }));
  };

  addNewModalClose() {
    this.setState({
      addNewModal: false,
      submitModalFalg: 0,
      suggestionHospitalList: [],
      suggestionsActive: false,
      hospitalInfo: {
        name: "",
        switch_board_phone_number: "",
        contact_person_name: "",
        contact_person_phone_number: "",
        sac_code: "",
        email: "",
        addressData: { address: [{ name: "", validate: false }] },
        departmentData: { department: [{ name: "", validate: false }] },
        notes: "",
      },
    });
    this.props.addNewModalClose();
  }

  nameHandler(val) {
    this.setState({
      name: val,
    });
  }

  hospitalInfoHandler = (name, value) => {
    const { hospitalInfo } = this.state;
    hospitalInfo[name] = value;
    this.setState(
      {
        hospitalInfo,
      },
      () => {
        if (name == "name") {
          this.getAutoSearch();
        }
      }
    );
  };

  addMoreAddress = () => {
    const { hospitalInfo } = this.state;
    var addmore = hospitalInfo["addressData"].address;
    addmore.push({ name: "" });
    hospitalInfo["addressData"].address = addmore;
    this.setState({
      hospitalInfo,
    });
  };

  addAddressHandler = (value, ind) => {
    const { hospitalInfo } = this.state;
    var addmore = hospitalInfo["addressData"].address;
    addmore[ind].name = value;
    hospitalInfo["addressData"].address = addmore;
    this.setState({
      hospitalInfo,
    });
  };

  addMoreDepartment = () => {
    const { hospitalInfo } = this.state;
    var addmore = hospitalInfo["departmentData"].department;
    addmore.push({ name: "" });
    hospitalInfo["departmentData"].department = addmore;
    this.setState({
      hospitalInfo,
    });
  };

  addDepartmentHandler = (value, ind) => {
    const { hospitalInfo } = this.state;
    var addmore = hospitalInfo["departmentData"].department;
    addmore[ind].name = value;
    hospitalInfo["departmentData"].department = addmore;
    this.setState({
      hospitalInfo,
    });
  };

  handleSubmit() {
    const { hospitalInfo } = this.state;
    var address = [];
    var flag = 0;
    hospitalInfo?.addressData?.address.map((sub) => {
      if (sub.name == "") {
        flag = 1;
        address.push({ name: sub.name, validate: true });
      } else {
        address.push({ name: sub.name, validate: false });
      }
    });

    var department = [];
    hospitalInfo?.departmentData?.department.map((sub) => {
      if (sub.name == "") {
        flag = 1;
        department.push({ name: sub.name, validate: true });
      } else {
        department.push({ name: sub.name, validate: false });
      }
    });

    hospitalInfo["addressData"].address = address;
    hospitalInfo["departmentData"].department = department;
    this.setState({
      hospitalInfo,
      submitModalFalg: 1,
    });

    if (
      flag == 0 ||
      hospitalInfo.name == "" ||
      hospitalInfo.contact_person_name == "" ||
      hospitalInfo.contact_person_phone_number == ""
    ) {
      this.props.dispatch(hospitalActions.add(this.state.hospitalInfo));
    }
  }

  deleteAddDepartment(ind) {
    const { hospitalInfo } = this.state;
    var deleteh = hospitalInfo["departmentData"].department;
    deleteh.splice(ind, 1);
    hospitalInfo["departmentData"].department = deleteh;
    this.setState({
      hospitalInfo,
    });
  }

  deleteAddAddress(ind) {
    const { hospitalInfo } = this.state;
    var deleteh = hospitalInfo["addressData"].address;
    deleteh.splice(ind, 1);
    hospitalInfo["addressData"].address = deleteh;
    this.setState({
      hospitalInfo,
    });
  }

  getAutoSearch() {
    const { hospitalInfo } = this.state;

    hospitalService
      .getSuggestionList({ search: hospitalInfo["name"], page: 1 })
      .then((response) => {
        this.handleSuggestionHospitalList(response.data);
      });
  }

  handleSuggestionHospitalList(data) {
    this.setState({
      suggestionHospitalList: data,
      suggestionsActive: true,
    });
  }

  handleOnHospitalSelect(item) {
    const { hospitalInfo } = this.state;
    hospitalInfo["name"] = item.name;
    this.setState({
      suggestionHospitalList: [],
      suggestionsActive: false,
      hospitalInfo,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("sssss", nextProps);
    if (nextProps.modal == false && this.props.addNewModal == true) {
      console.log("dddd", nextProps);
      this.addNewModalClose();
    }
  }

  render() {
    const { error, loading } = this.props;
    const { submitModalFalg } = this.state;
    return (
      <div>
        <Modal
          size="lg"
          backdrop="static"
          isOpen={this.props.addNewModal}
          toggle={() => this.addNewModalClose()}
        >
          <ModalHeader className="" toggle={() => this.addNewModalClose()}>
            Add New Hospital 
          </ModalHeader>
          <ModalBody>
            <form className="">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Hospital Name <span className="text-danger">*</span>
                    </label>
                    <div className="autocomplete">
                      <div className="wrapper remove-rounder">
                        <div className="sc-hKMtZM">
                          <Input
                            type="text"
                            className="form-control"
                            value={this.state.hospitalInfo.name}
                            onChange={(e) =>
                              this.hospitalInfoHandler("name", e.target.value)
                            }
                          />

                          {this.state.suggestionsActive &&
                            this.state.suggestionHospitalList.length > 0 &&
                            this.state.hospitalInfo.name != "" && (
                              <ul className="suggestions">
                                {this.state.suggestionHospitalList.map(
                                  (item, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        this.handleOnHospitalSelect(item)
                                      }
                                    >
                                      <i className="fa fa-search suggestionsSearch"></i>
                                      <span className="suggestionsText">
                                        {item.name}
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </div>
                        {submitModalFalg == 1 && error ? (
                          <span className="text-danger">
                            {error.name ? error.name : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Switch Board Phone Number</label>
                    <Input
                      type="number"
                      className="form-control"
                      value={this.state.hospitalInfo.switch_board_phone_number}
                      onChange={(e) =>
                        this.hospitalInfoHandler(
                          "switch_board_phone_number",
                          e.target.value
                        )
                      }
                    />
                    {submitModalFalg == 1 && error ? (
                      <span className="text-danger">
                        {error.switch_board_phone_number
                          ? error.switch_board_phone_number
                          : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Contact Person Name <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.hospitalInfo.contact_person_name}
                      onChange={(e) =>
                        this.hospitalInfoHandler(
                          "contact_person_name",
                          e.target.value
                        )
                      }
                    />
                    {submitModalFalg == 1 && error ? (
                      <span className="text-danger">
                        {error.contact_person_name
                          ? error.contact_person_name
                          : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Contact Person Phone Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="number"
                      className="form-control"
                      value={
                        this.state.hospitalInfo.contact_person_phone_number
                      }
                      onChange={(e) =>
                        this.hospitalInfoHandler(
                          "contact_person_phone_number",
                          e.target.value
                        )
                      }
                    />
                    {submitModalFalg == 1 && error ? (
                      <span className="text-danger">
                        {error.contact_person_phone_number
                          ? error.contact_person_phone_number
                          : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      SAC Code <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.hospitalInfo.sac_code}
                      onChange={(e) =>
                        this.hospitalInfoHandler("sac_code", e.target.value)
                      }
                    />
                    {submitModalFalg == 1 && error ? (
                      <span className="text-danger">
                        {error.sac_code ? error.sac_code : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    <Input
                      type="text"
                      className="form-control"
                      value={this.state.hospitalInfo.email}
                      onChange={(e) =>
                        this.hospitalInfoHandler("email", e.target.value)
                      }
                    />
                    {submitModalFalg == 1 && error ? (
                      <span className="text-danger">
                        {error.email ? error.email : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  {this.state.hospitalInfo?.addressData?.address &&
                    this.state.hospitalInfo?.addressData?.address.map(
                      (sub, ind) => (
                        <div className="form-group" key={ind}>
                          <label>
                            Hospital Address{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: "calc(100% - 30px)",
                              }}
                            >
                              <textarea
                                className="form-control"
                                name=""
                                onChange={(e) =>
                                  this.addAddressHandler(e.target.value, ind)
                                }
                                value={sub.name}
                                rows="2"
                              ></textarea>
                            </div>
                            {ind > 0 && (
                              <div>
                                <a
                                  className="ml-2"
                                  onClick={() => this.deleteAddAddress(ind)}
                                  href="javascript:;"
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </div>
                            )}
                          </div>
                          {sub.validate == true ? (
                            <span className="text-danger">
                              The address field is required.
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    )}
                  <div>
                    <a
                      href="javascript:;"
                      onClick={() => this.addMoreAddress()}
                      className="sml-btn mt-2 mb-3"
                    >
                      <i className="fa fa-plus"></i> Add More Address
                    </a>
                  </div>
                  {this.state.hospitalInfo?.departmentData?.department &&
                    this.state.hospitalInfo?.departmentData?.department.map(
                      (sub, ind) => (
                        <div className="form-group" key={ind}>
                          <label>
                            Hospital Department{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: "calc(100% - 30px)",
                              }}
                            >
                              <Input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  this.addDepartmentHandler(e.target.value, ind)
                                }
                                name=""
                                value={sub.name}
                              />
                            </div>
                            {ind > 0 && (
                              <div>
                                <a
                                  className="ml-2"
                                  onClick={() => this.deleteAddDepartment(ind)}
                                  href="javascript:;"
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </div>
                            )}
                          </div>
                          {sub.validate == true ? (
                            <span className="text-danger">
                              The department field is required.
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    )}
                  <div>
                    <a
                      href="javascript:;"
                      onClick={() => this.addMoreDepartment()}
                      className="sml-btn mt-2 mb-3"
                    >
                      <i className="fa fa-plus"></i> Add More Department
                    </a>
                  </div>
                  <div className="form-group">
                    <label>Notes</label>
                    <div
                              style={{
                                width: "calc(100% - 30px)",
                              }}
                            >
                          
                    <textarea
                      className="form-control"
                      name=""
                      value={this.state.hospitalInfo.notes}
                      onChange={(e) =>
                        this.hospitalInfoHandler("notes", e.target.value)
                      }
                      rows="2"
                    ></textarea>
  </div>
                    {submitModalFalg == 1 && error ? (
                      <span className="text-danger">
                        {error.notes ? error.notes : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <div className="">
              <button
                onClick={() => this.addNewModalClose()}
                className="btn btn-secondary mr-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
              {loading != true && (
                <button className="btn btn-primary" onClick={this.handleSubmit}>
                  Submit
                </button>
              )}
              {loading == true && (
                <button className="btn btn-primary loader-icon" disabled>
                  <Spinner className="img-svg"></Spinner>
                </button>
              )}
            </div>
          </ModalFooter>
        </Modal>
        {/* Add Modal */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { error, modal, refreshList, loading } = state.rootReducer.hospitals;
  return {
    error,
    modal,
    refreshList,
    loading,
  };
}

export default connect(mapStateToProps)(Add);
