import { authHeader,check419 } from "../helpers";
export const bookingRequestService = {
    getList,
    updateAcceptStatus,
    updateRejectStatus,
    getBookingRequestExport,
    getBookingDispatch,
    getBookingDispatchExport,
    getUnprocesseDispatchdList,
    getUnprocesseDispatchdExport
};

function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingRequest", requestOptions).then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function getBookingDispatch(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingDispatch", requestOptions).then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function getBookingDispatchExport(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingDispatchExport", requestOptions).then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function updateAcceptStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/updateBookingStatus", requestOptions).then(handleResponse);
}

function updateRejectStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: {...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/updateBookingStatusAsReject", requestOptions).then(handleResponse);
}

function getBookingRequestExport(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingRequestExport", requestOptions).then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function getUnprocesseDispatchdList(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/getPastBookingDispatch",
        requestOptions,
    )
        .then(handleResponse)
        .then(countDispatch => {
            return countDispatch;
        });
}

function getUnprocesseDispatchdExport(info) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(info),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "booking/getPastBookingDispatchExport",
        requestOptions,
    )
        .then(handleResponse)
        .then(countDispatch => {
            return countDispatch;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            } else if(response.status === 419){
                check419();
            }
        }

        return data;
    });
}