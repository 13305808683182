import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const WarningModal = ({addedKitFlag,warningModalClose}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    warningModalClose();
  };

  return (
    <div>
      <Modal isOpen={addedKitFlag} centered>
        <ModalHeader  className="center">
          <i  style={{fontSize:"30px"}} className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> Warning</ModalHeader>
        <ModalBody>
          <div className="text-danger">
            The selected kit is different from requested kits.
          </div>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="danger" onClick={toggleModal}>Proceed</Button>{" "} */}
          <Button color="primary" onClick={toggleModal} style={{width:"25%"}}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WarningModal;