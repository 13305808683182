import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Modal, ModalBody, ModalFooter, Input } from "reactstrap";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";
import { bookingService } from "../../../../../services";
import Select from "react-select";
import moment from "moment";

const options = [
    { label: "All Aces", value: "All Aces" },
    { label: "APC", value: "APC" },
    { label: "Sameday (HBC)", value: "Sameday (HBC)" },
    { label: "Other", value: "Other" },
];

const boxOptions = [
    { label: "Black Box", value: "Black Box" },
    { label: "Blue Box", value: "Blue Box" },
    { label: "Grey case", value: "Grey case" },
    { label: "Cardboard Box", value: "Cardboard Box" }
];

const priorityOptions = [
    { label: "Normal", value: "Normal" },
    { label: "High", value: "High" },
];


const ForClose = (props) => {
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState([{ label: "All Aces", value: "All Aces" }]);
    const [collectionData, setCollectionData] = useState({
        courier: "All Aces", return_date: "", additional_note: "", returnBoxesData: [],
        returnSetData: [], bookingId: "", log_status: "returned",
        log_by_id: "", priority_level: "Normal", comment: ""
    });
    const [collectionSubmitFlag, setCollectionSubmitFlag] = useState(0);
    const [selectedNumOfBox, setSelectedNumOfBox] = useState([{ label: "Black Box", value: "Black Box" }]);
    const [boxArray, setBoxArray] = useState([{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }]);
    const [selectedPriority, setSelectedPriority] = useState([{ label: "Normal", value: "Normal" }]);
    const [kitWithKitSet, setKitWithKitSet,kitWithKitSetRef] = useState([]);
    const [returnData, setReturnData] = useState({
        courier: "a", return_date: "", next_return_date: "",
        additional_note: "", returnBoxesData: [], returnSetData: [], bookingId: "",
        log_status: "returned", log_by_id: ""
    });
    const [returnSubmitFlag, setReturnSubmitFlag] = useState(0);
    //const [returnSetDataLength, setReturnSetDataLength] = useState(0);

    const [returnBoxArray, setReturnBoxArray] = useState([{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }]);
    const [selectedReturnNumOfBox, setSelectedReturnNumOfBox] = useState([{ label: "Black Box", value: "Black Box" }]);
    const [allTrue,setAllTrue] = useState(false);
    
    const handleCourier = (val) => {
        let result  = collectionData;
        result.courier = val.value;
        setCollectionData(result);
        setSelectedOption(val);
    };

    const handleBoxType = (index, val) => {
        //setSelectedNumOfBox(val);
        const result = [...boxArray];
        result[index].box_type = val.value;
        setBoxArray(result);
    };

    const handleBoxNumber = (index, val) => {
        const result = [...boxArray];
        result[index].number_of_box = val;
        result[index].numberOfBoxFlag = false;
        setBoxArray(result);
    };

    const handleBoxAdd = (e) => {
        e.preventDefault();
        const result = [...boxArray];
        result.push({ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false });
        setBoxArray(result);
    };

    const deleteAddBox = (e, index) => {
        e.preventDefault();
        const result = [...boxArray];
        result.splice(index, 1);
        setBoxArray(result);
    };

    const handlePriorityChange = (e) => {
        let value = e.value;
        let result = collectionData;
        setSelectedPriority(e);
        result.priority_level = value;
        setCollectionData(result);
    };

    const handleCommentChange = (e) => {
        setCollectionData(prevState => ({
            ...prevState,
            comment: e
          }));
    };

    const handleReturnSet = (val, outerIndex, innerIndex) => {
        const result = [...kitWithKitSet];

        var currentDate = new Date();
        currentDate = moment(currentDate).format("YYYY-MM-DD");
        result[outerIndex].sets[innerIndex].set_return_date = currentDate;

        result[outerIndex].sets[innerIndex].isreturn = val;
        setKitWithKitSet(result);

        //useEffect
        //handleMinMax();
        //this.checkDateInput();
    };

    const handleSetReturnDate = (e, outerIndex, innerIndex) => {
        const result = [...kitWithKitSet];
        result[outerIndex].sets[innerIndex].set_return_date = e.target.value;
        setKitWithKitSet(result);
        //useEffect
        // handleMinMax();
        // this.checkDateInput();
    };

    const handleMinMax = (resultData) => {
        const result = returnData;
        const dateArray = [];
        kitWithKitSet &&
            kitWithKitSet.map((kit) =>
                kit.sets
                    .filter((setd) => setd.added === 1 && setd.status !== "close" && setd.set_return_date !== undefined && setd.isreturn == false)
                    .map((setd) => {
                        dateArray.push(setd.set_return_date);
                    })
            );
            
        if(resultData?.length){
            resultData && resultData.map((kit) => kit.sets
                .filter((setd) => setd.added === 1 && setd.status !== "close")
                .map((setd) => {
                    if(setd?.set_return_date === undefined){
                        let currentDate = new Date();
                        currentDate = moment(currentDate).format("YYYY-MM-DD");
                        setd.set_return_date = currentDate;
                        dateArray.push(setd.set_return_date);
                    }else{
                        dateArray.push(setd.set_return_date);
                    }
                })
            );
        }


        if (dateArray.length > 0 && dateArray !== undefined) {
            const maxDate = new Date(Math.max(...dateArray.map(date => {
                return new Date(date);
            })));
            const minDate = new Date(Math.min(...dateArray.map(date => {
                return new Date(date);
            })));

            const formattedminDate = moment(minDate).format("YYYY-MM-DD");
            const formattedmaxDate = moment(maxDate).format("YYYY-MM-DD");

            result.next_return_date = formattedminDate;
            result.return_date = formattedmaxDate;

            setReturnData(result);
        }
    };

    const handleSetReturnComment = (e, outerIndex, innerIndex) => {
        const result = [...kitWithKitSet];
        result[outerIndex].sets[innerIndex].comment = e.target.value;
        setKitWithKitSet(result);
    };

    const modalClose = (flag) => {
        setCollectionSubmitFlag(0);
        setReturnSubmitFlag(0);
        setAllTrue(false);
        setSelectedOption([{ label: "All Aces", value: "All Aces" }]);
        setCollectionData({
            courier: "All Aces", return_date: "", additional_note: "", returnBoxesData: [],
            returnSetData: [], bookingId: "", log_status: "returned",
            log_by_id: "", priority_level: "Normal", comment: ""
        });
        setSelectedNumOfBox([{ label: "Black Box", value: "Black Box" }]);
        setBoxArray([{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }]);
        setSelectedPriority([{ label: "Normal", value: "Normal" }]);
        setKitWithKitSet([]);
        setReturnData({
            courier: "a", return_date: "", next_return_date: "",
            additional_note: "", returnBoxesData: [], returnSetData: [], bookingId: "",
            log_status: "returned", log_by_id: ""
        });
        setReturnBoxArray([{ box_type: "Black Box", number_of_box: "", numberOfBoxFlag: false }]);
        setSelectedReturnNumOfBox([{ label: "Black Box", value: "Black Box" }]);
        setLoading(false);
        setError({});
        props.forCloseModalClose(flag);
    };

    const handleForClose = async () => {
        setAllTrue(false);
        setCollectionSubmitFlag(0);
        setReturnSubmitFlag(0);
        setLoading(true);

        var flag=0; 
        var setArray = [];
        kitWithKitSetRef.current.map((kit) => {
            kit.sets.map((set) => {
                if(set.isreturn && set.added==1 && set.status!="close") {
                    setArray.push({"bookingKitSetId": set._id});
                }
            });
        });
        
        collectionData.collectionBoxesData = boxArray;
        collectionData.collectionSetData = setArray;
        collectionData.bookingId = props.bookingId;
        collectionData.log_status="collected";
        collectionData.log_by_id=props.userId;

        let collectionArray = boxArray.map((item)=>{
            if(item.number_of_box == 0 || item.box_type=="" || item.number_of_box=="")
            {
                flag=1;
            }

            if(item.number_of_box == 0 || item.number_of_box=="")
            {
                item.numberOfBoxFlag=true;
            }
            return item;
        });

        if(collectionData.collectionSetData.length==0)
        {
            flag=1;
        }

        var kitSetLength = 0;
        var returnSetArray = [];
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                if (set.added == 1 && set.status != "close") {
                    kitSetLength++;
                }
                if (set.added == 1 && set.status != "close") {
                    returnSetArray.push({ "bookingKitSetId": set._id, "set_return_date": set.set_return_date, "setStatusChecked": set.isreturn,"comment" : set.comment });
                }

            });
        });

        returnData.returnBoxesData = returnBoxArray;
        returnData.returnSetData = returnSetArray;
        returnData.bookingId = props.bookingId;
        returnData.log_status = "returned";
        returnData.log_by_id = props.userId;

        let returnArray = returnBoxArray.map((item) => {
            if (item.number_of_box == 0 || item.box_type == "" || item.number_of_box == "") {
                flag = 1;
            }

            if(item.number_of_box == 0 || item.number_of_box=="")
            {
                item.numberOfBoxFlag=true;
            }
            return item;
        });

        var checkedFlag=returnData.returnSetData.filter((task)=>task.setStatusChecked==true).length;

        if (checkedFlag == 0 || checkedFlag !== returnSetArray?.length) {
            flag = 1;
        }else{
            setAllTrue(true);
        }

        if (checkedFlag > 0 && checkedFlag != kitSetLength && returnData.return_date == "") {
            flag = 1;
        }
        else if (returnData.return_date != "" && checkedFlag > 0 && checkedFlag== kitSetLength) {
            const isChecked = returnData.returnSetData.map((val) => val.setStatusChecked);
            const allChecked = isChecked.every(value => value === true);
            if (allChecked) {
                returnData.return_date = "";
                returnData.next_return_date = "";
            }
        }

        setCollectionSubmitFlag(1);
        setReturnSubmitFlag(1);
        //setReturnSetDataLength(checkedFlag);

        if(flag==0){
                // this.props.dispatch(bookingActions.saveCollections(this.state.collectionData));
                try {
                    const res = await bookingService.saveCollections(collectionData);
                    if(res.status === 200){
                        try{
                            const response = await bookingService.saveReturn(returnData);
                            if(response.status === 200){
                                props.getList(1, props.search, true);
                                setLoading(false);
                                modalClose(true);
                            }
                        }catch (error){
                            console.error("Error: ", error);
                            setLoading(false);
                            setError(error);
                        }
                    }
                } catch (error) {
                    console.error("Error: ", error);
                    setLoading(false);
                    setError(error);
                }
            }else{
                setBoxArray(collectionArray);
                setReturnBoxArray(returnArray);
                setLoading(false);
            }

    };

    const handleReturnBoxAdd = (e) => {
        e.preventDefault();
        const result = [...returnBoxArray];
        result.push({ box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false });
        setReturnBoxArray(result);
    };

    const deleteAddReturnBox = (e, index) => {
        e.preventDefault();
        const result = [...returnBoxArray];
        result.splice(index, 1);
        setReturnBoxArray(result);
    };

    const handleReturnBoxType = (index, val) => {
        // setSelectedReturnNumOfBox(val);
        const result = [...returnBoxArray];
        result[index].box_type = val.value;
        setReturnBoxArray(result);
    };

    const handleReturnBoxNumber = (index, val) => {
        const result = [...returnBoxArray];
        result[index].number_of_box = val;
        result[index].numberOfBoxFlag = false;
        setReturnBoxArray(result);
    };

    const getSingleBooking = async (id) => {
        //this.props.dispatch(bookingActions.getById(id));
        try {
            const res = await bookingService.getById(id);
            if(res.status === 200){
                setBookingData(res.data);
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const setBookingData = (data) => {
        setKitWithKitSet([]);
        data?.bookingKitId.map(async(item)=> { 
            //this.props.dispatch(bookingActions.getKitSetByKit({kitId: item.kitId}));
            try {
                const res = await bookingService.getKitSetByKit({kitId: item.kitId});
                if(res.status === 200){
                    showKitsSetList(res.data);
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        });
    };

    const showKitsSetList = (data) => {
        var resultData =[...kitWithKitSetRef.current];
        var subKitSet=[]; 
        var lnt=0; 
        var hire_charge="";
        var status="";

        data[0]["kitsets"].map((kset)=>{
            hire_charge="";
            status="";
            lnt=data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == props.bookingId ).length;
            if(lnt>0)
            {
                data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == props.bookingId).map((result)=>{
                    hire_charge=result.hire_charge;
                    status=result.status;
                });
               
            }
            subKitSet.push({set_name:kset.set_name,_id:kset._id,hire_charge:hire_charge,added:lnt,status:status, isreturn: true});
        });

        resultData.push({name:data[0].name,_id:data[0]._id,sets:subKitSet});
        setKitWithKitSet(resultData);
        handleMinMax(resultData);
    };

    useEffect(()=>{
        if(props.bookingId !== 0){
            getSingleBooking(props.bookingId);
        }
    },[props.bookingId]);

    useEffect(()=>{
        handleMinMax();
    },[kitWithKitSetRef.current]);

    

    return (
        <Modal
            size="lg"
            backdrop="static"
            isOpen={props.forCloseModal}
            toggle={() => modalClose(false)}>

            <ModalBody>
                <div className="form-group no-gutters">
                    <h5>Collection</h5>
                    <div className="form-group col-5 position_up" style={{ zIndex: boxArray.length + 10000 }}>
                        <label>Courier</label>
                        <Select
                            defaultValue={selectedOption}
                            onChange={(e) => handleCourier(e)}
                            options={options}
                        />
                        {error &&
                            collectionSubmitFlag == 1 ? (
                            <span className="text-danger">
                                {error.courier
                                    ? error.courier
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>

                    <label>Box Type</label>
                    {boxArray.map((boxItem, index) => (
                        <div className="form-group row"
                            key={index}>
                            <div className="col-5" style={{ zIndex: (boxArray.length - index + 9999) }}>
                                <Select
                                    defaultValue={selectedNumOfBox}
                                    onChange={(e) => handleBoxType(index, e)}
                                    options={boxOptions}
                                    placeholder="Box Type"
                                />
                                {boxItem.box_type == "" && collectionSubmitFlag == 1 ? <span className="text-danger">Please select box type</span> : ""}
                            </div>
                            <div className="col-5 col-sm-6">
                                <Input
                                    type="number"
                                    className="form-control"
                                    placeholder="Number of Boxes"
                                    min="0"
                                    onChange={(e) => handleBoxNumber(index, e.target.value)}
                                    value={boxItem.number_of_box}
                                />
                                {boxItem.number_of_box == "" && collectionSubmitFlag == 1 && boxItem?.numberOfBoxFlag ? <span className="text-danger">Please enter number of boxes</span> : ""}
                            </div>
                            {index > 0 &&
                                <div className="col-1 text-center">
                                    <a className="ml-2" onClick={(e) => deleteAddBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                                </div>
                            }

                        </div>

                    ))}
                    
                    <div className="form-group">
                        <a href="#!" className="sml-btn" onClick={(e) => handleBoxAdd(e)}><i className="fa fa-plus"></i> Add More</a>
                    </div>
                    
                    
                    <div className="form-group mb-3">
                        <label>Priority Level</label>
                        <Select
                            defaultValue={selectedPriority}
                            onChange={(e) => handlePriorityChange(e)}
                            options={priorityOptions}
                        />
                    </div>

                    <div className="form-group">
                        <label>Comment</label>
                        <textarea
                            className="form-control"
                            rows="3"
                            value={collectionData.comment}
                            onChange={(e) => handleCommentChange(e.target.value)}
                        >
                        </textarea>
                    </div>

                </div>
            </ModalBody>

            <ModalBody>
                <h5>Return</h5>
                <div className="form-group">
                    <label>Select the returned Sets</label>
                    {kitWithKitSetRef && kitWithKitSetRef.current.map((kit, index) => (
                        <React.Fragment key={index}>
                            {kit?.sets && kit.sets.map((setd, ind) => (
                                <React.Fragment key={ind}>
                                    {setd.added == 1 && setd.status != "close" &&
                                        <div className="row" key={ind}>
                                            <div className="col-lg-3 col-md-6 col-sm-6">
                                                <div className="form-check">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={setd.isreturn}
                                                        onChange={(e) => handleReturnSet(e.target.checked, index, ind)}
                                                    />
                                                    <label className="form-check-label" htmlFor="r`eturn_set">{setd.set_name}</label>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        type="date"
                                                        className="small"
                                                        disabled={setd.isreturn}
                                                        value={moment(setd.set_return_date).format("YYYY-MM-DD")}
                                                        onChange={(e) => handleSetReturnDate(e, index, ind)}
                                                    />
                                                    {returnSubmitFlag === 1 && setd.set_return_date === "Invalid date" && (
                                                        <span className="text-danger">Please enter a date</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="small"
                                                        placeholder="Comment"
                                                        defaultValue={setd.comment}
                                                        onChange={(e) => handleSetReturnComment(e, index, ind)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                    {(returnSubmitFlag == 1 && allTrue ==false) ? <span className="text-danger">Please check all item</span> : ""}
                </div>
                
                {returnBoxArray.map((boxItem, index) => (
                    <div className="form-group row position_up"
                        style={{ zIndex: returnBoxArray.length - index }}
                        key={index}>
                        <div className="col-5 mb-3">

                            <Select
                                defaultValue={selectedReturnNumOfBox}
                                onChange={(e) => handleReturnBoxType(index, e)}
                                options={boxOptions}
                                placeholder="Box Type"
                            />
                            {boxItem.box_type ==
                                "" &&
                                returnSubmitFlag ==
                                1 ? (
                                <span className="text-danger">
                                    Please
                                    select
                                    box type
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-5 col-sm-6 mb-3">
                            <Input
                                type="number"
                                className="form-control"
                                placeholder="Number of Boxes"
                                min="0"
                                onChange={(e) => handleReturnBoxNumber(index, e.target.value)}
                                value={boxItem.number_of_box}
                            />
                            {boxItem.number_of_box == "" && returnSubmitFlag == 1 && boxItem?.numberOfBoxFlag ? <span className="text-danger">Please enter number of boxes</span> : ""}
                        </div>
                        {index > 0 &&
                            <div className="col-1 mb-3 text-center">
                                <a className="ml-2" onClick={(e) => deleteAddReturnBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                            </div>
                        }
                    </div>
                ))}
                <a href="#!" className="sml-btn" onClick={(e) => handleReturnBoxAdd(e)}><i className="fa fa-plus"></i> Add More</a>
            </ModalBody>

            <ModalFooter>
                <div className="">
                    <button
                        onClick={() => modalClose(false)}
                        className="btn btn-secondary mr-2"
                        data-dismiss="modal">
                        Cancel
                    </button>
                    {loading == false && (
                        <button
                            className="btn btn-primary"
                            onClick={() => handleForClose()}>
                            Ok
                        </button>
                    )}
                    {loading == true && (
                        <button
                            className="btn btn-primary loader-icon"
                            disabled>
                            <Spinner className="img-svg"></Spinner>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ForClose;