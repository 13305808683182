import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { userService } from "../../../../services";
import { Input } from "reactstrap";
import UsersNavbar from "./usersNavbar";

const Index = () => {
  const [archiveData, setArchiveData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const getAllArchiveUsers = () => {
    setLoading(true);
    let payload = { search: search, page: activePage,limit:50 };
    userService
      .getArchiveUsers(payload)
      .then((res) => {
        setTotalItemCount(res.total_count);
        setArchiveData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSearch = (val) => {
    setSearch(val);
    setActivePage(1);
  };

  const getUserInitials = (Name) => {
    if (Name != "" && Name != null) {
      const initials = Name.charAt(0);
      return initials.toUpperCase();
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (!user || user.data.user_role !== "admin") {
      return;
    }
    getAllArchiveUsers();
  }, [activePage, search]);

  if (!user || user.data.user_role !== "admin") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="archive" />
      <div className="container">
        <div className="pt-4 row align-items-center">
          <div className="col-md-6">
            <div className="sub-menu">
              <UsersNavbar activePage="archive" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-search text-right">
              <Input
                className="form-control mr-sm-2 "
                type="text"
                placeholder="Search.."
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div id="table-scroll" className="table-scroll mt-4">
          <table id="main-table" className="main-table">
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Full Name
                </th>
                <th scope="col" style={{ minWidth: "150px" }}>
                  Email
                </th>
                <th scope="col" style={{ minWidth: "120px" }}>
                  Contact Number
                </th>
                <th
                  scope="col"
                  className="text-right"
                  style={{ minWidth: "120px" }}
                >
                  User Type
                </th>
              </tr>
            </thead>
            <tbody>
              {archiveData.length > 0 &&
                archiveData.map((item) => (
                  <tr key={item._id}>
                    <td data-th="Full Name">
                      <div className="">
                        <span>
                          <strong>
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="avtar mr-2">
                                  {getUserInitials(item.first_name)}
                                  {getUserInitials(item.last_name)}
                                </div>
                              </div>
                              <div>
                                {item.first_name}{" "}
                                {item.last_name ? item.last_name : ""}
                              </div>
                            </div>
                          </strong>
                        </span>
                      </div>
                    </td>
                    <td data-th="Email">{item.email}</td>
                    <td data-th="Contact Number">{item.phone}</td>
                    <td className="text-right" data-th="User Type">
                      {item.user_role === "admin" ||
                      item.user_role === "super_admin"
                        ? "Admin"
                        : "Reps"}
                    </td>
                  </tr>
                ))}
              {archiveData.length === 0 && loading == false && (
                <tr className="text-center">
                  <td colSpan="3">No Record Found</td>
                </tr>
              )}

              {archiveData && archiveData.length == 0 && loading == true && (
                <tr className="text-center">
                  <td colSpan="3">
                    <div className="loading-state-new">
                      <div className="loading"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {archiveData.length > 0 && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={50}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          innerClass="pagination justify-content-center mb-5"
          activeLinkClass="active"
          nextPageText="Next"
          prevPageText="Prev"
        />
      )}
      <Footer />
    </div>
  );
};

export default Index;