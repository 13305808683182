export const collectionBookingConstants = {
   
    GETALLCOLLECTIONBOOKINGS_REQUEST: "COLECTIONBOOKINGS_GETALL_REQUEST",
    GETALLCOLLECTIONBOOKINGS_SUCCESS: "COLECTIONBOOKINGS_GETALL_SUCCESS",
    GETALLCOLLECTIONBOOKINGS_FAILURE: "COLECTIONBOOKINGS_GETALL_FAILURE",

    COUNTCOLECTIONNBOOKINGS_REQUEST: "COLECTIONBOOKINGS_COUNT_REQUEST",
    COUNTCOLECTIONBOOKINGS_SUCCESS: "COLECTIONBOOKINGS_COUNTL_SUCCESS",
    COUNTCOLECTIONBOOKINGS_FAILURE: "COLECTIONBOOKINGS_COUNT_FAILURE"


};