import React, { useState, useEffect, useCallback } from "react"; 
import Navbar from "../Navbar";
import { Redirect, Link } from "react-router-dom";
import { bookingService } from "../../../../services";
import dateFormat from "dateformat";
import DispatchModal from "./Modal/dispatchModal";
import AcceptModal from "./Modal/acceptModal";
import RejectModal from "./Modal/rejectModal";
import { Spinner } from "reactstrap";
import CloseModal from "./Modal/closeModal";
import NavbarComponent from "./Navbar/Index";
 
const PendingConsignmentRequest = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [bookings, setBookings] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [dispatchModal, setDispatchModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [searchParams, setSearchParams] = useState({});

  const fetchBookings = async (searchParams, page = 1) => {
    try {
      setLoading(true);
      const res = await bookingService.getConsignmentRequest({
        ...searchParams,
        page,
      });
      const newBookings = res.data;

      setBookings((prevBookings) => {
        return page === 1 ? newBookings : [...prevBookings, ...newBookings];
      });
      setHasMore(newBookings.length > 0);
      setLoading(false);
      if (res.status) {
        setTotalItemsCount(res?.total_count);
      }
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    if (Object.keys(searchParams).length > 0) {
      fetchBookings(searchParams, 1);
    }
  }, [searchParams]);

  useEffect(() => {
    console.log("Search Params updated: ", searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (page > 1) {
      fetchBookings(searchParams, page);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, loading]); 

  useEffect(() => {
    console.log("searhc", searchParams);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const dispatchModalOpen = (id) => {
    setDispatchModal(true);
    setBookingId(id);
  };

  const closeModalOpen = (id) => {
    setCloseModal(true);
    setBookingId(id);
  };

  const closeModalClose = () => {
    setCloseModal(false);
    setBookingId(null);
  };
  const dispatchModalClose = () => {
    setDispatchModal(false);
    setBookingId(null);
  };

  const acceptModalOpen = (id, deliveryDate) => {
    setAcceptModal(true);
    setBookingId(id);
    setDeliveryDate(deliveryDate);
  };

  const acceptModalClose = () => {
    setAcceptModal(false);
    setBookingId(null);
  };

  const rejectModalOpen = (id) => {
    setRejectModal(true);
    setBookingId(id);
  };

  const rejectModalClose = () => {
    setRejectModal(false);
    setBookingId(null);
  };

  const handleReload = () => {
    setPage(1);
    fetchBookings(searchParams, 1);
  };

  if (!user) {
    return <Redirect to="/" />;
  }

  if (user.data.user_role !== "admin") {
    return <Redirect to="/" />;
  }

  return ( 
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid">
        <NavbarComponent setSearchParams={setSearchParams} />
        <div
          className="small mr-3 badge badge-info"
          style={{
            fontWeight: 500,
            background: "#5d75e9",
            marginBottom: 0,
          }}
        >
          {totalItemsCount} Records found
        </div>
        <div id="table-scroll" className="table-scroll mt-2">
          <table id="main-table" className="main-table">
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Hospital Name
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Contact Person
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Surgeon Name
                </th>
                <th scope="col">Kits</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Booked By</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking) => {
                  const { _id: consignmentId, delivery_date } =
                    booking.consignments;
                  return (
                    <tr key={consignmentId}>
                      <td data-th="Hospital Name">
                        <div>
                          <Link
                            to={
                              "/admin/consignment-request/details/" +
                              booking.consignments._id
                            }
                          >
                            <span>
                              <strong>
                                <span
                                  style={{ display: "block" }}
                                  className="theme-text"
                                >
                                  {booking.consignments.consignment_id}
                                </span>
                                {booking.hospitalData[0]?.name}
                              </strong>
                            </span>
                            <br />
                            <small>
                              Booked on{" "}
                              {dateFormat(
                                booking.consignments.createdAt,
                                "ddd, mmm dS"
                              )}
                            </small>
                          </Link>
                        </div>
                      </td>
                      <td data-th="Contact Person">
                        <div className="info-left">
                          <strong>
                            {booking.hospitalData[0]?.contact_person_name}{" "}
                          </strong>
                          <br />
                          <small>
                            Location: {booking.consignments.hospital_address}{" "}
                          </small>
                          <br />
                          <small>Email: {booking.hospitalData[0]?.email}</small>
                          <br />
                          <small>
                            Phone:{" "}
                            {
                              booking.hospitalData[0]
                                ?.contact_person_phone_number
                            }
                          </small>
                          <br />
                          <small>
                            Department:{" "}
                            {booking.consignments.hospital_department}
                          </small>
                        </div>
                      </td>
                      <td data-th="Surgeon Name">
                        <div className="info-left">
                          {booking.surgeonData.length > 0
                            ? booking.surgeonData.map((surgeon) => (
                                <div key={surgeon._id}>
                                  <strong>
                                    {surgeon.title} {surgeon.first_name}{" "}
                                    {surgeon.last_name}
                                  </strong>
                                  <br />
                                </div>
                              ))
                            : "Not Assigned"}
                        </div>
                      </td>
                      <td data-th="Kits">
                        <small>
                          {booking.consignmentKits.length > 0
                            ? booking.consignmentKits.map((kit) => (
                                <a
                                  key={kit._id}
                                  href="javascript:;"
                                  data-toggle="modal"
                                  data-target="#kitDetails"
                                >
                                  {kit.kits[0]?.name} <br />
                                </a>
                              ))
                            : "None"}
                        </small>
                      </td>
                      <td data-th="Delivery Date">
                        {booking?.consignments?.delivery_date
                          ? dateFormat(
                              booking?.consignments?.delivery_date,
                              "ddd, mmm dS"
                            )
                          : ""}
                      </td>
                      <td data-th="Booked By">
                        <div className="info-left">
                          {booking.createdData[0]?.first_name}{" "}
                          {booking.createdData[0]?.last_name}
                        </div>
                      </td>
                      <td data-th="Actions">
                        <div className="action-area dropdown">
                          <div className="mb-2">
                            <span
                              className={
                                "capsule " +
                                (booking.consignments.status == "booked" ||
                                booking.consignments.status === "close" ||
                                booking.consignments.status == "dispatch" ||
                                booking.consignments.status ===
                                  "partial-dispatch"
                                  ? "green-dim "
                                  : "red-dim ") +
                                "mb-1"
                              }
                            >
                              {booking.consignments.status === "booked"
                                ? "Reviewed and Accepted"
                                : booking.consignments.status === "pending"
                                ? "Not Reviewed"
                                : booking.consignments.status == "dispatch"
                                ? "Dispatched"
                                : booking.consignments.status ===
                                  "partial-dispatch"
                                ? "Partially Dispatched"
                                : "booking.consignments.status"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          {booking.consignments.status === "pending" && (
                            <>
                              <div className="mb-2">
                                <span>
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      acceptModalOpen(
                                        consignmentId,
                                        delivery_date
                                      )
                                    }
                                    className="btn btn-outline-primary btn-sm1"
                                  >
                                    Accept
                                  </a>
                                </span>
                              </div>
                              <div className="mb-2">
                                <span>
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      rejectModalOpen(booking.consignments._id)
                                    }
                                    className="btn btn-outline-primary btn-sm1"
                                  >
                                    Reject
                                  </a>
                                </span>
                              </div>
                            </>
                          )}

                          {(booking.consignments.status === "booked" ||
                            booking.consignments.status ===
                              "partial-dispatch") && (
                            <div className="mb-2">
                              <span>
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    dispatchModalOpen(booking.consignments._id)
                                  }
                                  className="btn btn-outline-primary btn-sm1"
                                >
                                  Dispatch
                                </a>
                              </span>
                            </div>
                          )}
                          {booking.consignments.status === "dispatch" && (
                            <div className="mb-2">
                              <span>
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    closeModalOpen(booking.consignments._id)
                                  }
                                  className="btn btn-outline-primary btn-sm1"
                                >
                                  Close
                                </a>
                              </span>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No bookings found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {loading && (
            <div className="text-center my-4">
              <Spinner color="primary" />
            </div>
          )}
        </div>
      </div>
      <DispatchModal
        isOpen={dispatchModal}
        bookingId={bookingId}
        toggle={dispatchModalClose}
        onReload={handleReload}
      />
      <CloseModal
        isOpen={closeModal}
        bookingId={bookingId}
        toggle={closeModalClose}
        onReload={handleReload}
      />
      <AcceptModal
        isOpen={acceptModal}
        bookingId={bookingId}
        deliveryDate={deliveryDate} // Pass delivery date
        toggle={acceptModalClose}
        onReload={handleReload}
      />
      <RejectModal
        isOpen={rejectModal}
        bookingId={bookingId}
        toggle={rejectModalClose}
        onReload={handleReload}
      />
    </div>
  );
};

export default PendingConsignmentRequest;
