import React, { useEffect } from "react";
import useState from "react-usestateref";
import Navbar from "../Navbar";
import { Input } from "reactstrap";
import Pagination from "react-js-pagination";
import AddOperation from "./Add/Index";
import DeleteOperation from "./Delete/Index";
import EditOperation from "./Update/Index";
import { operationService } from "../../../../services";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ReadMore from "./Modal/ReadMore";
import Select from "react-select";

const Index = () => {
	const [operationList, setOperationList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [activePage, setActivePage, activePageRef] = useState(1);
	const [totalItemsCount, setTotalItemCount] = useState(0);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [editId, setEditId] = useState("");
	const [editName, setEditName] = useState("");
	const [editSuggestedName, setEditSuggestedName] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [readMoreContent, setReadMoreContent] = useState([]);
	const [readMoreModel, setReadMoreModel] = useState(false);
	const [suggestedSets, setSuggestedSets] = useState([]);
	const user = JSON.parse(localStorage.getItem("user"));
	const [status, setStatus] = useState("");
	const statusOptions = [
		{ value: "", label: "Status" },
		{ value: "Active", label: "Show" },
		{ value: "Hide", label: "Hide" },
	];

	const deleteModalOpen = (id) => {
		setDeleteId(id);
		setDeleteModal(true);
	};

	const openEditModal = (id, name, sets) => {
		setEditId(id);
		setEditName(name);
		setEditSuggestedName(sets);
		setEditModal(true);
	};

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
	};

	const getOperation = () => {
		setLoading(true);
		setOperationList([]);
		operationService
			.getOperationsListBYLimit({
				search: search,
				page: activePageRef.current,
				status: status,
			})
			.then((res) => {
				console.log("operations", res);
				setTotalItemCount(res.total_count);
				setActivePage(res.page);
				const data = res.data;
				console.log("Data:", data);
				setOperationList(data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error("Error:", error);
			});
	};

	const handleUpdate = (id, name, sets) => {
		console.log("sets", sets);
		setOperationList((prevList) =>
			prevList.map((item) =>
				item._id === id
					? { ...item, name: name, suggestedSets: sets }
					: item
			)
		);
	};

	const handleDelete = () => {
		setEditId("");
		setEditName("");
		setEditSuggestedName([]);
		setActivePage(1);
		getOperation();
	};

	const handleSearch = (val) => {
		setSearch(val);
		setActivePage(1);
	};

	useEffect(() => {
		setLoading(true);
		if (!user || user.data.user_role !== "admin") {
			return;
		}
		getOperation();
	}, [activePage, search]);

	useEffect(() => {
		getSetsListForOperationSuggestions();
	}, []);

	const handleReadMore = (val) => {
		setReadMoreContent(val);
		setReadMoreModel(true);
	};

	const handleReadMoreClose = () => {
		setReadMoreContent([]);
		setReadMoreModel(false);
	};

	const getSetsListForOperationSuggestions = () => {
		let payload = { search: "" };
		operationService
			.getSetsListForOperationSuggestions(payload)
			.then((res) => {
				if (res.status === 200) {
					let setsArray = [];
					res.data.length &&
						res.data.map((item) => {
							let obj = {
								label: item.set_name,
								value: item._id,
								kitID: item.kitID,
							};
							setsArray.push(obj);
						});
					setSuggestedSets(setsArray);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	if (!user || user.data.user_role !== "admin") {
		return <Redirect to="/" />;
	}

	const handleStatus = (e) => {
		console.log("handleStatus e", e);
		setStatus(e.value);
        setActivePage(1);
	};

	useEffect(() => {
		getOperation();
	}, [status, search, activePage]);

	const updateStatus = (id, status) => {
		operationService
			.updateOperationStatus({ status: status, id: id })
			.then(() => getOperation());
	};

	return (
		<div>
			<Navbar activePage="operations" />
			<div className="container">
				<div className="pt-4 row align-items-center">
					<div className="col-md-3">
						<div className="sub-menu">
							<h1 className="heading">Operations</h1>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-search">
							<Input
								className="form-control mr-sm-2"
								type="text"
								placeholder="Search.."
								value={search}
								onChange={(e) => handleSearch(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-md-3">
						<form className="form-search my-1">
							<Select
								defaultValue={status}
								onChange={handleStatus}
								options={statusOptions}
							/>
						</form>
					</div>
					<div className="col-md-2 text-right">
						<AddOperation
							setActivePage={setActivePage}
							getOperation={getOperation}
							suggestedSets={suggestedSets}
						/>
					</div>
				</div>
				<div id="table-scroll" className="table-scroll mt-4">
					<table id="main-table" className="main-table">
						<thead>
							<tr>
								<th scope="col" style={{ minWidth: "50%" }}>
									Operation Name
								</th>
								<th scope="col">Suggested Sets</th>
								<th scope="col">Status</th>
								<th
									scope="col"
									className="text-right"
									width="100"
								>
									Actions
								</th>
							</tr>
						</thead>
						<tbody>
							{operationList.length > 0 &&
								operationList.map((item) => (
									<tr key={item._id}>
										<td>
											<div className="">
												<strong>{item.name}</strong>
											</div>
										</td>
										<td>
											{item.suggestedSets.length > 0 &&
												item.suggestedSets
													.slice(0, 10)
													.map((sugSet, sugInd) => (
														<React.Fragment
															key={sugInd}
														>
															{sugInd > 0
																? ", "
																: ""}
															{
																sugSet.sets[0]
																	?.set_name
															}
														</React.Fragment>
													))}
											{item.suggestedSets.length > 10 && (
												<div>
													..{" "}
													<a
														href="javascript:;"
														onClick={() =>
															handleReadMore(
																item.suggestedSets
															)
														}
													>
														Read More
													</a>
												</div>
											)}
										</td>
										<td>
											{item.status === "Active" || !item.status
												? "Show"
												: "Hide"}
										</td>
										<td className="text-right">
											<div className="action-area dropdown">
												<a
													className="dropdown-toggle"
													href="#"
													id="navbarDropdown"
													role="button"
													data-bs-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													<span className="dots">
														...
													</span>
												</a>
												<div
													className="dropdown-menu"
													aria-labelledby="navbarDropdown"
												>
													<a
														className="dropdown-item"
														onClick={() =>
															openEditModal(
																item._id,
																item.name,
																item.suggestedSets
															)
														}
														href="javascript:;"
													>
														Edit
													</a>
													{item.status ===
																	"Active" || !item.status
															 ? (
																<a
                                                                className="dropdown-item"
                                                                onClick={() =>
                                                                    updateStatus(
                                                                        item._id,
                                                                        "Hide"
                                                                    )
                                                                }
                                                                href="javascript:;"
                                                            >
                                                                Hide
                                                            </a>
													 ) : (
														<a
															className="dropdown-item"
															onClick={() =>
																updateStatus(
																	item._id,
																	"Active"
																)
															}
															href="javascript:;"
														>
															Show
														</a>
													)}
													<a
														className="dropdown-item"
														onClick={() =>
															deleteModalOpen(
																item._id
															)
														}
														href="javascript:;"
													>
														Delete
													</a>
												</div>
											</div>
										</td>
									</tr>
								))}
							{operationList.length === 0 && loading === false && (
								<tr className="text-center">
									<td colSpan="4">No Record Found</td>
								</tr>
							)}
							{operationList.length === 0 && loading === true && (
								<tr className="text-center">
									<td colSpan="4">
										<div className="loading-state-new">
											<div className="loading"></div>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				{operationList.length > 0 && (
					<div>
						<Pagination
							activePage={activePage}
							itemsCountPerPage={50}
							totalItemsCount={totalItemsCount}
							pageRangeDisplayed={5}
							onChange={handlePageChange}
							itemClass="page-item"
							linkClass="page-link"
							innerClass="pagination justify-content-center mb-5"
							activeLinkClass="active"
							nextPageText="Next"
							prevPageText="Prev"
						/>
						<br className="d-block d-sm-none" />
						<br className="d-block d-sm-none" />
					</div>
				)}
				<DeleteOperation
					id={deleteId}
					deleteModalProps={deleteModal}
					handleDelete={handleDelete}
					setDeleteModalProps={setDeleteModal}
				/>
				<EditOperation
					id={editId}
					name={editName}
					handleUpdate={handleUpdate}
					editmodal={editModal}
					editSuggestedName={editSuggestedName}
					setEditModal={setEditModal}
					suggestedSets={suggestedSets}
					setEditSuggestedName={setEditSuggestedName}
				/>
				<ReadMore
					readMoreContent={readMoreContent}
					handleReadMoreClose={handleReadMoreClose}
					readMoreModel={readMoreModel}
				/>
			</div>
		</div>
	);
};

export default Index;
