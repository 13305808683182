export const surgeonConstants = {
    SURGEONADD_REQUEST: "SURGEON_ADD_REQUEST",
    SURGEONADD_SUCCESS: "SURGEON_ADD_SUCCESS",
    SURGEONADD_FAILURE: "SURGEON_ADD_FAILURE",

    SURGEONEDIT_REQUEST: "SURGEON_EDIT_REQUEST",
    SURGEONEDIT_SUCCESS: "SURGEON_EDIT_SUCCESS",
    SURGEONEDIT_FAILURE: "SURGEON_EDIT_FAILURE",

    SURGEONUPDATE_REQUEST: "SURGEON_UPDATE_REQUEST",
    SURGEONUPDATE_SUCCESS: "SURGEON_UPDATE_SUCCESS",
    SURGEONUPDATE_FAILURE: "SURGEON_UPDATE_FAILURE",

    GETALLSURGEONS_REQUEST: "SURGEONS_GETALL_REQUEST",
    GETALLSURGEONS_SUCCESS: "SURGEONS_GETALL_SUCCESS",
    GETALLSURGEONS_FAILURE: "SURGEONS_GETALL_FAILURE",

    GETALLSURGEONSLIST_REQUEST: "SURGEONSLIST_GETALL_REQUEST",
    GETALLSURGEONSLIST_SUCCESS: "SURGEONSLIST_GETALL_SUCCESS",
    GETALLSURGEONSLIST_FAILURE: "SURGEONSLIST_GETALL_FAILURE",

    

    DELETE_REQUEST: "SURGEONS_DELETE_REQUEST",
    DELETE_SUCCESS: "SURGEONS_DELETE_SUCCESS",
    DELETE_FAILURE: "SURGEONS_DELETE_FAILURE"    
};
