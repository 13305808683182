import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input, Button, Spinner } from "reactstrap";
import { kitService, repsBookingService } from "../../../../../../services";

const MyKitPage = ({ onSave, id, validationErrors, setValidationErrors }) => {
  const [selectedKit, setSelectedKit] = useState(null);
  const [kitWithKitSet, setKitWithKitSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [kitOptions, setKitOptions] = useState([]);

  useEffect(() => {
    const fetchKits = async () => {
      try {
        setIsLoading(true);

        const response = await kitService.kitListDropdownForReps({
          search: "",
        });
        const kits = response.data.map((kit) => ({
          value: kit._id,
          label: kit.name,
        }));
        setKitOptions(kits);

        const { data } = await repsBookingService.editConsignmentRequest({
          id,
        });
        const fetchedKits = data[0].consignmentKits.map((kit) => ({
          id: kit.kitId,
          name: kit.kits[0].name,
          expected_sales: kit.expected_sales || "",
          no_of_cases: kit.no_of_cases || "",
          sales_value: kit.sales_value || "",
          total: kit.total || 0,
          stock_level: kit.stock_level || "",
          kitDocument: kit.kitDocument || [],
        }));
        setKitWithKitSet(fetchedKits);
      } catch (err) {
        console.error("Failed to fetch kits", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchKits();
  }, [id]);

  useEffect(() => {
    onSave(kitWithKitSet);
  }, [kitWithKitSet, onSave]);

  const handleAddKit = () => {
    if (selectedKit) {
      setKitWithKitSet((prevState) => [
        ...prevState,
        {
          id: selectedKit?.value,
          name: selectedKit?.label,
          expected_sales: "",
          no_of_cases: "",
          sales_value: "",
          total: 0,
          stock_level: "",
          kitDocument: [],
        },
      ]);
      setSelectedKit(null);
    }
  };

  const handleKitChange = (index, field, value) => {
    const updatedKits = kitWithKitSet.map((kit, i) =>
      i === index ? { ...kit, [field]: value } : kit
    );
    setKitWithKitSet(updatedKits);

    if (field === "sales_value" || field === "no_of_cases") {
      const salesValue =
        field === "sales_value" ? value : kitWithKitSet[index].sales_value;
      const noOfCases =
        field === "no_of_cases" ? value : kitWithKitSet[index].no_of_cases;
      const total = salesValue * noOfCases;
      setKitWithKitSet((prevKits) =>
        prevKits.map((kit, i) => (i === index ? { ...kit, total } : kit))
      );
    }
  };

  const handleRemoveKit = (index) => {
    const updatedKits = kitWithKitSet.filter((_, i) => i !== index);
    setKitWithKitSet(updatedKits);

    const updatedValidationErrors = { ...validationErrors };
    Object.keys(validationErrors).forEach((key) => {
      if (key.startsWith(`kitData.${index}.`)) {
        delete updatedValidationErrors[key];
      }
    });

    const reIndexedErrors = {};
    Object.keys(updatedValidationErrors).forEach((key) => {
      const match = key.match(/^kitData\.(\d+)\.(.+)$/);
      if (match) {
        const kitIndex = parseInt(match[1], 10);
        const fieldName = match[2];
        const newKey = `kitData.${
          kitIndex > index ? kitIndex - 1 : kitIndex
        }.${fieldName}`;
        reIndexedErrors[newKey] = updatedValidationErrors[key];
      } else {
        reIndexedErrors[key] = updatedValidationErrors[key];
      }
    });

    setValidationErrors(reIndexedErrors);
  };

  const availableKits = kitOptions.filter(
    (option) => !kitWithKitSet.some((kit) => kit.id === option.value)
  );

  return (
    <div>
      <div className="d-card">
        <div className="row">
          <div className="col-sm-12 col-12">
            <label>
              Select Kit <span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-sm-9 col-8">
            <div className="form-group mb-0">
              {isLoading ? (
                <Spinner color="primary" />
              ) : (
                <Select
                  value={selectedKit}
                  onChange={setSelectedKit}
                  options={availableKits}
                  className="small"
                />
              )}
            </div>
            {validationErrors.kitData && (
              <span className="text-danger">Please select kit name</span>
            )}
          </div>
          <div className="col-sm-3 col-4">
            <Button className="btn btn-block addnow1" onClick={handleAddKit}>
              <i className="fa fa-plus"></i>{" "}
              <span className="d-none d-md-block">Add</span>
            </Button>
          </div>
        </div>

        {kitWithKitSet.length > 0 && (
          <div className="mt-4">
            <h6>Expected Sales</h6>
          <div className="table-responsive">
            <table className="table mb-0">
              <tbody>
                {kitWithKitSet.map((kit, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <span className="d-block mt-4">{kit.name}</span>
                        {kit?.kitDocument?.length > 0 && (
                          <span className="d-block mt-2">
                            <a
                              id={`UncontrolledTooltipPdf1${index}`}
                              target="_blank"
                              rel="noreferrer"
                              className="download-info badge1 badge-info1"
                              href={`${process.env.REACT_APP_APIURL}assets/uploads/kitDocuments/${kit.kitDocument[0].name}`}
                            >
                              Download Tray List
                            </a>
                          </span>
                        )}
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                        <div className="d-flex flex-column align-items-center">
                          <label
                            className="small"
                            style={{ height: "3rem", textAlign: "center" }}
                          >
                          Number of Sets
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            type="text"
                            min="0"
                            className="form-control"
                            value={kit.stock_level}
                            onChange={(e) =>
                              handleKitChange(
                                index,
                                "stock_level",
                                e.target.value
                              )
                            }
                            style={{ width: "85px" }}
                          />
                          {validationErrors[`kitData.${index}.stock_level`] && (
                            <span
                              style={{ width: "92px" }}
                              className="text-danger d-block"
                            >
                              The number of sets field is required.
                            </span>
                          )}
                        </div>
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <div className="d-flex flex-column align-items-center">
                          <label style={{ width: "110px" }} className="small">
                            Number of Cases
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            className="small"
                            style={{ height: "1.8rem", textAlign: "center" }}
                          >
                            (Per Kit)
                          </div>
                          <Input
                            type="number"
                            min="0"
                            className="form-control"
                            value={kit.no_of_cases}
                            onChange={(e) =>
                              handleKitChange(
                                index,
                                "no_of_cases",
                                e.target.value
                              )
                            }
                            style={{ width: "96px" }}
                          />
                          {validationErrors[`kitData.${index}.no_of_cases`] && (
                            <span
                              style={{ width: "94px" }}
                              className="text-danger d-block"
                            >
                              The number of cases field is required.
                            </span>
                          )}
                        </div>
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                        <div className="d-flex flex-column align-items-center">
                          <label
                            className="small"
                            style={{ height: "3rem", textAlign: "center" }}
                          >
                            Sales Value
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            type="text"
                            min="0"
                            className="form-control"
                            value={kit.sales_value}
                            onChange={(e) =>
                              handleKitChange(
                                index,
                                "sales_value",
                                e.target.value
                              )
                            }
                            style={{ width: "85px" }}
                          />
                          {validationErrors[`kitData.${index}.sales_value`] && (
                            <span
                              style={{ width: "92px" }}
                              className="text-danger d-block"
                            >
                              The sales value field is required.
                            </span>
                          )}
                        </div>
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                        <div className="d-flex flex-column align-items-center">
                          <label
                            className="small"
                            style={{ height: "3rem", textAlign: "center" }}
                          >
                            Total
                            </label>
                          <label
                              style={{
                                height: "3rem",
                                textAlign: "center",
                                paddingTop: "8px",
                              }}
                            >
                              <strong>{kit.total}</strong>
                            </label>
                          {validationErrors[`kitData.${index}.total`] && (
                            <span
                              style={{ width: "92px" }}
                              className="text-danger d-block"
                            >
                              The total field is required.
                            </span>
                          )}
                        </div>
                      </td>

                      <td
                          className="text-right"
                          style={{
                            verticalAlign: "bottom",
                          }}
                        >
                          <div className="trash">
                            <i
                              onClick={() => handleRemoveKit(index)}
                              className="fa fa-trash"
                              style={{ marginBottom: "25px" }}
                            ></i>
                          </div>
                        </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default MyKitPage;
