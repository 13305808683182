export const KitDetails = ({ consignmentKits }) => (
  <div className="d-card mt-4">
    <label style={{fontSize:"17px"}}><strong>Kit Details - </strong>Expected Sales</label>
    <div className="mt-4">
                         
      {consignmentKits?.map((kit, index) => {
        console.log("ayushi", kit);
        return(
        <div key={index} style={{ marginBottom: "20px" }}>
         <label style={{fontSize:"18px"}}><strong>{kit.kits[0].name}</strong></label>
          <div className="row">
            {(kit.sales_value > 0 || kit.no_of_cases > 0) && (
              <div style={{ flex: 1, marginRight: "20px", width:"80%" }}>
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style={{ width: "25%", textAlign: "center" }}>
                        Number of Sets
                      </th>
                      <th style={{ width: "32%", textAlign: "center" }}>
                        Number of Cases (Per Kit)
                      </th>
                      <th
                        style={{
                          width: "20%",
                          verticalAlign: "top",
                          textAlign: "center",
                        }}
                      >
                        Sales Value
                      </th>
                      <th
                        style={{
                          verticalAlign: "top",
                          width: "20%",
                          textAlign: "center",
                        }}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>{kit.stock_level}</td>
                      <td style={{ textAlign: "center" }}>{kit.no_of_cases}</td>
                      <td style={{ textAlign: "center" }}>{kit.sales_value}</td>
                      <td style={{ textAlign: "center" }}>{kit.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      );})}
    </div>
  </div>
);
