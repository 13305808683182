import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../../../GlobalComponents/Footer";
import Navbar from "../../../Navbar";
import DashboardSidebar from "../../NewDashboardSidebar";
import dateFormat from "dateformat";
import moment from "moment";
import { bookingRequestService } from "../../../../../../services/bookingRequest.services";

import AddDispatch from "./Model/AddDispatch";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { debounce } from "lodash";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { downloadFile } from "./DownloadFile";

var pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

const searchFromDate = new Date(pastYearDate);
const searchToDate = new Date(yesterdayDate);

const PastBookingDispatch = () => {
	const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
	const [search, setSearch] = useState("");
	const [
		filterDeliveryToDate,
		setfilterDeliveryToDate,
		filterDeliveryToDateRef,
	] = useState(yesterdayDate);
	const [
		filterDeliveryFromDate,
		setfilterDeliveryFromDate,
		filterDeliveryFromDateRef,
	] = useState(pastYearDate);
	const [activePage, setActivePage] = useState(1);
	const [totalItemsCount, setTotalItemsCount] = useState("");
	const [limit, setLimit] = useState(50);
	const [loading, setLoading] = useState(false);

	const [dayActive, setDateActive] = useState("past");
	const [bookingList, setBookingList] = useState([]);
	const [dispatchModal, setDispatchModal] = useState(false);
	const [bookingId, setBookingId] = useState(0);
	// const [error,setError] = useState({});
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

	const [searchDeliveryToDate, setSearchDeliveryToDate] =
		useState(searchToDate);
	const [searchDeliveryFromDate, setSearchDeliveryFromDate] =
		useState(searchFromDate);
	const [listRefresh, setListRefresh] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const handleSearch = (val) => {
		setSearch(val.target.value);
		setActivePage(1);
		// Perform the search or API call with searchTerm
		getList(
			filterDeliveryFromDateRef.current,
			filterDeliveryToDateRef.current,
			val.target.value,
			1
		);
		//debouncedSearch(val);
	};

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		if (pageNumber !== activePage) {
			setLoading(true);
		}
	};

	const handlePageStates = (activePage, totalItemsCount, limit) => {
		if (totalItemsCount === undefined) {
			totalItemsCount = 0;
		}
		setActivePage(activePage);
		setTotalItemsCount(totalItemsCount);
		setLimit(limit);
	};

	const getList = async (
		deliveryDate,
		toDeliveryDate,
		searchTerm,
		currentPage
	) => {
		setBookingList([]);
		setLoading(true);
		try {
			const res = await bookingRequestService.getBookingDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["booked", "dispatch"],
				deliveryDateFiter: deliveryDate,
				toDeliveryDateFiter: toDeliveryDate,
				search: searchTerm != undefined ? searchTerm : search,
				page: currentPage != undefined ? currentPage : activePage,
				limit: limit,
			});
			setBookingList(res.data);
			setTotalItemsCount(res.total_count);
			setLoading(false);
			setListRefresh(false);
		} catch (error) {
			console.error("Error: ", error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setDateActive("past");
		setUser(JSON.parse(localStorage.getItem("user")));
	}, [bookingList]);

	useEffect(() => {
		handlePageStates(activePage, totalItemsCount, limit);
	}, [activePage, totalItemsCount, limit]);

	useEffect(() => {
		getList(filterDeliveryFromDate, filterDeliveryToDate);
	}, [activePage, dayActive]);

	useEffect(() => {
		if (listRefresh) {
			getList(filterDeliveryFromDate, filterDeliveryToDate);
		}
	}, [listRefresh]);

	const dispatchModalOpen = (id) => {
		setDispatchModal(true);
		setBookingId(id);
	};

	const dispatchModalClose = (flag) => {
		if (flag) {
			getList(filterDeliveryFromDate, filterDeliveryToDate);
		}
		setDispatchModal(false);
	};

	const handleSearchDeliveryFromDate = (val) => {
		setActivePage(1);
		setSearchDeliveryFromDate(val != null ? new Date(val) : "");
		setfilterDeliveryFromDate(
			val != null ? moment(val).format("YYYY-MM-DD") : ""
		);
		setListRefresh(true);
	};

	const handleSearchDeliveryToDate = (val) => {
		setActivePage(1);
		setSearchDeliveryToDate(val != null ? new Date(val) : "");
		setfilterDeliveryToDate(
			val != null ? moment(val).format("YYYY-MM-DD") : ""
		);
		setListRefresh(true);
	};

	const debouncedSearch = useMemo(() => {
		return debounce(handleSearch, 1500);
	}, [search]);

	const getXlsxData = async (type, item) => {
		let dateInfo = "";
		let dayActiveString =
			dayActive === "past" ? "other booking " : dayActive + " ";

		if (dayActive === "past") {
			let dateTo = "";
			let dateFrom = "";

			if (filterDeliveryFromDate != "") {
				dateFrom =
					"from " +
					moment(filterDeliveryFromDate).format("DD/MM/YYYY") +
					" ";
			} else {
				dateFrom = "";
			}

			if (filterDeliveryToDate != "") {
				dateTo =
					"to " +
					moment(filterDeliveryToDate).format("DD/MM/YYYY") +
					" ";
			} else {
				dateTo = "";
			}

			dateInfo = dateFrom + dateTo;
		} else {
			dateInfo = moment(filterDeliveryFromDate).format("DD/MM/YYYY");
		}

		if (type === "pdf" && item?.booking_id) {
			downloadFile.handleGeneratePdf(
				item,
				[],
				dayActiveString + dateInfo
			);
		} else {
			setIsLoading(true);
			try {
				const res =
					await bookingRequestService.getBookingDispatchExport({
						bookedByFilter: "",
						hospitalFilter: "",
						type: ["consignment"],
						status: ["booked", "dispatch"],
						deliveryDateFiter: filterDeliveryFromDate,
						toDeliveryDateFiter: filterDeliveryToDate,
						search: search,
						page: activePage,
						limit: limit,
					});
				if (res.status === 200) {
					if (type === "xlsx") {
						downloadFile.handleExportClick(
							res?.data,
							dayActiveString + dateInfo
						);
					} else if (type === "pdf") {
						downloadFile.handleGeneratePdf(
							"",
							res?.data,
							dayActiveString + dateInfo
						);
					}
				}
			} catch (error) {
				console.error("Error: ", error);
				setIsLoading(false);
			} finally {
				setIsLoading(false);
			}
		}
	};

	if (!user || user.data.user_role !== "admin") {
		return <Redirect to="/" />;
	}

	return (
		<div>
			<Navbar activePage="dashboard" />
			<main className="offset">
				<div className="container-fluid">
					<div className="row pt-4">
						<div className="col-lg-2 side-filters">
							<span className="side-heading">Dashboard</span>
							<DashboardSidebar
								activePage="consignment-dispatch"
								dispatchCount={totalItemsCount}
							/>
						</div>

						<div className="col-lg-10">
							<div className="ground">
								<div className="row">
									<div className="col-lg-4 col-md-12">
										<div className="sub-menu mb-0 mb-sm-3">
											<div
												className="btn-group"
												role="group"
												aria-label="Basic example"
											>
												<Link
													to={
														"/admin/dashboard/consignment-dispatch"
													}
												>
													<button
														type="button"
														className={
															"btn btn-secondary " +
															(dayActive ==
															"today"
																? "active "
																: loading
																? "btn-disabled"
																: "")
														}
													>
														{"Today"}
													</button>
												</Link>

												<Link
													to={
														"/admin/dashboard/consignment-dispatch/tomorrow"
													}
												>
													<button
														type="button"
														className={
															"btn btn-secondary mx-1 " +
															(dayActive ==
															"tomorrow"
																? "active "
																: loading
																? "btn-disabled"
																: "")
														}
													>
														{"Tomorrow"}
													</button>
												</Link>

												<Link
													to={
														"/admin/dashboard/consignment-dispatch/past"
													}
												>
													<button
														type="button"
														className={
															"btn btn-secondary " +
															(dayActive == "past"
																? "active "
																: loading
																? "btn-disabled"
																: "")
														}
													>
														{"Other Bookings"}
													</button>
												</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-sm-6">
										<div className="form-search my-3 my-sm-0">
											<Input
												className="form-control mr-sm-2"
												type="text"
												placeholder="Search.."
												onChange={debouncedSearch}
											/>
										</div>
									</div>

									<div
										className={
											dayActive === "past"
												? "col-lg-4 col-sm-6"
												: "col-lg-4 col-sm-6 text-right"
										}
									>
										<div
											className={
												dayActive === "past"
													? "label_adjust lable-field-position"
													: ""
											}
										>
											{dayActive === "past" ? (
												<label className="small mb-0">
													Delivery Date{" "}
												</label>
											) : (
												""
											)}

											{dayActive === "past" ? (
												<div className="row no-gutters mb-2">
													<div className="col-6">
														<DatePicker
															dateFormat="dd/MM/yyyy"
															showClearButton={
																false
															}
															placeholderText="From"
															selected={
																searchDeliveryFromDate
															}
															onChange={(e) =>
																handleSearchDeliveryFromDate(
																	e
																)
															}
															//onKeyDown={handleclearFromDate}
															className="form-control"
															disabled={
																!loading
																	? false
																	: true
															}
															customInput={
																<MaskedInput
																	pipe={
																		autoCorrectedDatePipe
																	}
																	mask={[
																		/\d/,
																		/\d/,
																		"/",
																		/\d/,
																		/\d/,
																		"/",
																		/\d/,
																		/\d/,
																		/\d/,
																		/\d/,
																	]}
																	keepCharPositions={
																		true
																	}
																	guide={true}
																	showMask={
																		false
																	}
																/>
															}
														/>
													</div>

													<div className="col-6">
														<DatePicker
															dateFormat="dd/MM/yyyy"
															showClearButton={
																false
															}
															placeholderText="To"
															selected={
																searchDeliveryToDate
															}
															onChange={(e) =>
																handleSearchDeliveryToDate(
																	e
																)
															}
															//onKeyDown={handleclearToDate}
															className="form-control"
															disabled={
																!loading
																	? false
																	: true
															}
															customInput={
																<MaskedInput
																	pipe={
																		autoCorrectedDatePipe
																	}
																	mask={[
																		/\d/,
																		/\d/,
																		"/",
																		/\d/,
																		/\d/,
																		"/",
																		/\d/,
																		/\d/,
																		/\d/,
																		/\d/,
																	]}
																	keepCharPositions={
																		true
																	}
																	guide={true}
																	showMask={
																		false
																	}
																/>
															}
														/>
													</div>
												</div>
											) : (
												""
											)}

											<div style={{ float: "right" }}>
												<button
													type="button"
													className={
														!loading && !isLoading
															? "btn btn-default mr-md-2 my-1"
															: "btn btn-default mr-md-2 my-1 dropdown-disabled"
													}
													onClick={() =>
														!loading &&
														!isLoading &&
														bookingList?.length
															? getXlsxData("pdf")
															: ""
													}
												>
													{" "}
													Generate Dispatch Pdf
												</button>

												<button
													type="button"
													className={
														!loading && !isLoading
															? "btn btn-default add_option"
															: "btn btn-default add_option dropdown-disabled"
													}
													onClick={() =>
														!loading &&
														!isLoading &&
														bookingList?.length
															? getXlsxData(
																	"xlsx"
															  )
															: ""
													}
												>
													{" "}
													Print{" "}
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="table-scroll mt-4">
									<Table className="table">
										<thead>
											<tr>
												<th
													scope="col"
													style={{
														minWidth: "200px",
													}}
												>
													Booking #
												</th>
												<th scope="col">Reps name </th>
												<th scope="col">
													Hospital Name{" "}
												</th>
												<th scope="col">SET</th>
												<th scope="col">Date</th>
												<th
													scope="col"
													className="text-right"
												></th>
												<th
													scope="col"
													className="text-right"
												></th>
											</tr>
										</thead>
										<tbody>
											{bookingList &&
												bookingList.map((item) => (
													<tr key={item._id}>
														<td
															width="200"
															className="align-top"
														>
															<Link
																to={
																	"/admin/booking/details/" +
																	item._id
																}
															>
																<span>
																	<strong>
																		{" "}
																		{
																			item.booking_id
																		}{" "}
																	</strong>
																</span>
															</Link>
														</td>
														<td className="align-top">
															<div className="">
																{item
																	.assigned_rep
																	.first_name +
																	" " +
																	item
																		.assigned_rep
																		.last_name}
															</div>
														</td>
														<td className="align-top">
															<div className="">
																{
																	item
																		.hospitalId
																		.name
																}
															</div>
														</td>
														<td className="align-top">
															<small>
																{item.bookingKitSetId &&
																	item.bookingKitSetId.map(
																		(
																			kset,
																			ind
																		) => (
																			<React.Fragment
																				key={
																					ind
																				}
																			>
																				{/* <div><a href="javascript:;" onClick={()=>handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
																				<div>
																					<span>
																						{
																							kset
																								.setId[0]
																								.set_name
																						}{" "}
																					</span>
																				</div>
																			</React.Fragment>
																		)
																	)}
															</small>
														</td>
														<td className="align-top">
															{dateFormat(
																item.delivery_date,
																"ddd,mmm dS"
															)}{" "}
															{
																item.delivery_date_slot
															}
														</td>

														<td className="text-right align-top">
															<div className="d-flex">
																{" "}
																{item.status ==
																	"booked" && (
																	<React.Fragment>
																		<a
																			href="javascript:;"
																			onClick={() =>
																				dispatchModalOpen(
																					item._id
																				)
																			}
																			className="btn btn-primary ml-sm-2 btn-sm mr-2"
																		>
																			Dispatch
																		</a>

																		<div
																			className="btn btn-default ml-sm-2 btn-sm mr-2"
																			onClick={() =>
																				bookingList?.length
																					? getXlsxData(
																							"pdf",
																							item
																					  )
																					: ""
																			}
																		>
																			{" "}
																			Generate
																			Pdf
																		</div>
																	</React.Fragment>
																)}
																{item.status ==
																	"dispatch" && (
																	<React.Fragment>
																		<div>
																			<span className="capsule green-dim mb-2 mr-2">
																				Dispatched
																			</span>
																		</div>

																		<div
																			className="mr-2 sm-2 ml-2 btn btn-default btn-sm"
																			onClick={() =>
																				bookingList?.length
																					? getXlsxData(
																							"pdf",
																							item
																					  )
																					: ""
																			}
																		>
																			{" "}
																			<span className="generatePdf">
																				Generate
																				Pdf
																			</span>
																		</div>
																	</React.Fragment>
																)}
															</div>
														</td>
													</tr>
												))}

											{bookingList &&
												bookingList.length == 0 &&
												loading == true && (
													<div className="loading-state">
														<div className="loading"></div>
													</div>
												)}

											{bookingList &&
												bookingList.length == 0 &&
												loading == false && (
													<tr className="text-center">
														<td colSpan="7">
															No Record Found
														</td>
													</tr>
												)}
										</tbody>
									</Table>
								</div>
							</div>

							{bookingList && bookingList.length > 0 && (
								<div>
									<Pagination
										activePage={activePage}
										itemsCountPerPage={limit}
										totalItemsCount={totalItemsCount}
										pageRangeDisplayed={5}
										onChange={handlePageChange.bind(this)}
										itemClass="page-item"
										linkClass="page-link"
										innerClass="pagination justify-content-center mb-5"
										activeLinkClass="active"
										nextPageText="Next"
										prevPageText="Prev"
									/>
									<br className="d-block d-sm-none" />
									<br className="d-block d-sm-none" />
								</div>
							)}

							<AddDispatch
								bookingId={bookingId}
								dispatchModal={dispatchModal}
								dispatchModalClose={(flag) =>
									dispatchModalClose(flag)
								}
							/>

							{/* Dispatch Modal */}
						</div>
					</div>
					{/*<!--/row-->*/}
				</div>
			</main>

			<Footer />
		</div>
	);
};
export default PastBookingDispatch;
