import React, { useState } from "react";
import { operationService } from "../../../../../services/operation.services";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from "reactstrap";

const Index = ({ id, handleDelete, deleteModalProps, setDeleteModalProps }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    
    const onDelete = () => {
        setLoading(true);
        operationService
            .delete({ id: id })
            .then(() => {
                handleDelete(id);
                console.log("Item deleted successfully");
                setLoading(false);
                setDeleteModalProps(false);
            })
            .catch((errors) => {
                setError(errors);
                setLoading(false);
                console.error("Error deleting item:", error);
            });
    };

    const deleteModalClose = () => {
        setError("");
        setDeleteModalProps(false);
    };

    return (
        <div>
            {/* <a className="dropdown-item" onClick={onDelete} href="javascript:;">
                Delete
            </a> */}
            <Modal
                size="md"
                backdrop="static"
                isOpen={deleteModalProps}
                toggle={() => deleteModalClose()}
            >
                <ModalHeader className="" toggle={() => deleteModalClose()}>
                    Delete Operation
                </ModalHeader>
                <ModalBody>
                    <div className="my-2">
                        Please confirm if you want to delete this operation?
                    </div>
                    { error!="" ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}

                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button
                            onClick={() => deleteModalClose()}
                            className="btn btn-secondary mr-2"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        {loading == false && (
                            <button
                                className="btn btn-primary"
                                onClick={() => onDelete()}
                            >
                                Ok
                            </button>
                        )}
                        {loading == true && (
                            <button
                                className="btn btn-primary loader-icon"
                                disabled
                            >
                                <Spinner className="img-svg"></Spinner>
                            </button>
                        )}
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Index;
