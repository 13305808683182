import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Redirect, Link } from "react-router-dom";
import { repsBookingService } from "../../../../services";
import dateFormat from "dateformat";
import CancelModal from "./Modal/cancelModal";
import NavbarComponent from "./Navbar/Index";

const OpenBooking = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [bookings, setBookings] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelId, setCancelId] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchParams, setSearchParams] = useState({});

  const getList = async (searchParams, pageNumber = 1) => {
    try {
      setIsLoading(true);
      const res = await repsBookingService.getConsignmentRequest({
        ...searchParams,
        page: pageNumber,
        limit:10
      });
      const newBookings = res.data;

      setBookings((prevBookings) => {
        return page === 1 ? newBookings : [...prevBookings, ...newBookings];
      });
      setHasMore(newBookings.length > 0);
      setIsLoading(false);
      if (res.status) {
        setTotalItemsCount(res?.total_count);
      }
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading ||
      !hasMore
    )
      return;

    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      getList(searchParams, page);
    }
  }, [searchParams, page]);

  useEffect(() => {
    console.log("Search Params updated: ", searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (page > 1) {
      getList(searchParams, page);
    }
  }, [page]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore]);

  const cancelModalOpen = (id) => {
    setCancelModal(true);
    setCancelId(id);
  };

  const cancelModalClose = (refresh) => {
    setCancelModal(false);
    if (refresh) {
      setBookings([]);
      setPage(1);
      setHasMore(true);
      getList(searchParams, 1);
    }
  };

  if (!user) {
    return <Redirect to="/" />;
  }
  if (user.data.user_role !== "reps") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid">
        <NavbarComponent setSearchParams={setSearchParams} />
        <div
          className="small mr-3 badge badge-info"
          style={{
            fontWeight: 500,
            background: "#5d75e9",
            marginBottom: 0,
          }}
        >
          {totalItemsCount} Records found
        </div>
        <div id="table-scroll" className="table-scroll mt-2">
          <table id="main-table" className="main-table">
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Hospital Name
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Contact Person
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Surgeon Name
                </th>
                <th scope="col">Kits</th>
                <th scope="col">Delivery Date</th>
                <th scope="col" className="text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking) => (
                  <tr key={booking.consignments._id}>
                    <td data-th="Hospital Name">
                      <div>
                        <Link
                          to={
                            "/reps/consignment-request/details/" +
                            booking.consignments._id
                          }
                        >
                          <span>
                            <strong>
                              <span
                                style={{ display: "block" }}
                                className="theme-text"
                              >
                                {booking.consignments.consignment_id}
                              </span>
                              {booking.hospitalData[0]?.name}
                            </strong>
                          </span>
                          <br />
                          <small>
                            Booked on{" "}
                            {dateFormat(
                              booking.consignments.createdAt,
                              "ddd,mmm dS"
                            )}
                          </small>
                        </Link>
                      </div>
                    </td>
                    <td data-th="Contact Person">
                      <div className="info-left">
                        <strong>
                          {booking.hospitalData[0]?.contact_person_name}{" "}
                        </strong>
                        <br />
                        <small>
                          Location: {booking.consignments.hospital_address}{" "}
                        </small>
                        <br />
                        <small>Email: {booking.hospitalData[0]?.email}</small>
                        <br />
                        <small>
                          Phone:{" "}
                          {booking.hospitalData[0]?.contact_person_phone_number}
                        </small>
                        <br />
                        <small>
                          Department:{booking.consignments.hospital_department}
                        </small>
                      </div>
                    </td>
                    <td data-th="Surgeon Name">
                      <div className="info-left">
                        {booking.surgeonData.length > 0
                          ? booking.surgeonData.map((surgeon) => (
                              <div key={surgeon._id}>
                                <strong>
                                  {surgeon.title} {surgeon.first_name}{" "}
                                  {surgeon.last_name}
                                </strong>
                                <br />
                              </div>
                            ))
                          : "Not Assigned"}
                      </div>
                    </td>
                    <td data-th="Kits">
                      <small>
                        {booking.consignmentKits.length > 0
                          ? booking.consignmentKits.map((kit) => (
                              <a
                                key={kit._id}
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#kitDetails"
                              >
                                {kit.kits[0]?.name} <br />
                              </a>
                            ))
                          : "None"}
                      </small>
                    </td>
                    <td data-th="Delivery Date">
                      {booking?.consignments?.delivery_date
                        ? dateFormat(
                            booking?.consignments?.delivery_date,
                            "ddd, mmm dS"
                          )
                        : ""}
                    </td>

                    <td className="text-right">
                      <div className="action-area dropdown">
                        <div className="mb-2">
                          <span
                            className={
                              "capsule " +
                              (booking.consignments.status == "booked" ||
                              booking.consignments.status === "close" ||
                              booking.consignments.status == "dispatch" ||
                              booking.consignments.status === "partial-dispatch"
                                ? "green-dim "
                                : "red-dim ") +
                              " mb-1"
                            }
                          >
                            {booking.consignments.status === "booked"
                              ? "Reviewed and Accepted"
                              : booking.consignments.status === "pending"
                              ? "Not Reviewed"
                              : booking.consignments.status == "dispatch"
                              ? "Dispatched"
                              : booking.consignments.status ===
                                "partial-dispatch"
                              ? "Partially Dispatched"
                              : "booking.consignments.status"}
                          </span>
                        </div>

                        {(booking.consignments.status === "pending" ||
                          booking.consignments.status === "booked") && (
                          <div className="mb-2">
                            <span>
                              <Link
                                className="btn btn-sm1 btn-outline-primary"
                                to={`/reps/consignment-request/edit/${booking.consignments._id}`}
                              >
                                Edit
                              </Link>
                            </span>
                          </div>
                        )}

                        {booking.consignments.status != "booked" &&
                        booking.consignments.status != "partial-dispatch" &&
                        booking.consignments.status != "dispatch" ? (
                          <div className="mb-2">
                            <span>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  cancelModalOpen(booking.consignments._id)
                                }
                                className="btn btn-outline-primary btn-sm1"
                              >
                                Cancel
                              </a>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No bookings available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isLoading && (
            <div className="text-center mt-3">
              <span>Loading...</span>
            </div>
          )}
          <CancelModal
            cancelId={cancelId}
            cancelModal={cancelModal}
            cancelModalClose={(refresh) => cancelModalClose(refresh)}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenBooking;
