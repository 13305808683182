import { hospitalConstants } from "../constants";

const initialState =  { loading: null, lists: null, } || {};

export function hospitals(state = initialState, action) {
    switch (action.type) {
    case hospitalConstants.GETALLHOSPITALS_REQUEST:
        return {
            loading: true
        };
    case hospitalConstants.GETALLHOSPITALS_SUCCESS:
        return {
            loading: false,
            lists: action.lists.data,
            activePage: action.lists.page,
            totalItemsCount: action.lists.total_count,
            limit: action.lists.page_count
        };
    case hospitalConstants.GETALLHOSPITALS_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    case hospitalConstants.HOSPITALADD_REQUEST:
        return {
            loading: true,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.HOSPITALADD_SUCCESS:
        return {
            loading: false,
            lists: action.data,
            modal:false,
            refreshListHospital:true
        };
    case hospitalConstants.HOSPITALADD_FAILURE:
        return { 
            loading: false,
            error: action.error,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.HOSPITALEDIT_REQUEST:
        return {
            loading: true,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.HOSPITALEDIT_SUCCESS:
        return {
            loading: false,
            lists: state.lists, 
            list:action.list.data,
            editModal:true,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.HOSPITALEDIT_FAILURE:
        return { 
            loading: false,
            error: action.error,
            lists: state.lists,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.HOSPITALUPDATE_REQUEST:
        return {
            loading: true,
            lists: state.lists,
            kit: state.kit,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.HOSPITALUPDATE_SUCCESS:
        return {
            loading: false,
            lists: state.lists, 
            editModal:false,
            refreshListHospital:true
        };
    case hospitalConstants.HOSPITALUPDATE_FAILURE:
        return { 
            loading: false,
            error: action.error,
            lists: state.lists,
            kit: state.kit,
            activePage: state.activePage,
            totalItemsCount: state.totalItemsCount,
            limit: state.limit
        };
    case hospitalConstants.DELETE_REQUEST:
        // add 'deleting:true' property to user being deleted
        return {
            editModal:false,
            modal:false,
            loading: true,
            lists: state.lists
        };
    case hospitalConstants.DELETE_SUCCESS:
        // remove deleted user from state
        return {
            deleteModal:false,
            refreshListHospital:true,
            editModal:false,
            modal:false,
            loading: false,
        };
    case hospitalConstants.DELETE_FAILURE:
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        return {
            ...state,
            loading: false,
            lists: state.lists,
            error: action.error
            
        };
    default:
        return state;
    }
}