import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const ReadMoreContent = (props) => {
    return(
        <Modal size="md" backdrop="static" isOpen={props.readMoreModel} toggle={() => props.handleReadMoreClose()}>
            <ModalHeader className="" toggle={() => props.handleReadMoreClose()}>
                Reason Of Cancellation
            </ModalHeader>
            <ModalBody>
                <div className="my-2">{props.readMoreContent}</div>
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button className="btn btn-secondary ml-2" onClick={() => props.handleReadMoreClose()}>Close</button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default ReadMoreContent;