import {returnBookingConstants } from "../constants";

const initialState =  { loading: null, lists: null, } || {};

export function returnBookings(state = initialState, action) {
    switch (action.type) {
    case returnBookingConstants.GETALLRETURNBOOKINGS_REQUEST:
        return {
            loading: true
        };
    case returnBookingConstants.GETALLRETURNBOOKINGS_SUCCESS:
        return {
            loading: false,
            returnList: action.returnList.data,
            activePage: action.returnList.page,
            totalItemsCount: action.returnList.total_count,
            limit: action.returnList.page_count
        };
    case returnBookingConstants.GETALLRETURNBOOKINGS_FAILURE:
        return { 
            error: action.error
        };
    case returnBookingConstants.COUNTRETURNBOOKINGS_REQUEST:
        return {
            loading: true,
        };
    case returnBookingConstants.COUNTRETURNBOOKINGS_SUCCESS:
        return {
            loading: false,
            returnCount:action.count.data,
        };
    case returnBookingConstants.COUNTRETURNBOOKINGS_FAILURE:
        return { 
            error: action.error,
        };
  
    default:
        return state;
    }
}