import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import { surgeonActions, bookingActions } from "../../../../actions";
import { surgeonService } from "../../../../services";

import { ReactComponent  as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import Select from "react-select";

const titleOption=[
    {value:"Mr",label:"Mr"},
    {value:"Mrs",label:"Mrs"},
    {value:"Ms",label:"Ms"},
    {value:"Dr",label:"Dr"}
];

const levelOption=[
    {value:"Consultant",label:"Consultant"},
    {value:"Fellow",label:"Fellow"},
    {value:"Registrar",label:"Registrar"},
    {value:"Dr",label:"Dr"},
    {value:"Other",label:"Other"}
]; 

const specialityOption=[
    {value:"Foot and Ankle",label:"Foot and Ankle"},
    {value:"Hand and Wrist",label:"Hand and Wrist"},
    {value:"Knee",label:"Knee"},
    {value:"Shoulder",label:"Shoulder"},
    {value:"Trauma",label:"Trauma"},
    {value:"Other",label:"Other"}
];

class Add extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            addNewModal: false,
            name: "",
            id:"",
            surgeonInfo: {
                id:"",
                title:"",
                first_name:"",
                last_name:"",
                level:"",
                area_of_speciality:"",
                phone:"",
                mobile:"",
                work_email:"",
                personal_email:"",
                hospitalData:[],
                notes:""
            },
            selectedTitle:[],
            selectedLevel:[],
            selectedSpeciality:[],
            selectedHospital:[],
            hospitalOption:[],
            submitModalFalg:0,
            suggestionSurgeonList:[],
            suggestionsActive:false
        };

        this.addNewModalOpen = this.addNewModalOpen.bind(this);
        this.addNewModalClose = this.addNewModalClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setSelectedOptionTitle = this.setSelectedOptionTitle.bind(this);
        this.setSelectedOptionLevel = this.setSelectedOptionLevel.bind(this);
        this.setSelectedOptionSpeciality = this.setSelectedOptionSpeciality.bind(this);
        this.setSelectedOptionHospital = this.setSelectedOptionHospital.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);


    }

    setSelectedOptionTitle(e){
        var selectedTitle = [{value:e.value, label:e.label}];
        const { surgeonInfo } = this.state;
        surgeonInfo["title"]=e.value;
        this.setState({
            selectedTitle: selectedTitle,
            surgeonInfo:surgeonInfo
        });
    }

    setSelectedOptionLevel(e){
        var selectedLevel = [{value:e.value, label:e.label}];
        const { surgeonInfo } = this.state;
        surgeonInfo["level"]=e.value;
        this.setState({
            selectedLevel: selectedLevel,
            surgeonInfo:surgeonInfo
        });
    }
    
    setSelectedOptionSpeciality(e){
        var selectedSpeciality = [{value:e.value, label:e.label}];
        const { surgeonInfo } = this.state;
        surgeonInfo["area_of_speciality"]=e.value;
        this.setState({
            selectedSpeciality: selectedSpeciality,
            surgeonInfo:surgeonInfo
        });
    }

    setSelectedOptionHospital = selectedHospital => {
        var hospitalData = [];
        selectedHospital.map((item) => {
            hospitalData.push({id: item.value});
        });

        this.setState({ 
            selectedHospital: selectedHospital,
            surgeonInfo: {
                ...this.state.surgeonInfo,
                hospitalData: hospitalData
            }
        });

       
    };

    showHospitalsList(hospitalsList) {
        var tempHospitals = [];
        hospitalsList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempHospitals.push(option);
        });
        this.setState({
            hospitalOption: tempHospitals,
        });
    }




 
    addNewModalOpen() {
        this.setState({ addNewModal: !this.state.addNewModal});
    }

    addNewModalClose() {
        

        this.setState({ 
            addNewModal: false,
            submitModalFalg:0,
            surgeonInfo: {
                id:"",
                title:"",
                first_name:"",
                last_name:"",
                level:"",
                area_of_speciality:"",
                phone:"",
                mobile:"",
                work_email:"",
                personal_email:"",
                hospitalData:[],
                notes:""
            },
            selectedTitle:[],
            selectedLevel:[],
            selectedSpeciality:[],
            selectedHospital:[],
            suggestionSurgeonList:[],
            suggestionsActive:false
        });
        this.props.addNewModalClose();
    }

    nameHandler(val) {
        this.setState({
            name:val
        });
    }

    surgeonInfoHandler = (name, value) => {
        const { surgeonInfo } = this.state;
        surgeonInfo[name] = value;
        this.setState({
            surgeonInfo
        },()=>{ 
            if(name=="first_name"){
                this.getAutoSearch();
            }
        });
    };


    handleSubmit() {
        const { surgeonInfo } = this.state;
        this.setState({
            submitModalFalg:1
        });
        this.props.dispatch(surgeonActions.add(surgeonInfo));
    }

    getAutoSearch() {
        const { surgeonInfo } = this.state;
        
        surgeonService.getSuggestionList({ search: surgeonInfo["first_name"],page:1 })
            .then(response => {
                this.handleSuggestionSurgeonList(response.data);
            });

    }

    handleSuggestionSurgeonList(data){
        this.setState({
            suggestionSurgeonList:data,
            suggestionsActive:true
        });
    }

    handleOnSurgeonSelect(item) {

        const { surgeonInfo } = this.state;
        surgeonInfo["first_name"]=item.first_name;
        this.setState({
            suggestionSurgeonList:[],
            suggestionsActive:false,
            surgeonInfo
        });
    }

    handleNotesChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            surgeonInfo: {
                ...prevState.surgeonInfo,
                notes: value
            }
        }));
    };
    
    componentDidMount(){
        this.props.dispatch(bookingActions.getHospitalsList({search: ""}));

    }
 

  

    UNSAFE_componentWillReceiveProps(nextProps) {
        

        if(nextProps.modal==false)
        {
            this.addNewModalClose();
        }


       

        if(nextProps.hospitalsList !== undefined) {
            this.showHospitalsList(nextProps.hospitalsList);
        }
        
    }

    render() {
      
        const { error,loading } = this.props;
        const { submitModalFalg } =this.state;
   
        return (
            <div>
                <Modal size="lg" backdrop="static" isOpen={this.props.addNewModalProp} toggle={() => this.addNewModalClose()}>
                    <ModalHeader className="" toggle={() => this.addNewModalClose()}>
                        Add New Surgeon
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Title <span className="text-danger">*</span></label>
                                        <Select 
                                            value={this.state.selectedTitle}
                                            onChange={this.setSelectedOptionTitle}
                                            options={titleOption}
                                        /> 
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.title ? error.title : ""}</span> : ""}
                                      
                                    </div>

                                    <div className="form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <div className="autocomplete">
                                            <div className="wrapper remove-rounder">
                                                <div className="sc-hKMtZM">
                                                    <Input 
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.surgeonInfo.first_name}
                                                        onChange = {(e) => this.surgeonInfoHandler("first_name", e.target.value)}
                                                    />
                                                    {this.state.suggestionsActive && this.state.suggestionSurgeonList.length>0 && this.state.surgeonInfo.first_name!="" &&
                                                        <ul className="suggestions">

                                                            {this.state.suggestionSurgeonList.map((item, index) => 
                                                                <li
                                                                    key={index}
                                                                    onClick={()=>this.handleOnSurgeonSelect(item)}
                                                                >
                                                                    <i className="fa fa-search suggestionsSearch"></i>
                                                                    <span className="suggestionsText">{ item.first_name }</span>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    }
                                                </div>
                                                {submitModalFalg == 1 && error ? <span className="text-danger">{error.first_name ? error.first_name : ""}</span> : ""}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <Input 
                                            className="form-control"
                                            value={this.state.surgeonInfo.last_name}
                                            onChange = {(e) => this.surgeonInfoHandler("last_name", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.last_name ? error.last_name : ""}</span> : ""}
                                    </div> 
                                    <div className="form-group">
                                        <label>Level <span className="text-danger">*</span></label>
                                        <div className="position_up" style={{zIndex:7}}>
                                            <Select 
                                                value={this.state.selectedLevel}
                                                onChange={this.setSelectedOptionLevel}
                                                options={levelOption}
                                            /> 
                                            {submitModalFalg == 1 && error ? <span className="text-danger">{error.level ? error.level : ""}</span> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Area of Speciality <span className="text-danger">*</span></label>
                                        <Select 
                                            value={this.state.selectedSpeciality}
                                            onChange={this.setSelectedOptionSpeciality}
                                            options={specialityOption}
                                        /> 
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.area_of_speciality ? error.area_of_speciality : ""}</span> : ""}
                                    </div>
                                   
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.surgeonInfo.phone}
                                            onChange = {(e) => this.surgeonInfoHandler("phone", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.phone ? error.phone : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile</label>
                                        <Input 
                                            type="number"
                                            className="form-control"
                                            value={this.state.surgeonInfo.mobile}
                                            onChange = {(e) => this.surgeonInfoHandler("mobile", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.mobile ? error.mobile : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Work Email</label>
                                        <Input 
                                            className="form-control"
                                            value={this.state.surgeonInfo.work_email}
                                            onChange = {(e) => this.surgeonInfoHandler("work_email", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.work_email ? error.work_email : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Personal Email</label>
                                        <Input 
                                            className="form-control"
                                            value={this.state.surgeonInfo.personal_email}
                                            onChange = {(e) => this.surgeonInfoHandler("personal_email", e.target.value)}
                                        />
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.personal_email ? error.personal_email : ""}</span> : ""}
                                    </div>

                                    <div className="form-group">
                                        <label>Hospital</label>
                                        <Select 
                                            value={this.state.selectedHospital}
                                            onChange={this.setSelectedOptionHospital}
                                            options={this.state.hospitalOption}
                                            isMulti={true}
                                        /> 
                                        {submitModalFalg == 1 && error ? <span className="text-danger">{error.hospitalData ? error.hospitalData : ""}</span> : ""}
                                    </div>

                                    <div className="form-group">
                                            <label>Notes</label>
                                        <Input
                                            type="textarea"
                                            className="form-control"
                                            name=""
                                            onChange={this.handleNotesChange}
                                            placeholder="Type operation name & kit name"

                                        />
                                    {submitModalFalg == 1 && error ? <span className="text-danger">{error.notes ? error.notes : ""}</span> : ""}

                                    </div>

                                </div>
                            </div>              
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.addNewModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading==false && 
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                            }
                            {loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Add Modal */}
             
        
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { hospitalsList } = state.rootReducer.bookings;
    const { modal,error, refreshList, loading } = state.rootReducer.surgeons;

    return {
        modal,
        error,
        refreshList,
        loading,
        hospitalsList
    };
}

export default connect(mapStateToProps)(Add);