import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../../../GlobalComponents/Footer";
import Navbar from "../../../Navbar";
import DashboardSidebar from "../NewDashboardSidebar";
import dateFormat from "dateformat";
import moment from "moment";
import { bookingRequestService } from "../../../../../../services/bookingRequest.services";
import AddDispatch from "./Model/AddDispatch";
import useDebounce from "../../../../../../helpers/UseDebounce";

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { downloadFile } from "./DownloadFile";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;

let yesterday = moment(todayDate).subtract(1, "days").format("YYYY-MM-DD");
let lastYear = moment(todayDate).subtract(360, "days").format("YYYY-MM-DD");

const TodayBookingDispatch = () => {
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [bookingList, setBookingList] = useState([]);
    const [dispatchModal,setDispatchModal] = useState(false);
    const [bookingId,setBookingId] = useState(0);
    // const [error,setError] = useState({});
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));

    const [firstLoad, setFirstLoad] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
    };


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        if (pageNumber !== activePage) {
            setLoading(true);
            window.scrollTo({ top: 0 });
        }
    };


    const getList = async () => {
        setBookingList([]);
        setLoading(true);
        setFirstLoad(1);
        try {
            const res = await bookingRequestService.getUnprocesseDispatchdList({
                bookedByFilter: "",
                hospitalFilter: "",
                type: ["consignment"],
                status: ["booked", "dispatch"],
                deliveryDateFiter: lastYear,
                toDeliveryDateFiter: yesterday,
                search: search,
                page: activePage,
                limit: 50,
                isDispatched:true
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


 

    const dispatchModalOpen = (id) => {
        setDispatchModal(true);
        setBookingId(id);
    };

    const dispatchModalClose = (flag) => {
       
        setDispatchModal(false);
        setBookingId(0);
        setActivePage(1);
        if (activePage == 1 && flag) {
            getList();
        }
    };

    useDebounce(() => {
        if (firstLoad != 0)
            getList();
    }, [search], 800
    );

    useEffect(() => {
        getList();
    }, [activePage]);


    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));

    }, []);
  


    const getXlsxData = async (type,item) => {
        
        let dateInfo = "";
        let dayActiveString ="past ";
        
        dateInfo = "from "+ moment(lastYear).format("DD/MM/YYYY")+" to "+ moment(yesterday).format("DD/MM/YYYY")+" ";
        
        if(type === "pdf" && item?.booking_id){
            downloadFile.handleGeneratePdf(item,[],dayActiveString + dateInfo);
        }else{
            
            setLoading(true);
            try {
                const res = await bookingRequestService.getUnprocesseDispatchdExport({
                    bookedByFilter: "",
                    hospitalFilter: "",
                    type: ["consignment"],
                    status: ["booked", "dispatch"],
                    deliveryDateFiter: lastYear,
                    toDeliveryDateFiter: yesterday,
                    search: search,
                    page: activePage,
                    limit: 50,
                    isDispatched:true
                });
                if(res.status === 200){
                    if(type === "xlsx"){
                        downloadFile.handleExportClick(res?.data,dayActiveString + dateInfo);
                    }else if(type === "pdf"){
                        downloadFile.handleGeneratePdf("",res?.data,dayActiveString + dateInfo);
                    }  
                }
            } catch (error) {
                console.error("Error: ", error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    };

    if (!user || user.data.user_role !== "admin") {
        return <Redirect to="/" />;
    }

    return (
        <div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar
                                activePage="u-consignment-dispatch"
                                unprocessedDispatchCounts={totalItemsCount}
                            />
                        </div>

                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="sub-menu mb-0 mb-sm-3">
                                           
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="form-search my-3 my-sm-0">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-6 text-right">

                                        <div className= "">


                                        <div style={{float:"right"}}>
                                           <button type="button" className={!loading  ? "btn btn-default mr-md-2 my-1" : "btn btn-default mr-md-2 my-1 dropdown-disabled" }
                                            onClick={() => !loading  && bookingList?.length ? getXlsxData("pdf") : ""}
                                        > Generate Dispatch Pdf</button>

                                        <button type="button" className={!loading  ? "btn btn-default add_option" : "btn btn-default add_option dropdown-disabled"} 
                                        onClick={() => !loading  && bookingList?.length ? getXlsxData("xlsx") : ""}> Print </button>
                                        </div>


                                    </div>

                                    </div>
                                    </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Reps name{" "}
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">SET</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Status</th>
                                                <th
                                                    scope="col"
                                                    className="text-right"></th>
                                                <th
                                                    scope="col"
                                                    className="text-right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList &&
                                                bookingList.map(item => (
                                                    <tr key={item._id}>
                                                        <td
                                                            width="200"
                                                            className="align-top">
                                                            <Link
                                                                to={
                                                                    "/admin/booking/details/" +
                                                                    item._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item.booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="align-top">
                                                            <div className="">
                                                                {item
                                                                    .assigned_rep
                                                                    .first_name +
                                                                    " " +
                                                                    item
                                                                        .assigned_rep
                                                                        .last_name}
                                                            </div>
                                                        </td>
                                                        <td className="align-top">
                                                            <div className="">
                                                                {
                                                                    item
                                                                        .hospitalId
                                                                        .name
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="align-top">
                                                            <small>
                                                                {item.bookingKitSetId &&
                                                                    item.bookingKitSetId.map(
                                                                        (
                                                                            kset,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                {/* <div><a href="javascript:;" onClick={()=>handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
                                                                                <div>
                                                                                    <span>
                                                                                        {
                                                                                            kset?.setId[0]?.set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </small>
                                                        </td>
                                                        <td className="align-top">
                                                            {dateFormat(
                                                                item.delivery_date,
                                                                "ddd,mmm dS",
                                                            )}{" "}
                                                            {
                                                                item.delivery_date_slot
                                                            }
                                                        </td>
                                                        <td className="align-top">
                                                        Being Dispatched by 
                                                            {item?.booked_by_user?.length > 0 &&
                                                                " "+item.booked_by_user[0].first_name +" " +
                                                                item.booked_by_user[0].last_name
                                                            }
                                                        </td>
                                                        <td className="text-right align-top">
                                                            <div className="d-flex">
                                                            <div className="mr-2"><span>Being</span></div>
                                                            <div ><span>Prepare</span></div>
                                                                {" "}
                                                                {item.status ==
                                                                    "booked" && (
                                                                        <React.Fragment>
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                dispatchModalOpen(
                                                                                    item._id,
                                                                                )
                                                                            }
                                                                            className="btn btn-primary ml-sm-2 btn-sm mr-2">
                                                                            Dispatch
                                                                        </a>

                                                                        <div className="btn btn-default ml-sm-2 btn-sm mr-2"
                                                                        onClick={() => bookingList?.length ? getXlsxData("pdf",item) : ""}
                                                                        > Generate Pdf</div>
                                                                        </React.Fragment>
                                                                    )}
                                                                {item.status ==
                                                                    "dispatch" && (
                                                                        <React.Fragment>
                                                                            <div><span className="capsule green-dim mb-2 mr-2">
                                                                                Dispatched
                                                                            </span></div>
                                                                            
                                                                            <div 
                                                                                className="mr-2 sm-2 ml-2 btn btn-default btn-sm"
                                                                                onClick={() => bookingList?.length ? getXlsxData("pdf",item) : ""}
                                                                            > <span className="generatePdf">Generate Pdf</span></div>
                                                                            
                                                                        </React.Fragment>
                                                                    )}
                                                                    
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            {bookingList && bookingList.length == 0 && loading == true &&                                                    
                                                <div className="loading-state">
                                                    <div className="loading"></div>
                                                </div>                                               
                                            }

                                            {bookingList &&
                                                bookingList.length == 0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="7">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {bookingList && bookingList.length > 0 && (
                                <div>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={50}
                                        totalItemsCount={
                                            totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center mb-5"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Prev"
                                    />
                                    <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                </div>
                            )}

                            <AddDispatch
                                bookingId={bookingId}
                                dispatchModal={dispatchModal}
                                dispatchModalClose={(flag) => dispatchModalClose(flag)}
                            />

                            {/* Dispatch Modal */}
                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <Footer />
        </div>
    );
};
export default TodayBookingDispatch;

