import { repsBookingConstants } from "../constants";

const initialState = { loading: null, lists: null } || {};

export function repsBookings(state = initialState, action) {
  switch (action.type) {
    case repsBookingConstants.GETALLHOSPITALS_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.GETALLHOSPITALS_SUCCESS:
      return {
        loading: false,
        hospitalsList: action.hospitalsList.data,
      };
    case repsBookingConstants.GETALLHOSPITALS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.GETALLREPS_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.GETALLREPS_SUCCESS:
      return {
        loading: false,
        repsList: action.repsList.data,
      };
    case repsBookingConstants.GETALLREPS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.GETKITSETBYKIT_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.GETKITSETBYKIT_SUCCESS:
      return {
        loading: false,
        kitSetList: action.kitSetList.data,
      };
    case repsBookingConstants.GETKITSETBYKIT_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.GETALLBOOKINGS_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.GETALLBOOKINGS_SUCCESS:
      return {
        loading: false,
        bookingList: action.bookingList.data,
        activePage: action.bookingList.page,
        totalItemsCount: action.bookingList.total_count,
        limit: action.bookingList.page_count,
      };
    case repsBookingConstants.GETALLBOOKINGS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.BOOKINGADD_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.BOOKINGADD_SUCCESS:
      return {
        loading: false,
        redirect: true,
      };
    case repsBookingConstants.BOOKINGADD_FAILURE:
      return {
        error: action.error,
        loading: false,
        redirect: false,
      };
    case repsBookingConstants.BOOKINGEDIT_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.BOOKINGEDIT_SUCCESS:
      return {
        loading: false,
        bookingInfo: action.bookingInfo.data,
      };
    case repsBookingConstants.BOOKINGEDIT_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.GETHOSPITALADDRESS_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.GETHOSPITALADDRESS_SUCCESS:
      return {
        loading: false,
        hospitalDetails: action.hospitalDetails.data,
      };
    case repsBookingConstants.GETHOSPITALADDRESS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };

    case repsBookingConstants.CONSIGNMENTLIST_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.CONSIGNMENTLIST_SUCCESS:
      return {
        loading: false,
        getConsignmentRequest: action.getConsignmentRequest.data,
      };
    case repsBookingConstants.CONSIGNMENTLIST_FAILURE:
      return {
        error: action.error,
        loading: false,
      };

    case repsBookingConstants.BOOKINGUPDATE_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.BOOKINGUPDATE_SUCCESS:
      return {
        loading: false,
        redirect: true,
      };
    case repsBookingConstants.BOOKINGUPDATE_FAILURE:
      return {
        error: action.error,
        loading: false,
        redirect: false,
      };

    case repsBookingConstants.BOOKINGSTATUSUPDATE_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.BOOKINGSTATUSUPDATE_SUCCESS:
      return {
        loading: false,
        redirect: true,
      };
    case repsBookingConstants.BOOKINGSTATUSUPDATE_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.SETRETURN_REQUEST:
      return {
        loading: true,
        returnModal: false,
      };
    case repsBookingConstants.SETRETURN_SUCCESS:
      return {
        loading: false,
        returnModal: true,
      };
    case repsBookingConstants.SETRETURN_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        editModal: false,
        modal: false,
        loading: true,
      };
    case repsBookingConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        deleteModal: false,
        refreshList: true,
        editModal: false,
        modal: false,
        loading: false,
      };
    case repsBookingConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        loading: false,
        kitsList: state.kitsList.map((lst) => {
          if (lst._id === action.id) {
            // make copy of user without 'deleting:true' property
            // const { deleting, ...userCopy } = user;
            const { ...lstCopy } = lst;
            // return copy of user with 'deleteError:[error]' property
            return { ...lstCopy, deleteError: action.error };
          }

          return lst;
        }),
      };
    case repsBookingConstants.CONSIGNMENTREQUESTADD_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.CONSIGNMENTREQUESTADD_SUCCESS:
      return {
        loading: false,
        bookingList: action.bookingList.data,
        activePage: action.bookingList.page,
        totalItemsCount: action.bookingList.total_count,
        limit: action.bookingList.page_count,
      };
    case repsBookingConstants.CONSIGNMENTREQUESTADD_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case repsBookingConstants.CONSIGNMENTREQUESTEDIT_REQUEST:
      return {
        loading: true,
      };
    case repsBookingConstants.CONSIGNMENTREQUESTEDIT_SUCCESS:
      return {
        loading: false,
        bookingList: action.bookingList.data,
        activePage: action.bookingList.page,
        totalItemsCount: action.bookingList.total_count,
        limit: action.bookingList.page_count,
      };
    case repsBookingConstants.CONSIGNMENTREQUESTEDIT_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
      case repsBookingConstants.CONSIGNMENTREQUESTUPDATE_REQUEST:
        return {
            loading: true
        };
    case repsBookingConstants.CONSIGNMENTREQUESTUPDATE_SUCCESS:
        return {
            loading: false,
            bookingList: action.bookingList.data,
            activePage: action.bookingList.page,
            totalItemsCount: action.bookingList.total_count,
            limit: action.bookingList.page_count
        };
    case repsBookingConstants.CONSIGNMENTREQUESTUPDATE_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    default:
      return state;
  }
}
