import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
	bookingService,
	returnBookingService,
	collectionBookingService,
} from "../../../../../services";
import { Nav, Accordion } from "react-bootstrap";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${
	month < 10 ? `0${month}` : `${month}`
}${separator}${date < 10 ? `0${date}` : `${date}`}`;

let yesterday = moment(todayDate).subtract(1, "days").format("YYYY-MM-DD");
let lastYear = moment(todayDate).subtract(360, "days").format("YYYY-MM-DD");

const NewDashboardSidebar = (props) => {
	const [requestCounts, setRequestCounts] = useState(props.requestCount);
	const [dispatchCounts, setDispatchCounts] = useState(props.dispatchCount);
	const [returnCounts, setReturnCounts] = useState(props.returnCount);
	const [collectionCounts, setCollectionCounts] = useState(
		props.collectionCount
	);
	const [unprocessedCollectionCounts, setUnprocessedCollectionCounts] =
		useState(props.unprocessedCollectionCounts);
	const [unprocessedReturnCounts, setUnprocessedReturnCounts] = useState(
		props.unprocessedReturnCounts
	);
	const [unprocessedDispatchCounts, setUnprocessedDispatchCounts] = useState(
		props.unprocessedDispatchCounts
	);

	const [today, setToday] = useState(todayDate);

	const pendingCount = () => {
		bookingService
			.countByStatus({
				type: ["consignment"],
				status: ["pending"],
				deliveryDateFiter: "",
				toDeliveryDateFiter: "",
			})
			.then((response) => {
				setRequestCounts(response.data);
			});
	};

	const dispatchCount = () => {
		bookingService
			.countBookingDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["booked", "dispatch"],
				deliveryDateFiter: today,
				toDeliveryDateFiter: today,
				search: "",
				limit: 50,
			})
			.then((response) => {
				setDispatchCounts(response.data);
			});
	};

	const uDispatchCount = () => {
		bookingService
			.uCountDispatch({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["dispatch", "booked"],
				deliveryDateFiter: lastYear,
				toDeliveryDateFiter: yesterday,
				search: "",
				limit: 50,
				isDispatched: true,
			})
			.then((response) => {
				setUnprocessedDispatchCounts(response.data);
			});
	};

	const returnCount = () => {
		returnBookingService
			.countByDate({
				type: ["consignment"],
				status: ["dispatch", "close"],
				returnDateFiter: today,
				toReturnDateFiter: today,
			})
			.then((response) => {
				setReturnCounts(response.data);
			});
	};

	const uReturnCount = () => {
		returnBookingService
			.uCountByDate({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["dispatch", "close"],
				returnDateFiter: lastYear,
				toReturnDateFiter: yesterday,
				search: "",
				limit: 50,
				isReturned: true,
			})
			.then((response) => {
				setUnprocessedReturnCounts(response.data);
			});
	};

	const collectionCount = () => {
		collectionBookingService
			.countByDate({
				type: ["consignment"],
				status: ["dispatch"],
				collectionDateFilter: today,
				toCollectionDateFilter: today,
			})
			.then((response) => {
				setCollectionCounts(response.data);
			});
	};

	const uCollectionCount = () => {
		collectionBookingService
			.uCountByDate({
				bookedByFilter: "",
				hospitalFilter: "",
				type: ["consignment"],
				status: ["dispatch"],
				collectionDateFilter: lastYear,
				toCollectionDateFilter: yesterday,
				search: "",
				limit: 50,
			})
			.then((response) => {
				setUnprocessedCollectionCounts(response.data);
			});

		setUnprocessedCollectionCounts(0);
	};

	const getCount = () => {
		pendingCount();
		dispatchCount();
		returnCount();
		collectionCount();
		uDispatchCount();
		uReturnCount();
		uCollectionCount();
	};

	useEffect(() => {
		setToday(todayDate);
		getCount();
	}, []);

	useEffect(() => {
		if (props?.rejectStatus) {
			pendingCount();
		}
	}, [props?.rejectStatus]);

	useEffect(() => {
		if (props?.unprocessedDispatchCounts) {
			uDispatchCount();
		}
	}, [props?.unprocessedDispatchCounts]);

	useEffect(() => {
		if (props?.unprocessedReturnCounts) {
			uReturnCount();
		}
	}, [props?.unprocessedReturnCounts]);

	useEffect(() => {
		if (props?.unprocessedCollectionCounts) {
			uCollectionCount();
		}
	}, [props?.unprocessedCollectionCounts]);

	return (
		<div>
			<Nav className="list-group mt-4 pr-4" activeKey="/home">
				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage === "request"
										? "active"
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-request"
									eventKey="link-2"
								>
									Booking Requests
									<span className="badge badge-primary badge-pill p-right">
										{requestCounts !== "" &&
											requestCounts > 0 &&
											requestCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Header
							className={
								["dispatch", "u-dispatch"].includes(
									props.activePage
								) == true
									? "active "
									: ""
							}
						>
							Dispatch
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "dispatch"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-dispatch"
									eventKey="link-2"
								>
									consignment
									<span className="badge badge-primary badge-pill p-right">
										{dispatchCounts != "" &&
											dispatchCounts > 0 &&
											dispatchCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Header
							className={
								["return", "u-return"].includes(
									props.activePage
								) == true
									? "active "
									: ""
							}
						>
							Returns
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "return"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-returns"
									eventKey="link-2"
								>
									consignment
									<span className="badge badge-primary badge-pill p-right">
										{returnCounts != "" &&
											returnCounts > 0 &&
											returnCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Header
							className={
								["collection", "u-collection"].includes(
									props.activePage
								) == true
									? "active "
									: ""
							}
						>
							Collections
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "collection"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-collections"
									eventKey="link-2"
								>
									consignment
									<span className="badge badge-primary badge-pill p-right">
										{collectionCounts != "" &&
											collectionCounts > 0 &&
											collectionCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>

				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Header
							className={
								[
									"booking-transfer",
									"equipment",
								].includes(props.activePage) == true
									? "active "
									: ""
							}
						>
							Others
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									"list-group-item d-flex justify-content-between align-items-center " +
									(props.activePage == "equipment"
										? "active "
										: "")
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/equipment-used-report"
									eventKey="link-2"
								>
									Equipment Used Report
								</Nav.Link>
							</Nav.Item>
							<Nav.Item
								className={
									"list-group-item d-flex justify-content-between align-items-center " +
									(props.activePage == "booking-transfer"
										? "active "
										: "")
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-transfer"
									eventKey="link-2"
								>
									{" "}
									Booking Transfer
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<Accordion.Header
							className={
								[
									"u-collection",
									"u-dispatch",
									"u-return",
								].includes(props.activePage) == true
									? "active "
									: ""
							}
						>
							Unprocessed
						</Accordion.Header>
						<Accordion.Body>
							<Nav.Item
								className={
									props.activePage == "u-dispatch"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-dispatch/unprocessed"
									eventKey="link-2"
								>
									Dispatch
									<span className="badge badge-primary badge-pill p-right">
										{unprocessedDispatchCounts != "" &&
											unprocessedDispatchCounts > 0 &&
											unprocessedDispatchCounts}
									</span>
								</Nav.Link>
							</Nav.Item>

							<Nav.Item
								className={
									props.activePage == "u-return"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-returns/unprocessed"
									eventKey="link-2"
								>
									Returns
									<span className="badge badge-primary badge-pill p-right">
										{unprocessedReturnCounts != "" &&
											unprocessedReturnCounts > 0 &&
											unprocessedReturnCounts}
									</span>
								</Nav.Link>
							</Nav.Item>

							<Nav.Item
								className={
									props.activePage == "u-collection"
										? "active "
										: ""
								}
							>
								<Nav.Link
									as={Link}
									to="/admin/dashboard/consignment-collections/unprocessed"
									eventKey="link-2"
								>
									Collections
									<span className="badge badge-primary badge-pill p-right">
										{unprocessedCollectionCounts != "" &&
											unprocessedCollectionCounts > 0 &&
											unprocessedCollectionCounts}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Nav>
		</div>
	);
};
export default NewDashboardSidebar;
