import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Select from "react-select";
import { Input } from "reactstrap";
import Pagination from "react-js-pagination";
import AddOperation from "./Add/Index";
import DeleteOperation from "./Delete/Index";
import EditOperation from "./Update/Index";
import { operationService } from "../../../../services";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Index = () => {
    const [operationList, setOperationList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemCount] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteid, setDeleteId] = useState("");
    const [editid, setEditId] = useState("");
    const [editname, setEditName] = useState("");
    const [editmodal, setEditModal] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [status, setStatus] = useState("");
	const statusOptions = [
		{ value: "", label: "Status" },
		{ value: "Active", label: "Show" },
		{ value: "Hide", label: "Hide" },
	];

    const deleteModalOpen = (id) => {
        setDeleteId(id);
        setDeleteModal(true);
    };
    console.log(deleteid);

    const openEditModal = (id, name) => {
        setEditId(id);
        setEditName(name);
        setEditModal(true);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const getOperation = () => {
        operationService
            .getOperationsListBYLimit({ search: search, page: activePage,status: status })
            .then((res) => {
                console.log(res);
                setTotalItemCount(res.total_count);
                setActivePage(res.page);

                const data = res.data;
                console.log("Data:", data);
                setOperationList(data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error:", error);
            });
    };

    const handleUpdate = (id, name) => {
        setOperationList((prevList) =>
            prevList.map((item) =>
                item._id === id ? { ...item, name: name } : item
            )
        );
    };

    const handleDelete = (id) => {
        setOperationList((prevList) =>
            prevList.filter((item) => item._id !== id)
        );
    };

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
        //getOperation();
    };

    useEffect(() => {
        setLoading(true);
        if (!user || user.data.user_role !== "reps") {
            return;
        }

        getOperation();
    }, [activePage, search]);

    useEffect(() => {
		getOperation();
	}, [status, search, activePage]);


    const handleStatus = (e) => {
		console.log("handleStatus e", e);
		setStatus(e.value);
        setActivePage(1);
	};

    const updateStatus = (id, status) => {
		operationService
			.updateOperationStatus({ status: status, id: id })
			.then(() => getOperation());
	};

    if (!user || user.data.user_role !== "reps") {
        return <Redirect to="/" />;
    }

    return (
        <div>
            <Navbar activePage="operations" />
            <div className="container">
                <div className="pt-4 row align-items-center">
                    <div className="col-md-3">
                        <div className="sub-menu">
                            <h1 className="heading">Operations</h1>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-search">
                            <Input
                                className="form-control mr-sm-2"
                                type="text"
                                placeholder="Search.."
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
						<form className="form-search my-1">
							<Select
								defaultValue={status}
								onChange={handleStatus}
								options={statusOptions}
							/>
						</form>
					</div>
                    <div className="col-md-2 text-right">
                        <AddOperation getOperation={getOperation} />
                    </div>
                </div>
                <div id="table-scroll" className="table-scroll mt-4">
                    <table id="main-table" className="main-table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ minWidth: "50%" }}>
                                    Operation Name
                                </th>
                                <th scope="col">Status</th>
                                <th
                                    scope="col"
                                    className="text-right"
                                    width="100"
                                >
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {operationList.length > 0  && (
                                operationList.map((item) => (
                                    <tr key={item._id}>
                                        <td>
                                            <div className="">
                                                <strong>{item.name}</strong>
                                            </div>
                                        </td>
                                        <td>
											{item.status === "Active" || !item.status
												? "Show"
												: "Hide"}
										</td>
                                        <td className="text-right">
                                            <div className="action-area dropdown">
                                                <a
                                                    className="dropdown-toggle"
                                                    href="#"
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <span className="dots">
                                                        ...
                                                    </span>
                                                </a>
                                                <div
                                                    className="dropdown-menu"
                                                    aria-labelledby="navbarDropdown"
                                                >
                                                    <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            openEditModal(
                                                                item._id,
                                                                item.name
                                                            )
                                                        }
                                                        href="javascript:;"
                                                    >
                                                        Edit
                                                    </a>
                                                    {item.status ===
																	"Active" || !item.status
															 ? (
																<a
                                                                className="dropdown-item"
                                                                onClick={() =>
                                                                    updateStatus(
                                                                        item._id,
                                                                        "Hide"
                                                                    )
                                                                }
                                                                href="javascript:;"
                                                            >
                                                                Hide
                                                            </a>
													 ) : (
														<a
															className="dropdown-item"
															onClick={() =>
																updateStatus(
																	item._id,
																	"Active"
																)
															}
															href="javascript:;"
														>
															Show
														</a>
													)}
                                                    <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            deleteModalOpen(
                                                                item._id
                                                            )
                                                        }
                                                        href="javascript:;"
                                                    >
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}

                            {operationList.length === 0 && loading == false && 
                            (<tr className="text-center">
                                <td colSpan="3">No Record Found</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                {operationList.length > 0 && (
                    <div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={50}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center mb-5"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Prev"
                    />
                    <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                    </div>
                )}
                <DeleteOperation
                    id={deleteid}
                    deleteModalProps={deleteModal}
                    handleDelete={handleDelete}
                    setDeleteModalProps={setDeleteModal}
                />
                <EditOperation
                    id={editid}
                    name={editname}
                    handleUpdate={handleUpdate}
                    editmodal={editmodal}
                    setEditModal={setEditModal}
                />
            </div>
        </div>
    );
};

export default Index;
