export const hospitalConstants = {
    HOSPITALADD_REQUEST: "HOSPITAL_ADD_REQUEST",
    HOSPITALADD_SUCCESS: "HOSPITAL_ADD_SUCCESS",
    HOSPITALADD_FAILURE: "HOSPITAL_ADD_FAILURE",

    HOSPITALEDIT_REQUEST: "HOSPITAL_EDIT_REQUEST",
    HOSPITALEDIT_SUCCESS: "HOSPITAL_EDIT_SUCCESS",
    HOSPITALEDIT_FAILURE: "HOSPITAL_EDIT_FAILURE",

    HOSPITALUPDATE_REQUEST: "HOSPITAL_UPDATE_REQUEST",
    HOSPITALUPDATE_SUCCESS: "HOSPITAL_UPDATE_SUCCESS",
    HOSPITALUPDATE_FAILURE: "HOSPITAL_UPDATE_FAILURE",

    GETALLHOSPITALS_REQUEST: "HOSPITALS_GETALL_REQUEST",
    GETALLHOSPITALS_SUCCESS: "HOSPITALS_GETALL_SUCCESS",
    GETALLHOSPITALS_FAILURE: "HOSPITALS_GETALL_FAILURE",

    DELETE_REQUEST: "HOSPITALS_DELETE_REQUEST",
    DELETE_SUCCESS: "HOSPITALS_DELETE_SUCCESS",
    DELETE_FAILURE: "HOSPITALS_DELETE_FAILURE"    
};
