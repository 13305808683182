import React, {  useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { repsBookingService } from "../../../../../services/repsBooking.services";

const Cancle = (props) => {
    const [deleteComment, setDeleteComment] = useState("");
    const [error, setError] = useState({});
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));


    const cancelModalClose =()=>{
      props.cancelModalClose();
    };

    const deleteCommentHandler =(val)=>{
        setDeleteComment(val);
    };

    const handleCancle = async () => {
        repsBookingService.updateStatus({
            id: props.cancelId,
            delete_comment: deleteComment,
            status: "cancel",
            log_by_id: user.data._id,
        }).then((res)=>{
            console.log(res);
            props.refreshGetList();
           
        }).catch((error)=>{
            setError(error);
        });
    };

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
      
    }, []);

    return (
        <div>
            <Modal
                size="md"
                backdrop="static"
                isOpen={props.cancelModal}
                toggle={() => cancelModalClose()}>
                <ModalHeader
                    className=""
                    toggle={() => cancelModalClose()}>
                    Cancel Booking
                </ModalHeader>
                <ModalBody>
                    <div className="col">
                        <label>
                            Reason Of Cancellation{" "}
                            <strong className="text-danger">*</strong>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={deleteComment}
                            onChange={e =>
                                deleteCommentHandler(e.target.value)
                            }
                        />
                        {error ? (
                            <span className="text-danger">
                                {error?.delete_comment
                                    ? error?.delete_comment
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                        {error?.common ? (
                            <span className="text-danger">
                                {error?.common ? error?.common : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button
                            onClick={() => cancelModalClose()}
                            className="btn btn-secondary mr-2"
                            data-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => handleCancle()}>
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Cancle;
