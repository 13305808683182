import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { consignmentService } from "../../../../../services/consignment.services";
 
// Options for the select box
const dispatchOptions = [
  { value: "partial-dispatch", label: "Partial-Dispatch" },
  { value: "dispatch", label: "Dispatch" },
];
 
const DispatchModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({ value: "dispatch", label: "Dispatch" });
  const [comment, setComment] = useState("");
  const [error, setError] = useState([]);
 
  const modalClose = (flag) => {
    props.toggle(flag);
    setError([]);
    setSelectedStatus({ value: "dispatch", label: "Dispatch" });
    setComment("");
  };
 
  const handleDispatch = async () => {
    setLoading(true);
    const params = {
      id: props.bookingId,
      status: selectedStatus?.value || "",
      comment,
    };
    try {
      const response = await consignmentService.dispatchConsingnment(params);
      console.log("API response:", response);
      modalClose(true);
      props.onReload();
    } catch (error) {
      console.error("API call failed:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
 
  return (
    <Modal
      size="md"
      backdrop="static"
      centered
      isOpen={props.isOpen}
      toggle={() => modalClose(false)}
    >
      <ModalHeader className="" toggle={() => modalClose(false)}>
        Dispatch
      </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to Dispatch?</p>
        <div className="mb-3">
          <label htmlFor="dispatchStatus">
            Dispatch Status <span className="text-danger">*</span>
          </label>
          <Select
            id="dispatchStatus"
            value={selectedStatus}
            onChange={setSelectedStatus}
            options={dispatchOptions}
            placeholder="Select status"
          />
          {error.status && <span className="text-danger">{error.status}</span>}
        </div>
        <div className="mb-3">
          <label htmlFor="comment">
            Comment <span className="text-danger">*</span>
          </label>
          <textarea
            id="comment"
            className="form-control"
            rows="3"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {error.comment && (
            <span className="text-danger">{error.comment}</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="">
          <button
            onClick={() => modalClose(false)}
            className="btn btn-secondary mr-2"
          >
            Cancel
          </button>
          {!loading && (
            <button className="btn btn-primary" onClick={handleDispatch}>
              Ok
            </button>
          )}
          {loading && (
            <button className="btn btn-primary loader-icon" disabled>
              <Spinner className="img-svg"></Spinner>
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
 
export default DispatchModal;