import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class KitNavbar extends Component {
    render() {
        return (
            <div>
                <ul className="sub-menu">
                    <li><Link to="/admin/categories" className={"kit-navbar "+(this.props.activePage == "categories" ? "active" : "")}>Kits</Link></li>
                    <li><Link to="/admin/products" className={"kit-navbar "+(this.props.activePage == "products" ? "active" : "")}>Products</Link></li>
                    <li><Link to="/admin/kits" className={"kit-navbar "+(this.props.activePage == "sets" ? "active" : "")}>Sets</Link></li>    
                </ul> 
            </div>
        );
    }
}