import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input} from "reactstrap";
import { bookingActions } from "../../../../../../../actions";
import { ReactComponent  as Spinner } from "../../../../../../../assets/images/Spinner-1s-200px.svg";

class Collection extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            updateBookingInfo: {
                id: "",
                hospitalId: "",
                hospital_address: "",
                po_number: "",
                operation_name: "",
                shipping_charge: "",
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date:"",
                delivery_date_slot:"",
                operation_date:"",
                operation_date_slot:"",
                collection_date:"",
                booking_type:"",
                kitData:[],
                kitSetData:[],
                return_date:""
            },
            collectionData: {
                courier: "All Aces",
                return_date: "",
                additional_note: "",
                returnBoxesData: [],
                returnSetData: [],
                bookingId: "",
                log_status:"returned",
                log_by_id:"",
                priority_level: "Normal",
                comment: ""
            },
            showDateInput: true,
            boxArray: [{box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false}],
            returnKit: [],
            collectionModal: this.props.collectionModal,
            returnSubmitFlag:0,
            collectionSetDataLength:0,
            kitWithKitSet:[],
            priorityOptions: [
                { label: "Normal", value: "Normal" },
                { label: "High", value: "High" },
            ],
            selectedPriority: [{ label: "Normal", value: "Normal" }],
            boxOptions: [
                { label: "Black Box", value: "Black Box" },
                { label: "Blue Box", value: "Blue Box" },
                { label: "Grey case", value: "Grey case" },
                { label: "Cardboard Box", value: "Cardboard Box" }
            ],
            selectedNumOfBox: [{ label: "Black Box", value: "Black Box" }],
            options: [
                { label: "All Aces", value: "All Aces" },
                { label: "APC", value: "APC" },
                {label : "Sameday (HBC)", value: "Sameday (HBC)"},
                { label: "Other", value: "Other" },
            ],
            selectedOption: [{ label: "All Aces", value: "All Aces" }]
        };
     
        this.getSingleBooking = this.getSingleBooking.bind(this);
     
        this.handleCollection = this.handleCollection.bind(this);
        this.collectionModalClose = this.collectionModalClose.bind(this);
        this.collectionModalOpen = this.collectionModalOpen.bind(this);
        this.handleCourier = this.handleCourier.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handlePriorityChange = this.handlePriorityChange.bind(this);
        this.submitData = this.submitData.bind(this);
    }

    handleCommentChange = (e) => {
        this.setState({
            collectionData: {
                ...this.state.collectionData,
                comment: e
            },
        });
    };

    handlePriorityChange = (e) => {
        let value = e.value;
        //console.log("priority val,", value);
        var collectionData = this.state.collectionData;
        collectionData.priority_level = value;
        this.setState({
            collectionData: collectionData,
        });
        this.setState({ e });
    };

    getSingleBooking(id) {
        this.props.dispatch(bookingActions.getById(id));
    }


    handleCollection(){
        this.setState({
            collectionModal: true,
            collectionSubmitFlag:0,
            collectionSetDataLength:0
        });
    }


    collectionModalOpen(bookingId){
        this.setState({
            collectionModal:true,
            bookingId:bookingId,
            collectionSubmitFlag:0,
        });
        this.getSingleBooking(bookingId);

    }
    
    collectionModalClose(){
        const { kitWithKitSet } =  this.state;
     
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                set.isreturn =false;
                 
            });
        });


        this.setState({
            collectionModal: false,
            collectionData: {
                courier: "All Aces",
                collectionBoxesData: [],
                collectionSetData: [],
                bookingId: "",
                log_status:"collected",
                log_by_id:"",
                priority_level: "Normal",
                comment: ""
            },
            boxArray: [{box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false}],
            kitWithKitSet:kitWithKitSet
        });

        this.props.collectionModalClose();
    }

    collectionSuccess(){
        const { kitWithKitSet } =  this.state;
     
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
                set.isreturn =false;
                 
            });
        });


        this.setState({
            collectionModal: false,
            collectionData: {
                courier: "All Aces",
                collectionBoxesData: [],
                collectionSetData: [],
                bookingId: "",
                log_status:"collected",
                log_by_id:"",
                priority_level: "Normal",
                comment: ""
            },
            boxArray: [{box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false}],
            kitWithKitSet:kitWithKitSet
        });

        this.props.collectionSuccess();
    }

    handleCourier(val) {
        const { collectionData } =  this.state;
        collectionData.courier = val.value;
        this.setState({
            collectionData
        });
    }

    handleSet(val, outerIndex, innerIndex) {
        console.log(val,outerIndex,innerIndex);
    }

    handleBoxType(index, val) {
        const { boxArray } = this.state;
        boxArray[index].box_type = val.value;
        this.setState({
            boxArray
        });
    }

    handleBoxNumber(index, val) {
        const { boxArray } = this.state;
        boxArray[index].number_of_box = val;
        boxArray[index].numberOfBoxFlag = false;
        this.setState({
            boxArray
        });
    }

    handleBoxAdd(e) {
        e.preventDefault();
        const { boxArray } = this.state;
        boxArray.push({ box_type: "Black Box", number_of_box: "",numberOfBoxFlag:false });
        this.setState({
            boxArray
        });
    }

    deleteAddBox(e, index){
        e.preventDefault();
        const { boxArray } = this.state;
        var deleteBox = boxArray;
        deleteBox.splice(index, 1);
        this.setState({
            boxArray: deleteBox
        });
    }

    submitData() {
        const { kitWithKitSet, collectionData} = this.state;
        var falg=0; 
      
        var setArray = [];
        kitWithKitSet.map((kit) => {
            kit.sets.map((set) => {
               
                if(set.isreturn && set.added==1 && set.status!="close") {
                    setArray.push({"bookingKitSetId": set._id});
                }
            });
        });

        collectionData.collectionBoxesData = this.state.boxArray;
        collectionData.collectionSetData = setArray;
        collectionData.bookingId = this.state.bookingId;
        collectionData.log_status="collected";
        collectionData.log_by_id=this.state.user.data._id;
       

        this.state.boxArray.map((item)=>{
            if(item.box_type=="" || item.number_of_box=="")
            {
                falg=1;
            }

            if(item.number_of_box=="")
            {
                item.numberOfBoxFlag=true;
            }

        });

        if(collectionData.collectionSetData.length==0)
        {
            falg=1;
        }

        
         
        this.setState({
            collectionSubmitFlag:1,
            collectionSetDataLength:collectionData.collectionSetData.length
        });
        
        if(collectionData.courier== "" )
        {
            this.setState({
                collectionData
            }, () => {
                this.props.dispatch(bookingActions.saveCollections(this.state.collectionData));
            });
        }
        else if(falg==0)
        {
            this.setState({
                collectionData
            }, () => {
                this.props.dispatch(bookingActions.saveCollections(this.state.collectionData));
            });
        }
    }
   
    setBookingData(data) {
        this.setState({
            kitWithKitSet:[]
        });
        data.bookingKitId.map((item)=> {
           
            this.props.dispatch(bookingActions.getKitSetByKit({kitId: item.kitId}));
        });
    }

    showKitsSetList(data){
        const { kitWithKitSet } =this.state;
        var subKitSet=[]; 
        var lnt=0; 
        var hire_charge="";
        var status="";

        data[0]["kitsets"].map((kset)=>{
            hire_charge="";
            status="";
            lnt=data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == this.state.bookingId ).length;
            if(lnt>0)
            {
                data[0].bookingKitSets.filter(rs => rs.setId === kset._id && rs.bookingId == this.state.bookingId).map((result)=>{
                    hire_charge=result.hire_charge;
                    status=result.status;
                });
               
            }
            subKitSet.push({set_name:kset.set_name,_id:kset._id,hire_charge:hire_charge,added:lnt,status:status, isreturn: true});
        });

        kitWithKitSet.push({name:data[0].name,_id:data[0]._id,sets:subKitSet});
        this.setState({
            kitWithKitSet
        });
    }


    redirectPage(){
        this.setState({
            redirect:true
        });
        
    }


  

    componentDidMount(){
        //this.getSingleBooking(this.props.match.params.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
       
        if(nextProps.bookingInfo !== undefined) {
            this.setBookingData(nextProps.bookingInfo);
        }

        if(nextProps.kitSetList !== undefined ) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if(nextProps.collectionModal !== undefined && nextProps.collectionModal==true && this.state.collectionModal==false) {
            this.collectionModalOpen(nextProps.bookingId);
        }

        if(this.state.collectionModal==true && nextProps.collectionList == true) {
            this.collectionSuccess();
        }
             
    }

    render() {
        const { error, loading } = this.props;
        const { priorityOptions, selectedPriority} = this.state;
        const { boxOptions, selectedNumOfBox } = this.state;
        const { options, selectedOption } = this.state;


        return (
            <div>

                <Modal size="md" backdrop="static" isOpen={this.state.collectionModal} toggle={() => this.collectionModalClose()}>
                    <ModalHeader charcode="Y" toggle={() => this.collectionModalClose()}>
                        Add Collection
                    </ModalHeader>
                    <ModalBody>
                        <form className="">

                        <div className="form-group position_up" style={{zIndex:this.state.boxArray.length+10000}}>
                            <label>Courier</label>
                            <Select
                                defaultValue={selectedOption}
                                onChange={(e) => this.handleCourier(e)}
                                options={options}
                            />
                            {error &&
                            this.state.collectionSubmitFlag == 1 ? (
                                <span className="text-danger">
                                    {error.courier
                                        ? error.courier
                                        : ""}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>

                            <div className="form-group" style={{"display":"none"}}>
                                <label>Collected Sets</label>
                                {this.state.kitWithKitSet && this.state.kitWithKitSet.map((kit,index)=>(
                                    <React.Fragment  key={index}>
                                        {kit.sets.map((setd, ind)=>(
                                            <React.Fragment  key={ind}>
                                                {setd.added==1 && setd.status!="close" && 
                                                    <div className="form-check" key={ind}>
                                                       
                                                        <Input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked = {setd.isreturn}
                                                            onChange={(e) => this.handleSet(e.target.checked, index, ind)}
                                                        />
                                                        <label className="form-check-label" htmlFor="return_set">{setd.set_name}</label>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                                {(this.state.collectionSubmitFlag==1 && this.state.collectionSetDataLength==0) ? <span className="text-danger">Please check at least one item</span> : ""} 

                               
                            </div>
                            <label>Number of Boxes</label>
                            { this.state.boxArray.map((boxItem, index) => (            
                                <div className="form-group row align-items-top" 
                                    key={index}>
                                    <div className="col-5" style={{zIndex:(this.state.boxArray.length-index + 9999)}}>      
                                        <Select
                                            defaultValue={selectedNumOfBox}
                                            onChange={(e) => this.handleBoxType(index,e)}
                                            options={boxOptions}
                                            placeholder="Box Type"
                                        />        
                                 
                                        {boxItem.box_type=="" && this.state.collectionSubmitFlag==1 ? <span className="text-danger">Please select box type</span> : ""}
                                    </div>
                                    <div className="col-5 col-sm-6">              
                                        <Input 
                                            type="number" 
                                            className="form-control" 
                                            placeholder="Number of Boxes"
                                            min="0"
                                            onChange={(e) => this.handleBoxNumber(index, e.target.value)}
                                            value={boxItem.number_of_box}
                                        />
                                        {boxItem.number_of_box=="" && this.state.collectionSubmitFlag==1 && boxItem?.numberOfBoxFlag ? <span className="text-danger">Please enter number of boxes</span> : ""}
                                    </div>
                                    { index > 0 &&
                                        <div className="col-1 text-center">
                                            <a className="ml-2" onClick={(e) => this.deleteAddBox(e, index)} href="#"><i className="fa fa-trash"></i></a>
                                        </div>
                                    }
                                    
                                </div>

                            ))}
                            <a href="#!" className="sml-btn" onClick={(e) => this.handleBoxAdd(e)}><i className="fa fa-plus my-2"></i> Add More</a> 
                            <div className="form-group">
                                <label>Priority Level</label>
                                <Select
                                                defaultValue={selectedPriority}
                                                onChange={(e) => this.handlePriorityChange(e)}
                                                options={priorityOptions}
                                            />
                                {/* {this.state.collectionSubmitFlag==1 && error  ? <span className="text-danger">{error.courier ? error.courier : ""}</span> : ""} */}

                            </div>
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    value={this.state.collectionData.comment}
                                    onChange={(e) => this.handleCommentChange(e.target.value)}
                                ></textarea>
                                {/* {this.state.collectionSubmitFlag==1 && error  ? <span className="text-danger">{error.courier ? error.courier : ""}</span> : ""} */}

                            </div>
                            
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button className="btn btn-secondary mr-2" onClick={this.collectionModalClose}>Cancel</button>
                            {this.state.kitWithKitSet && this.state.kitWithKitSet.length>0 && loading==false && 
                                <button className="btn btn-primary" onClick={this.submitData}>Submit</button>
                            }

                            {this.state.kitWithKitSet && this.state.kitWithKitSet.length>0 && loading==true && 
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                            }
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Return Details Modal */}


            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingInfo,  error, kitSetList, redirect, collectionList, loading} = state.rootReducer.bookings;

    return {
        bookingInfo,
        kitSetList,
        error,
        redirect,
        collectionList,
        loading
      
    };
}

export default connect(mapStateToProps)(Collection);