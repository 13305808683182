import { operationConstants } from "../constants";
import { operationService } from "../services";

export const operationActions = {
    getOperationsList
};

function getOperationsList(search) {
    return dispatch => {
        dispatch(request());

        operationService.getOperationsList(search)
            .then(
                list => dispatch(success(list)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: operationConstants.GETALLOPERATIONSLIST_REQUEST }; }
    function success(lists) { return { type: operationConstants.GETALLOPERATIONSLIST_SUCCESS, lists }; }
    function failure(error) { return { type: operationConstants.GETALLOPERATIONSLIST_FAILURE, error }; }
}