import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../Navbar";
import { Button, Spinner } from "reactstrap";
import { repsBookingService } from "../../../../../services";
import BasicDetails from "./BasicDetails/Index";
import MyKitPage from "./KitSection/Index";
import dateFormat from "dateformat";

const CombinedForm = () => {
  const [bookingInfo, setBookingInfo] = useState({});
  const [kitData, setKitData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const history = useHistory();

  const handleSaveBasicDetails = (data) => {
    console.log("data save", data);
    setBookingInfo(data);
  };

  const handleSaveKitData = (data) => {
    setKitData(data);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setValidationErrors({});
    try {
      const info = {
        hospitalId: bookingInfo.hospitalId,
        hospital_address: bookingInfo.hospital_address,
        hospital_department: bookingInfo.hospital_department,
        kitData: kitData,
        surgeonData: bookingInfo.surgeonData,
        pickup_location_checkbox: bookingInfo.pickup_location_checkbox,
        pickup_location_text: bookingInfo.pickup_location_text,
        note: bookingInfo.note,
        delivery_date: bookingInfo.delivery_date ? dateFormat(bookingInfo.delivery_date, "yyyy-mm-dd"):"",
      };

      await repsBookingService.createConsignmentRequest(info);
      console.log("Consignment Request successfully created");
      history.push("/reps/consignment-request");
    } catch (error) {
      console.log("ssss", error);
      setValidationErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid px-5">
        <h1 className="date-scroll pt-4">
          <Link to="/reps/consignment-request">
            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
            <strong>Back</strong>
          </Link>
        </h1>
        <div className="row pt-4 mobile_nocard">
          <div className="col-md-6">
            <BasicDetails
              onSave={(data) => handleSaveBasicDetails(data)}
              validationErrors={validationErrors}
            />
          </div>
          <div className="col-md-6">
            <MyKitPage
              onSave={(data) => handleSaveKitData(data)}
              validationErrors={validationErrors}
              setValidationErrors={(err) => setValidationErrors(err)}
            />
            <div className="col-sm-12 text-right mt-4">
              <Link
                to="/reps/consignment-request"
                className="btn btn-secondary"
              >
                Cancel
              </Link>
              <Button
                color="primary ml-2"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? <Spinner size="lg" /> : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedForm;
